import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pvo-results',
  templateUrl: './pvo-results.component.html',
  styleUrls: ['./pvo-results.component.css']
})
export class PvoResultsComponent implements OnInit {

  @Input() app;

  @Output() deleteApp = new EventEmitter<any>();
  @Output() editAppType = new EventEmitter<any>();

  loading: any;
  isCollapsed = true;
  showTestLocation: any;

  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        console.log(res);
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
              .subscribe((res: any) => {
              // let certifLink = res.data.certificateDownLoadUrl;
              // window.open(certifLink, "_blank");

              window.location.href = res.data.certificateDownLoadUrl;

              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  showEditEnt(id: number, appTypeId: number){
    this.router.navigate(['profile/history/edit/pvo/' + appTypeId], { queryParams: { id: id, appType: appTypeId} });
  }

  showTestDate(id: any){
    this.api.showedDate(id)
      .subscribe((res: any) => {
        this.showTestLocation = true;
      }, (err: any) => {
        this.loading = false;
    });
    
  }

  showPayment(id: number){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 4} });
  }

  showDeleteModal(){
    this.deleteApp.emit();
  }

  showAppTypeModal(){
    this.editAppType.emit();
  }

  

}