import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-e-sertif-ib',
  templateUrl: './e-sertif-ib.component.html',
  styleUrls: ['./e-sertif-ib.component.css']
})
export class ESertifIbComponent implements OnInit {

  title = 'fileUpload';
  files: any[];
  currentFile: any;
  shortData: any;

  

  seasonId: any;
  loading = false;
  appTypeId = 16;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectList = [];
  langList = [];
  testOrgList = [];
  mathSubjects = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts = '0.0';
  year = '0000';
  regNumber = '000000000';
  ball = '0000';
  applicationAgree = false;
  collegeSpecialityList = [];
  eduProgramList = [];
  specialDisciplines = [];
  form: FormGroup;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  applicationId: any;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private fb: FormBuilder,
    private _notifications: NotificationsService,
    public translate: TranslateService) { 
      // this.form = this.fb.group({
      //   name: '',
      //   subjects: this.fb.array([])
      // });
  }

  

  

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');

    this.api.getShortInfoIb(this.applicationId)
      .subscribe((res: any) =>{
        this.shortData = res.data;
        // this.getProfileSubjectPair();
        this.addSubjects();
        this.getForeignLangCertList();
        this.applicationForm.get('registrationNumber').setValue(this.shortData.registrationNumber);
        this.applicationForm.get('mathSubjectId').setValue(this.shortData.mathSubject.id); 
        this.applicationForm.get('mathSubjectScore').setValue(this.shortData.mathSubject.score); 
        this.applicationForm.get('testDate').setValue(this.shortData.testDate); 
        this.applicationForm.get('profileSubjectPairId').setValue(this.shortData.profileSubjectPairId)
        this.applicationForm.get('subjects').setValue(this.shortData.subjects); 
        
      }, (err: any) => {

    });

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        if(res.data.sexId == 0){
          this.canEditSexId = true; 
        }
        if(res.data.nationId == 0 || res.data.nationId == 99){
          this.canEditNationId = true;
        }
        if(res.data.citizenshipId == 0 || res.data.citizenshipId == 99){
          this.canEditCitizenshipId = true;
        }
        if(res.data.phoneNumber == 0){
          this.canEditPhoneNumber = true;
        }

        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);

        

      });
      // Инициализация формы
      this.applicationForm = new FormGroup({
        phoneNumber: new FormControl(),
        reasoningScore: new FormControl(0),
        foreignLangCertificateTypeId: new FormControl(0),
        foreignLangCertificateScore: new FormControl('0'),
        registrationNumber: new FormControl(0, Validators.required),
        testDate: new FormControl(0, Validators.required),
        foreignLangCertificateDate: new FormControl(0, Validators.required),
        mathSubjectId: new FormControl(0, Validators.required),
        mathSubjectScore: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0, Validators.required),
        subjects: new FormArray ([])
        
      });

      for(let i = 0; i < 2; i++){
        this.subjects.push(this.subject());
        // console.log(this.subjects.controls);
      }



      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }
      
      // this.getForeignLangCertList();
      this.getIBMathSubjects();

      console.log(this.applicationForm.value);
  }

  addSubjects(){
    console.log(123);
    for(let i = 0; i < 2; i++){
      this.subjects.push(this.subject());
      this.subjects.controls[i].get('id').setvalue
      this.subjects.controls[i].get('score').setvalue
      // console.log(this.subjects.controls);
    }
  }

  subject(): FormGroup {
    return new FormGroup({
      id: new FormControl(),
      score: new FormControl()
    });
  }

  get subjects() : any { return this.applicationForm.get('subjects')}

  formateDate(){
    this.applicationForm.get('testDate').setValue(this.datepipe.transform(this.applicationForm.get('testDate').value, 'dd-MM-yyyy'));
  }

  getForeignLangCertList(){
    this.api.getIbAppForeignLangCert()
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
        this.applicationForm.get('foreignLangCertificateScore').setValue(this.shortData.foreignLangCertificate.score);
        this.applicationForm.get('foreignLangCertificateTypeId').setValue(this.shortData.foreignLangCertificate.typeId);
        this.applicationForm.get('foreignLangCertificateDate').setValue(this.shortData.foreignLangCertificate.date);
        this.checkSertif(this.shortData.foreignLangCertificate.typeId);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getIBMathSubjects(){
    this.api.getMathSubjects()
      .subscribe((res: any) => {
        this.mathSubjects = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }



  

  checkSertif(id){
    if(id == 4){
      this.foreignLangCertScoreMask = '0.0';
    }
    if(id !=4){
      this.foreignLangCertScoreMask = '000';
    }
    if(id == 0){
      // this.applicationForm.get('foreignLangCertScore').patchValue('0');
    }
  }

  scoreCheck(){
    if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);  
    }
  }

  postApplication(){
    this.scoreCheck();
    this.loading = true;
    this.api.postApplicationEnt(this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        if(res.data.paymentType.id == 2){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id } });
        }else{
          this.router.navigateByUrl('/profile/history');
        }
      },(err: any) =>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

}