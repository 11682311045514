import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  // phone = '+0 (000) 000-00-00'
  // iin = '000000000000';

  registrationEnd = false;
  samePass: any;
  loading = true;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.initRegForm();
    setTimeout(() => {
      this.loading = false;
      }, 100);
  }

  checkPass(){
    if(this.registrationForm.get('password').value == this.registrationForm.get('confirmpassword').value){
      this.samePass = true;
    } else {
      this.samePass = false;
    }
  }

  register(){
    if(this.registrationForm.valid){
      let formData = this.registrationForm.value;
      this.loading = true;
      this.api.register(formData)
        .subscribe((res: any) => {
          this.loading = false;
          this.registrationEnd = true;
        },(err: any) => {
          console.log(err);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          this.loading = false;
        });
      
    }
  }

  initRegForm() {
    this.registrationForm = this.fb.group({
      // lastname: [""],
      // firstname: [""],
      // patronymic: [""],
      // iin: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      // phoneNumber: ["", Validators.required],
      // password: ["", Validators.required],
      // confirmpassword: ["", Validators.required]
    });
  }


  // get isValid(): boolean {
  //   if (this.registrationForm.get('lastname').status === 'INVALID' && this.registrationForm.get('firstname').status === 'INVALID' && this.registrationForm.get('patronymic').status === 'INVALID') return true;
  // }

  // get confirmpassword() { return this.registrationForm.get('confirmpassword'); }
  // get iinCheck() { return this.registrationForm.get('iin'); }
  get emailCheck() { return this.registrationForm.get('email'); }
  // get phoneCheck() { return this.registrationForm.get('phoneNumber'); }
  // get passCheck() { return this.registrationForm.get('password'); }

}
