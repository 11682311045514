import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-perevodniki',
  templateUrl: './e-perevodniki.component.html',
  styleUrls: ['./e-perevodniki.component.css']
})
export class EPerevodnikiComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts = '0.0';
  applicationAgree = false;
  applicationId: any;
  appShortInfo: any;

  disableEditCitizenshipId = false;
  disableEditSexId = false;
  disableEditNationId = false;
  disableEditPhoneNumber = false;
  disableEditOblId = false;
  disableEditRaionId = false;
  dopSertifsList: any;
  disableEditSchoolId = false;
  dataofbday = 0;
  showParentData = false;
  

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.getApplicationInfo();
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        if(res.data.sexId != 0){
          this.applicationForm.get('sexId').disable();
        }
        if(res.data.nationId != 0 || res.data.nationId != 99){
          this.applicationForm.get('nationId').disable();
        }
        if(res.data.citizenshipId != 0 || res.data.citizenshipId != 99){
          this.applicationForm.get('citizenshipId').disable();
        }
        if(res.data.phoneNumber != 0){
          this.applicationForm.get('phoneNumber').disable();
        }
        this.applicationForm.get('sexId').patchValue(res.data.sexId);
        this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        
        this.getDateOfBirth(res.data.birthDate);

      });
      // Инициализация формы
      this.applicationForm = new FormGroup({
        seasonId: new FormControl(0),
        appTypeId: new FormControl(this.appTypeId),
        citizenshipId: new FormControl(0),
        nationId: new FormControl(0),
        foreignLangCertTypeId: new FormControl(0),
        foreignLangCertScore: new FormControl(0),
        phoneNumber: new FormControl(),
        birthDate: new FormControl(0),
        parentFullname: new FormControl(0),
        parentPhoneNumber: new FormControl(0),
        sexId: new FormControl(),
        anotherExamTypeId: new FormControl(0),
        oblId: new FormControl(0),
        raionId: new FormControl(0),
        schoolId: new FormControl(0),
        profileSubjectPairId: new FormControl(0),
        testLangId: new FormControl(0),
        testOrgId: new FormControl(0),
        testPeriodId: new FormControl(0),
      });
      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }
      
  }

  getSertifs(){
    this.api.getDopSertifs(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.dopSertifsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getNumberOfDays(start, end) { 
    const date1 = new Date(start); 
    const date2 = new Date(end); 
    
    // One day in milliseconds 
    const oneDay = 1000 * 60 * 60 * 24; 
    
    // Calculating the time difference between two dates 
    const diffInTime = date2.getTime() - date1.getTime(); 
    
    // Calculating the no. of days between two dates 
    const diffInDays = Math.round(diffInTime / oneDay); 
    
    return diffInDays; 
    } 

  getDateOfBirth(date: any){
    let today = new Date();
    this.dataofbday = this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
    if(this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()) < 6574){
      this.showParentData = true;
    }else{
      this.showParentData = false;
    }
  }

  getApplicationInfo(){
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.appShortInfo = res.data;
        this.seasonId = this.appShortInfo.seasonId;
        this.getNationList();
        this.getCountryList();
        this.getForeignLangCertList();
        this.getOblList();
        this.getSubjectPair();
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);
        this.applicationForm.get('parentFullname').patchValue(this.appShortInfo.parentFullname);
        this.applicationForm.get('parentPhoneNumber').patchValue(this.appShortInfo.parentPhoneNumber);
        this.getSertifs();
        this.applicationForm.get('anotherExamTypeId').patchValue(this.appShortInfo.anotherExamTypeId);
        // this.applicationForm.get('citizenshipId').patchValue(this.appShortInfo.citizenshipId);
        // this.applicationForm.get('nationId').patchValue(this.appShortInfo.nationId);
        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.appShortInfo.foreignLangCertTypeId);
        this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
        // this.applicationForm.get('sexId').patchValue(0);
        // this.applicationForm.get('sexId').setValue(this.appShortInfo.sexId);
        // console.log(this.appShortInfo.sexId);
        this.applicationForm.get('oblId').patchValue(this.appShortInfo.oblId);
        // this.applicationForm.get('phoneNumber').patchValue(this.appShortInfo.phoneNumber);
        this.applicationForm.get('profileSubjectPairId').patchValue(this.appShortInfo.profileSubjectPairId);
        this.applicationForm.get('schoolId').patchValue(this.appShortInfo.schoolId);
        this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);

        this.getLangList();
        this.getTestDate();


      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getNationList(){
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList(){
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList(){
    this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getOblList(){
    this.api.getOblEnt(this.seasonId, this.appTypeId, this.applicationId)
      .subscribe((res: any) => {
        this.applicationForm.get('oblId').setValue(0);
        this.applicationForm.get('raionId').setValue(0);
        this.applicationForm.get('schoolId').setValue(0);
        this.oblList = res.items;
        this.getEduInfo();
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getRaionList(){
    this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationId)
      .subscribe((res: any) => {
        this.raionList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSchoolList(){
    this.api.getSchoolEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('raionId').value, this.applicationId)
      .subscribe((res: any) => {
        this.schoolList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getEduInfo(){
    this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if(res.data.oblId != 0){
          this.applicationForm.get('oblId').setValue(res.data.oblId);
          this.applicationForm.get('oblId').disable();
          this.getRaionList();
          if(res.data.raionId != 0){
            this.applicationForm.get('raionId').setValue(res.data.raionId);
            this.applicationForm.get('raionId').disable();
            this.getSchoolList();
            if(res.data.schoolId != 0){
              this.applicationForm.get('schoolId').disable();
              this.applicationForm.get('schoolId').setValue(res.data.schoolId);
            }
          }
        }
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSubjectPair(){
    this.api.getProfileSubjectEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.subjectPairList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(){
    this.api.getLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.loading = false;
        this.langList = res.items;
        this.applicationForm.get('testLangId').patchValue(this.appShortInfo.testLangId);
      },(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestOrg(){
    this.api.getTestOrgEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate(){
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, this.applicationId)
      .subscribe((res: any) => {
        this.testDateList = res.items;
        this.applicationForm.get('testPeriodId').patchValue(this.appShortInfo.testPeriodId);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id){
    if(id == 4){
      this.foreignLangCertScoreMask = '0.0';
    }
    if(id !=4){
      this.foreignLangCertScoreMask = '000';
    }
    if(id == 0){
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck(){
    if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);  
    }
  }

  EditApplication(){
    this.loading = true;
    this.scoreCheck()
    this.api.postEditApplicationEnt(this.applicationForm.value, this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.router.navigateByUrl('/profile/history');
      },(err: any) =>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

}