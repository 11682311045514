<!-- <div *ngIf="router.url == '/profile/applications/1'" class="mb-4 flex bg-red-200 p-4">
    <button class="znak-blue mr-4">
        !
    </button>
    <div class="text-red-500 font-bold">
        {{ 'NQTdopInfo' | translate }}
    </div>
 </div> -->


<div *ngIf="router.url == '/profile/applications/1'" class="flex flow">

<!--    <div class="no-data">-->
<!--        <span *ngIf="translate.currentLang == 'kz'">-->
<!--            <p><strong>Хабарландыру</strong></p>            -->
<!--            <div class="msg">-->
<!--                <p>2024 жылғы 17-20 сәуір аралығында  педагогтердің білімін бағалауға (бұдан ары - тестілеу) біліктілік санаттарын арттыратын және растайтын педагогтер үшін өтініш қабылдау басталады. </p>-->
<!--                <p>Өтініш қабылдау <b>app.testcenter.kz</b> сілтемесі арқылы жүргізіледі. </p>-->
<!--                <p>Тестілеу 18 сәуір мен 13 мамыр аралығында өтеді.</p>-->
<!--            </div>-->
<!--    -->
<!--        </span>-->
<!--        <span *ngIf="translate.currentLang == 'ru'">-->
<!--            <p><strong>Объявление!</strong></p>-->
<!--            <div class="msg">-->
<!--                <p>С 17 по 20 апреля 2024 года начнется прием заявлений на оценку знаний педагогов (далее - тестирование) для повышения и подтверждения категорий.</p>-->
<!--                <p>Прием заявлений на тестирование осуществляется по ссылке  <b>app.testcenter.kz.</b></p>-->
<!--                <p>Тестирование будет проводиться с 18 апреля по 13 мая текущего года.</p>-->
<!--            </div>-->
<!--    -->
<!--        </span>-->
<!--    </div>-->

    <div *ngFor="let appType of appTypeList; let i = index" [routerLink]="this.router.url + '/' + appType.id" [queryParams]="{jobType: appType.jobType.id}"
        class="w-full">
        <div class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
            <span *ngIf="translate.currentLang == 'kz'">{{ appType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ appType.name.ru }}</span>
        </div>

        <div *ngIf="appTypeList.length-1 != i" [ngClass]="{'border-b-2 border-black p-2 m-2': appType.isLast}" class="">

        </div>
    </div>

    <div class="mt-2 w-full text-center">
        <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700"
            href="./../../../../../assets/inst_rus.pdf" target="_blank">
            <p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsNqt' | translate }}</p>
        </a>
        <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700"
            href="./../../../../../assets/inst_kaz.pdf" target="_blank">
            <p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsNqt' | translate }}</p>
        </a>
    </div>

    <p class="mb-2">
        {{ 'OrgTestQuestionsNQT' | translate }} : 8 (7172) 69 52 63, 8 (7172) 69 52 88
    </p>

</div>

<router-outlet *ngIf="router.url != '/profile/applications/1'"></router-outlet>
