import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class orleuConfig {
  backend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
        this.backend = 'https://app.debug.testcenter.kz/orleu/student/app/api/v1';


    }
    if (!isDevMode()) {
        this.backend = 'orleu/student/app/api/v1'; // Путь для боевого

    }

    this.apiRoutes = { // ссылки для запросов
      appType: '/app-type/items/',
      appItems: this.backend + '/app/items/test-data',
      eduItems: this.backend + '/app/edu-type/items',

    }

  }
}
