<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<app-app-status [appTypeId]="appTypeId" [seasonId]="seasonId" [step]="1"></app-app-status>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
    {{ 'ToApplyForParticipationInTheUNT(March)FillInTheData:' | translate }}
</p>
<form [formGroup]="applicationForm" action="">

    <div (click)="collapse.toggle()" class="mb-4 mt-4 mobile-style-2  pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[0]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[0]" class="absolute toggle-icon">-</div>
            <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'PersonalData' | translate }}
            </div>
        </div>
    </div>
    
    <div #collapse="ngbCollapse" [(ngbCollapse)]="barArray[0]" >
        
        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex  rounded-mdp-4">
                {{ 'IIN' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ iin }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Surname' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ lastname }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Name' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ firstname }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Patronymic' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ patronymic }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'YourEmail' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ email }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'DateOfBirth' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input disabled class="w-full pl-1 bg-gray-200 rounded" type="date" formControlName="birthDate"
                    (change)="getDateOfBirth($event.target.value)">
            </div>


            <div class="flex  rounded-mdp-4" *ngIf="showParentData">
                {{ 'FIOPredstavitelya' | translate }}
            </div>
            <div class="flex  rounded-mdp-4" *ngIf="showParentData">
                <input class="w-full pl-1 bg-gray-200 rounded" type="text" formControlName="parentFullname">
            </div>
            <div class="flex  rounded-mdp-4" *ngIf="showParentData">
                {{ 'PhoneNumberPredstavitelya' | translate }}
            </div>
            <div class="flex  rounded-mdp-4" *ngIf="showParentData">
                <input formControlName="parentPhoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00"
                    class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>


            <div class="flex  rounded-mdp-4">
                {{ 'Citizenship' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="citizenshipId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateСitizenship' | translate }}
                    </option>
                    <option [disabled]="!canEditCitizenshipId" *ngFor="let item of countryList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Nationality' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="nationId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateNationality' | translate }}
                    </option>
                    <option [disabled]="!canEditNationId" *ngFor="let item of nationsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Sex' | translate }}
            </div>
            <div class="flex rounded-mdp-4">
                <div class="flex justify-around text-center">
                    <div class="mr-4">
                        <input value="1" [checked]="applicationForm.get('sexId').value == 1" formControlName="sexId"
                            class="mr-2 bg-gray-200 rounded pol-radio" id="pol1" type="radio">
                        <label for="pol1">{{ 'Male' | translate }}</label>
                    </div>
                    <div class="">
                        <input value="2" [checked]="applicationForm.get('sexId').value == 2" formControlName="sexId"
                            class="mr-2  bg-gray-200 rounded pol-radio" id="pol2" type="radio">
                        <label for="pol2">{{ 'Female' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'PhoneNumber' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00"
                    class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'DopSertifs' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="anotherExamTypeId"
                    class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'DopSertifs' | translate }}
                    </option>
                    <option *ngFor="let item of dopSertifsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'InternationalEnglishCertificate' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="foreignLangCertTypeId"
                    (change)="checkSertif(applicationForm.get('foreignLangCertTypeId').value)"
                    class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="-1">
                        {{ 'SelectСertificate' | translate }}
                    </option>
                    <option *ngFor="let item of foreignLangCertList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>

                <div class="ml-2">{{ 'Ball' | translate }}:</div>
                <input (change)="scoreCheck()"
                    [mask]="applicationForm.get('foreignLangCertTypeId').value==4?foreignLangCertScoreMaskIelts:foreignLangCertScoreMask"
                    formControlName="foreignLangCertScore" placeholder="{{ 'Ball' | translate }}"
                    class="w-full h-6 bal pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'SetForeignLangCertificateDate' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input formControlName="foreignLangCertTestDate" class="w-full text-center bg-gray-200 rounded mb-2"
                    type="date">
            </div>
        </div>
        
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="barArray[0]" class="flex mt-4 mb-2">
        <button (click)="closeBar(0)" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>

    <div (click)="collapseStudy.toggle()" class="mb-4 mt-4 mobile-style-2 pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[1]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[1]" class="absolute toggle-icon">-</div>
            <div class="w-full ml-4 text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'PlaceOfStudy' | translate }}
            </div>
        </div>
    </div>

    <div #collapseStudy="ngbCollapse" [(ngbCollapse)]="barArray[1]" class="flex ">

        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex">
                {{ 'StudyCountry' | translate }}
            </div>
            <div class="flex">
                <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="eduCountryId" (change)="getRaionList()">
                    <option disabled [value]="0">
                        {{ 'StudyCountry' | translate }}
                    </option>
                    <option *ngFor="let item of eduCountryList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div #collapseStudy="ngbCollapse" [(ngbCollapse)]="barArray[1]" class="flex mb-2">
        <button (click)="closeBar(1)" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>

    <div (click)="collapseTestParams.toggle()" class="mb-4 mt-4 mobile-style-2 pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[2]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[2]" class="absolute toggle-icon">-</div>
            <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'TestParams' | translate }}
            </div>
        </div>
    </div>

    <div #collapseTestParams="ngbCollapse" [(ngbCollapse)]="barArray[2]" class="">
        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex">
                {{ 'SelectProfileSubjects' | translate }}
            </div>
            <div class="flex">
                <select (change)="getLangList()" formControlName="profileSubjectPairId" class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'ACoupleOfItems' | translate }}
                    </option>
                    <option *ngFor="let item of subjectPairList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex">
                {{ 'SelectTheLanguageForTesting' | translate }}
            </div>
            <div class="flex">
                <select formControlName="testLangId" class="w-full pl-1 h-6 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'LanguageOfSubjects' | translate }}
                    </option>
                    <option *ngFor="let item of langList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="applicationForm.get('anotherExamTypeId').value != 0" #collapseTestParams="ngbCollapse" [(ngbCollapse)]="barArray[2]" class="flex mb-2">
        <button (click)="closeBar(2)"  class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>
    <div *ngIf="applicationForm.get('anotherExamTypeId').value == 0" #collapseTestParams="ngbCollapse" [(ngbCollapse)]="barArray[2]" class="flex mb-2">
        <button (click)="closeBar(2)"  class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>

    <div *ngIf="applicationForm.get('anotherExamTypeId').value != 0" (click)="collapseTestCertif.toggle()" class="mb-4 mt-4 mobile-style-2 pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[3]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[3]" class="absolute toggle-icon">-</div>
            <div class="w-full ml-4 text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'SetSertifData' | translate }}
            </div>
        </div>
    </div>

    <div #collapseTestCertif="ngbCollapse" [(ngbCollapse)]="barArray[3]" class="">
        <div *ngIf="applicationForm.get('anotherExamTypeId').value == 1" class="">
            <div [formGroup]="applicationForm.get('satAnotherExamCertificateData')" class="">
                <div class="flex p-2 pt-3">
                    <div class="">
                      <input id="subs" class="" type="checkbox" formControlName="isSubjectsExists">
                    </div>
                    <label for="subs" class="w-1/3 ml-4">
                        {{ 'isSubjectsExists' | translate }}:
                    </label>
                    
                </div>
                <div class="mb-2">
                    <div formArrayName="subjects"  class="flex">
                        <div class="w-1/3">
                         <span>
                          {{ 'SelectProfileSubjectsSat' | translate }}:
                         </span>
                        </div>
                          <div class="w-2/3">
                              <div class="w-1/3 flex" [formGroupName]="i" *ngFor="let subject of subjects.controls; let i=index">
                                 <div class="mr-1">{{ i + 1 }}.</div> <select formControlName="id" class="w-full mb-3 pl-1 bg-gray-200 rounded">
                                      <option disabled [value]="0">
                                          {{ 'Subject' | translate }}
                                      </option>
                                      <option *ngFor="let item of subjectList" [disabled]="checkSubject()" [value]="item.id">
                                          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                                          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                                      </option>
                                  </select>
                                  <div class="w-2/6">
                                    <input [attr.disabled]="checkSubject()" placeholder="{{ 'Ball' | translate }}" class="w-full ml-3 mb-3 pl-1 bg-gray-200 rounded" type="text" formControlName="score" [mask]="ball">
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                    
                </div>
                <div class="flex mb-2">
                    <div class="w-1/3">
                        {{ 'SetTestDate' | translate }}:
                    </div>
                    <div class="w-1/3">
                        <input formControlName="testDate" class="w-full text-center bg-gray-200 rounded mb-2" type="date">
                    </div>
                </div>
                <div class="flex mb-2">
                    <div class="w-1/3">
                        {{ 'SetSertifRegNum' | translate }}:
                    </div>
                    <div class="w-1/3">
                        <input formControlName="registrationNumber" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                    </div>
                </div>
                <div class="flex mb-2">
                    <div class="w-1/3">
                        {{ 'SATReasoning' | translate }}:
                    </div>
                    <div class="w-1/3">
                        <input [mask]="ball" formControlName="reasoningScore" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="applicationForm.get('anotherExamTypeId').value == 2" [formGroup]="applicationForm.get('ibAnotherExamCertificateData')" class="">
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifTestDate' | translate }}:
                </div>
                <div class="w-1/3">
                    <input formControlName="testDate" class="w-full text-center bg-gray-200 rounded mb-2" type="date">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifRegNum' | translate }}:
                </div>
                <div class="w-1/3">
                    <input formControlName="registrationNumber" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
        
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'IbMathSubj' | translate }}:
                </div>
                <div [formGroup]="applicationForm.get('ibAnotherExamCertificateData').get('mathSubject')" class="w-2/3 flex">
                    <select formControlName="id" (change)="checkSertif(applicationForm.get('foreignLangCertificateTypeId').value)" class="w-full pl-1 bg-gray-200 rounded">
                        <option disabled [value]="-1">
                            {{ 'SelectСertificate' | translate }}
                        </option>
                        <option *ngFor="let item of mathSubjects" [value]="item.id">
                            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                        </option>
                    </select>
                    <div class="ml-4 w-1/3 self-end">
                        <div class="mb-3 flex h-5 w-24">
                           <div class="pb-1 ">{{ 'Ball' | translate }}:</div> 
                           <input [mask]="foreignLangCertScoreMask" formControlName="score" placeholder="{{ 'Ball' | translate }}" class="w-full bal pl-1 bg-gray-200 rounded" type="text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <div formArrayName="subjects" class="flex">
                    <div class="w-1/3">
                     <span>
                      {{ 'SelectProfileSubjectsIb' | translate }}:
                     </span>
                    </div>
                      <div class="w-2/3">
                          <div class="w-1/3 flex" [formGroupName]="i" *ngFor="let subject of subjectsIb.controls; let i=index">
                             <div class="mr-1">{{ i + 1 }}.</div> <select formControlName="id" class="w-full mb-3 pl-1 bg-gray-200 rounded">
                                  <option disabled [value]="0">
                                      {{ 'Subject' | translate }}
                                  </option>
                                  <option *ngFor="let item of subjectList" [value]="item.id">
                                      <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                                      <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                                  </option>
                              </select>
                              <div class="w-2/6">
                                <input placeholder="{{ 'Ball' | translate }}" class="w-full ml-3 mb-3 pl-1 bg-gray-200 rounded" type="text" formControlName="score" [mask]="ball">
                              </div>
                          </div>
                          
                      </div>
                  </div>
                
            </div>
        </div>

        <div *ngIf="applicationForm.get('anotherExamTypeId').value == 3" [formGroup]="applicationForm.get('astAnotherExamCertificateData')" class="">
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetTestDate' | translate }}:
                </div>
                <div class="w-1/3">
                    <input formControlName="testDate" class="w-full text-center bg-gray-200 rounded mb-2" type="date">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifRegNum' | translate }}:
                </div>
                <div class="w-1/3">
                    <input formControlName="registrationNumber" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifEnglishBlock' | translate }}:
                </div>
                <div class="w-1/3">
                    <input [mask]="ball" formControlName="englishScore" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifMathBlock' | translate }}:
                </div>
                <div class="w-1/3">
                    <input [mask]="ball" formControlName="mathScore" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifReadingBlock' | translate }}:
                </div>
                <div class="w-1/3">
                    <input [mask]="ball" formControlName="readingScore" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
            <div class="flex mb-2">
                <div class="w-1/3">
                    {{ 'SetSertifScienceBlock' | translate }}:
                </div>
                <div class="w-1/3">
                    <input [mask]="ball" formControlName="scienceScore" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
                </div>
            </div>
        </div>
        

        <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addCertifFile()">{{ 'addFile' | translate }}</button>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
        </div>
        <span class="add-files-scroll">
        <div formArrayName="anotherExamFiles" class="w-full mt-4 controls-border" *ngFor="let item of certifFiles.controls; index as i">
          <div [formGroup]="certifFiles.controls[i]">
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
            <div class="f-btn-group">
              <div class="relative">
                <input (change)="changeCertifListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                  <p>
                    <span *ngIf="dwnldCertifCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                    <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                  </p>
                </div>
                <p *ngIf="!dwnldCertifCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
              </div>
            </div>
            <div class="s-btn-group">
              <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeCertifFile(i)">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
              </button>
            </div>
          </div>
        </div>
       </span>

    </div>

    <div *ngIf="applicationForm.get('anotherExamTypeId').value != 0"  #collapseTestCertif="ngbCollapse" [(ngbCollapse)]="barArray[3]" class="flex mb-2">
        <button (click)="closeBar(3)" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>

    <div (click)="collapseTestOrg.toggle()" class="mb-4 mt-4 mobile-style-2 pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[4]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[4]" class="absolute toggle-icon">-</div>
            <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'TestOrg' | translate }}
            </div>
        </div>
    </div>

    <div #collapseTestOrg="ngbCollapse" [(ngbCollapse)]="barArray[4]" class="">

        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex">
                {{ 'SelectTestObl' | translate }}
            </div>
            <div class="flex">
                <select (change)="getRct()" formControlName="testOrgOblId" class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'TestObl' | translate }}
                    </option>
                    <option *ngFor="let item of testOblList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex">
                {{ 'SelectTestSite' | translate }}
            </div>
            <div class="flex">
                <select (change)="getTestDate()" formControlName="testOrgId" class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'TestLocation' | translate }}
                    </option>
                    <option *ngFor="let item of testOrgList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex">
                {{ 'SelectTestDate' | translate }}
            </div>
            <div class="flex">
                <select formControlName="testPeriodId" class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'TestDate' | translate }}
                    </option>
                    <option *ngFor="let item of testDateList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div #collapseTestOrg="ngbCollapse" [(ngbCollapse)]="barArray[4]" class="flex mb-2">
        <button (click)="closeBar(4)" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
            {{ 'Next' | translate }}
        </button>
    </div>

    <div (click)="collapseDisabilityData.toggle()" class="mb-4 mt-4 mobile-style-2 pb-1 px-1 relative">
        <div class="toggle-bar flex">
            <div *ngIf="barArray[5]" class="absolute toggle-icon">+</div>
            <div *ngIf="!barArray[5]" class="absolute toggle-icon">-</div>
            <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'DisabilityData' | translate }}
            </div>
        </div>
    </div>

    <div #collapseDisabilityData="ngbCollapse" [(ngbCollapse)]="barArray[5]" class="">
        <fieldset form="applicationForm" style="height: 70px;">
            <div class="flex text-center mb-3 h-5">
                <div class="w-4/5 text-left">
                    {{ 'DisabilityC' | translate }}:
                </div>
                <div class="w-2/5 mt-2">
                    <input value="true" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis1" type="radio">
                    <label class="cursor-pointer" for="dis1">{{ 'Yes' | translate }}</label>
                </div>
                <div class="w-2/5 mt-2">
                    <input value="false" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis2" type="radio">
                    <label class="cursor-pointer" for="dis2">{{ 'No' | translate }}</label>
                </div>
            </div>
        </fieldset>
        <div *ngIf="applicationForm.get('isHasDisability').value == 'true'" class="flex mobile-style-3">
            <div class="w-1/3">
                {{ 'Disability' | translate }}:
            </div>
            <div class="w-full grid">
                <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="disabilityId">
                    <option disabled [value]="0">
                        {{ 'SelectWhatNeed' | translate }}
                    </option>
                    <option *ngFor="let item of disabilityList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
        </div>
    
        <fieldset *ngIf="applicationForm.get('isHasDisability').value == 'true'" form="applicationForm" style="height: 70px;" >
            <div class="flex text-center mb-3 h-5">
                <div class="w-4/5 text-left mt-2">
                    {{ 'helper' | translate }}:
                </div>
                <div class="w-2/5 mt-2">
                    <input value="true" formControlName="isNeedHelperDueDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis3" type="radio">
                    <label class="cursor-pointer" for="dis3">{{ 'Yes' | translate }}</label>
                </div>
                <div class="w-2/5 mt-2">
                    <input value="false" formControlName="isNeedHelperDueDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis4" type="radio">
                    <label class="cursor-pointer" for="dis4">{{ 'No' | translate }}</label>
                </div>
            </div>
        </fieldset>
    
        <div *ngIf="applicationForm.get('isHasDisability').value == 'true' && applicationForm.get('disabilityId').value != 0" class="mt-8 p-2 bg-white rounded-sm shadow-lg">
            <div class="flex">
                <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile()">{{ 'addFile' | translate }}</button>
                <div class="mx-auto w-1/2 text-center text-xl font-bold">
                    {{ 'InvalidSpravka' | translate }}
                </div>
                <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
            </div>
            <span class="add-files-scroll">
            <div formArrayName="docFiles" class="w-full mt-4 controls-border" *ngFor="let item of files.controls; index as i">
              <div [formGroup]="files.controls[i]">
                <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
                <div class="f-btn-group">
                  <div class="relative">
                    <input (change)="changeListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                    <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                      <p>
                        <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                        <ng-template #elseBlock><span> {{ 'UploadInvFile' | translate }}</span></ng-template>
                      </p>
                    </div>
                    <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
                  </div>
                </div>
                <div class="s-btn-group">
                  <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile(i)">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
                  </button>
                </div>
              </div>
            </div>
           </span>
        </div>
    </div>

    

    





    
    

    <!-- <p class="text-center my-2 mt-4 mobile-style-3">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_ent.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>   -->

    

    <button *ngIf="showParentData" (click)="textModal = true" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
        {{ 'Continue' | translate }}
    </button>
    <button *ngIf="!showParentData" (click)="textModal = true" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
        {{ 'Continue' | translate }}
    </button>

</form>


<div *ngIf="textModal == true" class="overlay">
    <div class="modal-window">
        <div class="relative">
            <div class="modal-sub-header">
                {{ 'PeredPod' | translate }}
            </div>
            <div (click)="closeAgreeModal()" class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold">
                X
            </div>
        </div>
        <div (scroll)="onScrollEnd($event)" class="modal-text">
            <span *ngIf="showParentData">
                <div *ngIf="!rulesEnt; else showRulesParentEnt">
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.parent.agreement.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.parent.agreement.contentKaz)"></div>
                </div>
                <ng-template #showRulesParentEnt>
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.parent.introduction.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.parent.introduction.contentKaz)"></div>
                </ng-template>
            </span>

            <span *ngIf="!showParentData">
                <div *ngIf="!rulesEnt; else showRulesEnt">
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.child.agreement.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.child.agreement.contentKaz)"></div>
                </div>
                <ng-template #showRulesEnt>
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.child.introduction.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(rulesEntData.child.introduction.contentKaz)"></div>
                </ng-template>
            </span>

        </div>
        
        <div *ngIf="!rulesEnt" class="text-center">
            <div *ngIf="showParentData">
                <input (change)="rulesEnt = true" type="checkbox" id="get-agree" class="get-agreement">
                <label class="ml-2" for="get-agree">{{ 'RoditelSog' | translate }}</label>
            </div>
            <div *ngIf="!showParentData">
                <input (change)="rulesEnt = true" type="checkbox" id="get-agree" class="get-agreement">
                <label class="ml-2" for="get-agree">{{ 'RulesText-4' | translate }}</label>
            </div>
        </div>

        <div *ngIf="rulesEnt">
            <div class="help-text">
                *{{ 'ReadT' | translate }}
            </div>

            <div class="text-center">
                <input [disabled]="blockBtn == true" (change)="showParentData ? agreeCheckParent() : agreeCheck()" type="checkbox" id="get-agree"
                    class="get-agreement">
                <label class="ml-2" for="get-agree">{{ 'RulesEnt' | translate }}</label>
            </div>
        </div>
        <div class="w-full text-center">
            <button *ngIf="showParentData" (click)="postApplication()" [disabled]="!applicationAgreeParent"
            class="btn btn-primary w-full">
                {{ 'PostApplication' | translate }}
            </button>
            <button *ngIf="!showParentData" (click)="postApplication()" [disabled]="!applicationAgree"
                class="btn btn-primary w-full">
                {{ 'PostApplication' | translate }}
            </button>
        </div>
        
</div>

