import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class pvoConfig {
  pvoBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.pvoBackend = 'https://app.debug.testcenter.kz/pvo/student/app/api/v1'; // Путь для localhost  
    }
    if (!isDevMode()) {
      this.pvoBackend = 'pvo/student/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      getAppSeasonItems: this.pvoBackend + '/app/season/items',
      getAppItems: this.pvoBackend + '/app/items'
    }

  }
}
