import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";


@Component({
  selector: 'app-change-email-confirmation',
  templateUrl: './change-email-confirmation.component.html',
  styleUrls: ['./change-email-confirmation.component.css']
})
export class ChangeEmailConfirmationComponent implements OnInit {

  loading = true;
  emailConfirmForm: FormGroup;
  emailConfirmData: any;
  requestId: any;
  verificationCode: any;
  showSuccesMessage = false;
  showErrorMessage = false;
  errorMessage: any;


  constructor(
    public api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      this.requestId = res['id'];
      this.verificationCode = res['code'];
      this.initRegForm();
      this.emailConfirmData = this.emailConfirmForm.value;
    });

    
    this.api.changeEmailConfirmation(this.emailConfirmData)
      .subscribe((res: any) => {
        setTimeout(() => {
          this.router.navigate(['/']);
          }, 60000);
          this.showSuccesMessage = true;
          this.loading = false;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Ошибкакз', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
        this.showErrorMessage = true;
      });

    

  }

  initRegForm() {
    this.emailConfirmForm = this.fb.group({
      id: [this.requestId],
      verificationCode: [this.verificationCode]
    });
  }

}
