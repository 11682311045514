import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-perevodniki-from-tvor',
  templateUrl: './e-perevodniki-from-tvor.component.html',
  styleUrls: ['./e-perevodniki-from-tvor.component.css']
})
export class EPerevodnikiFromTvorComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  testOblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts = '0.0';
  applicationAgree = false;
  gopList = [];
  vuzList = [];
  applicationId: any;
  appShortInfo: any;
  eduAreaList: any;

  disabilityList: any;
  fileIdToDelete: number;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  CSID: any;
  dopSertifsList: any;
  NID: any;
  dataofbday = 0;
  showParentData = false;

  mathSubjects = [];

  subjectList = [];

  barArray: any = [false, true, true, true, true, true];

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

    this.seasonId = this.router.parseUrl(this.router.url).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.getApplicationInfo();
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        if(res.data.sexId == 0){
          this.canEditSexId = true; 
        }
        if(res.data.nationId == 0 || res.data.nationId == 99){
          this.canEditNationId = true;
        }
        if(res.data.citizenshipId == 0 || res.data.citizenshipId == 99){
          this.canEditCitizenshipId = true;
        }
        if(res.data.phoneNumber == 0){
          this.canEditPhoneNumber = true;
        }
        this.applicationForm.get('sexId').patchValue(res.data.sexId);
        this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        this.getDateOfBirth(res.data.birthDate);
        this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);

      });
      // Инициализация формы
      this.applicationForm = new FormGroup({
        seasonId: new FormControl(this.seasonId),
        appTypeId: new FormControl(this.appTypeId),
        citizenshipId: new FormControl(0),
        nationId: new FormControl(0),
        foreignLangCertTypeId: new FormControl(0),
        foreignLangCertScore: new FormControl(0),
        phoneNumber: new FormControl(),
        eduInstituteId: new FormControl(0),
        sexId: new FormControl(),
        eduInstituteOblId: new FormControl(0),
        raionId: new FormControl(0),
        birthDate: new FormControl(0),
        parentFullname: new FormControl(0),
        parentPhoneNumber: new FormControl(0),
        schoolId: new FormControl(0),
        profileSubjectPairId: new FormControl(0),
        testLangId: new FormControl(0),
        testOrgId: new FormControl(0),
        testOrgOblId: new FormControl(0),
        eduProgramId: new FormControl(0),
        testPeriodId: new FormControl(0),
        eduAreaId: new FormControl(0),
        isHasDisability: new FormControl(0),
        foreignLangCertTestDate: new FormControl(0),
        anotherExamTypeId: new FormControl(0),
        isNeedHelperDueDisability: new FormControl(0),
        disabilityId: new FormControl(0),
        docFiles: new FormArray([]),
        anotherExamFiles: new FormArray([]),
        satAnotherExamCertificateData: new FormGroup({
          testDate: new FormControl(0),
          registrationNumber: new FormControl(0),
          reasoningScore: new FormControl(0),
          isSubjectsExists: new FormControl(0),
          subjects: new FormArray([]),
        }),
        astAnotherExamCertificateData: new FormGroup({
          englishScore: new FormControl(0),
          mathScore: new FormControl(0),
          readingScore: new FormControl(0),
          scienceScore: new FormControl(0),
          testDate: new FormControl(0),
          registrationNumber: new FormControl(0)
        }),
        ibAnotherExamCertificateData: new FormGroup({
          testDate: new FormControl(0),
          registrationNumber: new FormControl(0),
          mathSubject: new FormGroup({
            id: new FormControl(0),
            score: new FormControl(0)
          }),
          subjects: new FormArray([])
        }),
      });
      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }

  }

  getNumberOfDays(start, end) { 
    const date1 = new Date(start); 
    const date2 = new Date(end); 
    
    // One day in milliseconds 
    const oneDay = 1000 * 60 * 60 * 24; 
    
    // Calculating the time difference between two dates 
    const diffInTime = date2.getTime() - date1.getTime(); 
    
    // Calculating the no. of days between two dates 
    const diffInDays = Math.round(diffInTime / oneDay); 
    
    return diffInDays; 
    } 

  getDateOfBirth(date: any){
    let today = new Date();
    this.dataofbday = this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
    if(this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()) < 6574){
      this.showParentData = true;
    }else{
      this.showParentData = false;
    }
  }

  closeBar(index: number){
    if(index == 0){
      this.barArray[index] = true;
      this.barArray[index+1] = false;
    }else{
      if(this.applicationForm.get('anotherExamTypeId').value == 0 && index == 2){
        this.barArray[index] = true;
        if(this.appShortInfo.canChangeTestPeriod){
          this.barArray[index+2] = false;
        }else{
          this.barArray[index+3] = false;
        }
      }else{
        if(index == 3 && !this.appShortInfo.canChangeTestPeriod){
          this.barArray[index] = true;
          this.barArray[index+2] = false;
        }
        else{
          this.barArray[index] = true;
          this.barArray[index+1] = false;
        }
        
      }
    }
  }

  getApplicationInfo(){
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.appShortInfo = res.data;
        this.seasonId = this.appShortInfo.seasonId;
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);
        this.applicationForm.get('parentFullname').patchValue(this.appShortInfo.parentFullname);
        this.applicationForm.get('parentPhoneNumber').patchValue(this.appShortInfo.parentPhoneNumber);
        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.appShortInfo.foreignLangCertTypeId);
        this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
        this.applicationForm.get('eduInstituteOblId').patchValue(this.appShortInfo.eduInstituteOblId);
        
        
        this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
        this.applicationForm.get('eduProgramId').patchValue(this.appShortInfo.eduProgramId);
        this.applicationForm.get('eduAreaId').patchValue(this.appShortInfo.eduAreaId);
        this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
        this.getNationList();
        this.getCountryList();
        this.getGopForVuz();
        this.getForeignLangCertList();
        this.getOblList();
        this.getEduArea();
        this.getSubjectPair(0);
        this.addCertifFile();

        if(this.appShortInfo.anotherExamTypeId == 1){
          // sat
          for(let i = 0; i < 2; i++){
            this.subjects.push(this.subject(this.appShortInfo.satAnotherExamCertificateData.subjects[i].id, this.appShortInfo.satAnotherExamCertificateData.subjects[i].score));
          }

          this.applicationForm.get('satAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
          this.applicationForm.get('satAnotherExamCertificateData').get('isSubjectsExists').patchValue(this.appShortInfo.satAnotherExamCertificateData.isSubjectsExists);
          this.applicationForm.get('satAnotherExamCertificateData').get('reasoningScore').patchValue(this.appShortInfo.satAnotherExamCertificateData.reasoningScore);
          this.applicationForm.get('satAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.satAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('satAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
        }
        if(this.appShortInfo.anotherExamTypeId == 2){
          // ib
          this.getIBMathSubjects();

          for(let i = 0; i < 2; i++){
            this.subjectsIb.push(this.subject(this.appShortInfo.ibAnotherExamCertificateData.subjects[i].id, this.appShortInfo.ibAnotherExamCertificateData.subjects[i].score));
          }

          this.applicationForm.get('ibAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.ibAnotherExamCertificateData.testDate);
          this.applicationForm.get('ibAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.ibAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('ibAnotherExamCertificateData').get('mathSubject').get('id').patchValue(this.appShortInfo.ibAnotherExamCertificateData.mathSubject.id);
          this.applicationForm.get('ibAnotherExamCertificateData').get('mathSubject').get('score').patchValue(this.appShortInfo.ibAnotherExamCertificateData.mathSubject.score);
          // this.applicationForm.get('ibAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
        }
        if(this.appShortInfo.anotherExamTypeId == 3){
          // act
          this.applicationForm.get('astAnotherExamCertificateData').get('englishScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.englishScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('mathScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.mathScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('readingScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.readingScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.astAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('astAnotherExamCertificateData').get('scienceScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.scienceScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.astAnotherExamCertificateData.testDate);
        }
        
        this.getVuzList(0);
        this.getRct(0);
        this.getHelper();
        this.addFiles();
        this.getSertifs();
        this.applicationForm.get('anotherExamTypeId').patchValue(this.appShortInfo.anotherExamTypeId);
        this.applicationForm.get('foreignLangCertTestDate').patchValue(this.appShortInfo.foreignLangCertTestDate);
        

        
        this.getTestDate(0);


      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getIBMathSubjects(){
    this.api.getMathSubjects()
      .subscribe((res: any) => {
        this.mathSubjects = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  subject(id = 0, score): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      score: new FormControl(score)
    });
  }

  checkSubject(){
    if(this.applicationForm.get('satAnotherExamCertificateData').get('isSubjectsExists').value == false){
      return true;
    }
  }

  dwnldCertifCheck(id: number): boolean {
    return this.certifFiles.controls[id].get('filename').value !== '';
  }

  clearFiles(){
    this.applicationForm.get('anotherExamFiles').value.forEach(element => {
      this.removeCertifFile('0');
    });

  }

  removeCertifFile(type: string){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.certifFiles;

    obj.removeAt(type);
  }

  getSertifs(){
    this.api.getDopSertifs(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.dopSertifsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  addCertifFile() {
    let obj = null;
    if(this.appShortInfo.anotherExamFiles != undefined){
      this.appShortInfo.anotherExamFiles.forEach(element => {

        obj = this.certifFiles;
      
        obj.push(
          new FormGroup({
            filename: new FormControl(element.filename, [Validators.required]),
            id: new FormControl(element.id),
            data: new FormControl('')
          })
        );
  
      });
    }
    
  }


  getEduArea(){
    this.api.getEduAreaEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.eduAreaList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  getRct(code: number){
    this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgOblId').value, 0)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        if(code == 1){
          this.applicationForm.get('testOrgId').patchValue(0);
          this.applicationForm.get('testPeriodId').patchValue(0);
        }
        

      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getVuzList(code: number){
    this.api.getVuzEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduInstituteOblId').value)
      .subscribe((res: any) => {
        this.vuzList = res.items;
        if(code == 1){
          this.applicationForm.get('eduInstituteId').patchValue(0);
        }else{
          this.applicationForm.get('eduInstituteId').patchValue(this.appShortInfo.eduInstituteId);
        }
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getGopForVuz(){
    this.api.getGopForVuzSpr(this.seasonId, this.appTypeId, this.applicationForm.get('eduAreaId').value, false)
      .subscribe((res: any) => {
        this.gopList = res.items;
      }, (err: any) => {

      });
  }

  getNationList(){
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList(){
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList(){
    this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getOblList(){
    this.api.getOblEnt(this.seasonId, this.appTypeId, this.applicationId)
      .subscribe((res: any) => {
        // this.applicationForm.get('eduInstituteOblId').setValue(0);
        this.oblList = res.items;
        this.getEduInfo();
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  getRaionList(){
    this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationId)
      .subscribe((res: any) => {
        this.raionList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  getEduInfo(){
    this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if(res.data.oblId != 0){
          this.applicationForm.get('oblId').setValue(res.data.oblId);
          this.applicationForm.get('oblId').disable();
          this.getRaionList();
        }
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSubjectPair(code: number){
    this.api.getProfileSubjectEntTvor(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.subjectPairList = res.items;
        if(code == 1){
          this.applicationForm.get('profileSubjectPairId').patchValue(0);
          this.applicationForm.get('testLangId').patchValue(0);
          this.getLangList(1);
        }else{
          this.applicationForm.get('profileSubjectPairId').patchValue(this.appShortInfo.profileSubjectPairId);
          this.getLangList(0);
        }
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code: number){
    this.getProfileSubject();
    this.api.getLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.loading = false;
        this.langList = res.items;
        if(code == 1){
          this.applicationForm.get('testLangId').patchValue(0);
        }else{
          this.applicationForm.get('testLangId').patchValue(this.appShortInfo.testLangId);
        }
        
      },(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getProfileSubject(){
    if(this.appShortInfo.anotherExamTypeId == 1){
      this.api.getSatProfileSubject(this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.subjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
    if(this.appShortInfo.anotherExamTypeId == 2){
      this.api.getIbSubjects(this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.subjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
     
  }

  getTestOrg(){
    this.api.getTestOrgEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate(code: number){
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, this.applicationId)
      .subscribe((res: any) => {
        this.testDateList = res.items;
        if(code == 1){
          this.applicationForm.get('testPeriodId').patchValue(0);
        }else{
          this.applicationForm.get('testPeriodId').patchValue(this.appShortInfo.testPeriodId);
        }
        
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id){
    if(id == 4){
      this.foreignLangCertScoreMask = '0.0';
    }
    if(id !=4){
      this.foreignLangCertScoreMask = '000';
    }
    if(id == 0){
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck(){
    if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);  
    }
  }


  EditApplication(){
    this.scoreCheck();
    this.loading = true;
    this.api.postEditApplicationEnt(this.applicationForm.value, this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();          
        }, 200);
      },(err: any) =>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

  getHelper(){
    this.api.getDisabilityEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
        let value = this.appShortInfo.isHasDisability;
        this.applicationForm.get('isHasDisability').setValue(value.toString());
        this.applicationForm.get('disabilityId').setValue(this.appShortInfo.disabilityId);
        this.applicationForm.get('isNeedHelperDueDisability').setValue(this.appShortInfo.isNeedHelperDueDisability.toString());
      }, (err: any) => {

      });
  }

  get files() : any { return this.applicationForm.get('docFiles')};
  get certifFiles() : any { return this.applicationForm.get('anotherExamFiles')};
  get subjects() : any { return this.applicationForm.get('satAnotherExamCertificateData').get('subjects')}
  get subjectsIb() : any { return this.applicationForm.get('ibAnotherExamCertificateData').get('subjects')}

  addFiles(){
    let obj = null;
    this.appShortInfo.docFiles.forEach(element => {

      obj = this.files;
    
      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, []),
          id: new FormControl(element.id),
          data: new FormControl('')
        })
      );

    });
  }

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', []),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event, i: number) : void {
    let obj = null;

    obj = this.files;
    
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;
      
      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile(type: string){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

}