<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
    {{ 'Editing' | translate }}
</p>

<form [formGroup]="applicationForm" action="">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex">
        <div class="">
            <div class="mb-3 h-5">
                {{ 'IIN' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Surname' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Name' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Patronymic' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'YourEmail' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Citizenship' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Nationality' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Sex' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'PhoneNumber' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'InternationalEnglishCertificate' | translate }} TOEFL ITP
            </div>
        </div>
        <div class="ml-4 w-1/3">
            <div class="mb-3 h-5">
                {{ iin }}
            </div>
            <div class="mb-3 h-5">
                {{ lastname }}
            </div>
            <div class="mb-3 h-5">
                {{ firstname }}
            </div>
            <div class="mb-3 h-5">
                {{ patronymic }}
            </div>
            <div class="mb-3 h-5">
                {{ email }}
            </div>

            <div class="mb-3 h-5">
                <select type="number" formControlName="citizenshipId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateСitizenship' | translate }}
                    </option>
                    <option  [disabled]="!canEditCitizenshipId" *ngFor="let item of countryList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="mb-3 h-5">
                <select type="number" formControlName="nationId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateNationality' | translate }}
                    </option>
                    <option [disabled]="!canEditNationId" *ngFor="let item of nationsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <fieldset [disabled]="!canEditSexId" form="applicationForm">
                <div class="flex justify-around text-center mb-3 h-5">
                    <div class="">
                        <input value="1" [checked]="applicationForm.get('sexId').value == 1" formControlName="sexId" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="pol1" type="radio">
                        <label for="pol1">{{ 'Male' | translate }}</label>
                    </div>
                    <div class="">
                        <input value="2" [checked]="applicationForm.get('sexId').value == 2" formControlName="sexId" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="pol2" type="radio">
                        <label for="pol2">{{ 'Female' | translate }}</label>
                    </div>
                </div>
            </fieldset>
            <div class="mb-3 h-5">
                <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            <!-- <div class="mb-3 flex h-5">
                <select type="number" formControlName="foreignLangCertTypeId" (change)="checkSertif(applicationForm.get('foreignLangCertTypeId').value)" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="-1">
                        {{ 'SelectСertificate' | translate }}
                    </option>
                    <option *ngFor="let item of foreignLangCertList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div> -->
            <div class="mb-3 flex h-5 w-2/3">
                <div class="pb-1 ">{{ 'Ball' | translate }}:</div>
                <input *ngIf="!showChars" (change)="scoreCheck()" [mask]="sertidTypeFloat==true?foreignLangCertScoreMaskIelts:foreignLangCertScoreMask" formControlName="foreignLangCertScore" placeholder="{{ 'Ball' | translate }}" class="w-full bal pl-1 bg-gray-200 rounded" type="text">
                <select type="number" formControlName="foreignLangCertScore" class="w-full bal pl-1 bg-gray-200 rounded" *ngIf="showChars">
                    <option value="">-----</option>
                    <option [value]="char.id" *ngFor="let char of charList" value="">
                     <span *ngIf="translate.currentLang == 'kz'">{{ char.value }} </span>
                     <span *ngIf="translate.currentLang == 'ru'">{{ char.value }} </span>
                    </option>
                </select>
             </div>
        </div>
        <div class="ml-4 w-1/3 self-end">

        </div>

    </div>
    <div class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
        <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile()">{{ 'addFile' | translate }}</button>
            <div class="mx-auto w-1/2 text-center text-xl font-bold">
                <!-- {{ 'InvalidSpravka' | translate }} --> Сертификат
            </div>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
        </div>
        <span class="add-files-scroll">
        <div formArrayName="foreignLangCertificateFiles" class="w-full mt-4 controls-border" *ngFor="let item of files.controls; index as i">
          <div [formGroup]="files.controls[i]">
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
            <div class="f-btn-group">
              <div class="relative">
                <input (change)="changeListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                  <p>
                    <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                    <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                  </p>
                </div>
                <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
              </div>
            </div>
            <div class="s-btn-group">
              <button  type="button" title="Удалить файл" class="x-btn mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile(i)">
                Удалить файл
                <!-- <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg> -->
              </button>
            </div>
          </div>
        </div>
       </span>
    </div>
    <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestOrg' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex">
        <div class="w-1/3">
            {{ 'TestCity' | translate }}:
        </div>
        <div class="w-2/3 grid">
            <select type="number" class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="testCityId">
                <option disabled [value]="0">
                    {{ 'TestCity' | translate }}
                </option>
                <option *ngFor="let item of cities" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <!-- <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'Disability' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <fieldset form="applicationForm">
        <div class="flex justify-around text-center mb-3 h-5">
            <div class="">
                <input [checked]="applicationForm.get('isHasDisability').value == 'true'" value="true" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis1" type="radio">
                <label class="cursor-pointer" for="dis1">{{ 'isHasDisability' | translate }}</label>
            </div>
            <div class="">
                <input [checked]="applicationForm.get('isHasDisability').value == 'false'" value="false" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis2" type="radio">
                <label class="cursor-pointer" for="dis2">{{ 'isNotHasDisability' | translate }}</label>
            </div>
        </div>
    </fieldset>
    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'" class="flex">
        <div class="w-1/3">
            {{ 'helper' | translate }}:
        </div>
        <div class="w-full grid">
            <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="disabilityId">
                <option disabled [value]="0">
                    {{ 'SelectWhatNeed' | translate }}
                </option>
                <option *ngFor="let item of disabilityList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'  && applicationForm.get('disabilityId').value != 1" class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
        <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile()">{{ 'addFile' | translate }}</button>
            <div class="mx-auto w-1/2 text-center text-xl font-bold">
                {{ 'InvalidSpravka' | translate }}
            </div>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
        </div>
        <span class="add-files-scroll">
        <div formArrayName="docFiles" class="w-full mt-4 controls-border" *ngFor="let item of files.controls; index as i">
          <div [formGroup]="files.controls[i]">
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
            <div class="f-btn-group">
              <div class="relative">
                <input (change)="changeListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                  <p>
                    <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                    <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                  </p>
                </div>
                <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
              </div>
            </div>
            <div class="s-btn-group">
              <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile(i)">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
              </button>
            </div>
          </div>
        </div>
       </span>
    </div>

    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'  && applicationForm.get('disabilityId').value != 1" class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
        <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFileZayav()">{{ 'addFile' | translate }}</button>
            <div class="mx-auto w-1/2 text-center text-xl font-bold">
                {{ 'DirZayav' | translate }}
            </div>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
        </div>
        <span class="add-files-scroll">
        <div formArrayName="appFiles" class="w-full mt-4 controls-border" *ngFor="let item of zayav.controls; index as i">
          <div [formGroup]="zayav.controls[i]">
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
            <div class="f-btn-group">
              <div class="relative">
                <input (change)="changeListenerZayav($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                  <p>
                    <span *ngIf="dwnldCheckZayav(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                    <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                  </p>
                </div>
                <p *ngIf="!dwnldCheckZayav(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
              </div>
            </div>
            <div class="s-btn-group">
              <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFileZayav(i)">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
              </button>
            </div>
          </div>
        </div>
       </span>
    </div> -->

<!--  href="./../../../../../../../assets/Заявление Директору НЦТ МОН РК.pdf"-->
    <p *ngIf="applicationForm.get('isHasDisability').value == 'true' && applicationForm.get('disabilityId').value != 1" class="text-center my-2 mt-4">
      <a class="text-blue-500" href="./assets/Заявление%20Директору%20НЦТ%20МОН%20РК.pdf" target="_blank">{{ 'DirZayavShablon' | translate }}
      </a></p>





    <button (click)="postApplication()" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
        {{ 'Edit' | translate }}
    </button>

</form>

