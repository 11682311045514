<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="text-xl z-10 relative text-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16">
    {{ 'NCT' | translate }}
</div>
<div class="container">
    <div class="bg-gray-300 h-screen text-center">
        <p class="mx-10 p-4 font-semibold text-lg">
            {{ 'FromRegConfirm' | translate }}
        </p>
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [routerLink]="'/auth'">{{ 'ReturnToStartPage' | translate }}</button>
    </div>
</div>