<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<p class="text-center text-3xl font-hairline text-blue-500 mb-4">{{ 'ForNqtSetData' | translate }}:</p>
<!-- <p class="my-2 text-xl text-center"> {{ 'PayForTry' | translate}}</p> -->
<form action="" [formGroup]="applicationForm">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex py-1">
        <div class="w-1/3">
            {{ 'IIN' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly (keyup)="changeIIN()" [mask]="iin" formControlName="iin" class="border h-6 rounded" type="text">
        </div>
    </div>
    <!-- <div (click)="findData(applicationForm.get('iin').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
        {{ 'SearchByIIN' | translate }}
    </div> -->
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Surname' | translate }} 
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="lastname" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Name' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="firstname" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Patronymic' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="patronymic" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'YourEmail' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="email" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'PhoneNumber' | translate }}
        </div>
        <div class="w-1/3">
            <input [mask]="phone" formControlName="phoneNumber" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="py-3">
        {{ 'DiplomName' | translate }}:
    </div>
    <div class="">
        <select formControlName="eduSpecialityId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                --
            </option>
            <option [ngValue]="item.id" *ngFor="let item of instituteSpecialityList">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
    </div>
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PlaceOfStudy' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'SelectObl' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="eduSchoolOblId" (change)="getRaion(0)">
                <option selected disabled value="-1">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let obl of oblList" [ngValue]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
        
        </div>
    </div>

    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'SelectRaion' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="eduSchoolRaionId" (change)="getInstitute(0)">
                <option selected disabled value="-1">
                    {{ 'SelectRaion' | translate }}
                </option>
                <option *ngFor="let obl of raionList" [ngValue]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
        
        </div>
    </div>

    <div class="flex py-3">
        <div class="w-48">
            {{ 'selectCollege' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="eduSchoolId">
                <option selected disabled value="-1">
                    {{ 'selectCollege' | translate }}
                </option>
                <option *ngFor="let inst of instituteList" [ngValue]="inst.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ inst.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ inst.name.ru }} </span>
                </option>
            </select>
        
        </div>
    </div>
    
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestParams' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div [formArrayName]="subjects" class="py-3" *ngFor="let item of subjects.controls; index as i">
        <div class="">
            <div class="">
                <div class="">
                    {{ 'TestBlock' | translate }}
                </div>
                <div>
                    <select class="w-full border" (change)="setSubjectValue(0, $event.target.value)">
                        <option selected value="-1">
                            --
                        </option>
                        <option [selected]="this.appShortInfo.subjects[0].subjectId == sub.id" [value]="sub.id" *ngFor="let sub of subjectList[i]">
                            <span *ngIf="translate.currentLang == 'kz'">{{ sub.name.kk }}</span>
                            <span *ngIf="translate.currentLang == 'ru'">{{ sub.name.ru }}</span>
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-3">
        <div class="">
            <div class="">
                {{ 'TestLang' | translate }}
            </div>
            <select formControlName="testLangId" class="w-full border my-1" name="">
                <option selected disabled value="-1">
                    --
                </option>
                <option [ngValue]="item.id" *ngFor="let item of langList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
    </div>
    <div class="w-full">
        <select (change)="getTestOrgList(0)" formControlName="testOrgOblId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'SelectTestObl' | translate }}
            </option>
            <option [ngValue]="item.id" *ngFor="let item of oblList">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select (change)="getTestPeriodList()" formControlName="testOrgId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestPlace' | translate }}
            </option>
            <option [ngValue]="item.id" *ngFor="let item of testOrg">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select formControlName="testPeriodId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestTime' | translate }}
            </option>
            <option [ngValue]="item.id" *ngFor="let item of testPeriod">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
            </option>
        </select>
    </div>
    
    <div class="">
        <button (click)="postForm()" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest"> {{ 'Save' | translate }} </button>
    </div>
</form>