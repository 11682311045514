<div [formGroup]="applicationForm">
  <div class="flex mb-4 mt-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "Disability" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div form="applicationForm" class="mb-4">
    <div class="grid grid-cols-1 gap-x-6 sm:grid-cols-6 md:text-center">
      <div class="md:col-span-3">
        <input
          value="true"
          formControlName="isHasDisability"
          class="mr-4 pl-1 bg-gray-200 rounded pol-radio"
          id="dis1"
          type="radio"
        />
        <label class="cursor-pointer" for="dis1">{{
          "isHasDisability" | translate
        }}</label>
      </div>
      <div class="md:col-span-3">
        <input
          value="false"
          formControlName="isHasDisability"
          class="mr-4 pl-1 bg-gray-200 rounded pol-radio"
          id="dis2"
          type="radio"
        />
        <label class="cursor-pointer" for="dis2">{{
          "isNotHasDisability" | translate
        }}</label>
      </div>
    </div>
  </div>
  <div *ngIf="applicationForm.get('isHasDisability').value == 'true'">
    <div class="grid grid-cols-1 gap-x-6">
      <div class="md:col-span-full">
        <label for="helper">{{ "helper" | translate }}</label>
        <select
          id="helper"
          class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          formControlName="disabilityId"
        >
          <option disabled [value]="0">
            {{ "SelectWhatNeed" | translate }}
          </option>
          <option *ngFor="let item of disabilityList" [value]="item.id">
            <span *ngIf="translate.currentLang == 'kz'"
              >{{ item.name.kk }}
            </span>
            <span *ngIf="translate.currentLang == 'ru'"
              >{{ item.name.ru }}
            </span>
          </option>
        </select>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      applicationForm.get('isHasDisability').value == 'true' &&
      applicationForm.get('disabilityId').value != 1
    "
    class="mt-4 p-2 bg-white rounded-sm shadow-lg"
  >
    <div class="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
      <div class="md:col-span-2 sm:col-span-full">
        <button
          type="button"
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-full"
          (click)="addFile()"
        >
          {{ "addFile" | translate }}
        </button>
      </div>
      <div class="md:col-span-4">
        <div class="mx-auto text-center text-xl font-bold">
          {{ "InvalidSpravka" | translate }}
        </div>
      </div>
    </div>
    <span class="add-files-scroll">
      <div
        formArrayName="docFiles"
        class="w-full mt-4 controls-border"
        *ngFor="let item of files.controls; index as i"
      >
        <div [formGroup]="files.controls[i]">
          <span>
            {{ "NameFile" | translate }}:
            <input
              disabled
              class="mb-4 p-2 w-full"
              type="text"
              readonly
              formControlName="filename"
          /></span>
          <div class="f-btn-group">
            <div class="relative">
              <input
                (change)="changeListener($event, i, 'edit')"
                class="w-full absolute top-0 left-0 h-full opacity-0 mb-4"
                type="file"
              />
              <div
                class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              >
                <p>
                  <span *ngIf="dwnldCheck(i); else elseBlock">
                    {{ "ReplaceFile" | translate }}</span
                  >
                  <ng-template #elseBlock
                    ><span> {{ "UploadFile" | translate }}</span></ng-template
                  >
                </p>
              </div>
              <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">
                * {{ "FileNotAdd" | translate }}
              </p>
            </div>
          </div>
          <div class="s-btn-group">
            <button
              type="button"
              title="Удалить файл"
              class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              (click)="removeFile(i)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash"
                class="svg-inline--fa fa-trash fa-w-14"
                role="img"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>

  <div
    *ngIf="
      applicationForm.get('isHasDisability').value == 'true' &&
      applicationForm.get('disabilityId').value != 1
    "
    class="mt-4 p-2 bg-white rounded-sm shadow-lg"
  >
    <div class="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
      <div class="md:col-span-2 sm:col-span-full">
        <button
          type="button"
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-full"
          (click)="addFileZayav()"
        >
          {{ "addFile" | translate }}
        </button>
      </div>
      <div class="md:col-span-4">
        <div class="mx-auto text-center text-xl font-bold">
          {{ "DirZayav" | translate }}
        </div>
      </div>
    </div>
    <span class="add-files-scroll">
      <div
        formArrayName="docFiles"
        class="w-full mt-4 controls-border"
        *ngFor="let item of zayav.controls; index as i"
      >
        <div [formGroup]="zayav.controls[i]">
          <span>
            {{ "NameFile" | translate }}:
            <input
              disabled
              class="mb-4 p-2 w-full"
              type="text"
              readonly
              formControlName="filename"
          /></span>
          <div class="f-btn-group">
            <div class="relative">
              <input
                (change)="changeListenerZayav($event, i, 'edit')"
                class="w-full absolute top-0 left-0 h-full opacity-0 mb-4"
                type="file"
              />
              <div
                class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              >
                <p>
                  <span *ngIf="dwnldCheckZayav(i); else elseBlock">
                    {{ "ReplaceFile" | translate }}</span
                  >
                  <ng-template #elseBlock
                    ><span> {{ "UploadFile" | translate }}</span></ng-template
                  >
                </p>
              </div>
              <p
                *ngIf="!dwnldCheckZayav(i)"
                class="text-red-500 text-sm text-left"
              >
                * {{ "FileNotAdd" | translate }}
              </p>
            </div>
          </div>
          <div class="s-btn-group">
            <button
              type="button"
              title="Удалить файл"
              class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              (click)="removeFileZayav(i)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash"
                class="svg-inline--fa fa-trash fa-w-14"
                role="img"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>
  <p
    *ngIf="
      applicationForm.get('isHasDisability').value == 'true' &&
      applicationForm.get('disabilityId').value != 1
    "
    class="text-center my-2 mt-4"
  >
<!--    href="/src/assets/Заявление Директору НЦТ МОН РК.pdf"-->
    <a
      class="text-blue-500"
      href="./assets/Заявление%20Директору%20НЦТ%20МОН%20РК.pdf"
      target="_blank"
      >{{ "DirZayavShablon" | translate }}</a
    >
  </p>
</div>
