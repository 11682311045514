<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<app-app-status
  [appTypeId]="appTypeId"
  [seasonId]="seasonId"
  [step]="1"
></app-app-status>

<form [formGroup]="applicationForm">
  <div class="min-h-screen mt-10 flex justify-center">
    <div class="max-w-screen-lg">
      <personal-info
        [(applicationForm)]="applicationForm"
        [isForeignLang]="true"
        [isCertKaztest]="true"
        (changeMasterType)="changeMasterType($event)"
      ></personal-info>

      <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
        <div
          class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
        >
          {{ "TestParams" | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      </div>
      <div class="">
        <label>{{ "По программе доктарантуры" | translate }}</label>
        <select (change)="getGop($event)" formControlName="masterTypeId" class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
          <option disabled [value]="0">-----</option>
          <option [value]="item.id" *ngFor="let item of masterTypeList" value="">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
          </option>
        </select>

      </div>
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="md:col-span-3">
          <label for="gop">{{ "Gop" | translate }}</label>
          <select
            id="gop"
            (change)="gopChange(0)"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="eduProgramId"
          >
            <option disabled [value]="0">
              {{ "Gop" | translate }}
            </option>
            <option *ngFor="let item of gopList" [value]="item.id">
              {{ item.code }} -
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>

        <div class="md:col-span-3">
          <label for="selectObl">{{ "SelectObl" | translate }}</label>
          <select
            id="selectObl"
            (change)="getIntitute(0)"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="oblId"
          >
            <option disabled [value]="0">
              {{ "SelectObl" | translate }}
            </option>
            <option *ngFor="let item of oblList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>

        <div class="md:col-span-3">
          <label for="vuzName">{{ "VuzName" | translate }}</label>
          <select
            id="vuzName"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            (change)="langChang()"
            formControlName="eduInstituteId"
          >
            <option disabled [value]="0">
              {{ "VuzName" | translate }}
            </option>
            <option *ngFor="let item of vuzList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
          <span class="text-sm italic w-20">{{
            applicationForm.get("grantMessage").value
          }}</span>
        </div>
        <div class="md:col-span-3">
          <label for="testLangExam">{{ "TestLangExam" | translate }}</label>
          <select
            id="testLangExam"
            (change)="
              getProfileDisciplines(applicationForm.get('eduProgramId').value)
            "
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="testLangId"
          >
            <option disabled [value]="0">
              {{ "TestLangExam" | translate }}
            </option>
            <option *ngFor="let item of langList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>

<!--        <div class="md:col-span-3">-->
<!--            <label for="tgo">{{ "TgoLang" | translate }}</label>-->
<!--            <select-->
<!--              formControlName="tgoLangId"-->
<!--              id="tgo"-->
<!--              name="tgo"-->
<!--              class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"-->
<!--            >-->
<!--              <option disabled [value]="0">-->
<!--                {{ "TgoLang" | translate }}-->
<!--              </option>-->
<!--              <option *ngFor="let item of tgoLangList" [value]="item.id">-->
<!--                <span *ngIf="translate.currentLang == 'kz'"-->
<!--                  >{{ item.name.kk }}-->
<!--                </span>-->
<!--                <span *ngIf="translate.currentLang == 'ru'"-->
<!--                  >{{ item.name.ru }}-->
<!--                </span>-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
      </div>
      <div *ngIf="showSubjects && applicationForm.get('testLangId').value != 0">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4">
          <!-- <div class="md:col-span-3">
            <label for="profileDisciplines"
              >{{ "ProfileDisciplines" | translate }} 1</label
            >
            <ol>
              <li style="list-style: decimal">
                <select
                  id="profileDisciplines"
                  class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  formControlName="profileSubject1Id"
                >
                  <option disabled [value]="0">
                    {{ "ProfileDisciplines" | translate }}
                  </option>
                  <option
                    *ngFor="let item of profileDisciplines1List"
                    [value]="item.id"
                  >
                    <span *ngIf="translate.currentLang == 'kz'"
                      >{{ item.name.kk }}
                    </span>
                    <span *ngIf="translate.currentLang == 'ru'"
                      >{{ item.name.ru }}
                    </span>
                  </option>
                </select>
              </li>
            </ol>
          </div> -->

          <div class="md:col-span-3">
            <label for="profileDisciplines2"
              >{{ "ProfileDisciplines" | translate }} 2</label
            >
            <ol>
              <li></li>
              <li style="list-style: decimal">
                <select
                  id="profileDisciplines2"
                  class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  formControlName="profileSubject2Id"
                >
                  <option disabled [value]="0">
                    {{ "ProfileDisciplines" | translate }}
                  </option>
                  <option
                    *ngFor="let item of profileDisciplines2List"
                    [value]="item.id"
                  >
                    <span *ngIf="translate.currentLang == 'kz'"
                      >{{ item.name.kk }}
                    </span>
                    <span *ngIf="translate.currentLang == 'ru'"
                      >{{ item.name.ru }}
                    </span>
                  </option>
                </select>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div *ngIf="greEnable">
        <gre-template [applicationForm]="applicationForm"></gre-template>
      </div>

      <test-org-template
        [(applicationForm)]="applicationForm"
      ></test-org-template>

      <disability-template
        [(applicationForm)]="applicationForm"
        [(shortInfo)]="shortInfo"
      >
      </disability-template>

      <button
        (click)="postApplication()"
        class="bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8"
      >
        {{ "Edit" | translate }}
      </button>
    </div>
  </div>
</form>
