import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class probtestConfig {
  backend: any;
  backendEnt: any;
  backendPvo: any;
  backendNqt: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.backend = 'https://probtest.debug.testcenter.kz/prob_online_test/tester/core/api/v1';
      this.backendEnt = 'https://probtest.debug.testcenter.kz/prob_online_test/tester/ent/api/v1'; // Путь для localhost 
      this.backendPvo = 'https://probtest.debug.testcenter.kz/prob_online_test/tester/pvo/api/v1';
      this.backendNqt = 'https://probtest.debug.testcenter.kz/prob_online_test/tester/nqt/api/v1';
    }
    if (!isDevMode()) {
      this.backend = 'prob_online_test/tester/core/api/v1';
      this.backendEnt = 'prob_online_test/tester/ent/api/v1'; // Путь для боевого
      this.backendPvo = 'prob_online_test/tester/pvo/api/v1';
      this.backendNqt = 'prob_online_test/tester/nqt/api/v1';

    }

    this.apiRoutes = {

    }

  }
}
