import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';


@Component({
  selector: 'app-school-head',
  templateUrl: './school-head.component.html',
  styleUrls: ['./school-head.component.css']
})
export class SchoolHeadComponent implements OnInit {

  token: any;
  applicationForm: FormGroup;
  pageLangId: number;
  eduTypeList: any;
  langList: any;
  testData: any;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.token = this.route.snapshot.queryParamMap.get('token');

    this.getTestData();

    this.applicationForm = new FormGroup({
      eduTypeId: new FormControl(0),
      testLangId: new FormControl(0)
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

  }

  getTestData() {
    this.api.getTestInfoDir(this.token)
      .subscribe((res: any) => {
        this.testData = res.data;
        this.applicationForm.get('eduTypeId').patchValue(res.data.eduType.id);
        this.getEduType();

      }, (err: any) => {

      });

  }

  getEduType() {
    this.api.getEduTypes()
      .subscribe((res: any) => {
        this.eduTypeList = res.items;
        this.getLangList(1);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code: any) {
    this.api.getLanguages(this.applicationForm.get('eduTypeId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if (code == 1) {
          this.applicationForm.get('testLangId').patchValue(this.testData.testLang.id);
        } else {
          this.applicationForm.get('testLangId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  edit() {

    this.api.putTestInfoDir(this.applicationForm.value, this.token)
      .subscribe((res: any) => {
        window.location.href = this.route.snapshot.queryParamMap.get('url');

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  cancel() {
    window.location.href = this.route.snapshot.queryParamMap.get('url');
  }


}