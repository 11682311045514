import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-e-doshkola',
  templateUrl: './e-doshkola.component.html',
  styleUrls: ['./e-doshkola.component.css'],
})
export class EDoshkolaComponent implements OnInit {
  loading = false;
  applicationForm: FormGroup;
  jobTypeId = 0;
  pageLangId: any;
  subjectCount: any;
  subjectCountArray = [];
  showNasPunkt = true;
  fromActualCategories = [];
  toActualCategories = [];
  KATOOblList = [];
  KATOCityList = [];
  KATOSeloList = [];
  KATORaionList = [];
  jobList = [];
  subjectList = [];
  jobId: number;
  langList = [];
  testOrg = [];
  testPeriod = [];
  KATOs = [];
  KATOsubject = [];
  pseudoId = [];
  lastChild = false;
  year = '0000';
  fullDate = '00-00-0000';
  iin = '000000000000';
  phone = '+0 (000) 000-00-00';
  applicationId: any;
  appShortInfo: any;
  employmentTypeList: any;
  oblList: any;
  instituteSpecialityList: any;
  collegeSpecialityList: any;
  vuzObl = 0;
  raion = 0;
  vuzList: any;
  raionList: any;
  disabilityList: any;
  headTypeList: any;

  constructor(
    private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.jobTypeId = +this.route.snapshot.queryParamMap.get('jobType');
    this.getApplicationInfo();

    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    this.applicationForm = new FormGroup({
      jobTypeId: new FormControl(this.jobTypeId),
      lastname: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      diplomaSpecialityName: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      iin: new FormControl(),
      bin: new FormControl(),
      fromCategoryId: new FormControl(0),
      fromCategoryValidTo: new FormControl(''),
      toCategoryId: new FormControl(0),
      jobId: new FormControl(),
      eduPeriodStartYear: new FormControl(),
      eduPeriodFinishYear: new FormControl(),
      testOrgId: new FormControl(),
      testPeriodId: new FormControl(),
      testLangId: new FormControl(),
      firstLevelKato: new FormControl(),
      OblId: new FormControl(),
      RaionId: new FormControl(),
      SeloId: new FormControl(),
      subjects: new FormArray([]),
      kato: new FormArray([]),
      isWithoutJob: new FormControl(false),
      employmentTypeId: new FormControl(0),
      oblId: new FormControl(-1),
      eduInstituteId: new FormControl(0),
      eduSpecialityId: new FormControl(0),
      eduSchoolId: new FormControl(0),
      isHasDisability: new FormControl(0),
      isNeedHelperDueDisability: new FormControl(0),
      disabilityId: new FormControl(0),
      headerTypeId: new FormControl(null),
    });

    this.getProfileData();

    this.applicationForm.get('firstLevelKato').patchValue(-1);
    this.applicationForm.get('RaionId').patchValue(-1);
    this.applicationForm.get('SeloId').patchValue(-1);
    this.applicationForm.get('testOrgId').patchValue(-1);
    this.applicationForm.get('testPeriodId').patchValue(-1);
    this.applicationForm.get('testLangId').patchValue(-1);
    this.applicationForm.get('jobId').patchValue(-1);
  }

  get subjects(): FormArray {
    return this.applicationForm.get('subjects') as FormArray;
  }

  get katoGetter(): FormArray {
    return this.applicationForm.get('kato') as FormArray;
  }

  getApplicationInfo() {
    this.api.getApplicationInformation(this.applicationId).subscribe(
      (res: any) => {
        this.appShortInfo = res.data;
        this.applicationForm.get('email').patchValue(this.appShortInfo.email);
        this.applicationForm
          .get('firstname')
          .patchValue(this.appShortInfo.firstname);
        this.applicationForm
          .get('diplomaSpecialityName')
          .patchValue(this.appShortInfo.diplomaSpecialityName);
        this.applicationForm
          .get('eduPeriodFinishYear')
          .patchValue(this.appShortInfo.eduPeriodFinishYear);
        this.applicationForm
          .get('eduPeriodStartYear')
          .patchValue(this.appShortInfo.eduPeriodStartYear);
        this.applicationForm
          .get('fromCategoryId')
          .patchValue(this.appShortInfo.fromCategoryId);
        this.applicationForm.get('iin').patchValue(this.appShortInfo.iin);
        this.applicationForm
          .get('lastname')
          .patchValue(this.appShortInfo.lastname);
        this.applicationForm
          .get('patronymic')
          .patchValue(this.appShortInfo.patronymic);
        this.applicationForm
          .get('phoneNumber')
          .patchValue(this.appShortInfo.phoneNumber);
        this.applicationForm
          .get('toCategoryId')
          .patchValue(this.appShortInfo.toCategoryId);
        this.applicationForm
          .get('fromCategoryValidTo')
          .patchValue(this.appShortInfo.fromCategoryValidTo);
        this.applicationForm
          .get('testOrgId')
          .patchValue(this.appShortInfo.testOrgId);
        this.applicationForm
          .get('testLangId')
          .patchValue(this.appShortInfo.testLangId);
        this.applicationForm
          .get('isWithoutJob')
          .patchValue(this.appShortInfo.isWithoutJob);
        this.applicationForm
          .get('employmentTypeId')
          .patchValue(this.appShortInfo.employmentTypeId);
        this.applicationForm
          .get('eduInstituteId')
          .patchValue(this.appShortInfo.eduInstituteId);
        this.applicationForm
          .get('eduSpecialityId')
          .patchValue(this.appShortInfo.eduSpecialityId);
        this.applicationForm
          .get('eduSchoolId')
          .patchValue(this.appShortInfo.eduSchoolId);
        this.raion = this.appShortInfo.eduSchoolRaionId;

        this.applicationForm
          .get('oblId')
          .patchValue(this.appShortInfo.testOrgOblId);
        if (this.appShortInfo.employmentTypeId == 2) {
          this.vuzObl = this.appShortInfo.eduInstituteOblId;
        }
        if (this.appShortInfo.employmentTypeId == 3) {
          this.vuzObl = this.appShortInfo.eduSchoolOblId;
        }

        this.getEmploymentTypeId();
        this.getHelper();
        this.getHeaderType(this.appShortInfo.employmentTypeId);
        this.applicationForm.get('headerTypeId').patchValue(this.appShortInfo.headerTypeId);

        this.fromListOfActualCategories(
          this.applicationForm.get('employmentTypeId').value
        );
        this.toListOfActualCategories(
          this.applicationForm.get('employmentTypeId').value
        );
        this.getKATOOblList();
        this.getSubjectsLang(this.appShortInfo.employmentTypeId, this.appShortInfo.headerTypeId, false);
        this.getOblList();
        this.getsubjectCount();
        this.getTestOrgList();
        this.getInstituteSpeciality();
        this.getCollegeSpeciality();
        if (this.applicationForm.get('employmentTypeId').value == 2) {
          this.getEduPlaceList();
        }
        if (this.applicationForm.get('employmentTypeId').value == 3) {
          this.getEduPlaceList();
          this.getCollege();
        }
        this.setJob(this.appShortInfo.jobId, this.appShortInfo.kato);
        this.api
          .testPeriodList(
            this.jobTypeId,
            this.appShortInfo.testOrgId,
            this.applicationId,
            this.applicationForm.get('employmentTypeId').value
          )
          .subscribe(
            (res: any) => {
              this.testPeriod = res.items;
              this.applicationForm
                .get('testPeriodId')
                .patchValue(this.appShortInfo.testPeriodId);
            },
            (err: any) => {}
          );
      },
      (err: any) => {}
    );
  }

  getHeaderType(employmentTypeId: any) {
    this.api.getHeaderTypeList(employmentTypeId, this.jobTypeId).subscribe(
      (res: any) => {
        this.headTypeList = res.items;
      },
      (err: any) => {}
    );
  }

  getHelper() {
    this.api
      .getDisabilityNqt(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.disabilityList = res.items;
          let value = this.appShortInfo.isHasDisability;
          this.applicationForm
            .get('isHasDisability')
            .setValue(value.toString());
          this.applicationForm
            .get('disabilityId')
            .setValue(this.appShortInfo.disabilityId);
          this.applicationForm
            .get('isNeedHelperDueDisability')
            .setValue(this.appShortInfo.isNeedHelperDueDisability.toString());
        },
        (err: any) => {}
      );
  }

  getOblList() {
    this.api
      .getOblList(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.oblList = res.items;
        },
        (err: any) => {}
      );
  }

  getRaionList() {
    this.api
      .getRaionList(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value,
        this.vuzObl
      )
      .subscribe(
        (res: any) => {
          this.raionList = res.items;
        },
        (err: any) => {}
      );
  }

  getEduPlaceList() {
    this.raionList = [];
    this.vuzList = [];
    console.log(this.applicationForm.get('employmentTypeId').value == 2);

    if (this.applicationForm.get('employmentTypeId').value == 2) {
      this.api
        .getVuzList(
          this.jobTypeId,
          this.applicationForm.get('employmentTypeId').value,
          this.vuzObl
        )
        .subscribe(
          (res: any) => {
            this.vuzList = res.items;
          },
          (err: any) => {}
        );
    }
    if (this.applicationForm.get('employmentTypeId').value == 3) {
      this.getRaionList();
    }
  }

  getCollege() {
    this.api
      .getCollegeList(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value,
        this.vuzObl,
        this.raion
      )
      .subscribe(
        (res: any) => {
          this.vuzList = res.items;
        },
        (err: any) => {}
      );
  }

  getLists() {
    this.vuzObl = -1;
    this.applicationForm.get('oblId').patchValue(-1);
    this.applicationForm.get('testOrgId').patchValue(-1);
    this.applicationForm.get('testPeriodId').patchValue(-1);
    this.applicationForm.get('testLangId').patchValue(-1);
    this.raionList = [];
    this.vuzList = [];
    this.oblList = [];
    this.collegeSpecialityList = [];
    this.instituteSpecialityList = [];
    this.applicationForm.get('testLangId').patchValue(-1);
    this.applicationForm.get('toCategoryId').patchValue(-1);
    this.testOrg = [];
    this.testPeriod = [];
    this.applicationForm.get('oblId').patchValue(-1);
    if (this.subjects.controls.length > 0) {
      this.subjects.controls[0].get('subjectId').patchValue(0);
    }
    this.getInstituteSpeciality();
    this.getCollegeSpeciality();
    this.fromListOfActualCategories(
      this.applicationForm.get('employmentTypeId').value
    );
    this.toListOfActualCategories(
      this.applicationForm.get('employmentTypeId').value
    );
    this.getOblList();
    this.getsubjectCount();
  }

  getInstituteSpeciality() {
    this.api.getInstituteSpeciality(this.jobTypeId).subscribe(
      (res: any) => {
        this.instituteSpecialityList = res.items;
      },
      (err: any) => {}
    );
  }

  getCollegeSpeciality() {
    this.api.getCollegeSpeciality(this.jobTypeId).subscribe(
      (res: any) => {
        this.collegeSpecialityList = res.items;
      },
      (err: any) => {}
    );
  }

  getEmploymentTypeId() {
    this.api.getEmploymentTypeId(this.jobTypeId).subscribe(
      (res: any) => {
        this.employmentTypeList = res.items;
      },
      (err: any) => {}
    );
  }

  getTestOrgList(code = 0) {
    this.api
      .testOrgList(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value,
        this.applicationForm.get('oblId').value
      )
      .subscribe(
        (res: any) => {
          if (code == 1) {
            this.applicationForm.get('testOrgId').patchValue(-1);
            this.applicationForm.get('testPeriodId').patchValue(-1);
            this.testPeriod = [];
          }
          this.testOrg = res.items;
        },
        (err: any) => {}
      );
  }

  getTestPeriodList(code: number = 1) {
    this.api
      .testPeriodList(
        this.jobTypeId,
        this.applicationForm.get('testOrgId').value,
        0,
        this.applicationForm.get('employmentTypeId').value
      )
      .subscribe(
        (res: any) => {
          if (res.items.length == 0) {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  'не осталось свободных мест тестирования в данном городе, дополнительные места не предусмотрены.',
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  'Бұл қалада бос тестілеу орыны қалмады, қосымша орындар қарастырылмаған',
                  NotificationType.Error,
                  5000
                );
          }
          if (code == 0) {
            this.applicationForm.get('testPeriodId').patchValue(-1);
          }
          this.testPeriod = res.items;
        },
        (err: any) => {}
      );
  }

  changeIIN() {
    this.applicationForm.get('firstname').setValue('');
    this.applicationForm.get('lastname').setValue('');
    this.applicationForm.get('patronymic').setValue('');
  }

  getProfileData() {
    this.api.profileInfo().subscribe(
      (res: any) => {
        this.applicationForm.get('iin').setValue(res.data.iin);
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
        this.applicationForm.get('email').setValue(res.data.email);
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);
      },
      (err: any) => {}
    );
  }

  findData(iin: number) {
    this.loading = true;
    this.api.personalData(iin).subscribe(
      (res: any) => {
        this.loading = false;
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
      },
      (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  fromListOfActualCategories(employmentTypeId: any) {
    this.api
      .getFromListOfActualCategories(this.jobTypeId, employmentTypeId)
      .subscribe(
        (res: any) => {
          this.fromActualCategories = res.items;
        },
        (err: any) => {}
      );
  }

  toListOfActualCategories(employmentTypeId: any) {
    this.api
      .getToListOfActualCategories(this.jobTypeId, employmentTypeId)
      .subscribe(
        (res: any) => {
          this.toActualCategories = res.items;
        },
        (err: any) => {}
      );
  }

  getKATOsList(i, j, firstSelect) {
    let id: number;
    if (firstSelect) {
      id = this.applicationForm.get('firstLevelKato').value;
      this.lastChild = false;
    } else {
      id = this.KATOs[i][j].id;
      this.lastChild = !this.KATOs[i][j].hasChildren;
    }
    if (!this.lastChild || firstSelect) {
      this.api.getKATOList(this.jobTypeId, id, this.pageLangId).subscribe(
        (res: any) => {
          if (firstSelect) {
            console.log(1);
            this.KATOs = [];
            this.pseudoId = [];
            this.KATOs.push(res.data.items);
          }

          if (firstSelect == false && this.lastChild) {
            console.log(2);
            this.KATOs.splice(i + 1, 1);
            this.KATOs.push(res.data.items);
          }
          if (firstSelect == false && this.KATOs[i][j].hasChildren) {
            console.log(3);
            this.KATOs.splice(i + 1, 2);
            this.KATOs.push(res.data.items);
          }
        },
        (err: any) => {}
      );
    }
    if (!firstSelect && !this.KATOs[i][j].hasChildren) {
      this.KATOs.splice(i + 1, this.KATOs.length);
      console.log(4);
      this.getJobItems(id, false);
    } else {
      this.lastChild = false;
    }
  }

  getKATOOblList() {
    this.api.getKATOList(this.jobTypeId, 0, this.pageLangId).subscribe(
      (res: any) => {
        this.KATOOblList = res.data.items;
      },
      (err: any) => {}
    );
  }

  getJobItems(nasPunkId, binSearch) {
    this.api.getJobList(this.jobTypeId, nasPunkId, this.pageLangId).subscribe(
      (res: any) => {
        this.jobList = res.items;
      },
      (err: any) => {}
    );
    if (binSearch == true) {
      setTimeout(() => {
        this.applicationForm.get('jobId').setValue(this.jobId);
        this.loading = false;
      }, 1000);
    }
  }

  getsubjectCount() {
    this.api
      .getsubjectCount(
        this.jobTypeId,
        this.applicationForm.get('employmentTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.subjectCount = res.data.subjectCount;
          this.subjectCountLoop();
        },
        (err: any) => {}
      );
  }

  subjectCountLoop() {
    this.subjectCountArray = [];
    let subj = this.applicationForm.controls.subjects as FormArray;
    for (var i = ++this.subjectCountArray.length; i <= this.subjectCount; i++) {
      this.api
        .getSubjectItems(
          this.jobTypeId,
          i,
          this.applicationForm.get('employmentTypeId').value
        )
        .subscribe(
          (response: any) => {
            this.subjectList.push(response.items);
          },
          (error: any) => {}
        );
      this.addSubject(i);
    }
    this.langList.reverse();
  }

  getSubjectsLang(employmentTypeId: any, headerTypeId: any, change: boolean) {
    if (change) {
      this.applicationForm.get('testLangId').patchValue(-1);
    }
    this.api
      .getHeaderLang(this.jobTypeId, employmentTypeId, headerTypeId)
      .subscribe(
        (response: any) => {
          this.langList = response.items;
        },
        (error: any) => {}
      );
  }

  newSubject(i): FormGroup {
    return this.fb.group({
      ord: new FormControl(i),
      subjectId: new FormControl(''),
      langId: new FormControl(''),
    });
  }

  setSubjectLangIdValue(status, i, value) {
    this.subjects.controls[i].get('langId').setValue(value);
  }

  postForm() {
    let phone = this.applicationForm.get('phoneNumber').value;
    const regex = /^7\d{10}$/;
    if (phone == null || phone.trim().length == 0) {
      this.translate.currentLang == 'ru'
        ? this._notifications.create(
            'Ошибка',
            'Не указан контактный телефон',
            NotificationType.Error,
            5000
          )
        : this._notifications.create(
            'Қате',
            'Байланыс телефоны көрсетілмеген',
            NotificationType.Error,
            5000
          );
      return;
    } else if (!regex.test(phone)) {
      this.translate.currentLang == 'ru'
        ? this._notifications.create(
            'Ошибка',
            'Передано некорректное значение номера контактного телефона (+7 (777) 777-77-77)',
            NotificationType.Error,
            5000
          )
        : this._notifications.create(
            'Қате',
            'Байланыс телефонының нөмірі қате берілді (+7 (777) 777-77-77)',
            NotificationType.Error,
            5000
          );
      return;
    }

    this.loading = true;
    this.api
      .editApplicationById(this.applicationId, this.applicationForm.value)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.router.navigateByUrl('/profile/history');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Заявление',
                'сохранено успешно',
                NotificationType.Success,
                5000
              )
            : this._notifications.create(
                'Успешно',
                'Пароль изменен',
                NotificationType.Success,
                5000
              );
        },
        (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  addSubject(i) {
    this.subjects.push(this.newSubject(i));
    this.subjectCountArray.push(this.subjects);
  }

  addKato(i) {
    this.katoGetter.push(this.createKato(i));
  }

  setSubjectValue(status, i, value) {
    this.subjects.controls[i].get('subjectId').setValue(value);
    this.applicationForm.get('testLangId').patchValue(-1);
    //this.getSubjectsLang(i);
  }

  createKato(katoId): FormGroup {
    return this.fb.group({
      katoId,
    });
  }

  setJob(jobId: any, kato: any) {
    this.loading = true;
    this.KATOs = [];
    this.jobId = jobId;
    this.applicationForm.get('firstLevelKato').setValue(kato[0]);
    kato.forEach((element) => {
      this.api.getKATOList(this.jobTypeId, element, this.pageLangId).subscribe(
        (resp: any) => {
          if (resp.data.items.length > 0) {
            this.KATOs.push(resp.data.items);
          }
          this.KATOs.sort();
          this.KATOs.reverse();
        },
        (err: any) => {}
      );
    });
    this.katoGetter.controls = [];

    this.pseudoId = [];

    kato.forEach((elem) => {
      this.pseudoId.push(elem);
    });

    this.getJobItems(kato[kato.length - 1], true);
  }

  searchJob(bin) {
    this.api.searchJobByBin(bin).subscribe((res: any) => {
      this.loading = true;
      this.KATOs = [];
      this.jobId = res.data.id;
      this.applicationForm.get('firstLevelKato').setValue(res.data.kato[0]);
      res.data.kato.forEach((element) => {
        this.api
          .getKATOList(this.jobTypeId, element, this.pageLangId)
          .subscribe(
            (resp: any) => {
              if (resp.data.items.length > 0) {
                this.KATOs.push(resp.data.items);
              }
              this.KATOs.sort();
              this.KATOs.reverse();
            },
            (err: any) => {}
          );
      });

      console.log(res);
      this.katoGetter.controls = [];

      this.pseudoId = [];

      res.data.kato.forEach((elem) => {
        this.pseudoId.push(elem);
      });
      console.log(this.pseudoId);

      this.getJobItems(res.data.kato[res.data.kato.length - 1], true);
    });
  }
}
