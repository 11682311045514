import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class konkursConfig {
  konkursBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.konkursBackend = 'https://app.debug.testcenter.kz/konkurs/bach/app/api/v1'; // Путь для localhost  
    }
    if (!isDevMode()) {
      this.konkursBackend = 'konkurs/bach/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
        getAppItems: this.konkursBackend + '/app/items'
    }

  }
}
