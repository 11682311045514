import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class LanguageSelectComponent implements OnInit {

  loading = true;

  constructor(public translate: TranslateService,
              private router: Router) { 
    
  }

  ngOnInit(): void {

    //localStorage.setItem('token', 'null');

    if(localStorage.getItem('Lang') == 'ru'){
      this.router.navigate(['/auth']);
    }
    if(localStorage.getItem('Lang') == 'kz'){
      this.router.navigate(['/auth']);
    }
    setTimeout(() => {
    this.loading = false;
    }, 1500);
  }

  setRuLang(){
    localStorage.setItem('Lang', 'ru');
    this.translate.currentLang = localStorage.getItem('Lang');
    this.translate.setDefaultLang(localStorage.getItem('Lang'));
    this.router.navigate(['/auth']);
  }

  setKzLang(){
    localStorage.setItem('Lang', 'kz');
    this.translate.currentLang = localStorage.getItem('Lang');
    this.translate.setDefaultLang(localStorage.getItem('Lang'));
    this.router.navigate(['/auth']);
  }

}
