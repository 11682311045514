<div *ngIf="router.url == '/profile/helper/ent-konkurs'">
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
  <div class="text-2xl text-center font-bold text-blue-500">
    {{
      translate.currentLang === "ru"
        ? "Помощник для участия в Конкурсе по присуждению образовательного гранта"
        : "Білім грантын тағайындау конкурсына қатысу үшін көмекші"
    }}
  </div>

  <div class="flex flex-col md:flex-row gap-3 p-4 bg-gray-100 mt-5 w-full justify-center items-center">
    <input type="radio" name="sokr" value="060426601708" id="sokr" [(ngModel)]="this.body.iin" (change)="getCertificates()" />
    <label for="sokr">Сокращенный</label>
    <input type="radio" name="konkurs" value="070117650699" id="konkurs" [(ngModel)]="this.body.iin" (change)="getCertificates()" />
    <label for="konkurs">Полный</label>
    <input type="radio" name="tvor" value="060608602071" id="tvor" [(ngModel)]="this.body.iin" (change)="getCertificates()" />
    <label for="tvor">Творческий</label>
  </div>

  <div
    class="flex flex-col md:flex-row gap-3 p-4 bg-gray-100 mt-5 w-full justify-center items-center"
  >
    <div class="">
      {{
        translate.currentLang === "ru"
          ? "Выберите сертификат"
          : "Куәлікті таңдаңыз"
      }}
    </div>
    <div class="">
      <select
        [(ngModel)]="selectedCert" (change)="chageCert()"
        class="w-full border-2 border-sky-500 focus:outline-none focus:border-sky-500 text-sky-500 rounded px-2 md:px-3 py-0 md:py-1 tracking-wider"
      >
        <option disabled selected [value]="-1">
          {{
            translate.currentLang === "ru"
              ? "Выберите сертификат"
              : "Куәлікті таңдаңыз"
          }}
        </option>
        <option *ngFor="let item of certList" [ngValue]="item">
          <span *ngIf="translate.currentLang == 'kz'"
            >{{ item.certificateNumber }} ({{
              item.testDate | date : "dd.MM.yyyy"
            }}ж - {{ item.score }} балл)
          </span>
          <span *ngIf="translate.currentLang == 'ru'"
            >{{ item.certificateNumber }} ({{
              item.testDate | date : "dd.MM.yyyy"
            }}г - {{ item.score }} балл)
          </span>
        </option>
      </select>
    </div>

    <!-- <button
    (click)="getInfo()"
    type="submit"
    class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-1 font-medium text-white shadow-sm hover:bg-blue-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
  >
    {{ translate.currentLang === 'ru' ? 'Поиск' : 'Іздеу'}}
  </button> -->
  </div>

  <div *ngIf="data">
    <div class="mt-5">
      <ul class="">
        <li class="text-left mb-10">
          <div class="flex flex-row items-start">
            <div class="flex flex-col items-center justify-center mr-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6m0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full">
              <p
                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 solid px-4 py-2"
              >
                {{
                  translate.currentLang === "ru"
                    ? "Данные абитуриента"
                    : "Абитуриенттің мәліметтері"
                }}
              </p>
              <div class="mt-2 text-base leading-6 bg-gray-100 px-4 py-2">
                <div class="grid grid-cols-2 gap-8 md:grid-cols-6">
                  <div class="col-span-2">
                    <h6 class="font-bold">
                      {{ translate.currentLang === "ru" ? "ФИО" : "ТАӘ" }}
                    </h6>
                    <p class="">
                      {{ data?.out_lastname }}
                      {{ data?.out_firstname }}
                      {{ data?.out_patronymic }}
                    </p>
                  </div>
                  <div class="">
                    <h6 class="font-bold">
                      {{ translate.currentLang === "ru" ? "ИИН" : "ЖСН" }}
                    </h6>
                    <p class="">{{ data?.out_iin }}</p>
                  </div>

                  <div class="">
                    <h6 class="font-bold">
                      {{ translate.currentLang === "ru" ? "ИКТ" : "ТЖК" }}
                    </h6>
                    <p class="">{{ data?.out_student_test }}</p>
                  </div>

                  <div class="col-span-2">
                    <h6 class="font-bold">
                      {{
                        translate.currentLang === "ru"
                          ? "Дата тестирования"
                          : "Тестілену күні"
                      }}
                    </h6>
                    <p class="" *ngIf="data?.out_test_date">
                      {{ data.out_test_date | date : "dd.MM.yyyy" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="mt-2">
      <ul class="">
        <li class="text-left mb-10">
          <div class="flex flex-row items-start">
            <div class="flex flex-col items-center justify-center mr-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V9h14zM5 7V5h14v2zm2 4h10v2H7zm0 4h7v2H7z"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full">
              <p
                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 solid px-4 py-2"
              >
                {{
                  translate.currentLang === "ru"
                    ? "Результаты ЕНТ и экзаменов для участия в конкурсе"
                    : "Конкурсқа қатысу үшін ҰБТ мен емтихан нәтижелері"
                }}
              </p>
              <div class="mt-2 text-sm leading-6">
                <div class="grid grid-cols-2 gap-2 md:grid-cols-3">
                  <div class="bg-gray-100 p-1">
                    <p class="font-bold underline">
                      {{
                        translate.currentLang === "ru"
                          ? "Результаты ЕНТ"
                          : "ҰБТ нәтижесі"
                      }}
                    </p>
                    <p *ngIf="data?.out_sub_rus_1">
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "Предмет 1: " : "Пән 1: "
                      }}</span>
                      <span class=""
                        >{{
                          translate.currentLang === "ru"
                            ? data?.out_sub_rus_1
                            : data?.out_sub_kaz_1
                        }}
                        - {{ data?.out_ball1 }} балл</span
                      >
                    </p>
                    <p *ngIf="data?.out_sub_rus_2">
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "Предмет 2: " : "Пән 2: "
                      }}</span>
                      <span class=""
                        >{{
                          translate.currentLang === "ru"
                            ? data?.out_sub_rus_2
                            : data?.out_sub_kaz_2
                        }}
                        - {{ data?.out_ball2 }} балл</span
                      >
                    </p>
                    <p *ngIf="data.out_test_type_id == 1 && data?.out_sub_rus_3">
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "Предмет 3: " : "Пән 3: "
                      }}</span>
                      <span class=""
                        >{{
                          translate.currentLang === "ru"
                            ? data?.out_sub_rus_3
                            : data?.out_sub_kaz_3
                        }}
                        - {{ data?.out_ball3 }} балл</span
                      >
                    </p>
                    <p *ngIf="data.out_test_type_id == 1 && data?.out_sub_rus_4">
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "Предмет 4: " : "Пән 4: "
                      }}</span>
                      <span class=""
                        >{{
                          translate.currentLang === "ru"
                            ? data?.out_sub_rus_4
                            : data?.out_sub_kaz_4
                        }}
                        - {{ data?.out_ball4 }} балл</span
                      >
                    </p>
                    <p *ngIf="data.out_test_type_id == 1 && data?.out_sub_rus_5">
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "Предмет 5: " : "Пән 5: "
                      }}</span>
                      <span class=""
                        >{{
                          translate.currentLang === "ru"
                            ? data?.out_sub_rus_5
                            : data?.out_sub_kaz_5
                        }}
                        - {{ data?.out_ball5 }} балл</span
                      >
                    </p>
                    <p>
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Сумма баллов: "
                            : "Балл сомасы: "
                        }}</span
                      >
                      <span class=""
                        >{{ data?.out_summball }} балл</span
                      >
                    </p>
                    <p>
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Сумма баллов для участия в Конкурсе: "
                            : "Конкурсқа қатысу үшін балл сомасы: "
                        }}</span
                      >
                      <span
                        >{{
                          totalKonkursBall
                            ? totalKonkursBall
                            : data?.out_summball
                        }}
                         балл</span
                      >
                    </p>
                  </div>
                  <div class="bg-gray-100 p-1" *ngIf="tvorExam.out_iin">
                    <p class="font-bold underline">
                      {{
                        translate.currentLang === "ru"
                          ? "Творческий экзамен"
                          : "Шығармашылық емтихан"
                      }}
                    </p>
                    <p>
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "ВУЗ: " : "ЖОО: "
                      }}</span>
                      <span
                        class=""
                        *ngIf="
                          tvorExam?.out_institute_id && tvorExam?.out_i_name_rus
                        "
                        >{{ tvorExam?.out_institute_id }} -
                        {{
                          translate.currentLang === "ru"
                            ? tvorExam?.out_i_name_rus
                            : tvorExam?.out_i_name_kaz
                        }}</span
                      >
                    </p>
                    <p>
                      <span class="font-bold">{{
                        translate.currentLang === "ru" ? "ГОП: " : "БББТ: "
                      }}</span>
                      <span
                        class=""
                        *ngIf="tvorExam?.out_code && tvorExam?.out_ep_name_rus"
                        >{{ tvorExam?.out_code }} -
                        {{
                          translate.currentLang === "ru"
                            ? tvorExam?.out_ep_name_rus
                            : tvorExam?.out_ep_name_kaz
                        }}</span
                      >
                    </p>
                    <p>
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Творческий 1: "
                            : "Шығармашылық 1: "
                        }}</span
                      >
                      <span
                        class=""
                        *ngIf="
                          tvorExam?.out_sub_name_rus_1 &&
                          tvorExam?.out_tvor_1_ball
                        "
                        >{{
                          translate.currentLang === "ru"
                            ? tvorExam?.out_sub_name_rus_1
                            : tvorExam?.out_sub_name_kaz_1
                        }}
                        - {{ tvorExam?.out_tvor_1_ball }} балл
                      </span>
                    </p>
                    <p *ngIf="tvorExam?.out_sub_name_rus_2">
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Творческий 2"
                            : "Шығармашылық 2"
                        }}:
                      </span>
                      <span
                        class=""
                        >{{
                          translate.currentLang === "ru"
                            ? tvorExam?.out_sub_name_rus_2
                            : tvorExam?.out_sub_name_kaz_2
                        }}
                        - {{ tvorExam?.out_tvor_2_ball }} балл
                      </span>
                    </p>
                    <p>
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Сумма баллов за творческие экзамены: "
                            : "Шығармашылық емтихан үшін балл сомасы: "
                        }}</span
                      >
                      <span class="" *ngIf="tvorExam.out_ep_name_rus != null"
                        >{{ tvorExam?.out_tvor_1_ball +  tvorExam?.out_tvor_2_ball}} балл
                      </span>
                    </p>
                    <p>
                      <span class="font-bold"
                        >{{
                          translate.currentLang === "ru"
                            ? "Сумма баллов для участия в Конкурсе: "
                            : "Конкурсқа қатысу үшін балл сомасы: "
                        }}</span
                      >
                      <span
                        class=""
                        *ngIf="
                        tvorExam.out_ep_name_rus != null
                        "
                        >{{
                          data?.out_summball_tvor  + tvorExam?.out_tvor_1_ball +  tvorExam?.out_tvor_2_ball
                        }}
                        балл
                      </span>
                    </p>
                  </div>
                  <div class="bg-gray-100 p-1" *ngIf="ped.out_spec_ped_result_kaz || med.out_spec_med_result_kaz">
                    <p class="font-bold underline">
                      {{
                        translate.currentLang === "ru"
                          ? "Специальный экзамен"
                          : "Арнайы емтихан"
                      }}
                    </p>
                    <p>
                      <span class="font-bold">{{
                        translate.currentLang === "ru"
                          ? "Пед.науки:"
                          : "Пед.ғылымдар:"
                      }}</span>
                      <span class="">{{
                        translate.currentLang === "ru"
                          ? ped?.out_spec_ped_result_rus
                          : ped?.out_spec_ped_result_kaz
                      }}</span>
                    </p>
                    <p *ngIf="med.out_spec_med_result_rus">
                      <span class="font-bold">{{
                        translate.currentLang === "ru"
                          ? "Мед.науки:"
                          : "Мед.ғылымдар:"
                      }}</span>
                      <span class="">{{
                        translate.currentLang === "ru"
                          ? med?.out_spec_med_result_rus
                          : med?.out_spec_med_result_kaz
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt-2" *ngIf="data.hasEnglish">
      <ul>
        <li class="text-left mb-10">
          <div class="flex flex-row items-start">
            <div class="flex flex-col items-center justify-center mr-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M3 10h11v2H3zm0-2h11V6H3zm0 8h7v-2H3zm15.01-3.13l1.41-1.41l2.12 2.12l-1.41 1.41zm-.71.71l-5.3 5.3V21h2.12l5.3-5.3z"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full">
              <p
                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 px-4 py-2"
              >
                {{
                  translate.currentLang === "ru"
                    ? "Сертификаты по иностранному языку"
                    : "Шет тілі сертификаттары"
                }}
              </p>
              <div class="mt-2 text-base leading-6 bg-gray-100 px-4 py-2">
                <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
                  <span class="w-full">{{
                    translate.currentLang === "ru"
                      ? "Выберите вид сертификата"
                      : "Сертификат түрін таңдау"
                  }}</span>
                  <select
                    class="w-full h-6"
                    [(ngModel)]="selectedForeignLangCert"
                  >
                    <option disabled [value]="-1">
                      {{ "SelectСertificate" | translate }}
                    </option>
                    <option
                      *ngFor="let item of foreignLangCertTypes"
                      [value]="item.id"
                    >
                      <span *ngIf="translate.currentLang == 'kz'">{{
                        item.nameKaz
                      }}</span>
                      <span *ngIf="translate.currentLang == 'ru'">{{
                        item.nameRus
                      }}</span>
                    </option>
                  </select>
                </div>

                <div class="grid grid-cols-2 gap-4 md:grid-cols-3 mt-2">
                  <span class="w-full">{{
                    translate.currentLang === "ru"
                      ? "Введите балл"
                      : "Баллды енгізіңіз"
                  }}</span>
                  <input
                    (change)="scoreCheck()"
                    [mask]="
                      selectedForeignLangCert == 4
                        ? foreignLangCertScoreMaskIelts
                        : foreignLangCertScoreMask
                    "
                    [(ngModel)]="foreignLangCertScore"
                    placeholder="{{ 'Ball' | translate }}"
                    class="w-full h-8 pl-1 bg-gray-200 rounded"
                    type="text"
                  />
                  <button
                    [ngClass]="
                      selectedForeignLangCert > 0 && foreignLangCertScore
                        ? 'inline-flex w-full items-center justify-center rounded-md bg-blue-500 px-4 py-1 font-medium text-white shadow-sm hover:bg-blue-600 sm:mt-0 sm:w-auto sm:text-sm'
                        : 'font-medium inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-300 px-4 py-1 rounded-md cursor-not-allowed opacity-50 sm:mt-0 sm:w-auto sm:text-sm'
                    "
                    (click)="calculate()"
                  >
                    {{
                      translate.currentLang === "ru" ? "Рассчитать" : "Есептеу"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="mt-2">
      <ul class="">
        <li class="text-left mb-10">
          <div class="flex flex-row items-start">
            <div class="flex flex-col items-center justify-center mr-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full">
              <p
                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 solid px-4 py-2"
              >
                {{
                  translate.currentLang === "ru"
                    ? "Куда можно поступить?"
                    : "Қайда оқуға түсуге болады?"
                }}
              </p>
              <div class="mt-2 leading-6 bg-gray-100 px-4 py-2">
                <button (click)="goPage(1)"
                  class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-1 font-medium text-white shadow-sm hover:bg-blue-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {{ translate.currentLang === "ru" ? "Показать" : "Көрсету" }}
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="mt-2">
      <ul class="">
        <li class="text-left mb-10">
          <div class="flex flex-row items-start">
            <div class="flex flex-col items-center justify-center mr-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 7H9c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2m0 2v2H9V9zm-6 6v-2h2v2zm2 2v2h-2v-2zm-4-2H9v-2h2zm6-2h2v2h-2zm-8 4h2v2H9zm8 2v-2h2v2zM6 17H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v1h-2V5H5v10h1z"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full">
              <p
                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 solid px-4 py-2"
              >
                {{
                  translate.currentLang === "ru"
                    ? "Аналитика прошлых лет"
                    : "Өткен жылдар бойынша талдау"
                }}
              </p>
              <div class="mt-2 leading-6 bg-gray-100 px-4 py-2">
                <button (click)="goPage(2)"
                  class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-1 font-medium text-white shadow-sm hover:bg-blue-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {{ translate.currentLang === "ru" ? "Показать" : "Көрсету" }}
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div> -->
  </div>

  <div *ngIf="modalVisible" class="overlay">
    <div class="modal-window">
      <div class="relative">
        
        <div
          (click)="modalVisible = false"
          class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold"
        >
          X
        </div>
      </div>
      <div class="text-center mt-4">
        Чтобы перейти сначала сделайте рассчет
      </div>
    </div>
  </div>

  <!--  <div class="mt-2">-->
  <!--      <ul class="">-->
  <!--        <li class="text-left mb-10">-->
  <!--          <div class="flex flex-row items-start">-->
  <!--            <div class="flex flex-col items-center justify-center mr-2">-->
  <!--              <div>-->
  <!--                  <svg xmlns="http://www.w3.org/2000/svg" class="flex items-center justify-center h-12 w-12 p-1 rounded-full bg-blue-500 text-white border-4 border-white text-xl font-semibold" viewBox="0 0 24 24"><path fill="currentColor" d="M10 20h4V4h-4zm-6 0h4v-8H4zM16 9v11h4V9z"/></svg>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="w-full">-->
  <!--              <p-->
  <!--                class="text-base text-white leading-6 bg-blue-500 border border-blue-500 solid px-4 py-2"-->
  <!--              >-->
  <!--                  Статистика приемной кампании текущего года-->
  <!--              </p>-->
  <!--              <div class="mt-2 leading-6 bg-gray-100 px-4 py-2">-->
  <!--                <button routerLink="statistics"-->
  <!--                  class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-1 font-medium text-white shadow-sm hover:bg-blue-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"-->
  <!--                >-->
  <!--                  Показать-->
  <!--                </button>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
</div>
<router-outlet></router-outlet>
