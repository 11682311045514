import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-modo',
  templateUrl: './modo.component.html',
  styleUrls: ['./modo.component.css']
})
export class ModoComponent implements OnInit {

  token: any;
  applicationForm: FormGroup;
  pageLangId: number;
  classNumList: any;
  langList: any;
  testData: any;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.token = this.route.snapshot.queryParamMap.get('token');

    this.getTestData();

    this.applicationForm = new FormGroup({
      schoolClassNumId: new FormControl(0),
      testLangId: new FormControl(0)
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

  }

  getTestData() {
    this.api.getModoTestData(this.token)
      .subscribe((res: any) => {
        this.testData = res.data;
        this.applicationForm.get('schoolClassNumId').patchValue(res.data.schoolClassNumId);
        this.getClassNum();

      }, (err: any) => {

      });

  }

  getClassNum() {
    this.api.getClassNum()
      .subscribe((res: any) => {
        this.classNumList = res.items;
        this.getLangList(1);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code: any) {
    this.api.getClassNumLang(this.applicationForm.get('schoolClassNumId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if (code == 1) {
          this.applicationForm.get('testLangId').patchValue(this.testData.testLangId);
        } else {
          this.applicationForm.get('testLangId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  edit() {
    this.api.putModoTestData(this.applicationForm.value, this.token)
      .subscribe((res: any) => {
        window.location.href = this.route.snapshot.queryParamMap.get('url');

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  cancel() {
    window.location.href = this.route.snapshot.queryParamMap.get('url');
  }


}
