<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<p class="text-center text-3xl font-hairline text-blue-500 mb-4">{{ 'EditPassword' | translate }}</p>
<form action="" (ngSubmit)="edit(3)" [formGroup]="changePassword">
    <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
        <input formControlName="oldPassword" placeholder="*{{ 'EnterYourCurrentPassword' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black" type="password"  >
    </show-hide-password>
    <div *ngIf="oldPassword.invalid && oldPassword.touched" class="err-txt">
        *{{ 'FillInTheField' | translate }}
    </div>
    <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
        <input (keyup)="checkPass()" formControlName="newPassword" placeholder="*{{ 'EnterNewCurrentPassword' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black" type="password"  >
    </show-hide-password>
    <div *ngIf="newPassword.invalid && newPassword.touched" class="err-txt">
        *{{ 'FillInTheField' | translate }}
    </div>
    <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
        <input (keyup)="checkPass()" type="password" formControlName="confirmpassword" placeholder="*{{ 'RepeatNewCurrentPassword' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black">
    </show-hide-password>
    <div *ngIf="!samePass && confirmpassword.touched" class="err-txt">
        *{{ 'NewPasswordsDoNotMatch' | translate }}
    </div>
    <p class="text-gray-500 mt-4 text-center italic"> <span [routerLink]="'/forgot-pass'" class="cursor-pointer underline">{{ 'ForgotYourPassword' | translate}}?</span></p>
    <div class="text-center mt-8">
        <button [routerLink]="'/profile/im'" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{ 'Cancel' | translate }}</button>
        <button type="submit" [disabled]="!samePass" class="bg-green-500 ml-16 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">{{ 'Save' | translate }}</button>    
    </div>
</form>