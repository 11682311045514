<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="text-xl z-10 relative text-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16">
    {{ 'NCT' | translate }}
</div>
<div class="container">
    <div class="bg-gray-200 auth-window mt-16 text-center md:w-3/6 sm:w-4/6 mx-auto">
        <div class="flex bg-white">
            <div (click)="showG()" class="w-1/2 xl:text-xl lg:text-base md:text-base auth-hover cursor-pointer p-2" [ngClass]="{'bg-gray-200': isCitizen, 'z-10': isCitizen,'text-blue-900': isCitizen,'font-bold ': isCitizen,'hover:underline': !isCitizen}">{{ 'IsCitizen' | translate }}</div>
<!--            <div (click)="showNG()" class="w-1/2 xl:text-xl lg:text-base md:text-base reg-hover cursor-pointer p-2" [ngClass]="{'bg-gray-200': !isCitizen, 'z-10': !isCitizen,'font-bold': !isCitizen,'text-blue-900': !isCitizen, 'hover:underline': isCitizen}" >{{ 'IsNotCitizen' | translate }}</div>-->
        </div>
        <div class="w-full px-4 pb-4">
            <form
             [formGroup]="form" class="" action="">
                <div class="">
                    <div class="h-full text-center">
                        <p class="mx-10 p-4 font-semibold text-lg">
                            {{ 'EnterProfileData' | translate }}
                        </p>
                        <form [formGroup]="userInfoForm">
                            <div class="flex user-form justify-between mx-auto">
                                <div class="text-left">
                                    <div *ngIf="isCitizen" class="mb-3">
                                        {{ 'IIN' | translate }} <span class="text-red-500">*</span>
                                        <div *ngIf="iinCheck.invalid && iinCheck.touched" class="err-txt">
                                            *{{ 'FillInTheField' | translate }}
                                        </div>
                                    </div>

                                    <p class="mb-3">
                                        {{ 'Surname' | translate }}
                                    </p>
                                    <p class="mb-3">
                                        {{ 'Name' | translate }}
                                    </p>
                                    <p class="mb-3">
                                        {{ 'Patronymic' | translate }}
                                    </p>
                                    <p *ngIf="!isCitizen" class="mb-3">
                                        {{ 'Nationality' | translate }}
                                    </p>
                                    <p *ngIf="!isCitizen" class="mb-3">
                                        {{ 'Citizenship' | translate }}
                                    </p>
                                    <p *ngIf="!isCitizen" class="mb-3">
                                        {{ 'Sex' | translate }}
                                    </p>
                                    <p *ngIf="!isCitizen" class="mb-3">
                                        {{ 'BirthDate' | translate }}
                                    </p>
                                    <p *ngIf="!isCitizen" class="mb-3">
                                        {{ 'DocumentId' | translate }}
                                    </p>
                                    <div class="mb-6">
                                        {{ 'PhoneNumber' | translate }}
                                        <div *ngIf="phoneCheck.invalid && phoneCheck.touched" class="err-txt">
                                            *{{ 'FillInTheField' | translate }}
                                        </div>
                                    </div>

                                </div>
                                <div class="w-1/3 text-right">
                                    <span *ngIf="isCitizen" class="relative">
                                        <svg (click)="findData(userInfoForm.get('iin').value)" class="search" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="49.999px" height="49.999px" viewBox="0 0 49.999 49.999" style="enable-background:new 0 0 49.999 49.999;" xml:space="preserve"><g><g>
                                                    <path d="M48.681,42.295l-8.925-8.904c-0.045-0.045-0.098-0.078-0.145-0.11c-0.802,1.233-1.761,2.405-2.843,3.487    c-1.081,1.082-2.255,2.041-3.501,2.845c0.044,0.046,0.077,0.1,0.122,0.144l8.907,8.924c1.763,1.76,4.626,1.758,6.383,0    C50.438,46.921,50.439,44.057,48.681,42.295z"/>
                                                    <path d="M35.496,6.079C27.388-2.027,14.198-2.027,6.089,6.081c-8.117,8.106-8.118,21.306-0.006,29.415    c8.112,8.105,21.305,8.105,29.413-0.001C43.604,27.387,43.603,14.186,35.496,6.079z M9.905,31.678    C3.902,25.675,3.904,15.902,9.907,9.905c6.003-6.002,15.77-6.002,21.771-0.003c5.999,6,5.997,15.762,0,21.774    C25.676,37.66,15.91,37.682,9.905,31.678z"/>
                                                    <path d="M14.18,22.464c-0.441-1.812-2.257-4.326-3.785-3.525c-1.211,0.618-0.87,3.452-0.299,5.128    c2.552,7.621,11.833,9.232,12.798,8.268C23.843,31.387,15.928,29.635,14.18,22.464z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                        </svg>
                                        <input placeholder="000000000000" [ngClass]="{'border-red-400': userInfoForm.get('iin').value == '', 'border-2': userInfoForm.get('iin').value == ''}" (keyup)="changeIIN()" [mask]="iin" formControlName="iin" class="w-full pl-1 bg-gray-300 rounded mb-3" type="text">
                                    </span>

                                    <input formControlName="lastname" [readonly]="isCitizen" class="w-full pl-1 bg-gray-300 rounded mb-3" type="text">
                                    <input formControlName="firstname" [readonly]="isCitizen" class="w-full pl-1 bg-gray-300 rounded mb-3" type="text">
                                    <input formControlName="patronymic" [readonly]="isCitizen" class="w-full pl-1 bg-gray-300 rounded mb-3" type="text">
                                    <select *ngIf="!isCitizen" formControlName="nationId" class="w-full pl-1 bg-gray-300 rounded mb-3">
                                        <option disabled [value]="0">
                                            {{ 'Nationality' | translate }}
                                        </option>
                                        <option *ngFor="let item of nationList" [value]="item.id">
                                            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                                            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                                        </option>
                                    </select>
                                    <select *ngIf="!isCitizen" (change)="getCurrentCitizenshipIndex()" formControlName="citizenshipId" class="w-full pl-1 bg-gray-300 rounded mb-3">
                                        <option disabled [value]="0">
                                            {{ 'Citizenship' | translate }}
                                        </option>
                                        <option *ngFor="let item of citizenshipList" [value]="item.id">
                                            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                                            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                                        </option>
                                    </select>
                                    <select *ngIf="!isCitizen" formControlName="sexId" class="w-full pl-1 bg-gray-300 rounded mb-3">
                                        <option disabled [value]="0">
                                            {{ 'Sex' | translate }}
                                        </option>
                                        <option *ngFor="let item of sexList" [value]="item.id">
                                            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                                            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                                        </option>
                                    </select>
                                    <input formControlName="birthDate" *ngIf="!isCitizen" class="w-full pl-1 bg-gray-300 rounded mb-3" type="date">

                                        <input *ngIf="!isCitizen" [ngClass]="{'border-red-600': haveDocumentIdPattern, 'border-2': haveDocumentIdPattern}" formControlName="documentId" (keyup)="checkDocId()" class="w-full pl-1 bg-gray-300 rounded mb-3" type="text">
                                        <div class="poskzk" *ngIf="!isCitizen && haveDocumentIdPattern == true">{{documentIdExample}}</div>

                                    <input (keyup)="checknumber()" [ngClass]="{'border-red-600': userInfoForm.get('phoneNumber').value == '', 'border-2': userInfoForm.get('phoneNumber').value == ''}" [mask]="phone" formControlName="phoneNumber" placeholder="+7 (777) 777-77-77" class="w-full pl-1 bg-gray-300 rounded" type="text">
                                </div>
                            </div>
                            <div class="batoni">
                                <button autofocus type="submit" class="w-64 mt-6 ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" (click)="saveUserInfo()">{{ 'Save' | translate }} </button>
                                <button type="button" class="w-64 mt-6 mr-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [routerLink]="'/auth'">{{ 'Cancel' | translate }} </button>
                            </div>
                            </form>
                        </div>
                </div>
            </form>
        </div>
    </div>

</div>
