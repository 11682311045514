import { isDevMode } from '@angular/core';

export class authConfig {
  authBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.authBackend = 'https://app.debug.testcenter.kz/auth/api/v2'; // Путь для localhost
    }
    if (!isDevMode()) {
      this.authBackend = 'auth/api/v2'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      auth: this.authBackend + '/login',
      logout: this.authBackend + '/logout',
      register: this.authBackend + '/register',
      registerConfirmation: this.authBackend + '/register/confirmation',
      profileInfo: this.authBackend + '/profile-info',
      resetPassword: this.authBackend + '/reset-password',
      resetPasswordConfirmation: this.authBackend + '/reset-password/confirmation',
      changePassword: this.authBackend + '/change-password',
      changeEmail: this.authBackend + '/change-email',
      changeEmailConfirmation: this.authBackend + '/change-email/confirmation',
      getPersonalData: this.authBackend + '/personal-data',
      nation: this.authBackend + '/profile-info/nation/items',
      citizenship: this.authBackend + '/profile-info/citizenship/items',
      sex: this.authBackend + '/profile-info/sex/items',
    }

  }
}
