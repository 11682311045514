import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-prob-test',
  templateUrl: './prob-test.component.html',
  styles: [
  ]
})
export class ProbTestComponent implements OnInit {

  testTypes: any;

  constructor(public router: Router, private api: ApiService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.api.getProbTestTypes().subscribe({
      next: (res: any) => {
        this.testTypes = res.items;
      }
    });
  }

  link(e:any){
    sessionStorage.setItem('info', JSON.stringify(e))
    this.router.navigate(['profile/prob-test/' + e.id]);
  }

}
