import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-orleu',
  templateUrl: './orleu.component.html',
  styleUrls: ['./orleu.component.css']
})
export class OrleuComponent implements OnInit {


  token: any;
  applicationForm: FormGroup;
  pageLangId: number;
  subjectList: any;
  langList: any;
  testData: any;
  eduTypeId: any;
  constructor(public api: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private _notifications: NotificationsService,
              public translate: TranslateService) { }

  ngOnInit(): void {

    this.token = this.route.snapshot.queryParamMap.get('token');
    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
      this.translate.use('kz');
    }
    this.getTestData();

    this.applicationForm = new FormGroup({
      subjectId: new FormControl(0),
      testLangId: new FormControl(0)
    });



  }

  getTestData() {
    this.api.getTestInfoOrleu(this.token)
      .subscribe((res: any) => {
        this.testData = res.data;
        this.applicationForm.get('subjectId').patchValue(res.data.subject.id);
        this.eduTypeId = res.data.eduType.id;
        this.getSubject();

      }, (err: any) => {

      });

  }

  getSubject() {
    this.api.getEduTypesOrleu(this.eduTypeId)
      .subscribe((res: any) => {
        this.subjectList = res.items;
        this.getLangList(1);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code: any) {
    this.api.getLanguageOrleu(this.eduTypeId, this.applicationForm.get('subjectId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if (code == 1) {
          this.applicationForm.get('testLangId').patchValue(this.testData.testLang.id);
        } else {
          this.applicationForm.get('testLangId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  edit() {

    this.api.putTestInfoOrleu(this.applicationForm.value, this.token)
      .subscribe((res: any) => {
        window.location.href = this.route.snapshot.queryParamMap.get('url');

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  cancel() {
    window.location.href = this.route.snapshot.queryParamMap.get('url');
  }


}
