<div [formGroup]="applicationForm">
  <div class="flex mb-4 mt-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "TestOrg" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>

  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
    <div class="md:col-span-full">
      <label for="testCity">{{ "TestCity" | translate }}</label>
      <select
        formControlName="testCityId"
        id="testCity"
        name="testCity"
        class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
      >
        <option disabled [value]="0">
          {{ "TestCity" | translate }}
        </option>
        <option *ngFor="let item of cities" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>
</div>
