import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-pvo',
  templateUrl: './pvo.component.html',
  styleUrls: ['./pvo.component.css']
})
export class PvoComponent implements OnInit {

  seasonsList = [];

  constructor(private api: ApiService, public router: Router,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.api.getSeasonsPvo()
      .subscribe((res: any) => {
        this.seasonsList = res.items;
      },(err: any) => {

      });
  }

}
