import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  @Output() onCancelEditProfile = new EventEmitter<number>();
  @Output() onApplyEditProfile = new EventEmitter<number>();

  public customPatterns = { '': { pattern: new RegExp('\[1-9\]')} };
  editProfileForm: FormGroup;
  phone = '+0 (000) 000-00-00'
  iin = '000000000000';
  trueData = false;
  loading = false;
  profileInfo: any;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
   
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.profileInfo = res.data;
        this.editProfileForm = new FormGroup({
          iin: new FormControl(this.profileInfo.iin),
          phoneNumber: new FormControl(this.profileInfo.phoneNumber)
        });
      }, (err: any) => {

      });
    setTimeout(() => {
      this.loading = false;
      }, 100);

  }

  cancel(page: any) {
    console.log(page);
    this.onCancelEditProfile.emit(page);
  }

  edit(page: any){
    if(this.editProfileForm.valid){
      let formData = this.editProfileForm.value;
      this.loading = true;
      this.api.editProfileInfo(formData)
        .subscribe((res: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Данные', 'успешно изменены', NotificationType.Success, 5000) : this._notifications.create('Данные', 'успешно изменены', NotificationType.Success, 5000);
          this.onApplyEditProfile.emit(page);
        },(err: any) => {
          console.log(err);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          this.loading = false;
        });
      
    }
  }

  changeIIN(){
    this.profileInfo.lastname = '';
    this.profileInfo.firstname = '';
    this.profileInfo.patronymic = '';
    this.trueData = false;
  }

  findData(iin: number){
    this.loading = true;
    this.api.personalData(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.profileInfo.lastname = res.data.lastname;
        this.profileInfo.firstname = res.data.firstname;
        this.profileInfo.patronymic = res.data.patronymic;
        this.trueData = true;
      }, (err: any) => {
        this.loading = false;
        this.trueData = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  get isValid(): boolean {
    if (this.editProfileForm.get('lastname').status === 'INVALID' && this.editProfileForm.get('firstname').status === 'INVALID' && this.editProfileForm.get('patronymic').status === 'INVALID') return true;
  }

  get iinCheck() { return this.editProfileForm.get('iin'); }
  get phoneCheck() { return this.editProfileForm.get('phoneNumber'); }

}
