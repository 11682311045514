import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-certif-results',
  templateUrl: './certif-results.component.html',
  styleUrls: ['./certif-results.component.css']
})
export class CertifResultsComponent implements OnInit {

  @Input() app;

  @Output() deleteApp = new EventEmitter<any>();
  @Output() editAppType = new EventEmitter<any>();

  loading: any;
  isCollapsed = true;

  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    // console.log(this.app);
  }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        // console.log(res);
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
              .subscribe((res: any) => {
              // let certifLink = res.data.certificateDownLoadUrl;
              // window.open(certifLink, "_blank");

              window.location.href = res.data.certificateDownLoadUrl;
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  showEdit(id: number, path: string){
    this.router.navigate(['profile/history/edit/ent/' + path], { queryParams: { id: id} });
  }

  showDeleteModal(){
    this.deleteApp.emit();
  }

}
