<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->

<div class="text-center mb-6 text-3xl font-semibold uppercase tracking-widest">
    {{ 'EditApplication' | translate }}
</div>

<form action="" [formGroup]="applicationForm">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex py-1">
        <div class="w-1/3">
            {{ 'IIN' | translate }}
        </div>
        <div class="w-1/3">
            <input readonly disabled (keyup)="changeIIN()" [mask]="iin" formControlName="iin" class="border h-6 rounded" type="text">
        </div>
    </div>
    <!-- <div (click)="findData(applicationForm.get('iin').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
        {{ 'SearchByIIN' | translate }}
    </div> -->
    <div class="flex py-3">
        <div class="w-1/3">
          {{ 'Surname' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="lastname" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Name' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="firstname" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Patronymic' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="patronymic" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'YourEmail' | translate }}
        </div>
        <div class="w-1/3">
            <input disabled readonly formControlName="email" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'PhoneNumber' | translate }}
        </div>
        <div class="w-1/3">
            <input [mask]="phone" formControlName="phoneNumber" class="border h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex my-3">
        <div class="mr-4 w-1/4">
            {{ 'TekZan' | translate }}:
        </div>
        <div class="w-full">
            <select (change)="getLists()" class="border" formControlName="employmentTypeId" name="" id="">
                <option disabled value="0">
                    {{ 'TekZan' | translate }}
                </option>
                <option [value]="item.id" *ngFor="let item of employmentTypeList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 3 || applicationForm.get('employmentTypeId').value == 2" class="flex my-3">
        <div class="mr-4 w-1/4">
            {{'NqtPlaceObl' | translate}}:
        </div>
        <div class="w-full">
            <select (change)="getEduPlaceList()" name="vuzObl" [ngModelOptions]="{standalone: true}" [(ngModel)]="vuzObl" class="w-full border my-1" name="">
                <option selected disabled value="-1">
                    {{'NqtPlaceObl' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of oblList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="applicationForm.get('employmentTypeId').value == 3" class="flex my-3">
        <div class="mr-4 w-1/4">
            {{'NqtPlaceRaion' | translate}}:
        </div>
        <div class="w-full">
            <select (change)="getCollege()" name="raion" [ngModelOptions]="{standalone: true}" [(ngModel)]="raion" class="w-full border my-1" name="">
                <option selected disabled value="-1">
                    {{'NqtPlaceRaion' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of raionList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="applicationForm.get('employmentTypeId').value == 2" class="my-3">
        <div class="mr-4 mb-2 w-1/4">
            {{'NqtPlace' | translate}}:
        </div>
        <div class="w-full">
            <select class="border w-full" formControlName="eduInstituteId" name="" id="">
                <option disabled value="0">
                    {{'NqtPlace' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of vuzList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="applicationForm.get('employmentTypeId').value == 3" class="my-3">
        <div class="mr-4 mb-2 w-1/4">
            {{'NqtPlace' | translate}}:
        </div>
        <div class="w-full">
            <select class="border w-full" formControlName="eduSchoolId" name="" id="">
                <option disabled value="0">
                    {{'NqtPlace' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of vuzList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    
    <div *ngIf="applicationForm.get('employmentTypeId').value == 2" class="flex my-3">
        <div class="mr-4 w-1/4">
            {{'NqtGOP' | translate}}:
        </div>
        <div class="w-full">
            <select class="border w-2/4" formControlName="eduSpecialityId" name="" id="">
                <option disabled value="0">
                    {{'NqtGOP' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of instituteSpecialityList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.code }} - {{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.code }} - {{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 3" class="flex my-3">
        <div class="mr-4 w-1/4">
            {{'NqtSpecStudy' | translate}}:
        </div>
        <div class="w-full">
            <select class="border w-2/4" formControlName="eduSpecialityId" name="" id="">
                <option disabled value="0">
                    {{'NqtSpecStudy' | translate}}
                </option>
                <option [value]="item.id" *ngFor="let item of collegeSpecialityList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1 || applicationForm.get('employmentTypeId').value == 4" class="py-3">
        {{ 'DiplomName' | translate }}:
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1 || applicationForm.get('employmentTypeId').value == 4" class="">
        <input formControlName="diplomaSpecialityName" type="text" class="w-full pl-2 border h-6 rounded">
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1 || applicationForm.get('employmentTypeId').value == 4" class="flex">
        <div class="py-3 flex mr-4">
            <p class="mr-4">
                {{ 'EducationDate' | translate }}:
            </p>
            <p class="mr-4">
                {{ 'EducationStart' | translate }}
            </p>
            <input [mask]="year" formControlName="eduPeriodStartYear" class="h-6 text-center border rounded" type="text">
        </div>
        <div class="py-3 flex">
            <p class="mr-4">
                {{ 'EducationEnd' | translate }}
            </p>
            <input [mask]="year" formControlName="eduPeriodFinishYear" class="h-6 border rounded mr-4 text-center" type="text">
            <p>{{ 'Year' | translate }}</p>
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1" class="flex py-3">
        <div class="w-1/3">
            {{ 'FromCategoryId' | translate }}:
        </div>
        <div class="w-1/3">
            <select class="border h-6 rounded" formControlName="fromCategoryId">

                <option selected disabled value="-1">
                    {{ 'SelectCategory' | translate }}
                </option>
                <option *ngFor="let item of fromActualCategories" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
        <div class="w-1/3 text-left flex relative">
            <p class="ml-8 w-full">{{ 'FromCategoryValidTo' | translate }}:</p>
            <input [dropSpecialCharacters]="false" placeholder="{{ 'DataFormat' | translate }}" mask="00.00.0000" formControlName="fromCategoryValidTo" type="text" class="border px-1 h-6 rounded w-full">
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1" class="flex py-3">
        <div class="w-1/3">
            {{ 'ToCategoryId' | translate }}:
        </div>
        <div class="w-1/3">
            <select class="border h-6 rounded" formControlName="toCategoryId">
                <option [selected] [disabled] value="-1">
                    {{ 'ToCategoryId' | translate }}
                </option>
                <option *ngFor="let item of toActualCategories" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
    </div>
    <div class="">
        
        <div *ngIf="applicationForm.get('employmentTypeId').value == 1" class="">
            <input formControlName="bin" class="w-full border rounded h-6 text-center" placeholder="БИН/БСН" type="text">
        </div>
        <div *ngIf="applicationForm.get('employmentTypeId').value == 1" (click)="searchJob(applicationForm.get('bin').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
            {{ 'SearchByBIN' | translate }}
        </div>
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1" class="">
        {{ 'Organization' | translate }}:
    </div>
    <div *ngIf="applicationForm.get('employmentTypeId').value == 1" class="flex py-3">
        <div class="w-1/3">
            {{ 'WorkPlace' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="firstLevelKato" (change)="getKATOsList(0, $event.target.value, true)">
                <option [selected] [disabled] value="-1">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let obl of KATOOblList" [value]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
            <div class="" *ngFor="let kat of KATOs; let i = index">
                <select class="border mt-2" (change)="getKATOsList(i, $event.target.value,  false)">
                    <option [disabled] value="">
                        --
                    </option>
                    <option [selected]="pseudoId[i+1] == subject.id" *ngFor="let subject of kat; let j = index" [value]="j">
                        <span *ngIf="translate.currentLang == 'kz'">{{ subject.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ subject.name.ru }}</span>
                    </option>
                </select>
            </div>
        
    </div>
    </div>
    <select *ngIf="applicationForm.get('employmentTypeId').value == 1" formControlName="jobId" class="w-full border my-1" name=""> 
        <option selected disabled value="-1">
            {{ 'WorkPlace' | translate }}
        </option>
        <option [value]="item.id" *ngFor="let item of jobList">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
        </option>
    </select>
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestParams' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div [formArrayName]="subjects" class="py-3" *ngFor="let item of subjects.controls; index as i">
        <div class="">
            <div class="">
                <div class="">
                    {{ 'TestBlock' | translate }}
                </div>
                <div>
                    <select class="w-full border" (change)="setSubjectValue($event.target.value)">
                        <option selected value="-1">
                            --
                        </option>
                        <option [selected]="this.appShortInfo.subjects[0].subjectId == sub.id" [value]="sub.id" *ngFor="let sub of subjectList[i]">
                            <span *ngIf="translate.currentLang == 'kz'">{{ sub.name.kk }}</span>
                            <span *ngIf="translate.currentLang == 'ru'">{{ sub.name.ru }}</span>
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-3">
        <div class="">
            <div class="">
                {{ 'TestLang' | translate }}
            </div>
            <select formControlName="testLangId" class="w-full border my-1" name="">
                <option selected disabled value="-1">
                    --
                </option>
                <option [value]="item.id" *ngFor="let item of langList">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                </option>
            </select>
        </div>
    </div>
    <div class="w-full">
        <select (change)="this.getTestOrgList(1);" formControlName="oblId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'SelectObl' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of oblList">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select (change)="this.getTestPeriodList();" formControlName="testOrgId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestPlace' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of testOrg">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select formControlName="testPeriodId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestTime' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of testPeriod">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
            </option>
        </select>
    </div>

    <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'DisabilityData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div> 
    </div>
    <fieldset form="applicationForm" style="height: 70px;">
        <div class="flex text-center mb-3 h-5">
            <div class="w-4/5 text-left">
                {{ 'DisabilityC' | translate }}:
            </div>
            <div class="w-2/5 mt-2">
                <input value="true" formControlName="isHasDisability"
                    class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis1" type="radio">
                <label class="cursor-pointer" for="dis1">{{ 'Yes' | translate }}</label>
            </div>
            <div class="w-2/5 mt-2">
                <input value="false" formControlName="isHasDisability"
                    class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis2" type="radio">
                <label class="cursor-pointer" for="dis2">{{ 'No' | translate }}</label>
            </div>
        </div>
    </fieldset>
    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'" class="flex mobile-style-3">
        <div class="w-1/3">
            {{ 'Disability' | translate }}:
        </div>
        <div class="w-full grid">
            <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="disabilityId">
                <option disabled [value]="0">
                    {{ 'SelectWhatNeed' | translate }}
                </option>
                <option *ngFor="let item of disabilityList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>

    <fieldset *ngIf="applicationForm.get('isHasDisability').value == 'true'" form="applicationForm"
        style="height: 70px;">
        <div class="flex text-center mb-3 h-5">
            <div class="w-4/5 text-left mt-2">
                {{ 'helper' | translate }}:
            </div>
            <div class="w-2/5 mt-2">
                <input value="true" formControlName="isNeedHelperDueDisability"
                    class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis3" type="radio">
                <label class="cursor-pointer" for="dis3">{{ 'Yes' | translate }}</label>
            </div>
            <div class="w-2/5 mt-2">
                <input value="false" formControlName="isNeedHelperDueDisability"
                    class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis4" type="radio">
                <label class="cursor-pointer" for="dis4">{{ 'No' | translate }}</label>
            </div>
        </div>
    </fieldset>

    <div class="">
        <button (click)="postForm()" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest"> {{ 'Save' | translate }} </button>
    </div>
</form>