<div *ngIf="router.url == '/profile/helper'" class="">
    <div class="text-center">
      {{ 'selectEvent' | translate }}
    </div>
    <div routerLink="ent-konkurs" class="uppercase text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
        {{ translate.currentLang === 'ru' ? 'ПОМОЩНИК В ВЫБОРЕ ПРОФЕССИИ' : 'МАМАНДЫҚ ТАҢДАУ БОЙЫНША КӨМЕКШІ' }}
    </div>

</div>

<router-outlet></router-outlet>
