import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'disability-template',
  templateUrl: './disability.component.html',
  styleUrls: ['./disability.component.css']
})
export class DisabilityComponent implements OnInit {
  @Input() applicationForm: FormGroup;
  @Input() shortInfo: any;

  disabilityList = [];
  fileIdToDelete: number;

  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {
  }

  getHelper(seasonId:number, appTypeId:number){
    this.api.getDisability(seasonId, appTypeId)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
        if(this.shortInfo){
          let value = this.shortInfo.isHasDisability;
          this.applicationForm.get('isHasDisability').setValue(value.toString());
          this.applicationForm.get('disabilityId').setValue(this.shortInfo.disabilityId);
        }
        
      }, (err: any) => {

      });
  }

  get files() : any { return this.applicationForm.get('docFiles')};
  get zayav() : any { return this.applicationForm.get('appFiles')};

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  addFileZayav() {
    let obj = null;

    obj = this.zayav;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event:any, i: number) : void {
    let obj = null;

    obj = this.files;
    
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;
      
      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  changeListenerZayav($event:any, i: number) : void {
    let obj = null;

    obj = this.zayav;
    
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;
      
      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  dwnldCheckZayav(id: number): boolean {
    return this.zayav.controls[id].get('filename').value !== '';
  }

  removeFile(type: string){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 1000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 1000);
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

  removeFileZayav(type: string){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 1000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 1000);
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.zayav;

    obj.removeAt(this.fileIdToDelete);
  }

}
