import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KaztestService } from '../../applications/kaztest/service/kaztest.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kaztest-zayav-results',
  templateUrl: './kaztest-zayav-results.component.html',
  styleUrls: ['./kaztest-zayav-results.component.css']
})
export class KaztestZayavResultsComponent implements OnInit {

  @Input() app;
  @Output() deleteApp = new EventEmitter<any>();

  loading: any;
  isCollapsed = true;

  constructor(
    private api: KaztestService,
    private router:Router,
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }
  showPayment(id: number){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 8} });
  }

  showEdit(id:number, testTypeId:number){
    if(id !== 0 || testTypeId != 0){
      this.router.navigate(['profile/history/edit/kaztest', testTypeId, id]);
    }
  }

  showDeleteModal(){
    this.deleteApp.emit();
  }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        console.log(res);
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
              .subscribe((res: any) => {
              let certifLink = res.data.certificateDownLoadUrl;
              window.open(certifLink, "_blank");
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

}
