import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 'app-dir-results',
  templateUrl: './dir-results.component.html',
  styleUrls: ['./dir-results.component.css']
})
export class DirResultsComponent implements OnInit {

  @Input() app;

  @Output() deleteApp = new EventEmitter<any>();

  loading: any;
  isCollapsed = true;
  esseAnswer: any;
  showEsse = false;
  edit = false;
  textAnswer: any;
  essetext: any;
  showEditButton = false;
  public Editor = ClassicEditor;
  public config = {
    // builtinPlugins: { Autosave },
    language: 'ru',
    toolbar: {
      items: [
        'bold', 'italic', '|',
        'bulletedList', 'numberedList', '|',
        'insertTable', '|',
        'undo', 'redo'
      ],
      shouldNotGroupWhenFull: true
    },

  };

  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    // document.getElementsByClassName('ck-toolbar')[0].classList.value = 'hidden ck-toolbar-hidden';
  }

  chesse(value: string) {
    console.log(value);
    this.essetext = value;


  }

  editorChange({ editor }: ChangeEvent) {
    console.log(editor.getData());
    const data = editor.getData();
    this.textAnswer = { answerText: data };
    localStorage.setItem('esseText', this.textAnswer);
  }

  getEsse(id: number) {
    this.api.getEsseAnswer(id)
      .subscribe((res: any) => {
        this.esseAnswer = res.items;
        this.showEsse = true;
        this.showEditButton = true;
        this.loading = false;
        setTimeout(() => {
          document.getElementsByClassName('ck-toolbar')[0].classList.value = 'hidden ck-toolbar-hidden';
        }, 200);
      }, (err: any) => {

      });
  }

  saveEsse(studentTestId: any, textAnswerId: any) {
    this.edit = false;
    this.loading = true;
    document.getElementsByClassName('ck-toolbar')[0].classList.value = 'hidden ck-toolbar-hidden';
    this.api.saveNqtEsse(this.textAnswer, studentTestId, textAnswerId)
      .subscribe((res: any) => {
        this.getEsse(studentTestId);
        this.translate.currentLang == 'ru' ? this._notifications.create('Сохранено', '', NotificationType.Success, 5000) : this._notifications.create('Сақталды', '', NotificationType.Success, 5000);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  editEsse() {
    this.edit = true;
    document.getElementsByClassName('ck-toolbar-hidden')[0].classList.value = 'ck ck-toolbar';
  }

  print(studentData: any, theme: any) {

    let frame;
    let frameBody;


    frame = document.getElementById('iframe');

    frameBody = this.esseAnswer[0].answerText; //will get all html within the iframe

    let popupWin;

    popupWin = window.open('', '_blank', 'top=0,left=0, width=900,height=700');
    popupWin.document.open();
    popupWin.document.write(`
     <html>
       <head>
       <title>ЭССЕ</title>
       <style>
        @media print{
          .doNotPrint{
            display:none;!important
           }
          .printVisible{
            visability: content;
          }
          .print-header{
            font-size: 14px;
            font-weight: bold;
            min-width: 100px;
          }
         .print-cont{
         }
         .print-header-wrapper{
           padding-bottom: 50px
         }
       }
        </style>
       </head>
    <body onload="window.print();window.close()">
    Аты-жөні, тегі/ФИО: ${studentData.lastname} ${studentData.firstname} ${studentData.patronymic} </br>
    Тест тапсыру уақыты/Дата тестирования: ${studentData.testPeriod.testDate} </br>
    Эссенің тақырыбы/Тема ЭССЕ: </br>
    ${theme}
    </br>
    Эссенің мәтіні/Текст ЭССЕ: </br>
    ${frameBody}
      </body>
      </html>`
    );
    popupWin.document.close();
  }

  checkCertificate(id, iin, year, testType) {
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        console.log(res);
        if (res.data.isExists) {
          this.api.downloadCertificate(id, iin, year, testType)
            .subscribe((res: any) => {
              // let certifLink = res.data.certificateDownLoadUrl;
              // window.open(certifLink, "_blank");

              window.location.href = res.data.certificateDownLoadUrl;
              this.loading = false;
            },
              (err: any) => {
                this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.errorMessage.kk, NotificationType.Error, 5000);
                this.loading = false;
              }
            );
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  showPayment(id: number) {
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 5 } });
  }

  showEditDir(id: any) {
    this.router.navigate(['profile/history/edit/dir'], { queryParams: { id: id } });
  }

  showDeleteModal() {
    this.deleteApp.emit();
  }



}
