import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KaztestService } from './service/kaztest.service';

interface TestType{
  id:number;
  name:{
    kk:string;
    ru:string;
  }
}

@Component({
  selector: 'app-kaztest',
  templateUrl: './kaztest.component.html',
  styleUrls: ['./kaztest.component.css']
})
export class KaztestComponent implements OnInit {

  testTypes:TestType[] = [];

  constructor(public translate:TranslateService, public router:Router, private api:KaztestService){
    
  }

  ngOnInit(): void {
   this.api.getTestType().subscribe((res:any)=>this.testTypes = res.items)
  }

 

}
