import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-gre',
  templateUrl: './e-gre.component.html',
  styleUrls: ['./e-gre.component.css']
})
export class EGreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
