<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="text-xl z-10 relative justify-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16 flex">
    {{ 'NCT' | translate }}
</div>
<div class="mt-6 text-center form-wrap mx-auto">
    <form class="pass-form mx-auto p-4" action="" [formGroup]="passDropForm">
        <div class="">
            <div *ngIf="passDropForm.get('accountTypeId').value == 1" class="">
                <p class="text-center p-0 mb-4 font-md">{{ 'EnterYourEmailAddress' | translate }}</p>
                <input formControlName="email" placeholder="*{{ 'EmailReg' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="email">
                <div *ngIf="emailCheck.invalid && emailCheck.touched" class="err-txt">
                    *{{ 'FillInTheField' | translate}}
                </div>
                <div (click)="passDropForm.get('accountTypeId').setValue(2)" class="underline mt-2 text-gray-500 cursor-pointer">
                   {{ 'GetPassByIIN' | translate }}
                </div>
            </div> 
            <div *ngIf="passDropForm.get('accountTypeId').value == 2" class="">
                <p class="text-center p-0 mb-4 font-md">{{ 'EnterIIN' | translate }}</p>
                <input [mask]="iin" formControlName="iin" placeholder="*{{ 'IIN' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="email">
                <div *ngIf="iinCheck.invalid && iinCheck.touched" class="err-txt">
                    *{{ 'FillInTheField' | translate}}
                </div>
                <div (click)="passDropForm.get('accountTypeId').setValue(1)" class="underline mt-2 text-gray-500 cursor-pointer">
                    {{ 'GetPassByEmail' | translate }}
                </div>
            </div>
        </div>
        <button (click)="newPass()" class="bg-blue-500 mt-8 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{ 'GetPass' | translate}}</button>
    </form>

    <div class="text-gray-500 instr mt-4 text-center italic">
        <a *ngIf="translate.currentLang == 'ru'" class="text-blue-500 cursor-pointer underline" href="./../../../../../../assets/reset-pass-guide-ru.pdf" target="_blank"><p class="text-center text-xl text-blue-600 mb-4">{{ 'InstructionsResetPass' | translate }}</p></a> 
        <a *ngIf="translate.currentLang == 'kz'" class="text-blue-500 cursor-pointer underline" href="./../../../../../../assets/reset-pass-guide-kz.pdf" target="_blank"><p class="text-center text-xl text-blue-600 mb-4">{{ 'InstructionsResetPass' | translate }}</p></a> 
    </div>
</div>
<div class="text-gray-500 text-center mt-40">© {{ 'nct' | translate}}</div>