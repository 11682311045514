import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  isMoble = false;

  constructor() {
    let isMobile = localStorage.getItem('isMobile');
    if(isMobile){
      this.isMoble = true;
    }
  }

}
