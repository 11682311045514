import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-ent',
  templateUrl: './ent.component.html',
  styleUrls: ['./ent.component.css']
})
export class EntComponent implements OnInit {

  seasonsList = [];

  loading = true;

  constructor(private api: ApiService, public router: Router,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.api.getSeasonsEnt()
      .subscribe((res: any) => {
        this.seasonsList = res.items;
        this.loading = false;
      },(err: any) => {
        this.loading = false;
      });
  }

  click(season:any){
    let pageTitle =  this.translate.currentLang == 'ru' ? season.name.ru : season.name.kk;
    this.router.navigate([`/profile/applications/2/seasons/${season.id}`]).then(()=>{
      sessionStorage.setItem("pageTitle", pageTitle);
    });
  }

}
