import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-app-status',
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.css']
})
export class AppStatusComponent implements OnInit {

  @Input() step: number;
  @Input() seasonId: number;
  @Input() appTypeId: number;

  constructor() { }

  ngOnInit(): void {
  }

}
