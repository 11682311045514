<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="mb-2 border-2 border-blue-200 rounded py-2">
    {{ 'RegisterInfo' | translate}}
</div>
<form *ngIf="!registrationEnd" action="" (ngSubmit)="register()" [formGroup]="registrationForm">
    <input formControlName="email" placeholder="*{{ 'EmailReg' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="email">
    <div *ngIf="emailCheck.invalid && emailCheck.touched" class="err-txt">
        *{{ 'FillInTheField' | translate }}
    </div>
    <button type="submit" [disabled]="!registrationForm.valid" [ngClass]="{'hover:bg-blue-800': registrationForm.valid,'hover:cursor-pointer': registrationForm.valid}" class="mx-auto w-4/6 mt-8 mb-8 text-white cursor-default font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{ 'GetReg' | translate}}</button>
</form>
<div class="after-reg-txt" *ngIf="registrationEnd">
    <p class="text-2xl text-center mb-2"><span class="underline">{{ registrationForm.get('email').value }}</span></p>
    {{ 'CheckMailText' | translate }}
</div>