import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'gre-template',
  templateUrl: './gre-template.component.html',
  styleUrls: ['./gre-template.component.css']
})
export class GreTemplateComponent implements OnInit {

  @Input() applicationForm: FormGroup;

  fileIdToDeleteGre: number;

  constructor(
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  get greFiles(): any { return this.applicationForm.get('greCertificateFiles') };

  addFileGre() {
    let obj = null;

    obj = this.greFiles;

    obj.push(
      new FormGroup({
        id: new FormControl(0),
        filename: new FormControl('', [Validators.required]),
        data: new FormControl()
      })
    );
  }

  changeListenerGre($event, i: number): void {
    let obj = null;

    obj = this.greFiles;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheckGre(id: number): boolean {
    return this.greFiles.controls[id].get('filename').value !== '';
  }

  
  removeFileGre(type: string) {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.greFiles;

    obj.removeAt(this.fileIdToDeleteGre);
  }

}
