<div *ngIf="app.moduleType?.id == 3" class="bg-blue-100 border-blue-500 border-2 zayav rounded mb-4 p-3">
    <div class="flex w-full justify-between test-type-name">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>

        <div class="text-lg text-green-400 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.status.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.status.name.ru }}</span>
        </div>
        
    </div>

    <div class="flex w-full justify-between test-type-name">
        <div class="py-2 font-bold">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.anotherExamType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.anotherExamType.name.ru }}</span>
        </div>
        <div class="py-2 font-bold">
         {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">
                
                <div class="px-3 text-gray-600">

                    <!-- <div class="py-2 font-bold">
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.anotherExamType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.anotherExamType.name.ru }}</span>
                    </div> -->
                    
            
                    <div class="mb-2">
                        {{ 'IIN' | translate }} : {{ app.iin}}
                    </div>
            
                    <div class="mb-2">
                        {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                    </div>
            
                    <div class="mb-2">
                        {{ 'email' | translate }} : {{ app.email }}
                    </div>
            
                    <div class="mb-2">
                        {{ 'PhoneNumber' | translate }} : {{ app.phoneNumber }}
                    </div>
                    <div class="flex">
                        <table class="w-full table border-l border-r border-b">
                            <tr class="table-row">
                                <td class="w-1/2 table-cell">
                                    {{ 'Subject' | translate }}
                                </td>
                                <td class="w-1/2 table-cell border-l">
                                    {{ 'Ball' | translate }}
                                </td>
                            </tr>
                            <tr class="table-row" *ngFor="let sub of app.subjects; index as num">
                                <td class="table-cell">
                                    <span *ngIf="translate.currentLang == 'ru'">{{ sub.name.ru  }}</span>  
                                    <span *ngIf="translate.currentLang == 'kz'">{{ sub.name.kk  }}</span>
                                </td>
                                <td class="table-cell border-l">
                                    {{ sub.score }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    
                   
            
                    
                </div>
                <div class="flex w-full justify-end mt-2 zayav-buttons">
                    <button (click)="checkCertificate(app.idForCertificate, app.iin, app.appYear, 1)" *ngIf="app.status.id == 2 && app.idForCertificate != 0" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'CheckCertificate' | translate }}
                    </button> 
                    <button *ngIf="app.status.id == 1" (click)="showEdit(app.id, app.anotherExamType.path)" class="bg-blue-400 zayav-button ml-3 tracking-wide w-64 hover:bg-blue-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'EditApplication' | translate }} 
                    </button>
                    <button *ngIf="app.status.id == 1" (click)="showDeleteModal()" class="bg-red-400 zayav-button ml-3 tracking-wide w-64 hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'DeleteApplication' | translate }} 
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z"/></svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z"/></svg>
    </div>

   
</div>