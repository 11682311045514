import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../service/api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.css']
})
export class SeasonsComponent implements OnInit {

  seasonId: number;
  appId: any;
  appTypeList = [];

  constructor(private api: ApiService, public router: Router,
              private route: ActivatedRoute, public translate: TranslateService) { }

  ngOnInit(): void {

    this.appId = this.router.parseUrl(this.router.url).root.children.primary.segments[2].path;

      this.route.params.subscribe(seasonId => {
        this.seasonId = seasonId.seasonId;
      });


      if(this.appId == 4){
        this.api.getAppTypePvo(this.seasonId)
        .subscribe((res: any) => {
          this.appTypeList = res.items;
        },(err: any) => {

        });
      }else{
        this.api.getAppTypeEnt(this.seasonId)
        .subscribe((res: any) => {
          this.appTypeList = res.items;
        },(err: any) => {

        });
      }
      
  }

}
