<div *ngIf="router.url == '/profile/applications/9'" class="">
<!--    <div class="vajnayainfo">-->
<!--        <div *ngIf="translate.currentLang == 'kz'">-->
<!--            <p class="uppercase font-bold text-center">-->
<!--                <strong><span>Назар аударыңыз!</span></strong></p>-->
<!--            <p class="uppercase font-bold text-center mb-2">-->
<!--                <strong><span>Құрметті үміткерлер!</span></strong></p>-->
<!--            -->
<!--            <p style="text-align: justify"><span>Білім беру ұйымдарының бірінші басшыларын тағайындау үшін тестілеуге қатысуға өтініштер қабылдау 2024 жылғы 23 сәуірде жүргізіледі.</span></p>-->
<!--            <p style="text-align: justify"><span>Тестілеу 2024 жылғы 24 сәуірде өтеді.</span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>Тестлеуге кіргізу уақыты: сағат 8:30-ден 9:00-ге дейін.</span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>Тестілеудің басталу уақыты: 9:00 (жергілікті уақытпен).</span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>Тестлеуге кіргізу уақыты аяқталғаннан кейін үміткерлерге тестілеуге кіру рұқсат етілмейді.</span></p>-->
<!--        </div>-->
<!--        <div *ngIf="translate.currentLang == 'ru'">-->
<!--            <p class="uppercase font-bold text-center">-->
<!--                <strong><span>Внимание!</span></strong></p>-->
<!--            <p class="uppercase font-bold text-center mb-2">-->
<!--                <strong><span>Уважаемые кандидаты!</span></strong></p>-->
<!--            -->
<!--            <p style="text-align: justify"><span>Прием заявлений для участия в тестировании на назначение на должность первых руководителей организаций образования будет осуществляться 23 апреля 2024 года.</span></p>-->
<!--            <p style="text-align: justify"><span>Тестирование будет проводиться 24 апреля 2024 года. </span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>Время запуска на тестирование: с 8:30 до 9:00.</span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>Время начала тестирования: 9:00 (местного времени).</span></p>-->
<!--            <p style="text-align: justify">-->
<!--                <span>После окончания времени запуска, кандидаты не допускаются на тестирование.</span></p>-->
<!--        </div>-->
<!--    </div>-->

    <div [routerLink]="'/profile/applications/9/dir'"
        class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
        {{ 'DirTest' | translate}}
    </div>

    <div class="mt-2 w-full text-center">
        <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700"
            href="./../../../../../assets/instr_dir_rus.pdf" target="_blank">
            <p class="text-center text-2xl text-blue-600 mb-4">{{ 'Instructions' | translate }}</p>
        </a>
        <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700"
            href="./../../../../../assets/instr_dir_kaz.pdf" target="_blank">
            <p class="text-center text-2xl text-blue-600 mb-4">{{ 'Instructions' | translate }}</p>
        </a>
    </div>

</div>

<router-outlet *ngIf="router.url != '/profile/applications/dir'"></router-outlet>
