<div *ngIf="router.url == '/profile/prob-test'">
      <div class="text-center text-lg mb-8">
          {{ 'SelectTestType' | translate }}:
      </div>
      <div *ngFor="let item of testTypes" (click)="link(item)"  class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
      </div>
  </div>
  <router-outlet></router-outlet>
