import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class dirConfig {
  backend: any;
  apiRoutes: any;
  certificate:any;
  constructor() {
    if (isDevMode()) {
        this.backend = 'https://app.debug.testcenter.kz/school-head/student/app/api/v1';
        this.certificate = 'https://certificate.debug.testcenter.kz/api/v1';
      
    }
    if (!isDevMode()) {
        this.backend = 'school-head/student/app/api/v1'; // Путь для боевого
        this.certificate = 'https://certificate.testcenter.kz/api/v1'
    }

    this.apiRoutes = { // ссылки для запросов
      appType: '/app-type/items/',
      appItems: this.backend + '/app/items/test-data',
      eduItems: this.backend + '/app/edu-type/items',
      certUrl: this.certificate
    }

  }
}
