import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  requestId: any;
  verificationCode: any;
  confirmData: any;
  passDropEnd = false;
  samePass = true;
  loading = true;
  passDropForm: FormGroup;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      this.requestId = res['id'];
      this.verificationCode = res['code'];
      this.initRegForm();
    });
  }

  initRegForm() {
    this.passDropForm = this.fb.group({
      id: [this.requestId],
      verificationCode: [this.verificationCode],
      newPassword: ["", Validators.required],
      confirmpassword: ["", Validators.required]
    });
  }

  reset(){
    if(this.passDropForm.valid){
      let formData = this.passDropForm.value;
      this.loading = true;
      this.api.resetPasswordConfirmation(formData)
        .subscribe((res: any) => {
          this.loading = false;
          this.passDropEnd = true;
          this.translate.currentLang == 'ru' ? this._notifications.create('Пожалуйста', 'проверьте вашу почту', NotificationType.Success, 5000) : this._notifications.create('Пожалуйста', 'проверьте вашу почту', NotificationType.Success, 5000);
          setTimeout(() => {
            this.router.navigate(['/']);
            }, 60000);
        },(err: any) => {
          console.log(err);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          this.loading = false;
        });
      
    }
  }

  checkPass(){
    if(this.passDropForm.get('password').value == this.passDropForm.get('confirmpassword').value){
      this.samePass = true;
    } else {
      this.samePass = false;
    }
  }
  get isValid(): boolean {
    if (this.passDropForm.get('email').status === 'INVALID') return true;
  }
  get passCheck() { return this.passDropForm.get('newPassword'); }
  get confirmpassword() { return this.passDropForm.get('confirmpassword'); }
  get emailCheck() { return this.passDropForm.get('email'); }

}
