import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { KaztestService } from 'src/app/components/profile/applications/kaztest/service/kaztest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-kaztest-zayav',
  templateUrl: './kaztest-zayav.component.html',
  styleUrls: ['./kaztest-zayav.component.css'],
})
export class KaztestZayavComponent implements OnInit {
  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  nationsList = [];
  countryList = [];
  dataofbday = 0;
  phone = '+0 (000) 000-00-00';
  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  testTypeId: number = 0;

  obls = [];
  categories = [];
  testOrgs = [];
  testPeriod = [];
  visibleLoad = false;
  viewPhoto = false;
  photoSrc:any;

  applicationAgree = false;

  id:number = 0;

  isEdit = false;

  constructor(
    public mainApi: ApiService,
    public api: KaztestService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer:DomSanitizer,
    private router:Router
  ) {
    this.route.url.subscribe((v) => (this.testTypeId = +v[0].path));
    this.route.params.subscribe({
      next:params=>{
        if(params['testTypeId'] && params['id']){
          this.testTypeId = +params['testTypeId'];
          this.id = +params['id'];
          if(this.testTypeId != 0 && this.id != 0){
            this.isEdit = true;
          }
        }
      }
    })

    this.applicationForm = new FormGroup({
      email:new FormControl(null, Validators.required),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      birthDate: new FormControl(0),
      oblId: new FormControl(0, Validators.required),
      testTypeId: new FormControl(this.testTypeId),
      categoryId: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      promocodeId: new FormControl(0),
      photo: new FormGroup({
        filename: new FormControl(null, Validators.required),
        id: new FormControl(null),
        data: new FormControl(null),
      }, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getNationList();
    this.getCitizenshipList();
    this.getCategories();
    this.getObl();

    this.mainApi.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      if (res.data.sexId == 0) {
        this.canEditSexId = true;
      }
      if (res.data.nationId == 0 || res.data.nationId == 99) {
        this.canEditNationId = true;
      }
      if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
        this.canEditCitizenshipId = true;
      }
      if (res.data.phoneNumber == 0) {
        this.canEditPhoneNumber = true;
      }
      this.applicationForm.get('sexId').patchValue(res.data.sexId);
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm
        .get('citizenshipId')
        .patchValue(res.data.citizenshipId);
      this.applicationForm.get('nationId').patchValue(res.data.nationId);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
      this.applicationForm.get('birthDate').setValue(res.data.birthDate);
      this.getDateOfBirth(res.data.birthDate);
    });

    if(this.isEdit){
      this.api.getShort(this.id).subscribe({
        next:(res:any)=>{
          const data = res.data;

          // Test Params
          this.applicationForm.get('categoryId').setValue(data.categoryId);
          this.applicationForm.get('oblId').setValue(data.testOrgOblId);

          const obl = {
            target: {
              value: data.testOrgOblId
            }
          }
          this.changeObl(obl);

          const testOrg = {
            target: {
              value: data.testOrgId
            }
          }
          this.changeTestOrg(testOrg);
          this.applicationForm.get('testOrgId').setValue(data.testOrgId);
          this.applicationForm.get('testPeriodId').setValue(data.testPeriodId);
          
          if(data.photo?.id != 0){
            this.visibleLoad = true;
            this.applicationForm.get('photo').get('id').setValue(data.photo.id);
            this.applicationForm.get('photo').get('filename').setValue(data.photo.filename);
            this.loadPhoto();
          }
        }
      })
    }

    

    
    
  }

  getCategories() {
    this.api
      .getCategories(this.testTypeId)
      .subscribe((res: any) => (this.categories = res.items));
  }

  getObl() {
    this.api
      .getObl(this.testTypeId)
      .subscribe((res: any) => (this.obls = res.items));
  }

  changeObl(e: any) {
    this.testOrgs = [];
    this.testPeriod = [];

    this.applicationForm.get('testOrgId').setValue(0);
    this.applicationForm.get('testPeriodId').setValue(0);

    this.api
      .getTestOrg(this.testTypeId, +e.target.value)
      .subscribe((res: any) => (this.testOrgs = res.items));
  }

  changeTestOrg(e: any) {
    this.testPeriod = [];
    this.applicationForm.get('testPeriodId').setValue(0);
    this.api
      .getTestPeriod(this.testTypeId, +e.target.value)
      .subscribe((res: any) => {
        this.testPeriod = res.items
      });
  }

  getDateOfBirth(date: any) {
    let today = new Date();
    this.dataofbday = this.getNumberOfDays(
      date,
      today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    );
  }

  loadPhoto(){
    this.api.getPhoto(this.id).subscribe({
      next:(res:any)=>{
        this.photoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${res.data.data}`)
      }
    })
  }

  get photo(): any {
    return this.applicationForm.get('photo');
  }

  addPhoto() {
    this.visibleLoad = true;
  }

  changePhoto($event: any) {
    let obj = null;

    obj = this.photo;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj
        .get('data')
        .setValue(
          imgData.toString().substring(imgData.toString().indexOf(',') + 1)
        );

      this.photoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${obj.get('data').value}`)
    };
    myReader.readAsDataURL(file);
  }

  removePhoto() {

    this.visibleLoad = false;
    this.photo.reset();

    // this.translate.currentLang == 'ru'
    //   ? this._notifications.create(
    //       'Файл',
    //       'успешно удален',
    //       NotificationType.Success,
    //       5000
    //     )
    //   : this._notifications.create(
    //       'Файл',
    //       'сәтті жойылды',
    //       NotificationType.Success,
    //       5000
    //     );
  }

  photoCheck(): boolean {
    return this.photo.get('filename').value !== null;
  }

  getNumberOfDays(start: any, end: any) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  getNationList() {
    this.api.getNations(this.testTypeId).subscribe(
      (res: any) => {
        this.nationsList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getCitizenshipList() {
    this.api.getCitizenShips(this.testTypeId).subscribe(
      (res: any) => {
        this.countryList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }
  postForm(){
    if(this.applicationForm.invalid){
      return;
    }
    this.loading = true;
    if(this.isEdit){
      this.api.update(this.id, this.applicationForm.value).subscribe({
        next:(res: any) => {
        this.loading = false;
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();          
        }, 1000);
        this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'сохранено успешно', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'Пароль изменен', NotificationType.Success, 5000);
      },
        error:(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    });
    }else{
      this.api.post(this.applicationForm.value).subscribe({
        next:(res: any) => {
        this.loading = false;
        if(res.notice){
          this.translate.currentLang == 'ru' ? this._notifications.success('Заявление', res.notice.ru, { timeOut: 0 }) : this._notifications.success('Өтініш', res.notice.kk, { timeOut: 0 });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
        }
  
        if(res.data.paymentTypeId == 2){
          this.router.navigate(['/profile/applications/payment'], {  queryParams: { id: res.data.id, type: 8 }, });
        }else{
          this.router.navigateByUrl('/profile/history');
        }
      },
        error:(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    });
    }
    
  }

  changeAgree(e:any){
    this.applicationAgree = !this.applicationAgree;
  }
}
