
<div *ngIf="router.url == '/profile/applications/3'" class="flex flow">

    <!-- <div class="no-data">
        <span *ngIf="translate.currentLang == 'kz'">
            <p><strong>Хабарландыру</strong></p>            
            <div class="msg">
                <p>2024 жылғы 12-14 ақпан аралығында жоғары оқу орындарын және техникалық және кәсіптік, орта білімнен кейінгі білім беру ұйымдарының түлектері мен педагогтарға жұмысқа орналасу үшін және педагогикалық қызметті қайта жалғастырғысы келетін адамдар үшін Педагогтердің білімін бағалауына (бұдан әрі – Тестілеу) өтініштер қабылдау жүргізіледі. </p>
                <p>Тестілеуге өтініштерді қабылдау <b>app.testcenter.kz</b> сілтемесі арқылы жүргіледі. </p>
                <p>Тестілеу ағымдағы жылғы 14-17 ақпан аралығында өткізіледі өткізіледі.</p>
            </div>
    
        </span>
        <span *ngIf="translate.currentLang == 'ru'">
            <p><strong>Объявление!</strong></p>
            <div class="msg">
                <p>С 12-14 февраля 2024 года начнется прием заявлений на Оценку знаний педагогов (далее – Тестирование) для трудоустройства педагогов и выпускников высших учебных заведений организаций технического и профессионального, послесреднего образования и лицам, желающие возобновить педагогическую деятельность.</p>
                <p>Прием заявлений на тестирование осуществляется по ссылке <b>app.testcenter.kz.</b></p>
                <p>Тестирование будет проводиться с 14 по 17 февраля текущего года. </p>
            </div>
    
        </span>
    </div> -->

    <div *ngFor="let testType of testTypes; let i = index" [routerLink]="this.router.url + '/' + testType.id"
        class="w-full">
        <div class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
            <span *ngIf="translate.currentLang == 'kz'">{{ testType.title.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ testType.title.ru }}</span>
        </div>
    </div>
    

    <!-- <p class="mb-2">
        {{ 'OrgTestQuestionsNQT' | translate }} : 8 (7172) 69 52 63, 8 (7172) 69 52 88
    </p> -->

</div>

<router-outlet *ngIf="router.url != '/profile/applications/3'"></router-outlet>