<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<app-app-status [seasonId]="seasonId" [step]="1"></app-app-status>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
    {{ 'ToApplyForParticipationInTheUNT(March)FillInTheData:' | translate }}
</p>
<form [formGroup]="applicationForm" action="">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex">
        <div class="">
            <div class="mb-3 h-5">
                {{ 'IIN' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Surname' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Name' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Patronymic' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'YourEmail' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'DateOfBirth' | translate }}
            </div>
            <div *ngIf="showParentData" class="mb-3 h-5">
                {{ 'FIOPredstavitelya' | translate }}
            </div>
            <div *ngIf="showParentData" class="mb-3 h-5">
                {{ 'PhoneNumberPredstavitelya' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Citizenship' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Nationality' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Sex' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'PhoneNumber' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'DopSertifs' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'InternationalEnglishCertificate' | translate }}
            </div>
        </div>
        <div class="ml-4 w-1/3">
            <div class="mb-3 h-5">
                {{ iin }}
            </div>
            <div class="mb-3 h-5">
                {{ lastname }}
            </div>
            <div class="mb-3 h-5">
                {{ firstname }}
            </div>
            <div class="mb-3 h-5">
                {{ patronymic }}
            </div>
            <div class="mb-3 h-5">
                {{ email }}
            </div>

            <div class="mb-3 h-5">
                <input disabled class="w-full pl-1 bg-gray-200 rounded" type="date" formControlName="birthDate" (change)="getDateOfBirth($event.target.value)">
            </div>
            <div *ngIf="showParentData" class="mb-3 h-5">
                <input class="w-full pl-1 bg-gray-200 rounded" type="text" formControlName="parentFullname">
            </div>
            <div *ngIf="showParentData" class="mb-3 h-5">
                <input formControlName="parentPhoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            
            <div class="mb-3 h-5">
                <select formControlName="citizenshipId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateСitizenship' | translate }}
                    </option>
                    <option *ngFor="let item of countryList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="mb-3 h-5">
                <select formControlName="nationId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateNationality' | translate }}
                    </option>
                    <option *ngFor="let item of nationsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <fieldset form="applicationForm">
                <div class="flex justify-around text-center mb-3 h-5">
                    <div class="">
                        <input value="1" [checked]="applicationForm.get('sexId').value == 1" formControlName="sexId" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="pol1" type="radio">
                        <label for="pol1">{{ 'Male' | translate }}</label>
                    </div>
                    <div class="">
                        <input value="2" [checked]="applicationForm.get('sexId').value == 2" formControlName="sexId" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="pol2" type="radio">
                        <label for="pol2">{{ 'Female' | translate }}</label>
                    </div>
                </div>
            </fieldset>
            <div class="mb-3 h-5">
                <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="mb-3 h-5">
                <select formControlName="anotherExamTypeId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'DopSertifs' | translate }}
                    </option>
                    <option *ngFor="let item of dopSertifsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="mb-3 flex h-5">
                <select formControlName="foreignLangCertTypeId" (change)="checkSertif(applicationForm.get('foreignLangCertTypeId').value)" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'SelectСertificate' | translate }}
                    </option>
                    <option *ngFor="let item of foreignLangCertList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
        </div>
        <div class="ml-4 w-1/3 self-end">
            <div class="mb-3 flex h-5 w-1/3">
               <div class="pb-1 ">{{ 'Ball' | translate }}:</div> 
               <input [mask]="applicationForm.get('foreignLangCertTypeId').value==4?foreignLangCertScoreMaskIelts:foreignLangCertScoreMask" formControlName="foreignLangCertScore" placeholder="{{ 'Ball' | translate }}" class="w-full bal pl-1 bg-gray-200 rounded" type="text">
            </div>
        </div>
    </div>
    <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PlaceOfStudy' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="flex">
        <div class="w-1/3">
            {{ 'PlaceOfStudy' | translate }}:
        </div> 
        <div class="w-2/3 grid">
            <select class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="oblId" (change)="getRaionList()">
                <option disabled [value]="0">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let item of oblList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
            <select  class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="raionId" (change)="getSchoolList()">
                <option disabled [value]="0">
                    {{ 'SelectRaion' | translate }}
                </option>
                <option *ngFor="let item of raionList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
            <select class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="schoolId">
                <option disabled [value]="0">
                    {{ 'PlaceOfStudy' | translate }}
                </option>
                <option *ngFor="let item of schoolList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestParams' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectProfileSubjects' | translate }}:
        </div>
        <div class="w-1/3">
            <select (change)="getLangList()" formControlName="profileSubjectPairId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'ACoupleOfItems' | translate }}
                </option>
                <option *ngFor="let item of subjectPairList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTheLanguageForTesting' | translate }}:
        </div>
        <div class="w-1/3">
            <select formControlName="testLangId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'LanguageOfSubjects' | translate }}
                </option>
                <option *ngFor="let item of langList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTestSite' | translate }}:
        </div>
        <div class="w-1/3">
            <select (change)="getTestDate()" formControlName="testOrgId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'TestLocation' | translate }}
                </option>
                <option *ngFor="let item of testOrgList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTestDate' | translate }}:
        </div>
        <div class="w-1/3">
            <select formControlName="testPeriodId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'TestDate' | translate }}
                </option>
                <option *ngFor="let item of testDateList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }})</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }})</span>
                </option>
            </select>
        </div>
    </div>

    <p class="text-center my-2 mt-4">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_ent.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>  

    <div *ngIf="showParentData" class="text-center">
        <input (change)="agreeCheckParent()" type="checkbox" id="get-agree2" class="get-agreement">
        <label class="ml-2" for="get-agree2">{{ 'RoditelSog' | translate }}</label>
    </div>

    <div class="text-center">
        <input (change)="agreeCheck()" type="checkbox" id="get-agree" class="get-agreement">
        <label class="ml-2" for="get-agree">{{ 'RulesText-3' | translate }}</label>
    </div>

    <button *ngIf="showParentData" [disabled]="!applicationAgree || !applicationAgreeParent" (click)="postApplication()" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
        {{ 'PostApplication' | translate }}
    </button>
    <button *ngIf="!showParentData" [disabled]="!applicationAgree" (click)="postApplication()" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
        {{ 'PostApplication' | translate }}
    </button>

</form>

