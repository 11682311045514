<div class="">
  <div *ngIf="router.url == '/profile/applications'" class="">
    <div class="text-center text-lg mb-8">
        {{ 'SelectTestType' | translate }}:
    </div>
    <div [routerLink]="'/profile/applications/1'" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
        {{ 'NationalQualificationTesting' | translate}}
    </div>
    <div [routerLink]="'/profile/applications/2'" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
      {{ 'UnifiedNationalTesting' | translate}}
    </div>
    <div [routerLink]="'/profile/applications/4'" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
      {{ 'AfterUniversityEducation' | translate}}
    </div>
<!--    <div [routerLink]="'/profile/applications/9'" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">-->
<!--      {{ 'DirTest' | translate}}-->
<!--    </div>-->

<!--    <div [routerLink]="'/profile/applications/3'" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">-->
<!--      {{ 'KazTest' | translate}}-->
<!--    </div>-->
<!--    *ngIf="isDev"-->

    <div *ngFor="let item of certifList" [routerLink]="'/profile/applications/' + item.path" class="uppercase mb-8 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer">
      <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
      <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
    </div>
  </div>

  <router-outlet *ngIf="router.url != '/profile/applications'"></router-outlet>

</div>


