<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="text-xl text-center uppercase text-white bg-black p-4 py-4">
    {{ 'NCT' | translate}} - {{ 'ApplicationService' | translate}}
</div>

<div class="">
    <p class="my-8 text-center text-2xl">Выберите тип тестирования для подачи заявления:</p>
    <div class="bg-white mb-10 text-center w-8/12 mx-auto hover:bg-gray-100 text-gray-800 text-3xl cursor-pointer font-bold py-4 px-4 border border-gray-400 rounded shadow">Единое Национальное Тестирование</div>
    <div class="bg-white mb-10 text-center w-8/12 mx-auto hover:bg-gray-100 text-gray-800 text-3xl cursor-pointer font-bold py-4 px-4 border border-gray-400 rounded shadow">Национальное Квалификационное Тестирование</div>
    <div class="bg-white mb-10 text-center w-8/12 mx-auto hover:bg-gray-100 text-gray-800 text-3xl cursor-pointer font-bold py-4 px-4 border border-gray-400 rounded shadow">Тестирование Директоров</div>
</div>