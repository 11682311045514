<section id="comparison" class="bg-slate-50 dark:bg-gray-800">
  <div class="mx-auto">
    <div class="text-2xl text-center font-bold text-blue-500">
      {{
        translate.currentLang === "ru"
          ? "Аналитика прошлых лет"
          : "Өткен жылдар бойынша талдау"
      }}
    </div>
    <ul
      role="list"
      class="mx-auto grid grid-cols-1 gap-6 sm:gap-8 lg:max-w-none lg:grid-cols-3"
    >
      <li *ngFor="let item of analytics">
        <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
          <li>
            <figure
              class="relative rounded-2xl bg-white dark:bg-gray-900 p-6 text-center shadow-xl shadow-slate-900/10"
            >
              <blockquote class="relative">
                <p
                  class="text-2xl font-bold tracking-tight text-slate-900 dark:text-white"
                >
                  {{ item.value }}
                </p>
              </blockquote>
            </figure>
            <figcaption class="text-center">
              <div class="font-display text-slate-900 dark:text-white">
                {{
                  translate.currentLang === "ru" ? item.label.ru : item.label.kk
                }}
              </div>
            </figcaption>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</section>

<div class="flex w-full mx-auto rounded shadow mt-5">
  <a
    *ngFor="let tab of tabs"
    aria-current="false"
    (click)="activeTab = tab.value"
    [ngClass]="
      activeTab === tab.value
        ? 'w-full flex justify-center font-medium px-5 py-2 border-t border-b bg-gray-900 text-white  border-gray-900 hover:bg-gray-800 cursor-pointer'
        : 'w-full flex justify-center font-medium rounded-l px-5 py-2 border bg-white text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer'
    "
  >
    {{ translate.currentLang === 'ru' ? tab.label.ru : tab.label.kk }}
  </a>
</div>

<div *ngIf="activeTab === 1">
  <div class="mt-4 text-base leading-6 bg-gray-100 px-4 py-2">
    <div class="grid grid-cols-2 gap-4 md:grid-cols-2">
      <span class="">{{translate.currentLang === 'ru' ? 'Выберите интересующую специальность' : 'Қажетті мамандықты таңдаңыз'}} </span>
      <select class="h-6">
        <option>-----</option>
      </select>
    </div>
  </div>

  <div class="relative overflow-x-auto">
    <table
      class="w-full text-sm text-left rtl:text-right border-collapse border border-blue-500 mt-8"
    >
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr class="bg-blue-500 text-white">
          <th rowspan="2" scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? 'Специальность' : 'Мамандық' }}</th>
          <th rowspan="2" scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? 'ВУЗ' : 'ЖОО' }}</th>
          <th colspan="3" scope="col" class="px-6 py-3 text-center">{{translate.currentLang === 'ru' ? 'Минимальный проходной балл по общему конкурсу' : 'Жалпы конкурс бойынша минималды өту балы' }}</th>
        </tr>
        <tr class="bg-blue-500 text-white">
          <th scope="col" class="px-6 py-3"> {{translate.currentLang === 'ru' ? '2021 год' : '2021 жыл' }}</th>
          <th scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? '2022 год' : '2022 жыл' }}</th>
          <th scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? '2023 год' : '2023 жыл' }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            В057
          </th>
          <td class="px-6 py-4">Информационные технологии</td>
          <td class="px-6 py-4">118</td>
          <td class="px-6 py-4">Да</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

<div *ngIf="activeTab === 2">
  <div class="mt-4 text-base leading-6 bg-gray-100 px-4 py-2">
    <div class="grid grid-cols-2 gap-4 md:grid-cols-2">
      <span class="">{{translate.currentLang === 'ru' ? 'Выберите интересующую специальность' : 'Қажетті мамандықты таңдаңыз'}} </span>
      <select class="h-6">
        <option>-----</option>
      </select>
    </div>
  </div>

  <div class="relative overflow-x-auto">
    <table
      class="w-full text-sm text-left rtl:text-right border-collapse border border-blue-500 mt-8"
    >
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr class="bg-blue-500 text-white">
          <th rowspan="2" scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? 'Специальность' : 'Мамандық' }}</th>
          <th rowspan="2" scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? 'ВУЗ' : 'ЖОО' }}</th>
          <th colspan="3" scope="col" class="px-6 py-3 text-center">{{translate.currentLang === 'ru' ? 'Минимальный проходной балл по общему конкурсу' : 'Жалпы конкурс бойынша минималды өту балы' }}</th>
        </tr>
        <tr class="bg-blue-500 text-white">
          <th scope="col" class="px-6 py-3"> {{translate.currentLang === 'ru' ? '2021 год' : '2021 жыл' }}</th>
          <th scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? '2022 год' : '2022 жыл' }}</th>
          <th scope="col" class="px-6 py-3">{{translate.currentLang === 'ru' ? '2023 год' : '2023 жыл' }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            В057
          </th>
          <td class="px-6 py-4">Информационные технологии</td>
          <td class="px-6 py-4">118</td>
          <td class="px-6 py-4">Да</td>
        </tr>
      </tbody>
    </table>
  </div>
 
</div>
