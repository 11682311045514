import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../helper.service';
import { ApiService } from '../../../service/api.service';

interface Model {
  iin: string;
  ikt: number;
}

interface KonvertModel {
  ikt?: number;
  foreignLangCertTypeId?: number;
  score?: string;
}

@Component({
  selector: 'app-ent-konkurs',
  templateUrl: './ent-konkurs.component.html',
  styleUrls: ['./ent-konkurs.component.css'],
})
export class EntKonkursComponent implements OnInit {
  certList: any[];
  body: Model = {
    iin: '',
    ikt: 0,
  };

  selectedCert: any;
  loading = false;

  foreignLangCertTypes: any[] = [];
  selectedForeignLangCert = -1;
  foreignLangCertScoreMask: string = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  foreignLangCertScore: any;
  totalKonkursBall = null;
  tvorExam: any;
  ped: any;
  med: any;
  data:any;

  modalVisible = false;

  iin: any;

  constructor(
    public router: Router,
    public translate: TranslateService,
    private service: HelperService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.getProfile();
    this.getForeingLangCertType();
  }

  getForeingLangCertType() {
    this.service
      .getForeignLangCert()
      .subscribe((res: any) => (this.foreignLangCertTypes = res));
  }

  getProfile(): void {
    this.api.profileInfo().subscribe({
      next: (res: any) => {
        // this.body.iin = res.data.iin;
        //this.body.iin = '070117650699';
      },
      complete: (): void => {
        //this.getCertificates();
      },
    });
  }

  getCertificates(){
    this.service.getEntCertificate(this.body).subscribe((res:any)=>this.certList = res)
  }

  getData(): void {
    this.body.ikt = this.selectedCert.ikt;
    this.service.getEntKonkursData(this.body).subscribe((res: any) => {
      this.data = res.data;
      this.tvorExam = res.tvorExam;
      this.ped = res.ped;
      this.med = res.med;
    });
  }

  chageCert(){
    this.totalKonkursBall = null
  
    if(this.selectedCert){
      this.getData();
    }
   
  }

  scoreCheck() {
    if (this.selectedForeignLangCert && this.selectedForeignLangCert == 4 && this.foreignLangCertScore > 9) {
      this.foreignLangCertScore = this.foreignLangCertScore / 10;
    }
  }

  calculate() {
    if (!this.selectedForeignLangCert && !this.foreignLangCertScore) {
      return;
    }

    const body: KonvertModel = {
      ikt: this.selectedCert.ikt,
      foreignLangCertTypeId: this.selectedForeignLangCert,
      score: this.foreignLangCertScore,
    };

    this.service.calculateKonvertBall(body).subscribe({
      next: (res: any) => {
        this.totalKonkursBall = res.out_convert_score;
      },
    });
  }

  goPage(code: number) {

    if(!this.selectedCert){
      return;
    }

    const info = {
      ikt: this.data.out_student_test,
      konkursScore: this.totalKonkursBall ? this.totalKonkursBall : this.data.out_summball,
      tvorScore: this.tvorExam.out_ep_name_rus != null ? (this.data.out_summball_tvor + this.tvorExam.out_tvor_1_ball +  this.tvorExam.out_tvor_2_ball) : 0,
      studyFormId: this.data.out_test_type_id
    }

    sessionStorage.setItem('helpInfo', JSON.stringify(info));

    if (code == 1) {
      this.router.navigate(['profile/helper/ent-konkurs/enroll']);
    }

    if(code == 2){
      this.router.navigate(['profile/helper/ent-konkurs/analytics']);
    }
  }
}
