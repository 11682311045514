import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {

  @Output() onCancelChangeEmail = new EventEmitter<number>();
  @Output() onApplyChangeEmail = new EventEmitter<number>();

  loading = false;
  newEmailData: any;
  changeEmail: FormGroup;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.initEmailForm();
  }

  edit(page: any) {
    this.loading = true;
    this.newEmailData = this.changeEmail.value;
    console.log(this.newEmailData);
    this.api.changeEmail(this.newEmailData)
      .subscribe((res: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Подтвердите ваш новый электронный адрес на вашей почте', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'электронный адрес изменен', NotificationType.Success, 5000);
        this.onApplyChangeEmail.emit(page);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    console.log(page);
  }

  initEmailForm() {
    this.changeEmail = this.fb.group({
      newEmail: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });
  }
  get isValid(): boolean {
    if (this.changeEmail.get('password').status === 'INVALID' &&
      this.changeEmail.get('newEmail').status === 'INVALID') return true;
  }
  get password() { return this.changeEmail.get('password'); }
  get newEmail() { return this.changeEmail.get('newEmail'); }

}
