<div class="text-xl z-10 relative justify-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16 flex">
    {{ 'NCT' | translate }}
</div>
<div class="container">
    <div class="mx-auto w-4/5">
        <p class="mt-48 text-3xl font-hairline mb-4">{{ 'PasswordRecovery' | translate }}</p>
        <form *ngIf="!passDropEnd" action="" (ngSubmit)="reset()" [formGroup]="passDropForm">
            <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
                <input (change)="checkPass()" formControlName="newPassword" placeholder="*{{ 'NewPassword' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black" type="password"  >
            </show-hide-password>
            <div *ngIf="passCheck.invalid && passCheck.touched" class="err-txt">
                *{{ 'FillInTheField' | translate }}
            </div>
            <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
                <input (change)="checkPass()" type="password" formControlName="confirmpassword" placeholder="*{{ 'ConfirmNewPassword' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black">
            </show-hide-password>
            <div *ngIf="!samePass && confirmpassword.touched" class="err-txt">
                *{{ 'PasswordsDoNotMatch' | translate }}
            </div>
            <div class="text-center">
                <button type="submit" [disabled]="!passDropForm.valid && samePass && isValid" [ngClass]="{'hover:bg-blue-800': passDropForm.valid && samePass,'hover:cursor-pointer': passDropForm.valid && samePass}" class="mx-auto w-4/6 mt-8 mb-8 text-white cursor-default font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">Изменить пароль</button>
            </div>
        </form>
        <div class="bg-gray-300 h-64 text-center" *ngIf="passDropEnd">
            <p class="mx-10 p-4 font-semibold text-lg">{{ 'PasswordReset' | translate }}</p>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [routerLink]="'/'">{{ 'GoToAuth' | translate }}</button>
        </div>
    </div>
</div>