import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Output() onCancelChange = new EventEmitter<number>();
  @Output() onApplyChange = new EventEmitter<number>();

  edit(page: any) {
    this.loading = true;
    this.passData = this.changePassword.value;
    this.api.changePassword(this.passData)
      .subscribe((res: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пароль изменен', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'Пароль изменен', NotificationType.Success, 5000);
        this.onApplyChange.emit(page);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    console.log(page);
  }

  loading = false;
  passData: any;
  samePass = true;
  changePassword: FormGroup;


  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.initRegForm();
  }

  back() {
    this.router.navigate(['/profile']);
  }

  checkPass() {
    if (this.changePassword.get('newPassword').value == this.changePassword.get('confirmpassword').value) {
      this.samePass = true;
    } else {
      this.samePass = false;
    }
  }

  initRegForm() {
    this.changePassword = this.fb.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      confirmpassword: ["", Validators.required]
    });
  }

  get isValid(): boolean {
    if (this.changePassword.get('oldPassword').status === 'INVALID' &&
      this.changePassword.get('newPassword').status === 'INVALID' &&
      this.changePassword.get('confirmpassword').status === 'INVALID') return true;
  }

  get oldPassword() { return this.changePassword.get('oldPassword'); }
  get newPassword() { return this.changePassword.get('newPassword'); }
  get confirmpassword() { return this.changePassword.get('confirmpassword'); }

}
