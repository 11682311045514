import { Component, OnInit, isDevMode, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
declare var $: any;
declare var html2canvas: any;

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {

  @Input() appData;
  @Input() certFiles;
  @Input() subjectsArr;
  @Input() type;


  appealMotivate: FormGroup; // Форма аппеляции по техническим причинам
  appealEditMotivate: FormGroup; // Форма редактирования аппеляции по техническим причинам
  appealEnd: FormGroup; // Форма завершения тестирования
  appealContent: FormGroup; // Форма аппеляции по содержанию задания
  addAppealForm: FormGroup;
  editAppealForm: FormGroup;
  applicationForm: FormGroup;
  

  contentAppealItems = []; // Список причин аппеляции по содержанию задания
 
  additionalFilesItems: any;

  ball = '000';
  subjectList = [];
 
  dataImgScreen = '';

  applicationAgree = false;
  
  lastQestion: any;
  submitted = false;
  editSubmitted = false;
  appealReason: any;
  appealDescription: any;
  editContent = null;
  loading = true;

  currentEditStatus: any;
  isTimeLimitedCheck: boolean;
  fileId: any;
  buttonText: any;
  descriptionCheck = false;
  fileNameCheck: any;
  editFileCheck = false;
  reasonCheck = false;
  fileDeleteModal = false;
  editFileDeleteModal = false;
  deleteModal = false;
  serverMessage: any;
  errWindow = false;
  profileSubjectList: any;


  fileIdToDelete: number;

  constructor(private router: Router,
              private route: ActivatedRoute, public sanitizer: DomSanitizer, 
              public translateService: TranslateService, private _notifications: NotificationsService,
              private api: ApiService,
              private http: HttpClient,
              public translate: TranslateService) { 
              }

  // Модальное окно с аппеляцией
  // appealModal(): void {
  //   this.dataImgScreen = '';
  //   let that = this;
  //   // var offsetHeight = document.getElementById('preview-block').offsetHeight + 130;
  //   var offsetHeight = document.getElementById('currentQuest').offsetHeight + 130;

  //   html2canvas($('#currentQuest')[0], {
  //     useCORS: true,
  //     height: offsetHeight,
  //     x: 310,
  //     y: 100
  //   }).then(function (canvas) {
  //     var canvasImg = canvas.toDataURL("image/jpg");
  //     that.dataImgScreen = canvasImg;
  //     that.data.setValue(canvasImg.substring(22));
  //   });
  // }


  private createAddAppealForm(): void {
    this.addAppealForm = new FormGroup({
      reasonTypeId: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      additionalFiles: new FormArray([])
    });
  }
  get additionalFiles(): any { return this.addAppealForm.get('additionalFiles');}
  get reasonType(): AbstractControl { return this.addAppealForm.get('reasonTypeId'); }
  get description(): AbstractControl { return this.addAppealForm.get('description'); }
  get files(): any { return this.applicationForm.get('files');}

  private createEditAppealForm(): void {
    this.editAppealForm = new FormGroup({
      reasonTypeId: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      additionalFiles: new FormArray([])
    });
  }
  get editReasonType(): AbstractControl { return this.editAppealForm.get('reasonTypeId'); }
  get editDescription(): AbstractControl { return this.editAppealForm.get('description'); }
  get editAdditionalFiles() : any { return this.applicationForm.get('files');}

  

  ngOnInit() {

    

    // console.log(this.appData.subjects);

    if(this.type == 'sat'){
      this.applicationForm = new FormGroup({
        phoneNumber: new FormControl(),
        reasoningScore: new FormControl(0),
        foreignLangCertificateTypeId: new FormControl(0),
        foreignLangCertificateScore: new FormControl('0'),
        registrationNumber: new FormControl(0, Validators.required),
        testDate: new FormControl(0, Validators.required),
        foreignLangCertificateDate: new FormControl(0, Validators.required),
        isSubjectsExists: new FormControl(true, Validators.required),
        subjects: new FormArray ([]),
        profileSubjectPairId: new FormControl(0, Validators.required),
        files: new FormArray([])
      });  
    }if(this.type == 'ib'){
      this.applicationForm = new FormGroup({
        phoneNumber: new FormControl(),
        foreignLangCertificateTypeId: new FormControl(0),
        foreignLangCertificateScore: new FormControl('0'),
        registrationNumber: new FormControl(0, Validators.required),
        testDate: new FormControl(0, Validators.required),
        foreignLangCertificateDate: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0, Validators.required),
        mathSubject: new FormGroup ({
          id: new FormControl(0),
          score: new FormControl(0)
        }),
        subjects: new FormArray ([]),
        files: new FormArray([])
      });  
    }if(this.type == 'ast'){
      this.applicationForm = new FormGroup({
        phoneNumber: new FormControl(),
        englishScore: new FormControl(),
        mathScore: new FormControl(0, Validators.required),
        readingScore: new FormControl(0, Validators.required),
        scienceScore: new FormControl(0, Validators.required),
        foreignLangCertificateTypeId: new FormControl(0),
        foreignLangCertificateScore: new FormControl('0'),
        registrationNumber: new FormControl(0, Validators.required),
        testDate: new FormControl(0, Validators.required),
        foreignLangCertificateDate: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0),
        subjects: new FormArray ([]),
        files: new FormArray([])
      });
    }

    if(this.type != 'ast'){
      for(let i = 0; i < 2; i++){
        this.subjects.push(this.subject());
      }
  
      
    }

    
    this.getEntProfileSubjects();

    

    // console.log(this.appData);

    this.createAddAppealForm();



    this.appealContent = new FormGroup({
      additionalFiles: new FormGroup({
        id: new FormControl(''),
        filename: new FormControl('appealScreenShot.jpg'),
        data: new FormControl(this.dataImgScreen, [Validators.required])
      })
    });
    this.appealEnd = new FormGroup({
      nanoCaptcha: new FormControl('')
    })

    this.createEditAppealForm();
   
      
      this.appealMotivate = new FormGroup({
        reasonTypeId: new FormControl(''),
        description: new FormControl(''),
        testPageImage: new FormGroup({
          filename: new FormControl('appealScreenShot.jpg'),
          data: new FormControl(this.dataImgScreen)
        })
      });

  }

  subject(): FormGroup {
    return new FormGroup({
      id: new FormControl(0),
      score: new FormControl()
    });
  }

  checkSubject(){
    if(this.type == 'sat' && this.applicationForm.get('isSubjectsExists').value == false){
      return true;
    }
  }

  getEntProfileSubjects(){
    if(this.type == 'ib'){
      this.api.getCertEntIbProfileSubject()
      .subscribe((res: any) => {
        this.profileSubjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }if(this.type == 'sat'){
      this.api.getCertEntProfileSubject()
      .subscribe((res: any) => {
        this.profileSubjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
    
  }

  getProfileSubject(){
    if(this.type == 'sat'){
      this.api.getSatProfileSubject(this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.subjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
    if(this.type == 'sat'){
      this.api.getSatProfileSubject(this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.subjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
    if(this.type == 'ib'){
      console.log('12312312312312321');
      this.api.getIbSubjects(this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.subjectList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }
  }

  get subjects() : any { return this.applicationForm.get('subjects')}

  get filename() { return this.appealMotivate.get('testPageImage').get('filename'); }
  get data() { return this.appealMotivate.get('testPageImage').get('data'); }

  // Добавить еще 1 input для файла
  addFile(type: string) {
    let obj = null;
    if (type === 'add') {
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }
    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }


  changeListener($event, i: number, type: string) : void {
    // console.log($event.target.files);
    let obj = null;
    if (type === 'add') {
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      // obj.controls[i].get('description').setValue();
      let imgData = myReader.result;
      
      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
      // console.log(obj.controls[i].get('data'));
    }
    myReader.readAsDataURL(file);
  }

  // Удаление input для файла
  removeFile(type: string){
    // console.log(this.editAppealForm);
    this.translateService.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    this.fileDeleteModal = false;
    this.editFileDeleteModal = false;
    let obj = null;
    if(type == "add"){
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }
    obj.removeAt(this.fileIdToDelete);
  }
  // Кнопка редактирования input
  changeEditContent(index: number, filename: string) {
      this.editContent = (index == this.editContent ? null:index);
  }

// downloadFile(id: number) {
//   this.api.contentAppelFileData(this.currentTab, this.currentPredmetId, +this.editAdditionalFiles.controls[id].get('id').value)
//     .subscribe((res: any) => {
//       saveAs(this.b64toBlob(res.data.data, '**/*'), res.data.filename);
//     });
// }

b64toBlob(b64Data, contentType='', sliceSize=512){
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


agreeCheck(){
  this.applicationAgree = !this.applicationAgree;
}

  onSubmitAdd(){
    this.submitted = true;

    if (this.addAppealForm.invalid) {
      return;
    }

  }

  onSubmitEdit(){
    this.editSubmitted = true;

    if (this.editAppealForm.invalid) {
      return;
    }

  }

  fileDelete(i: number): void{
    this.fileIdToDelete = i;
    this.fileDeleteModal = true;
  }
  editFileDelete(i: number): void{
    this.fileIdToDelete = i;
    this.editFileDeleteModal = true;
  }
  closeDeleteModal(): void {
    this.editFileDeleteModal = false;
    this.fileDeleteModal = false;
  }


  dwnldCheck(id: number): boolean {
    return this.editAdditionalFiles.controls[id].get('filename').value !== '';
  }

  dwnldCheckAdd(id: number): boolean {
    return this.additionalFiles.controls[id].get('filename').value !== '';
  }

  dwnldCheckId(id: number): boolean {
    return +this.editAdditionalFiles.controls[id].get('id') != 0;
  }

  postApplication(){
    if(this.type == 'sat'){
      this.applicationForm.get('phoneNumber').setValue(this.appData.phoneNumber);
      // this.appData.subjects.forEach(element => {
      //   this.subjects.controls.push(element);
      // });
      // this.applicationForm.get('subjects').patchValue(this.appData.subjects);
      this.applicationForm.get('testDate').setValue(this.appData.testDate);
      this.applicationForm.get('reasoningScore').setValue(this.appData.reasoningScore);
      this.applicationForm.get('registrationNumber').setValue(this.appData.registrationNumber);
      this.applicationForm.get('foreignLangCertificateTypeId').setValue(this.appData.foreignLangCertificateTypeId);
      this.applicationForm.get('foreignLangCertificateDate').setValue(this.appData.foreignLangCertificateDate);
      this.applicationForm.get('foreignLangCertificateScore').setValue(this.appData.foreignLangCertificateScore); 
    }if(this.type == 'ib'){
      this.applicationForm.get('phoneNumber').setValue(this.appData.phoneNumber);
      this.applicationForm.get('testDate').setValue(this.appData.testDate);
      // this.applicationForm.get('reasoningScore').setValue(this.appData.reasoningScore);mathSubjectId
      this.applicationForm.get('mathSubject').get('id').setValue(this.appData.mathSubjectId);
      this.applicationForm.get('mathSubject').get('score').setValue(this.appData.mathSubjectScore);
      this.applicationForm.get('registrationNumber').setValue(this.appData.registrationNumber);
      this.applicationForm.get('foreignLangCertificateTypeId').setValue(this.appData.foreignLangCertificateTypeId);
      this.applicationForm.get('foreignLangCertificateDate').setValue(this.appData.foreignLangCertificateDate);
      this.applicationForm.get('foreignLangCertificateScore').setValue(this.appData.foreignLangCertificateScore); 
    }if(this.type == 'ast'){
      this.applicationForm.get('phoneNumber').setValue(this.appData.phoneNumber);
      this.applicationForm.get('englishScore').setValue(this.appData.englishScore);
      this.applicationForm.get('mathScore').setValue(this.appData.mathScore);
      this.applicationForm.get('readingScore').setValue(this.appData.readingScore);
      this.applicationForm.get('scienceScore').setValue(this.appData.scienceScore);
      this.applicationForm.get('profileSubjectPairId').setValue(this.appData.profileSubjectPairId);
      this.applicationForm.get('testDate').setValue(this.appData.testDate);
      this.applicationForm.get('registrationNumber').setValue(this.appData.registrationNumber);
      this.applicationForm.get('foreignLangCertificateTypeId').setValue(this.appData.foreignLangCertificateTypeId);
      this.applicationForm.get('foreignLangCertificateDate').setValue(this.appData.foreignLangCertificateDate);
      this.applicationForm.get('foreignLangCertificateScore').setValue(this.appData.foreignLangCertificateScore);
    }
    
    // console.log(this.applicationForm);
    // console.log(this.appData);
    
    this.loading = true;

    if(this.type == 'sat'){
      this.api.postSatApp(this.applicationForm.value)
      .subscribe((res: any) => {
          this.router.navigateByUrl('/profile/history');
      },(err: any) =>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
    }if(this.type == 'ib'){
      this.api.postIbApp(this.applicationForm.value)
      .subscribe((res: any) => {
          this.router.navigateByUrl('/profile/history');
      },(err: any) =>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
    }if(this.type == 'ast'){
      this.api.postAstApp(this.applicationForm.value)
      .subscribe((res: any) => {
          this.router.navigateByUrl('/profile/history');
      },(err: any) =>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
    }

    
  }

 



}
