import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DisabilityComponent } from 'src/app/components/profile/template/disability/disability.component';
import { PersonalInfoComponent } from 'src/app/components/profile/template/personal-info/personal-info.component';
import { TestOrgComponent } from 'src/app/components/profile/template/test-org/test-org.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-justice-academy',
  templateUrl: './e-justice-academy.component.html',
  styles: [],
})
export class EJusticeAcademyComponent implements OnInit {
  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  pageLangId: any;
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOblList = [];
  testOrgList = [];
  testDateList = [];
  foreignLangCertCharScore: any;
  charList: any;
  studyTypeList: any;
  eduAreaList: any;
  masterTypeList: any;
  gopList: any;
  foreignLangList: any;
  profileDisciplines1List: any;
  profileDisciplines2List: any;
  showChars = false;
  showSubjects = false;
  sertidTypeFloat = false;
  fileList: any;

  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  fileIdToDelete: number;
  prevEduArea: any;
  prevEduDirection: any;

  eduOblList: any;
  eduInstituteList: any;

  shortInfo: any;
  applicationId: any;
  incriment: number = 0;
  CSID: any;
  NID: any;

  @ViewChild(DisabilityComponent) disability: DisabilityComponent;
  @ViewChild(TestOrgComponent) testOrg: TestOrgComponent;
  @ViewChild(PersonalInfoComponent) person: PersonalInfoComponent;

  constructor(
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

    // Инициализация формы
    let emptyValue: number = 0;
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(+this.seasonId),
      appTypeId: new FormControl(+this.appTypeId),
      citizenshipId: new FormControl(+emptyValue, Validators.required),
      nationId: new FormControl(+emptyValue, Validators.required),
      foreignLangCertTypeId: new FormControl(+emptyValue),
      foreignLangCertScore: new FormControl(+emptyValue),
      phoneNumber: new FormControl(+emptyValue, Validators.required),
      sexId: new FormControl(+emptyValue, Validators.required),
      isHasDisability: new FormControl(+emptyValue, Validators.required),
      disabilityId: new FormControl(+emptyValue, Validators.required),
      masterTypeId: new FormControl(+emptyValue, Validators.required),
      eduInstituteId: new FormControl(+emptyValue),
      eduInstituteOblId: new FormControl(+emptyValue),
      profileSubjectPairId: new FormControl(+emptyValue),
      testLangId: new FormControl(+emptyValue),
      testCityId: new FormControl(+emptyValue),
      eduProgramId: new FormControl(+emptyValue),
      eduAreaId: new FormControl(+emptyValue),
      testPeriodId: new FormControl(+emptyValue),
      prevEduAreaId: new FormControl(+emptyValue),
      prevEduDirectionId: new FormControl(+emptyValue),
      profileSubject1Id: new FormControl(+emptyValue),
      foreignLangId: new FormControl(+emptyValue),
      profileSubject2Id: new FormControl(+emptyValue),
      docFiles: new FormArray([]),
      appFiles: new FormArray([]),
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }
    this.getDataPvo();
  }

  getDataPvo() {
    this.api.getShortInfoPvo(this.applicationId).subscribe(
      (res: any) => {
        this.shortInfo = res.data;
        this.seasonId = this.shortInfo.seasonId;

        this.disability.getHelper(this.seasonId, this.appTypeId);
        this.person.getData(this.seasonId, this.appTypeId);
        this.testOrg.getCity(this.seasonId, this.appTypeId);

        this.applicationForm.get('testCityId').setValue(this.shortInfo.testCityId);

        this.getForeignLangCertList();
        this.getStudyType();
        this.getMasterType();
        this.getForeignLang();
        this.addFiles();
        this.addFilesZayav();
        this.getPrevEduArea();
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);

        if (this.shortInfo.citizenshipId != 0 && this.CSID == 0) {
          this.applicationForm.get('citizenshipId').patchValue(this.shortInfo.citizenshipId);
        }

        if (this.shortInfo.nationId != 0 && this.NID == 0) {
          this.applicationForm.get('nationId').patchValue(this.shortInfo.nationId);
        }

        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.shortInfo.foreignLangCertTypeId);
        this.applicationForm.get('foreignLangCertScore').patchValue(this.shortInfo.foreignLangCertScore);

        for (this.incriment; this.incriment < 1; this.incriment++) {
          this.applicationForm.get('foreignLangCertScore').patchValue(this.shortInfo.foreignLangCertScore);
        }
        // this.applicationForm.get('sexId').setValue(this.shortInfo.sexId);
        this.applicationForm.get('phoneNumber').patchValue(this.shortInfo.phoneNumber);
      },
      (err: any) => {}
    );
  }
  getInstituteObl(code: any) {
    this.api.getEduInstuteOblPvo(
        this.seasonId,
        this.applicationForm.get('masterTypeId').value,
        this.applicationForm.get('eduProgramId').value,
        this.appTypeId
      )
      .subscribe({
        next: (res: any) => {
          this.eduOblList = res.items;
          if (code == 1) {
            this.applicationForm.get('eduInstituteOblId').patchValue(this.shortInfo.eduInstituteOblId);
            this.getInsitute(1);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getInsitute(code: any) {
    this.api
      .getEduInstutePvo(
        this.seasonId,
        this.applicationForm.get('masterTypeId').value,
        this.applicationForm.get('eduProgramId').value,
        this.appTypeId,
        this.applicationForm.get('eduInstituteOblId').value
      )
      .subscribe({
        next: (res: any) => {
          this.eduInstituteList = res.items;
          if (code == 1) {
            this.applicationForm
              .get('eduInstituteId')
              .patchValue(this.shortInfo.eduInstituteId);
          } else {
            this.applicationForm.get('eduInstituteId').setValue(0);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getPrevEduArea() {
    this.api.getPrevEduArea(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.prevEduArea = res.items;
      },
      (err: any) => {}
    );
  }

  getEduDirection() {
    this.api
      .getPrevEduDirection(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('prevEduAreaId').value
      )
      .subscribe(
        (res: any) => {
          this.prevEduDirection = res.items;
          this.applicationForm.get('prevEduDirectionId').setValue(0);
          this.clearDown();
          this.eduAreaList = [];
          this.gopList = [];
        },
        (err: any) => {}
      );
  }

  clearDown() {
    this.applicationForm.get('masterTypeId').setValue(0);
    this.applicationForm.get('eduAreaId').setValue(0);
    this.applicationForm.get('eduProgramId').setValue(0);
    this.applicationForm.get('testLangId').setValue(0);
    this.applicationForm.get('foreignLangId').setValue(0);
  }

  getEduArea(code: any) {
    if (code == 1) {
      this.applicationForm
        .get('prevEduDirectionId')
        .setValue(this.shortInfo.prevEduDirectionId);
    }
    this.api
      .getEduAreaPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('masterTypeId').value,
        this.applicationForm.get('prevEduDirectionId').value
      )
      .subscribe(
        (res: any) => {
          this.eduAreaList = res.items;
          if (code == 1) {
            this.applicationForm
              .get('eduInstituteOblId')
              .setValue(this.shortInfo.eduInstituteOblId);
            this.applicationForm
              .get('eduProgramId')
              .setValue(this.shortInfo.eduProgramId);
            this.applicationForm
              .get('eduAreaId')
              .setValue(this.shortInfo.eduAreaId);
            this.getGop(1);
          } else {
            this.applicationForm.get('eduProgramId').setValue(0);
            this.applicationForm.get('eduAreaId').setValue(0);
          }
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getProfileDisciplines(id: any, code: any) {
    let index = document.forms[0].elements['gop'].selectedIndex;
    this.applicationForm.get('profileSubject1Id').setValue(0);
    this.applicationForm.get('profileSubject2Id').setValue(0);
    if (
      this.gopList[index - 1].id == id &&
      this.gopList[index - 1].hasMultipleProfileSubjects
    ) {
      this.showSubjects = true;
      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          1
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines1List = res.items;
            if (code == 1) {
              this.applicationForm
                .get('profileSubject1Id')
                .setValue(this.shortInfo.profileSubject1Id);
            }
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );

      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          2
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines2List = res.items;
            if (code == 1) {
              this.applicationForm
                .get('profileSubject2Id')
                .setValue(this.shortInfo.profileSubject2Id);
            }
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );
    } else {
      this.showSubjects = false;
    }
  }

  getForeignLang() {
    this.api.getForeignLangPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getTestLang(code: any) {
    this.api
      .getTestLangPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduProgramId').value
      )
      .subscribe(
        (res: any) => {
          this.langList = res.items;
          if (code == 1) {
            this.applicationForm
              .get('testLangId')
              .setValue(this.shortInfo.testLangId);
            this.getProfileDisciplines(
              this.applicationForm.get('eduProgramId').value,
              1
            );
          } else {
            this.getProfileDisciplines(
              this.applicationForm.get('eduProgramId').value,
              0
            );
            this.applicationForm.get('testLangId').setValue(0);
          }
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getMasterType() {
    this.api.getMasterTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.masterTypeList = res.items;
        this.applicationForm.get('masterTypeId').setValue(this.shortInfo.masterTypeId);
        this.getEduArea(1);
        this.getInstituteObl(1);
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getGop(code: any) {
    this.api
      .getGopPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduAreaId').value,
        this.applicationForm.get('masterTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.gopList = res.items;
          if (code == 1) {
            this.applicationForm
              .get('eduProgramId')
              .setValue(this.shortInfo.eduProgramId);
            this.getTestLang(1);
          } else {
            this.applicationForm.get('eduProgramId').setValue(0);
            this.applicationForm.get('testLangId').setValue(0);
            this.applicationForm.get('eduInstituteOblId').setValue(0);
            this.applicationForm.get('eduInstituteId').setValue(0);
          }
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getStudyType() {
    this.api.getStudyTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.studyTypeList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getForeignLangCertList() {
    this.api.getForeignLangCertPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangCertList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  get files(): any {
    return this.applicationForm.get('docFiles');
  }
  get zayav(): any {
    return this.applicationForm.get('appFiles');
  }

  checkSertif(id: number) {
    this.foreignLangCertList.forEach((element) => {
      if (element.isScoreInChars && element.id == id) {
        this.charList = element.availableValues;
        this.showChars = true;
      }
      if (!element.isScoreInChars && element.id == id) {
        this.showChars = false;
      }
      if (element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = true;
        // this.foreignLangCertScoreMask = '0.0';
      }
      if (!element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = false;
        // this.foreignLangCertScoreMask = '000';
      }
    });
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck() {
    if (
      this.sertidTypeFloat == true &&
      this.applicationForm.get('foreignLangCertScore').value > 9
    ) {
      this.applicationForm
        .get('foreignLangCertScore')
        .patchValue(
          this.applicationForm.get('foreignLangCertScore').value / 10
        );
    }
  }

  addFiles() {
    let obj = null;
    this.shortInfo.docFiles.forEach((element) => {
      obj = this.files;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl(''),
        })
      );
    });
  }

  addFilesZayav() {
    let obj = null;
    this.shortInfo.appFiles.forEach((element) => {
      obj = this.zayav;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl(''),
        })
      );
    });
  }

  postApplication() {
    this.scoreCheck();
    this.loading = true;
    this.api
      .putAppItemsPvo(this.applicationId, this.applicationForm.value)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.router.navigateByUrl('/profile/history');
          setTimeout(() => {
            window.location.reload();
          }, 200);
        },
        (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }
}
