import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class modoConfig {
  backend: any;
  apiRoutes: any;
  constructor() {
    if (isDevMode()) {
        this.backend = 'https://app.debug.testcenter.kz/modo/student/app/api/v1';
    }
    if (!isDevMode()) {
        this.backend = 'modo/student/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      appItems: this.backend + '/test-data'
    }

  }
}
