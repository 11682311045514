import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class kaztestConfig {
  backend: any;
  apiRoutes: any;
  certificate:any;
  constructor() {
    if (isDevMode()) {
        this.backend = 'https://app.debug.testcenter.kz/kaztest/blank/data-transfer/api/v1'; // Путь для localhost
        this.certificate = 'https://certificate.debug.testcenter.kz/api/v1';
    }
    if (!isDevMode()) {
        this.backend = 'kaztest/blank/data-transfer/api/v1'; // Путь для боевого
        this.certificate = 'https://certificate.testcenter.kz/api/v1'
    }

    this.apiRoutes = { // ссылки для запросов

    }

  }
}
