import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KaztestService } from '../../applications/kaztest/service/kaztest.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kaztest-zayav-results',
  templateUrl: './kaztest-zayav-results.component.html',
  styleUrls: ['./kaztest-zayav-results.component.css']
})
export class KaztestZayavResultsComponent implements OnInit {

  @Input() app;
  @Output() deleteApp = new EventEmitter<any>();

  loading: any;
  id: any;
  shortList: any;
  isCollapsed = true;
  isTableVisible = false;

  constructor(
    private api: KaztestService,
    private router:Router,
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.appItemsInfo();
  }
  showPayment(id: number){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 8} });
  }

  showEdit(id:number, testTypeId:number){
    if(id !== 0 || testTypeId != 0){
      this.router.navigate(['profile/history/edit/kaztest', testTypeId, id]);
    }
  }

  showDeleteModal(){
    this.deleteApp.emit();
  }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
              .subscribe((res: any) => {
              let certifLink = res.data.certificateDownLoadUrl;
              window.open(certifLink, "_blank");
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  appItemsForApeal(){

    // console.log(this.shortList);
    let data: any = {};
    data = {
      iin: this.shortList.iin,
      studentTestId: this.app.studentTestId,
      email: this.shortList.email,
      phoneNumber: this.app.phoneNumber,
      subjects: [
        {
          id: this.app.id,
          appealReason: this.app.appealReason
        }
      ]
    };
    this.api.appItemsForApeals(data)
      .subscribe((res: any) => {

      });
  }

  appItemsInfo(){
    console.log(this.shortList);
    this.api.getShort(this.app.id)
      .subscribe((res: any) => {
        this.shortList = res.data;
        console.log(this.shortList);

      });
  }

}
