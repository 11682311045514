import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-perevodniki-ped',
  templateUrl: './perevodniki-ped.component.html',
  styleUrls: ['./perevodniki-ped.component.css']
})
export class PerevodnikiPedComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  testOblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts = '0.0';
  applicationAgree = false;
  gopList = [];
  vuzList: any;
  showParentData = false;
  applicationAgreeParent = false;
  dataofbday = 0;
  dopSertifsList: any;

  disabilityList: any;
  fileIdToDelete: number;
  subjectList: any;

  mathSubjects: any;

  blockBtn = true;
  barArray: any = [false, true, true, true, true, true];

  isCollapsedPersonalData = false;
  isCollapsedPlaceOfStudy = true;
  isCollapsedTestParams = true;
  isCollapseTestCertif = true;
  isCollapseTestOrg = true;
  isCollapseDisabilityData = true;

  textModal = false;

  rulesEnt = false;
  rulesEntData: any;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private rules: MessageService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.appTypeId = this.route.snapshot.queryParamMap.get('appTypeId');

    this.seasonId = this.router.parseUrl(this.router.url).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    this.rules.getEntSog().subscribe(res=> this.rulesEntData = res);

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        // if(res.data.sexId != 0){
        //   this.applicationForm.get('sexId').disable();
        // }
        // if(res.data.nationId != 0 || res.data.nationId != 99){
        //   this.applicationForm.get('nationId').disable();
        // }
        // if(res.data.citizenshipId != 0 || res.data.citizenshipId != 99){
        //   this.applicationForm.get('citizenshipId').disable();
        // }
        // if(res.data.phoneNumber != 0){
        //   this.applicationForm.get('phoneNumber').disable();
        // }
        this.applicationForm.get('sexId').patchValue(res.data.sexId);
        this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        this.getDateOfBirth(res.data.birthDate);

      });
    // Инициализация формы
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(this.seasonId),
      appTypeId: new FormControl(this.appTypeId),
      citizenshipId: new FormControl(0),
      nationId: new FormControl(0),
      foreignLangCertTypeId: new FormControl(0),
      foreignLangCertScore: new FormControl(0),
      foreignLangCertTestDate: new FormControl('0'),
      phoneNumber: new FormControl(0),
      sexId: new FormControl(0),
      oblId: new FormControl(0),
      raionId: new FormControl(0),
      eduInstituteId: new FormControl(0),
      profileSubjectPairId: new FormControl(0),
      testLangId: new FormControl(0),
      testOrgId: new FormControl(0),
      testOrgOblId: new FormControl(0),
      eduProgramId: new FormControl(0),
      birthDate: new FormControl(0),
      parentFullname: new FormControl(),
      parentPhoneNumber: new FormControl(),
      anotherExamTypeId: new FormControl(0),
      testPeriodId: new FormControl(0),
      isHasDisability: new FormControl(0),
      isNeedHelperDueDisability: new FormControl(0),
      disabilityId: new FormControl(0),
      docFiles: new FormArray([]),
      anotherExamFiles: new FormArray([]),
      satAnotherExamCertificateData: new FormGroup({
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0),
        reasoningScore: new FormControl(0),
        isSubjectsExists: new FormControl(0),
        subjects: new FormArray([]),
      }),
      astAnotherExamCertificateData: new FormGroup({
        englishScore: new FormControl(0),
        mathScore: new FormControl(0),
        readingScore: new FormControl(0),
        scienceScore: new FormControl(0),
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0)
      }),
      ibAnotherExamCertificateData: new FormGroup({
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0),
        mathSubject: new FormGroup({
          id: new FormControl(0),
          score: new FormControl(0)
        }),
        subjects: new FormArray([])
      }),
    });
    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    for (let i = 0; i < 2; i++) {
      this.subjects.push(this.subject());
    }

    for (let i = 0; i < 2; i++) {
      this.subjectsIb.push(this.subject());
    }

    this.getNationList();
    this.getCountryList();
    this.getForeignLangCertList();
    this.getOblList();
    // this.getSubjectPair();
    this.getTestOrg();
    this.getGopForVuz();
    this.getHelper();
    this.getSertifs();
    this.getIBMathSubjects();

  }

  subject(): FormGroup {
    return new FormGroup({
      id: new FormControl(),
      score: new FormControl()
    });
  }

  getProfileSubject() {
    if (this.applicationForm.get('anotherExamTypeId').value == 1) {
      this.api.getSatProfileSubject(this.applicationForm.get('profileSubjectPairId').value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }
    if (this.applicationForm.get('anotherExamTypeId').value == 2) {
      this.api.getIbSubjects(this.applicationForm.get('profileSubjectPairId').value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

  }

  getIBMathSubjects() {
    this.api.getMathSubjects()
      .subscribe((res: any) => {
        this.mathSubjects = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSubject() {
    if (this.applicationForm.get('satAnotherExamCertificateData').get('isSubjectsExists').value == false) {
      return true;
    }
  }

  closeBar(index: number) {
    if (index == 0) {
      this.barArray[index] = true;
      this.barArray[index + 1] = false;
    } else {
      if (this.applicationForm.get('anotherExamTypeId').value == 0 && index == 2) {
        this.barArray[index] = true;
        this.barArray[index + 2] = false;
      } else {
        this.barArray[index] = true;
        this.barArray[index + 1] = false;
      }
    }
  }

  addCertifFile() {
    let obj = null;

    obj = this.certifFiles;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  clearFiles() {
    this.applicationForm.get('anotherExamFiles').value.forEach(element => {
      this.removeCertifFile('0');
    });

  }

  removeCertifFile(type: string) {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.certifFiles;

    obj.removeAt(type);
  }

  getSertifs() {
    this.api.getDopSertifs(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.dopSertifsList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds 
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates 
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates 
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  getDateOfBirth(date: any) {
    let today = new Date();
    this.dataofbday = this.getNumberOfDays(date, today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate());
    if (this.getNumberOfDays(date, today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()) < 6574) {
      this.showParentData = true;
    } else {
      this.showParentData = false;
    }
  }


  getVuzList() {
    this.api.getVuzEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value)
      .subscribe((res: any) => {
        this.vuzList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getRct() {
    this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgOblId').value, 0)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        this.applicationForm.get('testOrgId').patchValue(0);
        this.applicationForm.get('testPeriodId').patchValue(0);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getGopForVuz() {
    this.api.getGopForVuzSpr(this.seasonId, this.appTypeId, 1, true)
      .subscribe((res: any) => {
        this.gopList = res.items;
      }, (err: any) => {

      });
  }

  getNationList() {
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList() {
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList() {
    this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getOblList() {
    this.api.getOblEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.applicationForm.get('oblId').setValue(0);
        // this.applicationForm.get('raionId').setValue(0);
        // this.applicationForm.get('schoolId').setValue(0);
        this.oblList = res.items;
        this.getEduInfo();
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  getRaionList() {
    this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value)
      .subscribe((res: any) => {
        this.raionList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSchoolList() {
    this.api.getSchoolEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('raionId').value)
      .subscribe((res: any) => {
        this.schoolList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getEduInfo() {
    this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if (res.data.oblId != 0) {
          this.applicationForm.get('oblId').setValue(res.data.oblId);
          this.applicationForm.get('oblId').disable();
          this.getRaionList();
          if (res.data.raionId != 0) {
            this.applicationForm.get('raionId').setValue(res.data.raionId);
            this.applicationForm.get('raionId').disable();
            this.getSchoolList();
            if (res.data.schoolId != 0) {
              this.applicationForm.get('schoolId').disable();
              this.applicationForm.get('schoolId').setValue(res.data.schoolId);
            }
          }
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSubjectPair() {
    this.api.getProfileSubjectEntTvor(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.applicationForm.get('profileSubjectPairId').patchValue(0);
        this.applicationForm.get('testLangId').patchValue(0);
        this.subjectPairList = res.items;
        this.subjectPairList.forEach(element => {
          if (element.default == true) {
            this.applicationForm.get('profileSubjectPairId').setValue(element.id)
            this.getLangList();
          }
        });
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  changeCertifListener($event, i: number): void {
    let obj = null;

    obj = this.certifFiles;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }


  getLangList() {
    this.getProfileSubject();
    this.api.getLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.applicationForm.get('testLangId').patchValue(0);
        this.langList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestOrg() {
    this.api.getTestOrgEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate() {
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, 0)
      .subscribe((res: any) => {
        this.testDateList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id) {
    if (id == 4) {
      this.foreignLangCertScoreMask = '0.0';
    }
    if (id != 4) {
      this.foreignLangCertScoreMask = '000';
    }
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck() {
    if (this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9) {
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
    }
  }

  postApplication() {
    this.loading = true;
    this.scoreCheck();
    if (this.dataofbday == 0) {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'вы не ввели дату рождения', NotificationType.Error, 5000) : this._notifications.create('Қате', 'сіз туған күніңізді енгізген жоқсыз', NotificationType.Error, 5000);
      this.loading = false;
    } else {
      this.api.postApplicationEnt(this.applicationForm.value)
        .subscribe((res: any) => {
          this.loading = false;
          if (res.data.paymentType.id == 2) {
            this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id } });
          } else {
            this.router.navigateByUrl('/profile/history');
          }
        }, (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

  }

  agreeCheckParent() {
    this.applicationAgreeParent = !this.applicationAgreeParent;
  }

  agreeCheck() {
    this.applicationAgree = !this.applicationAgree;
  }

  getHelper() {
    this.api.getDisabilityEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
      }, (err: any) => {

      });
  }

  get files(): any { return this.applicationForm.get('docFiles') };
  get certifFiles(): any { return this.applicationForm.get('anotherExamFiles') };
  get subjects(): any { return this.applicationForm.get('satAnotherExamCertificateData').get('subjects') }
  get subjectsIb(): any { return this.applicationForm.get('ibAnotherExamCertificateData').get('subjects') }

  dwnldCertifCheck(id: number): boolean {
    return this.certifFiles.controls[id].get('filename').value !== '';
  }

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', []),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event, i: number): void {
    let obj = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile(type: string) {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

  checkForPostData() {
    if (this.showParentData == true) {
      if (this.applicationAgreeParent == false) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.applicationAgree == false) {
        return false;
      } else {
        return true;
      }
    }

  }

  closeAgreeModal() {
    this.textModal = false;
    this.applicationAgree = false;
    this.applicationAgreeParent = false;
  }

  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.blockBtn = false;
    }
  }

}