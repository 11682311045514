import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { DisabilityComponent } from '../../../template/disability/disability.component';
import { TestOrgComponent } from '../../../template/test-org/test-org.component';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PersonalInfoComponent } from '../../../template/personal-info/personal-info.component';

@Component({
  selector: 'app-tvor',
  templateUrl: './tvor.component.html',
  styleUrls: ['./tvor.component.css'],
})
export class TvorComponent implements OnInit, AfterViewInit {
  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  pageLangId: any;
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOblList = [];
  testOrgList = [];
  testDateList = [];
  applicationAgree = false;
  foreignLangCertCharScore: any;
  charList: any;
  studyTypeList: any;
  eduAreaList: any;
  masterTypeList: any;
  gopList: any;
  foreignLangList: any;
  profileDisciplines1List: any;
  profileDisciplines2List: any;
  showChars = false;
  showSubjects = false;
  vuzList: any;

  sertidTypeFloat = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  fileIdToDeleteGre: any;
  prevEduArea: any;
  prevEduDirection: any;

  greEnable = false;

  @ViewChild(DisabilityComponent)
  disabilityC: DisabilityComponent;
  @ViewChild(TestOrgComponent) testOrg: TestOrgComponent;
  @ViewChild(PersonalInfoComponent) person: PersonalInfoComponent;

  modalAgreement = false;
  sogData: any;
  blockBtn = true;

  constructor(
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private messageService: MessageService,
    public sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {
    this.person.getData(this.seasonId, this.appTypeId);
    this.disabilityC.getHelper(this.seasonId, this.appTypeId);
    this.testOrg.getCity(this.seasonId, this.appTypeId);
  }

  ngOnInit(): void {
    this.seasonId = this.router.parseUrl(
      this.router.url
    ).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    this.appTypeId = this.route.snapshot.queryParamMap.get('appTypeId');

    this.messageService.getPvoSog().subscribe((res) => (this.sogData = res));

    // Инициализация формы
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(this.seasonId),
      appTypeId: new FormControl(this.appTypeId),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      isGreCertificateExists: new FormControl(false),
      foreignLangCertTypeId: new FormControl(0),
      foreignLangCertScore: new FormControl('0'),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      isHasDisability: new FormControl(0, Validators.required),
      disabilityId: new FormControl(0, Validators.required),
      masterTypeId: new FormControl(0, Validators.required),
      profileSubjectPairId: new FormControl(0),
      testLangId: new FormControl(0),
      testCityId: new FormControl(0),
      testOrgId: new FormControl(0),
      prevEduDirectionId: new FormControl(0),
      prevEduAreaId: new FormControl(0),
      oblId: new FormControl(0),
      eduProgramId: new FormControl(0),
      eduAreaId: new FormControl(0),
      testPeriodId: new FormControl(0),
      profileSubject1Id: new FormControl(0),
      foreignLangId: new FormControl(0),
      eduInstituteId: new FormControl(0),
      docFiles: new FormArray([]),
      appFiles: new FormArray([]),
      gre: new FormGroup({
        registrationNumber: new FormControl(0),
        printDate: new FormControl(0),
        testDate: new FormControl(0),
        quantitativeReasoningScore: new FormControl(0),
        verbalReasoningScore: new FormControl(0),
        analyticalWritingScore: new FormControl(0),
      }),
      greCertificateFiles: new FormArray([]),
      applicationAgree: new FormControl({value: false, disabled: true}),
      grantMessage: new FormControl('')
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }
    this.getForeignLangCertList();
    this.getStudyType();
    this.getMasterType();
    this.getForeignLang();
    this.getPrevEduArea();
  }

  onScrollEnd(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  getProfileDisciplines(id: any) {
    let index = document.forms[0].elements['gop'].selectedIndex;
    this.applicationForm.get('profileSubject1Id').setValue(0);
    this.applicationForm.get('profileSubject2Id').setValue(0);
    if (
      this.gopList[index - 1].id == id &&
      this.gopList[index - 1].hasMultipleProfileSubjects
    ) {
      this.showSubjects = true;
      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          1
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines1List = res.items;
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );

      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          2
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines2List = res.items;
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );
    } else {
      this.showSubjects = false;
    }
  }

  getForeignLang() {
    this.api.getForeignLangPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getTestLang() {
    this.gopList.forEach((e: any) => {
      if (e.id == this.applicationForm.get('eduProgramId').value) {
        this.applicationForm.get('isGreCertificateExists').setValue(false);
        this.greEnable = e.isGreAllowed;
        if(!e.isGrantExists){
          this.applicationForm.get('grantMessage').setValue(this.translate.currentLang === 'ru' ? e.grantMessage.ru : e.grantMessage.kk);
        }else{
          this.applicationForm.get('grantMessage').setValue('');
        }
      }
    });

    // console.log(document.forms[0].elements["gop"].selectedIndex)
    this.api
      .getTestLangPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduProgramId').value
      )
      .subscribe(
        (res: any) => {
          this.langList = res.items;
          this.applicationForm.get('oblId').setValue(0);
          this.applicationForm.get('eduInstituteId').setValue(0);
          this.applicationForm.get('testLangId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
    this.getIntituteObl(
      this.applicationForm.get('eduProgramId').value,
      this.applicationForm.get('masterTypeId').value
    );
  }

  getIntituteObl(gopId: number, masterTypeId: number) {
    this.api
      .getEduInstituteOblPvo(this.seasonId, this.appTypeId, gopId, masterTypeId)
      .subscribe(
        (res: any) => {
          this.oblList = res.items;
        },
        (err: any) => {}
      );
  }

  getIntitute() {
    this.api
      .getInstitutePvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('oblId').value,
        this.applicationForm.get('eduProgramId').value,
        this.applicationForm.get('masterTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.vuzList = res.items;
          this.applicationForm.get('eduInstituteId').setValue(0);
        },
        (err: any) => {}
      );
  }

  getMasterType() {
    this.api.getMasterTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.masterTypeList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getGop() {
    this.api
      .getGopPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduAreaId').value,
        this.applicationForm.get('masterTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.gopList = res.items;
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('testLangId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getPrevEduArea() {
    this.api.getPrevEduArea(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.prevEduArea = res.items;
      },
      (err: any) => {}
    );
  }

  getEduDirection() {
    this.api
      .getPrevEduDirection(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('prevEduAreaId').value
      )
      .subscribe(
        (res: any) => {
          this.prevEduDirection = res.items;
          this.applicationForm.get('prevEduDirectionId').setValue(0);
          this.clearDown();
          this.eduAreaList = [];
          this.gopList = [];
        },
        (err: any) => {}
      );
  }

  getEduArea() {
    this.api
      .getEduAreaPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('masterTypeId').value,
        this.applicationForm.get('prevEduDirectionId').value
      )
      .subscribe(
        (res: any) => {
          this.eduAreaList = res.items;
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('eduAreaId').setValue(0);
          this.applicationForm.get('oblId').setValue(0);
          this.applicationForm.get('eduInstituteId').setValue(0);
          this.applicationForm.get('testLangId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  clearDown() {
    this.applicationForm.get('masterTypeId').setValue(0);
    this.applicationForm.get('eduAreaId').setValue(0);
    this.applicationForm.get('eduProgramId').setValue(0);
    this.applicationForm.get('testLangId').setValue(0);
    this.applicationForm.get('foreignLangId').setValue(0);
  }

  getStudyType() {
    this.api.getStudyTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.studyTypeList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getForeignLangCertList() {
    this.api.getForeignLangCertPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangCertList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  scoreCheck() {
    if (
      this.sertidTypeFloat == true &&
      this.applicationForm.get('foreignLangCertScore').value > 9
    ) {
      this.applicationForm
        .get('foreignLangCertScore')
        .patchValue(
          this.applicationForm.get('foreignLangCertScore').value / 10
        );
    }
  }

  postApplication() {
    this.scoreCheck();
    this.loading = true;
    this.api.postAppItemsPvo(this.applicationForm.value).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.data.paymentType.id == 2) {
          this.router.navigate(['/profile/applications/payment'], {
            queryParams: { id: res.data.id, type: 4 },
          });
        } else {
          this.router.navigateByUrl('/profile/history');
        }
      },
      (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }
}
