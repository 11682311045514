<div class="flex mb-4">
  <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  <div class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest">
    {{ "PersonalData" | translate }}
  </div>
  <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
</div>

<div [formGroup]="applicationForm">
  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 mb-6">
    <div class="md:col-span-12">
      <label for="iin">{{ "IIN" | translate }}</label>
      <div
        id="iin"
        class="flex items-center h-8 border mt-1 rounded px-2 w-40 bg-gray-50"
      >
        {{ iin }}
      </div>
    </div>

    <div class="md:col-span-4">
      <label for="lastname">{{ "Surname" | translate }}</label>
      <div
        id="lastname"
        class="flex items-center h-8 border mt-1 rounded px-2 w-full bg-gray-50"
      >
        {{ lastname }}
      </div>
    </div>
    <div class="md:col-span-4">
      <label for="firstname">{{ "Name" | translate }}</label>
      <div
        id="firstname"
        class="flex items-center h-8 border mt-1 rounded px-2 w-full bg-gray-50"
      >
        {{ firstname }}
      </div>
    </div>
    <div class="md:col-span-4">
      <label for="patronymic">{{ "Patronymic" | translate }}</label>
      <div
        id="patronymic"
        class="flex items-center h-8 border mt-1 rounded px-2 w-full bg-gray-50"
      >
        {{ patronymic }}
      </div>
    </div>

    <div class="md:col-span-6">
      <label for="email">{{ "YourEmail" | translate }}</label>
      <div
        id="email"
        class="flex items-center h-8 border mt-1 rounded px-2 w-full bg-gray-50"
      >
        {{ email }}
      </div>
    </div>

    <div class="md:col-span-6">
      <label for="phone">{{ "PhoneNumber" | translate }}</label>
      <input
        type="text"
        name="phone"
        id="phone"
        class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
        formControlName="phoneNumber"
        [mask]="phone"
        placeholder="+7 (000) 000-00-00"
      />
    </div>
    <div class="md:col-span-4">
      <label for="city">{{ "Citizenship" | translate }}</label>
      <select
        formControlName="citizenshipId"
        id="city"
        name="city"
        autocomplete="city-name"
        class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
      >
        <option disabled [value]="0">
          {{ "IndicateСitizenship" | translate }}
        </option>
        <option
          [disabled]="!canEditCitizenshipId"
          *ngFor="let item of countryList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="md:col-span-4">
      <label for="nation">{{ "Nationality" | translate }}</label>
      <select
        formControlName="nationId"
        id="nation"
        name="nation"
        autocomplete="nation-name"
        class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
      >
        <option disabled [value]="0">
          {{ "IndicateNationality" | translate }}
        </option>
        <option
          [disabled]="!canEditNationId"
          *ngFor="let item of nationsList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="md:col-span-4">
      <label for="sex">{{ "Sex" | translate }}</label>
      <div id="sex">
        <fieldset [disabled]="!canEditSexId" form="applicationForm">
          <div class="flex justify-start mb-3 h-5 gap-x-4">
            <div class="">
              <input
                value="1"
                [checked]="applicationForm.get('sexId').value == 1"
                formControlName="sexId"
                class="pl-1 w-6 bg-gray-200 rounded pol-radio"
                id="pol1"
                type="radio"
              />
              <label for="pol1">{{ "Male" | translate }}</label>
            </div>
            <div class="">
              <input
                value="2"
                [checked]="applicationForm.get('sexId').value == 2"
                formControlName="sexId"
                class="pl-1 w-6 bg-gray-200 rounded pol-radio"
                id="pol2"
                type="radio"
              />
              <label for="pol2">{{ "Female" | translate }}</label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

  <label *ngIf="isCertKaztest" for="kazTest" style="width: 300px">
    {{ "KazTest" | translate }}</label
  >
  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-6" *ngIf="isCertKaztest">

  <div class="flex md:col-span-full">

    <select id="kazTest" formControlName="kazTestCertificateId" class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
      <option disabled [value]="0">-----</option>
      <option [value]="k.id" *ngFor="let k of kazTestList" value="">
          <span *ngIf="translate.currentLang == 'kz'">{{ k.levelCode }} ({{'KazTestTestDate' | translate}}: {{k.testDate}}, {{'KazTestTestExpiration' | translate}}: {{k.validTo}})</span>
          <span *ngIf="translate.currentLang == 'ru'">{{ k.levelCode }} ({{'KazTestTestDate' | translate}}: {{k.testDate}}, {{'KazTestTestExpiration' | translate}}: {{k.validTo}})</span>
      </option>
  </select>
    <div *ngIf="kazTestList == undefined || kazTestList == null || kazTestList.length <1" class="ml-2">
      <span *ngIf="translate.currentLang == 'kz'" class="text-sm italic w-20">{{'* Данные по сертификату КАЗТЕСТ не найдены. \n' +
      'Для подачи заявления на вступительный \n' +
      'экзамен в докторантуру необходимо наличие \n' +
      'сертификата КАЗТЕСТ'}}</span>
      <span *ngIf="translate.currentLang == 'ru'" class="text-sm italic w-20">{{'* Данные по сертификату КАЗТЕСТ не найдены. \n' +
      'Для подачи заявления на вступительный \n' +
      'экзамен в докторантуру необходимо наличие \n' +
      'сертификата КАЗТЕСТ'}}</span>
    </div>
  </div>
</div>

<!--<div class="">-->
<!--  <label>{{ "По программе доктарантуры" | translate }}</label>-->
<!--  <select (change)="getGop($event)" formControlName="masterTypeId" class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">-->
<!--    <option disabled [value]="0">-&#45;&#45;&#45;&#45;</option>-->
<!--    <option [value]="item.id" *ngFor="let item of masterTypeList" value="">-->
<!--        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>-->
<!--        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>-->
<!--    </option>-->
<!--</select>-->

<!--</div>-->

  <div
    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 mb-6"
    *ngIf="isForeignLang"
  >
    <div *ngIf="this.applicationForm.get('masterTypeId').value == 4 || this.applicationForm.get('masterTypeId').value == 3 || this.applicationForm.get('masterTypeId').value == 2" class="md:col-span-8 mt-3">
      <label for="certificate">
        {{ "InternationalEnglishCertificate" | translate }}</label
      >
      <select
        (change)="
          checkSertif(applicationForm.get('foreignLangCertTypeId').value, 1)
        "
        formControlName="foreignLangCertTypeId"
        id="nation"
        name="nation"
        autocomplete="nation-name"
        class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
      >
        <option disabled [value]="-1">
          {{ "SelectСertificate" | translate }}
        </option>
        <option *ngFor="let item of foreignLangCertList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
      <div *ngIf="this.applicationForm.get('foreignLangCertTypeId').value == 12" class="">
        <span *ngIf="translate.currentLang == 'kz'" class="text-sm italic w-20">{{'* TOEFL ITP сертификатының иегерлері "ағылшын тілін білуге арналған қосымша тестілеуге" қатысуға өтініш беруі керек'}}</span>
        <span *ngIf="translate.currentLang == 'ru'" class="text-sm italic w-20">{{'* Обладателям сертификата TOEFL ITP необходимо также подать заявление на участие в "Дополнительном тестировании на знание английского языка"'}}</span>
      </div>
      <!-- <span class="text-sm italic w-20">{{applicationForm.get('grantMessages').value}}</span> -->
    </div>

    <div *ngIf="this.applicationForm.get('masterTypeId').value == 4 || this.applicationForm.get('masterTypeId').value == 3 || this.applicationForm.get('masterTypeId').value == 2" class="md:col-span-2 mt-3">
      <label for="ball" class="w-full"> {{ "Ball" | translate }}</label>
      <input
        *ngIf="sertidTypeFloat && !showChars"
        (change)="scoreCheck()"
        mask="0.0"
        formControlName="foreignLangCertScore"
        placeholder="{{ 'Ball' | translate }}"
        class="w-20 h-8 bal pl-1 bg-gray-200 rounded"
        type="text"
      />
      <input
        *ngIf="!sertidTypeFloat && !showChars"
        mask="000"
        formControlName="foreignLangCertScore"
        placeholder="{{ 'Ball' | translate }}"
        class="w-20 h-8 bal pl-1 bg-gray-200 rounded"
        type="text"
      />
      <select
        *ngIf="showChars"
        formControlName="foreignLangCertScore"
        class="w-20 h-8 bal pl-1 bg-gray-200 rounded"
      >
        <option value="">-----</option>
        <option [value]="char.id" *ngFor="let char of charList" value="">
          <span *ngIf="translate.currentLang == 'kz'">{{ char.value }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ char.value }} </span>
        </option>
      </select>
    </div>
  </div>


</div>
