import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  loading: any;
  trueData = false;
  phoneData = false;
  userInfoForm: FormGroup;
  phone = '+0 (000) 000-00-00'
  iin = '000000000000';
  form: FormGroup;
  // tab: any;
  isCitizen = true;
  userData: any;
  nationList: any;
  citizenshipList: any;
  sexList: any;
  haveDocumentIdPattern: any;
  documentIdPattern: any;
  documentIdExample: any;
  // reg = false;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    localStorage.setItem('isRegistrationComplete', '0');
    this.inituserInfoForm();
    this.userInfoForm.get('citizenshipId').setValue(0);
    this.loading = true;
    this.getNation();
    this.getCitizenship();
    this.getSex();
    this.api.profileInfo()
      .subscribe((res: any) => {
        if(localStorage.getItem('token') != 'null' && localStorage.getItem('isRegistrationComplete') != '0'){
          this.router.navigate(['/profile/im']);
          this.loading = false;
        }else{
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Внимание!', 'для получения Ф.И.О сделайте поиск по ИИНу', NotificationType.Info, 15000) : this._notifications.create('Назар аударыңыз', 'толық аты-жөнін алу үшін ЖСН бойынша іздеңіз', NotificationType.Info, 15000);
        }
      },(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCurrentCitizenshipIndex(){
    this.citizenshipList.forEach((element: any) => {
      if(element.id == this.userInfoForm.get('citizenshipId').value){
        this.documentIdPattern = element.documentIdPattern;
        this.documentIdExample = element.documentIdExample;
        // console.log(this.documentIdPattern);
      }
    });
  }

  checkDocId(){
    // console.log(this.userInfoForm.get('documentId').value.match(this.citizenshipList[index].documentIdPattern));
    if(this.userInfoForm.get('documentId').value.match(this.documentIdPattern) == null){
      this.haveDocumentIdPattern = true;
    }else{
      this.haveDocumentIdPattern = false;
    }
  }

  inituserInfoForm() {
    this.userInfoForm = new FormGroup({
      iin: new FormControl(),
      phoneNumber: new FormControl(),
      lastname: new FormControl(),
      patronymic: new FormControl(),
      firstname: new FormControl(),
      nationId: new FormControl(0),
      citizenshipId: new FormControl(0),
      birthDate: new FormControl(0),
      sexId: new FormControl(0),
      documentId: new FormControl(0)
    });



  }

  showNG(){
    // this.router.navigate(['auth/registration']);
    this.changeIIN();
    this.isCitizen = false;
    // this.auth = false;
  }
  showG(){
    this.changeIIN();
    this.userInfoForm.get('nationId').setValue(0);
    this.userInfoForm.get('citizenshipId').setValue(0);
    this.userInfoForm.get('sexId').setValue(0);
    this.userInfoForm.get('documentId').setValue(0);
    // this.router.navigate(['auth']);
    this.isCitizen = true;
    // this.auth = true;
  }

  changeIIN(){
    this.userInfoForm.get('firstname').setValue('');
    this.userInfoForm.get('lastname').setValue('');
    this.userInfoForm.get('patronymic').setValue('');
    this.trueData = false;
  }

  get iinCheck() { return this.userInfoForm.get('iin'); }

  get phoneCheck() { return this.userInfoForm.get('phoneNumber'); }

  checknumber(){
    let number:string;
    number = this.userInfoForm.get('phoneNumber').value;
    // console.log(number.length);
    if(number.length == 11){
      this.phoneData = true;
    }else{
      this.phoneData = false;
    }
    this.checkUserData();
  }

  checkUserData(){
    if(this.trueData && this.phoneData){
      return true;
    }else{
      return false;
    }
  }

  saveUserInfo(){
    // this.checkDocId();
    if(this.userInfoForm.valid){
      this.loading = true;
      if(this.isCitizen == true){
        this.userData = {
          iinTypeId: 1,
          iin: this.userInfoForm.get('iin').value,
          phoneNumber: this.userInfoForm.get('phoneNumber').value
        };
      }else{
        this.userData = {
          iinTypeId: 2,
          lastname: this.userInfoForm.get('lastname').value,
          firstname: this.userInfoForm.get('firstname').value,
          patronymic: this.userInfoForm.get('patronymic').value,
          citizenshipId: this.userInfoForm.get('citizenshipId').value,
          nationId: this.userInfoForm.get('nationId').value,
          sexId: this.userInfoForm.get('sexId').value,
          birthDate: this.userInfoForm.get('birthDate').value,
          documentId: this.userInfoForm.get('documentId').value,
          phoneNumber: this.userInfoForm.get('phoneNumber').value
        };
      }
      this.api.editProfileInfo(this.userData)
        .subscribe((res: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Данные', 'успешно сохранены', NotificationType.Success, 5000) : this._notifications.create('Данные', 'успешно сохранены', NotificationType.Success, 5000);
          localStorage.setItem('isRegistrationComplete', '1');
          if(localStorage.getItem('isRegistrationComplete') == '1'){
            this.router.navigate(['/profile/im']);
          }
          
        },(err: any) => {
          console.log(err);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          this.loading = false;
        });
      
    }
  }
  
  getNation(){
    this.api.getNationList()
      .subscribe((res: any) => {
        this.nationList = res.items;
      }, (err: any) => {

      });
  }

  getCitizenship(){
    this.api.getCitizenshipList()
      .subscribe((res: any) => {
        this.citizenshipList = res.items;
      }, (err: any) => {

      });
  }

  getSex(){
    this.api.getSexList()
      .subscribe((res: any) => {
        this.sexList = res.items;
      }, (err: any) => {

      });
  }

  findData(iin: number){
    this.loading = true;
    this.api.personalData(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.userInfoForm.get('firstname').setValue(res.data.firstname);
        this.userInfoForm.get('lastname').setValue(res.data.lastname);
        this.userInfoForm.get('patronymic').setValue(res.data.patronymic);
        this.trueData = true;
        this.checkUserData();
      }, (err: any) => {
        this.loading = false;
        this.trueData = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

}
