<div *ngIf="info">
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>

  <section id="comparison" class="bg-slate-50 dark:bg-gray-800">
    <div class="mx-auto">
      <div class="text-2xl text-center font-bold text-blue-500">
        {{
          translate.currentLang === "ru"
            ? "Куда можно поступить?"
            : "Қайда оқуға түсуге болады?"
        }}
      </div>
      <ul
        role="list"
        class="mx-auto grid grid-cols-1 gap-6 sm:gap-8 lg:max-w-none lg:grid-cols-5"
      >
        <li *ngFor="let item of enrolls">
          <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
            <li>
              <figure
                class="relative rounded-2xl bg-white dark:bg-gray-900 p-6 text-center shadow-xl shadow-slate-900/10"
              >
                <blockquote class="relative">
                  <p
                    class="text-2xl font-bold tracking-tight text-slate-900 dark:text-white"
                  >
                    {{ item.value }}
                  </p>
                </blockquote>
              </figure>
              <figcaption class="text-center">
                <div class="font-display text-slate-900 dark:text-white">
                  {{
                    translate.currentLang === "ru"
                      ? item.label.ru
                      : item.label.kk
                  }}
                </div>
              </figcaption>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
  <div class="flex w-full mx-auto rounded shadow mt-5">
    <a
      *ngFor="let tab of tabs"
      aria-current="false"
      (click)="activeTab = tab.value"
      [ngClass]="
        activeTab === tab.value
          ? 'w-full flex justify-center font-medium px-5 py-2 border-t border-b bg-gray-900 text-white  border-gray-900 hover:bg-gray-800 cursor-pointer'
          : 'w-full flex justify-center font-medium rounded-l px-5 py-2 border bg-white text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer'
      "
    >
      {{ translate.currentLang === "ru" ? tab.label.ru : tab.label.kk }}
    </a>
  </div>

  <div *ngIf="activeTab === 1">
    <div class="mt-4 text-base leading-6 bg-gray-100 px-4 py-2">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-3 auto-cols-max">
        <span class=""
          >{{
            translate.currentLang === "ru"
              ? "Выберите интересующую специальность"
              : "Қажетті мамандықты таңдаңыз"
          }}
        </span>
        <select
          class="h-6 w-full md:col-span-2 border-2 border-sky-500 rounded"
          [(ngModel)]="selectedSpec"
          (change)="changeSpec($event.target.value)"
        >
          <option disabled [value]="-1">
            {{
              translate.currentLang === "ru"
                ? "Выберите интересующую специальность"
                : "Қажетті мамандықты таңдаңыз"
            }}
          </option>
          <option *ngFor="let item of spec" [value]="item.out_ep_id">
            {{ item.out_code }} -
            {{
              translate.currentLang === "ru"
                ? item.out_ep_name_rus
                : item.out_ep_name_kaz
            }}
          </option>
        </select>
      </div>
    </div>

    <div
        class="w-full h-px max-w-6xl mx-auto my-5 devide"

      ></div>

    <div class="relative overflow-x-auto">
      <table
        class="w-full text-sm text-left rtl:text-right border-collapse border border-blue-500"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr class="bg-gray-100">
            <th scope="col" class="px-6 py-3">Шифр</th>
            <th scope="col" class="px-6 py-3">
              {{
                translate.currentLang === "ru"
                  ? "Наименование специальности"
                  : "Мамандық атауы"
              }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{
                translate.currentLang === "ru"
                  ? "Количество грантов на общий конкурс по РК"
                  : "ҚР бойынша жалпы конкурстың гранттар саны"
              }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{
                translate.currentLang === "ru"
                  ? "Наличие сельской квоты"
                  : "Ауыл квоталардың болуы"
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            *ngFor="let item of gosZakaz"
          >
            <th
              class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
            >
              {{ item.out_code }}
            </th>
            <td class="px-6 py-4">
              {{
                translate.currentLang === "ru"
                  ? item.out_ep_name_rus
                  : item.out_ep_name_kaz
              }}
            </td>
            <td class="px-6 py-4">{{ item.out_goz_zakaz }}</td>
            <td class="px-6 py-4">
              {{
                translate.currentLang === "ru"
                  ? item.out_selo_rus
                  : item.out_selo_kaz
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="w-full h-px max-w-6xl mx-auto my-5 devide"
    ></div>
    <p-table
      [value]="minBall"
      [paginator]="true"
      [rows]="5"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines mt-3"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <ng-template pTemplate="header">
        <tr class="bg-blue-500 text-white">
          <th scope="col" class="px-6 py-3">
            {{ translate.currentLang === "ru" ? "Регион" : "Аймақ" }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ translate.currentLang === "ru" ? "ВУЗ" : "ЖОО" }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Минимальный проходной балл"
                : "Минималды өту баллы"
            }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Количество грантов по общему конкурсу по РК"
                : "ҚР бойынша жалпы конкурстың гранттары саны"
            }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Количество грантов по целевому по данному ВУЗу"
                : "Осы ЖОО бойынша нысаналы гранттардың саны"
            }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td
            class="text-gray-900 whitespace-nowrap dark:text-white text-sm font-bold"
          >
            {{
              translate.currentLang === "ru"
                ? item.out_o_name_rus
                : item.out_o_name_kaz
            }}
          </td>
          <td class="">
            {{ item.out_institute_id }} -
            {{
              translate.currentLang === "ru"
                ? item.out_i_name_rus
                : item.out_i_name_kaz
            }}
          </td>
          <td class="">{{ item.out_min_score }}</td>
          <td class="">{{ item.out_goz_zakaz }}</td>
          <td class="">{{ item.out_gz_tselevoi }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="activeTab === 2">
    <div class="mt-4 text-base leading-6 bg-gray-100 px-4 py-2">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div>
          <span class="">{{
            translate.currentLang === "ru"
              ? "Выберите регион"
              : "Аймақты таңдаңыз"
          }}</span>
          <select
            class="h-6 w-full border-2 border-sky-500 rounded"
            (change)="changeObl($event.target.value)"
            [(ngModel)]="selectedObl"
          >
            <option disabled value="-1">
              {{
                translate.currentLang === "ru"
                  ? "Выберите регион"
                  : "Аймақты таңдаңыз"
              }}
            </option>
            <option *ngFor="let item of obls" [value]="item.out_obl_i">
              {{
                translate.currentLang === "ru"
                  ? item.out_obl_name_rus
                  : item.out_obl_name_kaz
              }}
            </option>
          </select>
        </div>

        <div>
          <span class="">{{
            translate.currentLang === "ru" ? "Выберите ВУЗ" : "ЖОО таңдаңыз"
          }}</span>
          <select
            class="h-6 w-full border-2 border-sky-500 rounded"
            [(ngModel)]="selectedInstitute"
            (change)="changeInstitute($event.target.value)"
          >
            <option disabled value="-1">
              {{
                translate.currentLang === "ru" ? "Выберите ВУЗ" : "ЖОО таңдаңыз"
              }}
            </option>
            <option *ngFor="let item of institutes" [ngValue]="item">
              {{
                translate.currentLang === "ru"
                  ? item.out_name_rus
                  : item.out_name_kaz
              }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div
      class="w-full h-px max-w-6xl mx-auto my-5 devide"
    ></div>

    <p-table
      [value]="minBallOblInstitute"
      [paginator]="true"
      [rows]="5"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <ng-template pTemplate="header">
        <tr class="bg-blue-500 text-white">
          <th scope="col" class="px-6 py-3">Шифр</th>
          <th scope="col" class="px-6 py-3">
            {{ translate.currentLang === "ru" ? "Специальность" : "Мамандық" }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Минимальный проходной балл"
                : "Минималды өту баллы"
            }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Количество грантов по общему конкурсу по РК"
                : "ҚР бойынша жалпы гранттардың саны"
            }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{
              translate.currentLang === "ru"
                ? "Количество грантов по целевому по данному ГОПу"
                : "Осы мамандық бойынша нысаналы гранттардың саны"
            }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ item.out_code }}
          </th>
          <td class="px-6 py-4">
            {{
              translate.currentLang === "ru"
                ? item.out_ep_name_rus
                : item.out_ep_name_kaz
            }}
          </td>
          <td class="px-6 py-4">{{ item.out_min_score }}</td>
          <td class="px-6 py-4">{{ item.out_goz_zakaz }}</td>
          <td class="px-6 py-4">{{ item.out_gz_tselevoi }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
