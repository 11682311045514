<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="">
    <div class="text-xl z-10 relative text-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16">
        {{ 'NCT' | translate }}
    </div>
    <div class="bg-gray-200 auth-window mt-16 text-center md:w-3/6 sm:w-4/6 mx-auto">
        <div class="flex bg-white">
            <div (click)="showAuth()" class="w-1/2 xl:text-xl lg:text-base md:text-base auth-hover cursor-pointer p-2" [ngClass]="{'bg-gray-200': auth, 'z-10': auth,'text-blue-900': auth,'font-bold ': auth,'hover:underline': reg}">{{ 'Authorization' | translate }}</div>
            <div (click)="showRegistration()" class="w-1/2 xl:text-xl lg:text-base md:text-base reg-hover cursor-pointer p-2" [ngClass]="{'bg-gray-200': reg, 'z-10': reg,'font-bold': reg,'text-blue-900': reg, 'hover:underline': auth}" >{{ 'GetReg' | translate }}</div>
        </div>
        <div class="w-full mt-4 p-4">
            <form *ngIf="auth" [formGroup]="form" class="" action="">
                <div class="">
                    <input formControlName="username" placeholder="{{ 'Set' | translate }} {{ 'EmailReg' | translate }}/{{ 'IIN' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="email">
                    <input formControlName="password" placeholder="{{ 'Password' | translate }}" class="border rounded-md p-2 mb-2 w-full border-black" type="password">
                    <button (click)="submitForm()" class="mx-auto w-2/6 mt-8 mb-8 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{ 'Voiti' | translate}}</button>
                </div>
            </form>
            <app-registration *ngIf="reg"></app-registration>
        </div>
    </div>
    <p *ngIf="auth" class="text-gray-500 mt-4 text-center italic"> <span [routerLink]="'/forgot-pass'" class="cursor-pointer underline">{{ 'ForgotYourPassword' | translate}}?</span></p>

    <div class="text-gray-500 instr mt-4 text-center italic">
        <a *ngIf="translate.currentLang == 'ru'" class="text-blue-500 cursor-pointer underline" href="./../../../../../../assets/instr_reg.pdf" target="_blank"><p class="text-center text-xl text-blue-600 mb-4">{{ 'InstructionsReg' | translate }}</p></a>
        <a *ngIf="translate.currentLang == 'kz'" class="text-blue-500 cursor-pointer underline" href="./../../../../../../assets/instr_regkz.pdf" target="_blank"><p class="text-center text-xl text-blue-600 mb-4">{{ 'InstructionsReg' | translate }}</p></a>
    </div>


    <div class="text-gray-500 text-center absolute copyright">© {{ 'nct' | translate }}</div>
</div>
