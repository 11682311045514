

<div *ngIf="app.moduleType?.id == 1" class="bg-blue-100 border-blue-500 border-2 zayav rounded mb-4 p-3">
    <div class="flex w-full justify-between test-type-name">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>
        <div class="text-lg text-green-400 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.testStatus.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.testStatus.name.ru }}</span>
        </div>
    </div>

    <div class="flex w-full justify-between test-type-name">
        <div class="py-2 font-bold">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.jobType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.jobType.name.ru }}</span>
        </div>
        <div class="py-2 font-bold">
         {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">
                <div class="px-3 text-gray-600">
            
                    <div class="mb-2">
                        {{ 'IKT' | translate }} : <input disabled readonly class="bg-transparent" [value]="app.id"> 
                    </div>
            
                    <div class="mb-2">
                        {{ 'IIN' | translate }} : {{ app.iin}}
                    </div>
            
                    <div class="mb-2">
                        {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                    </div>
            
                    <div class="mb-2">
                        {{ 'AppliesoCategory' | translate }}: 
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.toCategory.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.toCategory.name.ru }}</span>   
                    </div>
            
                    <div class="mb-2">
                        {{ 'TestDate' | translate }}: 
                        <span class="font-bold">{{ app.testPeriod.testDate }} ({{ 'startTime' | translate }}: {{ app.testPeriod?.enterTime }})</span> 
                    </div>
            
                    <div class="mb-2">
                        {{ 'TestOrg' | translate }}: 
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.testOrg.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.testOrg.name.ru }}</span>  
                    </div>

                    <div class="mb-2">
                        {{ 'TekZan' | translate }}: 
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.employmentType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.employmentType.name.ru }}</span>   
                    </div>
            
                    <!-- Таблица результатов ПК Версия -->

                    <table *ngIf="app.testStatus.id == 7 || app.testStatus.id == 6" class="table table-bordered pc-version">
                        <tr class="text-center">
                            <td class="w-6/12">
                                {{ 'Subject' | translate }}
                            </td>
                            <td class="w-2/12">
                                {{ 'testBall' | translate }}
                            </td>
                            <td *ngIf="app.testStatus.id == 7" class="w-2/12">
                                {{ 'appealBall' | translate }}
                            </td>
                            <td class="w-2/12">
                                {{ 'subjectBall' | translate }}
                            </td>
                        </tr>
                        <tr *ngFor="let sub of app.subjects; index as num">
                            <td>
                                <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>  
                                <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                            </td>
                            <td class="text-center">
                                {{ sub.testScore }}
                            </td>
                            <td *ngIf="app.testStatus.id == 7" class="text-center">
                                {{ sub.appealScore }}
                            </td>
                            
                            <td class="text-center">
                                {{ sub.totalScore }}
                            </td>
                        </tr>
                        <tr> 
                            <td *ngIf="app.testStatus.id != 7" colspan="2" class="w-3/4">
                                {{ 'totalScore' | translate }}
                            </td>
                            <td *ngIf="app.testStatus.id == 7" colspan="3" class="w-3/4">
                                {{ 'totalScore' | translate }}
                            </td>
                            <td class="text-center">
                                {{ app.totalScore }}
                            </td>
                        </tr>
                    </table>

                    <!-- Таблица результатов мобильная версия -->
            
                    <div *ngIf="app.testStatus.id == 7 || app.testStatus.id == 6" class="mobile-version">
            
                        <div class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                            {{ 'testResults' | translate }}
                        </div>
            
                        <table class="table table-bordered">
                            <thead *ngFor="let sub of app.subjects; index as num">
                                <tr>
                                    <td *ngIf="app.testStatus.id == 7" colspan="3" class="bg-blue-200 text-center font-bold">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>  
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                                    </td>
                                    <td *ngIf="app.testStatus.id != 7" colspan="2" class="bg-blue-200 text-center font-bold">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>  
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ 'testBall' | translate }}
                                    </td>
                                    <td *ngIf="app.testStatus.id == 7" class="text-center">
                                        {{ 'appealBall' | translate }}
                                    </td>
                                    <td class="text-center">
                                        {{ 'subjectBall' | translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ sub.testScore }}
                                    </td>
                                    <td *ngIf="app.testStatus.id == 7" class="text-center">
                                        {{ sub.appealScore }}
                                    </td>
                                    <td class="text-center">
                                        {{ sub.totalScore }}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td *ngIf="app.testStatus.id == 7" colspan="2" class="bg-blue-300 text-center">
                                        {{ 'totalScore' | translate }}
                                    </td>
                                    <td *ngIf="app.testStatus.id != 7" colspan="1" class="bg-blue-300 text-center">
                                        {{ 'totalScore' | translate }}
                                    </td>
                                    <td class="bg-blue-300 text-center">
                                        {{ app.totalScore }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            
                    <!-- Таблица ЭССЕ  ПК версия -->

                    <table *ngIf="app.testStatus.id == 7 || app.testStatus.id == 6 && showEsse" class="table table-bordered pc-version">
                        <tbody *ngFor="let sub of esseAnswer; index as num">
                            <tr class="text-center">
                                <td class="w-full font-bold">
                                    {{ 'EsseTheme' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full">
                                    <iframe style="height: 50px;" class="w-full" [srcdoc]="sub.questionText" frameborder="0"></iframe>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full justify-center font-bold flex relative">
                                    {{ 'EsseAnswerText' | translate }} <span *ngIf="edit == true" (click)="saveEsse(app.id, sub.id)" class="bg-green-300 ed-button cursor-pointer rounded absolute">Сохранить</span> <span *ngIf="edit == false && showEditButton == true" (click)="print(app, sub.questionText)" class="bg-blue-300 print-button cursor-pointer rounded absolute">Печать</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full">
                                    <!-- <iframe id="iframe" class="w-full" [srcdoc]="sub.answerText" frameborder="0"></iframe> -->
                                    <ckeditor id="CkEditor" [disabled]="!edit" (change)="editorChange($event)" [config]="config" [editor]="Editor" [data]="sub.answerText"></ckeditor>
                                    <!-- <input (change)="chesse($event.target.value)" [readonly]="!edit" type="text" class="w-full" [value]="sub.answerText"> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Таблица ЭССЕ мобильная версия -->

                    <table *ngIf="app.testStatus.id == 7 || app.testStatus.id == 6 && showEsse" class="table table-bordered mobile-version">
                        <tbody *ngFor="let sub of esseAnswer; index as num">
                            <tr class="text-center">
                                <td class="w-full font-bold">
                                    {{ 'EsseTheme' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full">
                                    <iframe style="height: 50px;" class="w-full" [srcdoc]="sub.questionText" frameborder="0"></iframe>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full justify-center font-bold flex relative">
                                    {{ 'EsseAnswerText' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td class="w-full">
                                    <iframe id="iframe" class="w-full" [srcdoc]="sub.answerText" frameborder="0"></iframe>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            
                    <table *ngIf="app.testStatus.id == 8 || app.testStatus.id == 9" class="table table-bordered">
                        <tr class="text-center">
                            <td class="w-1/12">
                                №
                            </td>
                            <td class="w-3/12">
                                {{ 'LanguageOfSubject' | translate }}
                            </td>
                            <td class="w-7/12">
                                {{ 'Subject' | translate }}
                            </td>
                        </tr>
                        <tr *ngFor="let sub of app.subjects; index as num">
                            <td class="text-center">
                                {{ sub.ord }}
                            </td>
                            <td class="text-center">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.testLang.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.testLang.name.ru }}</span>  
                            </td>
                            
                            <td class="text-center">
                                <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru }}</span>  
                            </td>
                        </tr>
                    </table>
                    
                </div>
                <div class="flex w-full justify-end mt-2 zayav-buttons">
                    <button (click)="getEsse(app.id)" *ngIf="(app.testStatus.id == 7 || app.testStatus.id == 6) && showEditButton == false" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'GetEsseAnswer' | translate }}
                    </button> 
                    <span *ngFor="let sub of esseAnswer; index as num">
                        <button (click)="editEsse()" *ngIf="sub.isEditable == true && edit == false && showEditButton == true" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                            {{ 'EditEsse' | translate }}
                        </button>
                    </span> 
                    <!-- <button (click)="getEsse(app.id)" *ngIf="(app.testStatus.id == 7 || app.testStatus.id == 6) && showEditEsse == false" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'Save' | translate }}
                    </button>  -->
                    <button (click)="checkCertificate(app.id, app.iin, app.testPeriod.testYear, 3)" *ngIf="app.testStatus.id == 7" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'CheckCertificate' | translate }}
                    </button> 
                    <button *ngIf="app.testStatus.id == 8 || app.testStatus.id == 9 " (click)="showEditNqt(app.id, app.jobType.id)" class="bg-blue-400 zayav-button ml-3 tracking-wide w-64 hover:bg-blue-700 text-white font-hairline py-1 px-3 rounded">
                     {{ 'EditApplication' | translate }} 
                    </button>
                    <button *ngIf="app.testStatus.id == 9 || app.testStatus.id == 8" (click)="showDeleteModal()" class="bg-red-400 zayav-button ml-3 tracking-wide w-64 hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                     {{ 'DeleteApplication' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z"/></svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z"/></svg>
    </div>
    
</div>

