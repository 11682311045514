import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class notificationConfig {
  backend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.backend = 'https://app.debug.testcenter.kz/notification/api/v1'; // Путь для localhost  
    }
    if (!isDevMode()) {
      this.backend = 'notification/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      newMessages: this.backend + '/items'
    }

  }
}
