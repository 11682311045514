<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<!-- <p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
    {{ 'ToApplyForParticipationInTheUNT(March)FillInTheData:' | translate }}
</p> -->
<form [formGroup]="applicationForm" action="">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex">
        <div class="">
            <div class="mb-3 h-5">
                {{ 'IIN' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Surname' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Name' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'Patronymic' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'YourEmail' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'PhoneNumber' | translate }}
            </div>
            <div class="mb-3 h-5">
                {{ 'InternationalEnglishCertificate' | translate }}
            </div>
        </div>
        <div class="ml-4 w-1/3">
            <div class="mb-3 h-5">
                {{ iin }}
            </div>
            <div class="mb-3 h-5">
                {{ lastname }}
            </div>
            <div class="mb-3 h-5">
                {{ firstname }}
            </div>
            <div class="mb-3 h-5">
                {{ patronymic }}
            </div>
            <div class="mb-3 h-5">
                {{ email }}
            </div>
            
            <div class="mb-3 h-5">
                <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="mb-3 flex h-5">
                <select formControlName="foreignLangCertificateTypeId" (change)="checkSertif(applicationForm.get('foreignLangCertificateTypeId').value)" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="-1">
                        {{ 'SelectСertificate' | translate }}
                    </option>
                    <option *ngFor="let item of foreignLangCertList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
        </div>
        <div class="ml-4 w-1/3 self-end">
            <div class="mb-3 flex h-5 w-1/3">
               <div class="pb-1 ">{{ 'Ball' | translate }}:</div> 
               <input [mask]="applicationForm.get('foreignLangCertTypeId').value==4?foreignLangCertScoreMaskIelts:foreignLangCertScoreMask" formControlName="foreignLangCertificateScore" placeholder="{{ 'Ball' | translate }}" class="w-full bal pl-1 bg-gray-200 rounded" type="text">
            </div>
        </div>
        
    </div>

    <div class="flex mt-4 mb-2">
        <div class="w-1/3">
            {{ 'SetForeignLangCertificateDate' | translate }}:
        </div>
        <div class="w-1/3 ml-4">
            <input formControlName="foreignLangCertificateDate" class="w-full text-center bg-gray-200 rounded mb-2" type="date">
        </div>
    </div>

    <div class="flex mb-4 mt-4">
        <div class="w-1/12 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-11/12 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'SetSertifData' | translate }} IB:
        </div>
        <div class="w-1/12 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SetSertifTestDate' | translate }}:
        </div>
        <div class="w-1/3">
            <input formControlName="testDate" class="w-full text-center bg-gray-200 rounded mb-2" type="date">
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SetSertifRegNum' | translate }}:
        </div>
        <div class="w-1/3">
            <input formControlName="registrationNumber" class="w-full text-center bg-gray-200 rounded mb-2" type="text">
        </div>
    </div>

    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'IbMathSubj' | translate }}:
        </div>
        <div class="w-2/3 flex">
            <select formControlName="mathSubjectId" (change)="checkSertif(applicationForm.get('foreignLangCertificateTypeId').value)" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="-1">
                    {{ 'SelectСertificate' | translate }}
                </option>
                <option *ngFor="let item of mathSubjects" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
            <div class="ml-4 w-1/3 self-end">
                <div class="mb-3 flex h-5 w-24">
                   <div class="pb-1 ">{{ 'Ball' | translate }}:</div> 
                   <input [mask]="foreignLangCertScoreMask" formControlName="mathSubjectScore" placeholder="{{ 'Ball' | translate }}" class="w-full bal pl-1 bg-gray-200 rounded" type="text">
                </div>
            </div>
        </div>
    </div>
    

    <app-e-upload-files [subjectsArr]="shortData.subjects" [type]="'ib'" [appData]="applicationForm.value" [certFiles]="shortData.files"></app-e-upload-files>

    

</form>

