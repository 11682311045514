import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, FormControlName } from "@angular/forms";
import { ApiService } from "../../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dir-test',
  templateUrl: './dir-test.component.html',
  styleUrls: ['./dir-test.component.css']
})
export class  DirTestComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  jobTypeId = 5;
  pageLangId: any;
  subjectCount: any;
  subjectCountArray = [];
  showNasPunkt = true;
  fromActualCategories = [];
  toActualCategories = [];
  KATOOblList = [];
  KATOCityList = [];
  KATOSeloList = [];
  KATORaionList = [];
  jobList = [];
  jobList2 = [];
  subjectList = [];
  isInvalid = false;
  jobId: number;
  jobId2: number;
  langList = [];
  testOrg = [];
  testPeriod = [];
  KATOs = [];
  KATOs2 = [];
  KATOsubject = [];
  pseudoId = [];
  pseudoId2 = [];
  lastChild = false;
  year = '0000';
  fullDate = '00-00-0000';
  iin = "000000000000";
  phone = '+0 (000) 000-00-00';
  employmentTypeList: any;
  oblList: any;
  instituteSpecialityList: any;
  collegeSpecialityList: any;
  vuzObl = 0;
  raion = 0;
  vuzList: any;
  raionList: any;

  eduType: any;

  modalAgreement = false;
  sogData:any;
  blockBtn = true;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private messageService:MessageService,
    public sanitizer: DomSanitizer
    ) {}

  ngOnInit(): void {

    if(localStorage.getItem('Lang') == 'ru'){
      this.pageLangId = 2;
    }else{
      this.pageLangId = 1;
    }

    this.messageService.getDirSog().subscribe(res=>this.sogData = res);

    this.applicationForm = new FormGroup({
      jobTypeId: new FormControl(this.jobTypeId),
      isInvalid: new FormControl(),
      lastname: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      diplomaSpecialityName: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      iin: new FormControl(),
      bin: new FormControl(),
      bin2: new FormControl(),
      fromCategoryId: new FormControl(0),
      fromCategoryValidTo: new FormControl(''),
      toCategoryId: new FormControl(0),
      eduTypeId: new FormControl(0),
      pretendedJobId: new FormControl(),
      currentJobId: new FormControl(),
      eduPeriodStartYear: new FormControl(),
      eduPeriodFinishYear: new FormControl(),
      testOrgId: new FormControl(),
      testPeriodId: new FormControl(),
      testLangId: new FormControl(),
      firstLevelKato: new FormControl(),
      OblId: new FormControl(),
      RaionId: new FormControl(),
      SeloId: new FormControl(),
      subjects: new FormArray([]),
      kato: new FormArray([]),
      isWithoutJob: new FormControl(false),
      employmentTypeId: new FormControl(0),
      oblId: new FormControl(-1),
      eduInstituteId: new FormControl(0),
      eduSpecialityId: new FormControl(0),
      eduSchoolId: new FormControl(0),
      applicationAgree: new FormControl({value: false, disabled: true})
    });

    this.getProfileData();
    this.getKATOOblList();
    this.getEmploymentTypeId();
    this.getEduType();
    this.getOblList();
    
    this.applicationForm.get('firstLevelKato').patchValue(-1); 
    this.applicationForm.get('RaionId').patchValue(-1);
    this.applicationForm.get('SeloId').patchValue(-1);
    this.applicationForm.get('testOrgId').patchValue(-1);
    this.applicationForm.get('testPeriodId').patchValue(-1);
    this.applicationForm.get('testLangId').patchValue(-1);
    this.applicationForm.get('currentJobId').patchValue(-1);
    this.applicationForm.get('pretendedJobId').patchValue(-1);
    
  }

  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  getOblList(){
    this.api.getOblListDir()
      .subscribe((res: any) => {
        this.oblList = res.items;
      }, (err: any) => {

    });
  }

  getRaionList(){
    this.api.getRaionList(this.jobTypeId, this.applicationForm.get('employmentTypeId').value, this.vuzObl)
      .subscribe((res: any) => {
        this.raionList = res.items;
      }, (err: any) => {

    });
  }

  getEduPlaceList(){
    this.raionList = [];
    this.vuzList = [];
    
    if(this.applicationForm.get('employmentTypeId').value == 2){
      this.api.getVuzList(this.jobTypeId, this.applicationForm.get('employmentTypeId').value, this.vuzObl)
      .subscribe((res: any) => {
        this.vuzList = res.items;
      }, (err: any) => {

      });
    }
    if(this.applicationForm.get('employmentTypeId').value == 3){
      this.getRaionList();
    }
  }

  getEduType(){
    this.api.getDirEduType()
    .subscribe((res: any) => {
      this.eduType = res.items;
    }, (err: any) => {

    });
  }

  getCollege(){
    this.api.getCollegeList(this.jobTypeId, this.applicationForm.get('employmentTypeId').value, this.vuzObl, this.raion)
    .subscribe((res: any) => {
      this.vuzList = res.items;
    }, (err: any) => {

    });
  }

  getLists(){
    this.raionList = [];
    this.vuzList = [];
    this.oblList = [];
    this.collegeSpecialityList = [];
    this.instituteSpecialityList = [];
    this.applicationForm.get('testLangId').patchValue(-1);
    this.applicationForm.get('toCategoryId').patchValue(-1);
    this.applicationForm.get('testLangId').patchValue(-1);
    this.getInstituteSpeciality();
    this.getCollegeSpeciality();
    this.fromListOfActualCategories(this.applicationForm.get('employmentTypeId').value);
    this.toListOfActualCategories(this.applicationForm.get('employmentTypeId').value);
    this.getOblList();
    this.getsubjectCount();
  }

  getInstituteSpeciality(){
    this.api.getInstituteSpeciality(this.jobTypeId)
      .subscribe((res: any) => {
        this.instituteSpecialityList = res.items;
      }, (err: any) => {

      });
  }

  getCollegeSpeciality(){
    this.api.getCollegeSpeciality(this.jobTypeId)
      .subscribe((res: any) => {
        this.collegeSpecialityList = res.items;
      }, (err: any) => {

      });
  }

  getEmploymentTypeId(){
    this.api.getEmploymentTypeId(this.jobTypeId)
      .subscribe((res: any) => {
        this.employmentTypeList = res.items;
      }, (err: any) => {

      });
  }

  get subjects() : FormArray {
    return this.applicationForm.get("subjects") as FormArray;
  }

  get katoGetter() : FormArray {
    return this.applicationForm.get("kato") as FormArray;
  }

  getTestOrgList(){
    this.api.testOrgListDir(this.applicationForm.get('oblId').value)  
      .subscribe((res: any) => {
        this.applicationForm.get('testOrgId').patchValue(-1);
        this.applicationForm.get('testPeriodId').patchValue(-1);
        this.testPeriod = [];
        this.testOrg = res.items;
      }, (err: any) => {

      });
  }

  getTestPeriodList(){
    this.api.testPeriodListDir(this.applicationForm.get('testOrgId').value, 0)  
      .subscribe((res: any) => {
        console.log(res.items.length);
        if(res.items.length == 0){
         this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'не осталось свободных мест тестирования в данном городе, дополнительные места не предусмотрены.', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Бұл қалада бос тестілеу орыны қалмады, қосымша орындар қарастырылмаған', NotificationType.Error, 5000);
        }
        this.testPeriod = res.items;
      }, (err: any) => {

      });
  }


  invalidCheck(){
    this.isInvalid = !this.isInvalid;
  }

  getProfileData(){
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.applicationForm.get('iin').setValue(res.data.iin);
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
        this.applicationForm.get('email').setValue(res.data.email);
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);
        
      }, (err: any) => {

      });
  }

  findData(iin: number){
    this.loading = true;
    this.api.personalData(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  fromListOfActualCategories(employmentTypeId: any){
    this.api.getFromListOfActualCategories(this.jobTypeId, employmentTypeId)
      .subscribe((res: any) => {
        this.fromActualCategories = res.items;
      }, (err: any) => {

      });
  }

  toListOfActualCategories(employmentTypeId: any){
    this.api.getToListOfActualCategories(this.jobTypeId, employmentTypeId)
      .subscribe((res: any) => {
        this.toActualCategories = res.items;
      }, (err: any) => {

      });
  }

  getKATOsList(i, j, firstSelect){
    let id: number;
    if(firstSelect){
      id = this.applicationForm.get('firstLevelKato').value;
      this.lastChild = false;
      this.applicationForm.get('currentJobId').setValue(-1);
      this.jobList = [];
    }else{
      id = this.KATOs[i][j].id;
      this.lastChild = !this.KATOs[i][j].hasChildren;
    }
    if(!this.lastChild || firstSelect){ 
      this.api.getKATOList2(id, this.pageLangId)
      .subscribe((res: any) => {
        if(firstSelect){
          console.log(1);
          this.KATOs = [];
          this.pseudoId = [];
          this.KATOs.push(res.data.items);
        };

        if(firstSelect == false && this.lastChild){
          console.log(2);
          this.KATOs.splice(i+1, 1);
          this.KATOs.push(res.data.items);
        };
        if(firstSelect == false && this.KATOs[i][j].hasChildren){
          console.log(3);
          this.KATOs.splice(i+1, 2);
          this.KATOs.push(res.data.items);
        };
      }, (err: any) => {

      });
    }
      if(!firstSelect && !this.KATOs[i][j].hasChildren){
        this.KATOs.splice(i+1, this.KATOs.length);
        console.log(4);
        this.getJobItems(id, false);
      }else{
        this.lastChild = false;
      };
    
  }

  getKATOsList2(i, j, firstSelect){
    let id: number;
    if(firstSelect){
      id = this.applicationForm.get('firstLevelKato').value;
      this.lastChild = false;
      this.jobList2 = [];
      this.applicationForm.get('pretendedJobId').setValue(-1);
    }else{
      id = this.KATOs2[i][j].id;
      this.lastChild = !this.KATOs2[i][j].hasChildren;
    }
    if(!this.lastChild || firstSelect){ 
      this.api.getKATOList2(id, this.pageLangId)
      .subscribe((res: any) => {
        if(firstSelect){
          console.log(1);
          this.KATOs2 = [];
          this.pseudoId2 = [];
          this.KATOs2.push(res.data.items);
        };

        if(firstSelect == false && this.lastChild){
          console.log(2);
          this.KATOs2.splice(i+1, 1);
          this.KATOs2.push(res.data.items);
        };
        if(firstSelect == false && this.KATOs2[i][j].hasChildren){
          console.log(3);
          this.KATOs2.splice(i+1, 2);
          this.KATOs2.push(res.data.items);
        };
      }, (err: any) => {

      });
    }
      if(!firstSelect && !this.KATOs2[i][j].hasChildren){
        this.KATOs2.splice(i+1, this.KATOs2.length);
        console.log(4);
        this.getJobItems2(id, false);
      }else{
        this.lastChild = false;
      };
    
  }

  getKATOOblList(){
    this.api.getKATOList2(0, this.pageLangId)
      .subscribe((res: any) => {
        this.KATOOblList = res.data.items;
      }, (err: any) => {

      });
  }

  agreeCheck(e:any){
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }

  getJobItems(nasPunkId, binSearch){
      this.api.getJobList2(nasPunkId, this.pageLangId)
      .subscribe((res: any) => {
        this.jobList = res.items;
      }, (err: any) => {

      });
    if(binSearch == true){
      setTimeout(() => {
        this.applicationForm.get('currentJobId').setValue(this.jobId);
        this.loading = false;
      }, 1000);
    }
    
  }

  getJobItems2(nasPunkId, binSearch){
    this.api.getJobList2(nasPunkId, this.pageLangId)
    .subscribe((res: any) => {
      this.jobList2 = res.items;
    }, (err: any) => {

    });
  if(binSearch == true){
    setTimeout(() => {
      this.applicationForm.get('pretendedJobId').setValue(this.jobId2);
      this.loading = false;
    }, 1000);
  }
  
}

  getsubjectCount(){
    this.api.getsubjectCount(this.jobTypeId, this.applicationForm.get('employmentTypeId').value)
      .subscribe((res: any) => {
        this.subjectCount = res.data.subjectCount;
        this.subjectCountLoop();
      }, (err: any) => {

      });
  }

  subjectCountLoop(){
    this.subjectCountArray = [];
    let subj = this.applicationForm.controls.subjects as FormArray;
    for(var i = ++this.subjectCountArray.length; i <= this.subjectCount; i++){
      this.api.getSubjectItems(this.jobTypeId, i, this.applicationForm.get('employmentTypeId').value)
          .subscribe((response: any) => {
            this.subjectList.push(response.items);
          }, (error: any) =>{

          });
      this.addSubject(i);
    }
    this.langList.reverse();

  }

  getSubjectsLang(){
    this.api.getSubjectLangDir(this.applicationForm.get('eduTypeId').value)
      .subscribe((response: any) => {
          this.langList = response.items;
      }, (error: any) =>{

      });
  }

  newSubject(i): FormGroup {
    return this.fb.group({
      ord: new FormControl(i),
      subjectId: new FormControl(''),
      langId: new FormControl('')
    })
  }

  setSubjectLangIdValue(status, i, value){
    this.subjects.controls[i].get('langId').setValue(value);
  }

  changeIIN(){
    this.applicationForm.get('firstname').setValue('');
    this.applicationForm.get('lastname').setValue('');
    this.applicationForm.get('patronymic').setValue('');
  }

  postForm(){
    this.loading = true;
    this.api.postNqtSoFormDir(this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        if(res.notice){
          this.translate.currentLang == 'ru' ? this._notifications.success('Заявление', res.notice.ru, { timeOut: 0 }) : this._notifications.success('Өтініш', res.notice.kk, { timeOut: 0 });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
        }
        if(res.data.paymentType.id == 2){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id, type: 5 } });
        }else{
          this.router.navigateByUrl('/profile/history');
        }
      },(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  addSubject(i) {
    this.subjects.push(this.newSubject(i));
    this.subjectCountArray.push(this.subjects);
  }

  addKato(i) {
    this.katoGetter.push(this.createKato(i));
  }

  setSubjectValue(status, i, value){
    this.subjects.controls[i].get('subjectId').setValue(value);


  }
  
  createKato(katoId): FormGroup {
    return this.fb.group({
      katoId
    })
  }

  searchJob(bin){
    this.loading = true;
    this.api.searchJobByBin2(bin)
      .subscribe((res: any) => {
        this.loading = false;
        this.KATOs = [];
        this.jobId = res.data.id;
        this.applicationForm.get('firstLevelKato').setValue(res.data.kato[0]);
        res.data.kato.forEach(element => {
          this.api.getKATOList2(element, this.pageLangId)
          .subscribe((resp: any) => {
            if(resp.data.items.length > 0){
              this.KATOs.push(resp.data.items);
            }
            this.KATOs.sort();
            this.KATOs.reverse();
          }, (err: any) => {

          });
          
        });

        console.log(res);
        this.katoGetter.controls = [];
        
        this.pseudoId = [];

        res.data.kato.forEach(elem => {
          this.pseudoId.push(elem);
        });
        
        this.getJobItems(res.data.kato[res.data.kato.length-1], true);

        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  searchJob2(bin){
    this.loading = true;
    this.api.searchJobByBin2(bin)
      .subscribe((res: any) => {
        this.loading = false;
        this.KATOs2 = [];
        this.jobId2 = res.data.id;
        this.applicationForm.get('firstLevelKato').setValue(res.data.kato[0]);
        res.data.kato.forEach(element => {
          this.api.getKATOList2(element, this.pageLangId)
          .subscribe((resp: any) => {
            if(resp.data.items.length > 0){
              this.KATOs2.push(resp.data.items);
            }
            this.KATOs2.sort();
            this.KATOs2.reverse();
          }, (err: any) => {

          });
          
        });

        console.log(res);
        this.katoGetter.controls = [];
        
        this.pseudoId2 = [];

        res.data.kato.forEach(elem => {
          this.pseudoId2.push(elem);
        });
        
        this.getJobItems2(res.data.kato[res.data.kato.length-1], true);

        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

}
