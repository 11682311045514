import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sertif-ast',
  templateUrl: './sertif-ast.component.html',
  styleUrls: ['./sertif-ast.component.css']
})
export class SertifASTComponent implements OnInit {


  title = 'fileUpload';
  files: any[];
  currentFile: any;

  

  seasonId: any;
  loading = false;
  appTypeId = 7;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts = '0.0';
  year = '0000';
  regNumber = '000000000';
  ball = '0000';
  applicationAgree = false;
  collegeSpecialityList = [];
  eduProgramList = [];
  specialDisciplines = [];

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    // this.seasonId = this.router.parseUrl(this.router.url).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        if(res.data.sexId == 0){
          this.canEditSexId = true; 
        }
        if(res.data.nationId == 0 || res.data.nationId == 99){
          this.canEditNationId = true;
        }
        if(res.data.citizenshipId == 0 || res.data.citizenshipId == 99){
          this.canEditCitizenshipId = true;
        }
        if(res.data.phoneNumber == 0){
          this.canEditPhoneNumber = true;
        }
        // this.applicationForm.get('sexId').patchValue(res.data.sexId);
        // this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        // this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);

        

      });
      // Инициализация формы
      this.applicationForm = new FormGroup({
        phoneNumber: new FormControl(),
        englishScore: new FormControl(0),
        mathScore: new FormControl(0, Validators.required),
        readingScore: new FormControl(0, Validators.required),
        scienceScore: new FormControl(0, Validators.required),
        foreignLangCertificateTypeId: new FormControl(0),
        foreignLangCertificateScore: new FormControl('0'),
        registrationNumber: new FormControl(0, Validators.required),
        testDate: new FormControl(0, Validators.required),
        foreignLangCertificateDate: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0),
        
      });



      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }
      this.getProfileSubjectPair();
      this.getForeignLangCertList();
  }

  formateDate(){
    this.applicationForm.get('testDate').setValue(this.datepipe.transform(this.applicationForm.get('testDate').value, 'dd-MM-yyyy'));
  }

  getNationList(){
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList(){
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList(){
    this.api.getAstAppForeignLangCert()
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  

  
  

  
  
  

  

  

  // getEduInfo(){
  //   this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
  //     .subscribe((res: any) => {
  //       if(res.data.oblId != 0){
  //         this.applicationForm.get('oblId').setValue(res.data.oblId);
  //         this.canEditOblId = true;
  //         this.getRaionList();
  //         if(res.data.raionId != 0){
  //           this.applicationForm.get('raionId').setValue(res.data.raionId);
  //           this.canEditRaionId = true;
  //           this.getSchoolList();
  //           if(res.data.schoolId != 0){
  //             this.canEditSchoolId = true;
  //             this.applicationForm.get('schoolId').setValue(res.data.schoolId);
  //           }
  //         }
  //       }
  //     },(err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //     });
  // }

  // getSubjectPair(){
  //   this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
  //     .subscribe((res: any) => {
  //       this.subjectPairList = res.items;
  //     },(err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //     });
  // }



  getProfileSubjectPair(){
    this.api.getAstProfileSubjectPair()
      .subscribe((res: any) => { 
        this.applicationForm.get('profileSubjectPairId').setValue(0);
        this.subjectPairList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(){
    this.api.getTipoLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('tipoSubjectId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
    });
  }

  getTestOrg(){
    this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOblId').value, 0)
      .subscribe((res: any) => {
        this.applicationForm.get('testOrgId').setValue(0);
        this.testOrgList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate(){
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, 0)
      .subscribe((res: any) => {
        this.testDateList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id){
    if(id == 4){
      this.foreignLangCertScoreMask = '0.0';
    }
    if(id !=4){
      this.foreignLangCertScoreMask = '000';
    }
    if(id == 0){
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck(){
    if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);  
    }
  }

  postApplication(){
    this.scoreCheck();
    this.loading = true;
    this.api.postApplicationEnt(this.applicationForm.value)
      .subscribe((res: any) => {
        if(res.data.paymentType.id == 2){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id } });
        }else{
          this.router.navigateByUrl('/profile/history');
        }
      },(err: any) =>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

}
