import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.css']
})
export class AddApplicationComponent implements OnInit {

  loading = true;

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      this.loading = false;
      }, 150);
      
  }

}
