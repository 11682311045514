import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {
  passDropForm: FormGroup;

  loading = true;
  formData: any;
  iin = '000000000000';

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }
  ngOnInit(): void {

    this.initRegForm();
    this.passDropForm.get('accountTypeId').setValue(1);

    setTimeout(() => {
      this.loading = false;
    }, 100);

  }

  newPass() {
    this.loading = true;
    this.formData = this.passDropForm.value;
    this.api.resetPassword(this.formData)
      .subscribe((res: any) => {
        this.loading = false;
        
        this.translate.currentLang == 'ru' ? this._notifications.create(res.data.email, 'Запрос на изменение пароля был отправлен на данную почту', NotificationType.Success, 5000) : this._notifications.create(res.data.email, 'Құпия сөзді өзгерту туралы өтініш осы электрондық поштаға жіберілді', NotificationType.Success, 5000);

        this.router.navigate(['/auth']);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          this.loading = false;
      });
  }
  

  initRegForm() {
    this.passDropForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      iin: ["", [Validators.required, Validators.required]],
      accountTypeId: ["", [Validators.required, Validators.email]]
    });
  }

  get emailCheck() { return this.passDropForm.get('email'); }
  get iinCheck() { return this.passDropForm.get('iin'); }

}
