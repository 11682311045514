import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'test-org-template',
  templateUrl: './test-org.component.html',
  styleUrls: ['./test-org.component.css']
})
export class TestOrgComponent implements OnInit {

  @Input() applicationForm: FormGroup;
  cities = [];

  constructor(
     private api: ApiService,
     public translate: TranslateService
  ) { }

  ngOnInit(): void {

  }

  getCity(seasonId:number, appTypeId:number) {
    this.api.getCityPvo(seasonId, appTypeId)
      .subscribe((res: any) => {
        this.cities = res.items;
      }, (err: any) => {

      });
  }

}
