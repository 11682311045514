<div *ngIf="app.moduleType?.id == 8" class="bg-blue-100 border-blue-500 border-2 zayav rounded mb-4 p-3">
    <div class="flex w-full justify-between test-type-name">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>

        <div class="text-lg text-green-400 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.testStatus.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.testStatus.name.ru }}</span>
        </div>
    </div>

    <div class="flex w-full justify-between test-type-name">
        <div class="py-2 font-bold">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.testType?.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.testType?.name.ru }}</span>
        </div>
        <div class="py-2 font-bold">
            {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy' }}
        </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">
                <div class="px-3 text-gray-600">

                    <div class="mb-2">
                        {{ 'IKT' | translate }} : <input disabled readonly class="bg-transparent" [value]="app.id">
                    </div>

                    <div class="mb-2">
                        {{ 'IIN' | translate }} : {{ app.iin}}
                    </div>

                    <div class="mb-2">
                        {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                    </div>

                    <div class="mb-2" *ngIf="app.level?.id !== 0">
                        {{ 'Level' | translate }}:
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.level?.name.kk
                            }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.level?.name.ru
                            }}</span>
                    </div>

                    <div class="mb-2">
                        {{ 'Category' | translate }}:
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.category?.name.kk
                            }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.category?.name.ru
                            }}</span>
                    </div>

                    <div *ngIf="!!app.testPeriod.testDate" class="mb-2">
                        {{ 'TestDate' | translate }}: 
                        <span class="font-bold"> {{ app.testPeriod.testDate }} ({{ 'startTime' | translate }}: {{ app.testPeriod.enterTestTime }})</span> 
                    </div>

                    <div *ngIf="!!app.testOrg.name.kk" class="mb-2">
                        {{ 'TestOrg' | translate }}: 
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.testOrg.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.testOrg.name.ru }}</span>  
                    </div>

                    <!-- Таблица результатов ПК Версия -->

                    <table class="table table-bordered pc-version" *ngIf="app.subjects">
                        <tr class="text-center">
                            <td class="w-6/12">
                                {{ 'Block' | translate }}
                            </td>
                            <td class="w-2/12">
                                {{ 'Status' | translate }}
                            </td>
                            <td class="w-2/12">
                                {{ 'Ball' | translate }}
                            </td>

                        </tr>
                        <tr *ngFor="let sub of app.subjects; index as num">
                            <td>
                                <span *ngIf="translate.currentLang == 'ru'">{{ sub?.name.ru }}</span>
                                <span *ngIf="translate.currentLang == 'kz'">{{ sub?.name.kk }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="translate.currentLang == 'ru'">{{ sub.status?.name.ru }}</span>
                                <span *ngIf="translate.currentLang == 'kz'">{{ sub.status?.name.kk }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="sub.status?.id == 3">
                                    {{ sub.score }} ({{ sub.percent }}%)
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="w-3/4" colspan="2">
                                {{ 'totalScore' | translate }}
                            </td>
                            <td class="text-center">
                                {{ app.totalScore }} ({{ app.totalPercent }}%)
                            </td>
                        </tr>
                    </table>

                    <!-- Таблица результатов мобильная версия -->

                    <div class="mobile-version"  *ngIf="app.subjects">

                        <div class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                            {{ 'testResults' | translate }}
                        </div>

                        <table class="table table-bordered">
                            <thead *ngFor="let sub of app.subjects; index as num">
                                <tr>
                                    <td colspan="3" class="bg-blue-200 text-center font-bold">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub?.name.ru }}</span>
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub?.name.kk }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ 'Status' | translate }}
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub.status?.name.ru }}</span>
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub.status?.name.kk }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ 'Ball' | translate }}
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="sub.status?.id == 3">
                                            {{ sub.score }} ({{ sub.percent }}%)
                                        </span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bg-blue-300 font-bold text-center" colspan="2">
                                        {{ 'totalScore' | translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-blue-300 font-bold text-center" colspan="2">
                                        {{ app.totalScore }} ({{ app.totalPercent }}%)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-2" *ngIf="app.testStatus.id == 1">
                        <span *ngIf="translate.currentLang == 'ru'">
                            * Результаты тестирования по блокам «Письмо» и «Говорение» будут доступны в течении 2 (двух) рабочих дней после проверки и оценивания экспертами.
                        </span>
                        <span *ngIf="translate.currentLang == 'kz'">
                            * Тестілеу нәтижесі «Жазылым» және «Айтылым» бөліктерін сарапшылар тексеріп бағалағаннан кейін 2 (екі) жұмыс күні ішінде  қолжетімді болады.
                        </span>
                    </div>
                </div>
                <div class="flex w-full justify-end mt-2 zayav-buttons px-3">
                    <button *ngIf="app.testStatus.id == 8" (click)="showPayment(app.id)" class=" w-1/2 bg-blue-400 ml-3 tracking-wide w-64 hover:bg-blue-700 zayav-button text-white font-hairline py-1 px-3 rounded">
                        {{ 'Pay' | translate }}
                    </button>
                    <button *ngIf="app.testStatus.id == 8 || app.testStatus.id == 9 " (click)="showEdit(app.id, app.testType.id)" class=" w-1/2 bg-blue-400 zayav-button ml-3 tracking-wide w-64 hover:bg-blue-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'EditApplication' | translate }} 
                    </button>
                    <button *ngIf="app.testStatus.id == 9 || app.testStatus.id == 8" (click)="showDeleteModal()" class=" w-1/2 bg-red-400 zayav-button ml-3 tracking-wide w-64 hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'DeleteApplication' | translate }} 
                    </button>
                    <button (click)="checkCertificate(app.id, app.iin, app.testPeriod.testYear, 4)" *ngIf="app.testStatus.id == 7"
                        class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'CheckCertificateKaztest' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
        class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z" />
        </svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z" />
        </svg>
    </div>

</div>