import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { DecimalPipe, formatNumber } from '@angular/common';

@Component({
  selector: 'app-edit-subjects',
  templateUrl: './edit-subjects.component.html',
  styleUrls: ['./edit-subjects.component.css']
})
export class EditSubjectsComponent implements OnInit {

  seasonId: any;
  token: any;
  applicationForm: FormGroup;
  appTypeId: any;
  pageLangId: number;
  subjectPairList: any;
  langList: any;
  collegeSpecialityList = [];
  eduProgramList = [];
  specialDisciplines = [];
  testData: any;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.token = this.route.snapshot.queryParamMap.get('token');

    this.getTestData();

    this.applicationForm = new FormGroup({
      profileSubjectPairId: new FormControl(0),
      collegeSpecialityId: new FormControl(0),
      eduProgramId: new FormControl(0),
      tipoSubjectId: new FormControl(0),
      testLangId: new FormControl(0)
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

  }

  getTestData() {
    this.api.getTestInfo(this.token)
      .subscribe((res: any) => {
        this.testData = res.data;
        this.seasonId = res.data.seasonId;
        this.appTypeId = res.data.appTypeId;
        if (res.data.appTypeId == 7) {
          this.applicationForm.get('collegeSpecialityId').patchValue(res.data.collegeSpeciality.id);

          this.getCollegeSpeciality();
        } else {
          this.applicationForm.get('profileSubjectPairId').patchValue(res.data.profileSubjectPair.id);

          this.getSubjectPair(1);
        }

      }, (err: any) => {

      });

  }

  getCollegeSpeciality() {
    this.api.collegeSpeciality(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.collegeSpecialityList = res.items;
        this.getEduProgram(1);
      }, (err: any) => {

      });
  }

  getEduProgram(code: number) {
    this.api.eduProgram(this.seasonId, this.appTypeId, this.applicationForm.get('collegeSpecialityId').value)
      .subscribe((res: any) => {
        this.eduProgramList = res.items;
        if (code == 1) {
          this.applicationForm.get('eduProgramId').patchValue(this.testData.eduProgram.id);
        } else {
          this.applicationForm.get('eduProgramId').patchValue(0);
        }
        this.getSpecialDisciplines(code);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSpecialDisciplines(code: number) {
    this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.specialDisciplines = res.items;
        if (code == 1) {
          this.applicationForm.get('tipoSubjectId').patchValue(this.testData.tipoSubject.id);
        } else {
          this.applicationForm.get('tipoSubjectId').setValue(0);
        }
        this.getLangListTipo(code);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangListTipo(code: number) {
    this.api.getTipoLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('tipoSubjectId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if (code == 1) {
          this.applicationForm.get('testLangId').patchValue(this.testData.testLang.id);
        } else {
          this.applicationForm.get('testLangId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSubjectPair(code: number) {
    this.api.getProfileSubjectEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.subjectPairList = res.items;
        this.getLangList(code);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code: number) {
    this.api.getLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if (code == 1) {
          this.applicationForm.get('testLangId').patchValue(this.testData.testLang.id);
        } else {
          this.applicationForm.get('testLangId').setValue(0);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  edit() {
    this.api.putTestInfo(this.applicationForm.value, this.token)
      .subscribe((res: any) => {

        window.location.href = this.route.snapshot.queryParamMap.get('url');

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }
  cancel() {
    window.location.href = this.route.snapshot.queryParamMap.get('url');
  }

}
