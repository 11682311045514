import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TestOrgComponent } from '../../../template/test-org/test-org.component';

@Component({
  selector: 'app-toelf',
  templateUrl: './toelf.component.html',
  styleUrls: ['./toelf.component.css'],
})
export class ToelfComponent implements OnInit, AfterViewInit {
  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOblList = [];
  testOrgList = [];
  testDateList = [];
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask: string = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  foreignLangCertCharScore: any;
  charList: any;
  studyTypeList: any;
  eduAreaList: any;
  masterTypeList: any;
  gopList: any;
  foreignLangList: any;
  profileDisciplines1List: any;
  profileDisciplines2List: any;
  cities: any;
  disabilityList: any;
  showChars = false;
  showSubjects = false;
  sertidTypeFloat = false;
  fileList: any;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  fileIdToDelete: number;
  prevEduArea: any;
  prevEduDirection: any;

  modalAgreement = false;
  sogData: any;

  @ViewChild(TestOrgComponent) testOrg: TestOrgComponent;

  constructor(
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private messageService: MessageService,
    public sanitizer: DomSanitizer
  ) {}
  ngAfterViewInit(): void {
    this.testOrg.getCity(this.seasonId, this.appTypeId);
  }

  ngOnInit(): void {
    this.seasonId = this.router.parseUrl(
      this.router.url
    ).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    this.appTypeId = this.route.snapshot.queryParamMap.get('appTypeId');

    this.messageService.getDocSog().subscribe((res) => (this.sogData = res));
    // Получение данных из профиля
    this.api.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      if (res.data.sexId == 0) {
        this.canEditSexId = true;
      }
      if (res.data.nationId == 0 || res.data.nationId == 99) {
        this.canEditNationId = true;
      }
      if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
        this.canEditCitizenshipId = true;
      }
      if (res.data.phoneNumber == 0) {
        this.canEditPhoneNumber = true;
      }
      this.applicationForm.get('sexId').patchValue(res.data.sexId);
      this.applicationForm.get('isHasDisability').patchValue(0);
      this.applicationForm
        .get('citizenshipId')
        .patchValue(res.data.citizenshipId);
      this.applicationForm.get('nationId').patchValue(res.data.nationId);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
    });

    // Инициализация формы
    let emptyValue: number = 0;
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(+this.seasonId),
      appTypeId: new FormControl(+this.appTypeId),
      citizenshipId: new FormControl(+emptyValue, Validators.required),
      nationId: new FormControl(+emptyValue, Validators.required),
      foreignLangCertTypeId: new FormControl(+emptyValue),
      foreignLangCertScore: new FormControl(+emptyValue),
      phoneNumber: new FormControl(+emptyValue, Validators.required),
      sexId: new FormControl(+emptyValue, Validators.required),
      isHasDisability: new FormControl(+emptyValue, Validators.required),
      disabilityId: new FormControl(+emptyValue, Validators.required),
      masterTypeId: new FormControl(+emptyValue, Validators.required),
      profileSubjectPairId: new FormControl(+emptyValue),
      testLangId: new FormControl(+emptyValue),
      testCityId: new FormControl(+emptyValue),
      eduProgramId: new FormControl(+emptyValue),
      eduAreaId: new FormControl(+emptyValue),
      testPeriodId: new FormControl(+emptyValue),
      prevEduAreaId: new FormControl(+emptyValue),
      prevEduDirectionId: new FormControl(+emptyValue),
      profileSubject1Id: new FormControl(+emptyValue),
      foreignLangId: new FormControl(+emptyValue),
      profileSubject2Id: new FormControl(+emptyValue),
      foreignLangCertificateFiles: new FormArray([]),
      appFiles: new FormArray([]),
      applicationAgree: new FormControl({ value: false, disabled: true }),
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    this.getNationList();
    this.getCountryList();
    this.getForeignLangCertList();
    this.getStudyType();
    this.getMasterType();
    // this.getEduArea();
    this.getForeignLang();
    this.getCity();
    this.getHelper();
    this.getPrevEduArea();
  }

  onScrollEnd(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  getCity() {
    this.api.getCityPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.cities = res.items;
      },
      (err: any) => {}
    );
  }

  getPrevEduArea() {
    this.api.getPrevEduArea(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.prevEduArea = res.items;
      },
      (err: any) => {}
    );
  }

  getEduDirection() {
    this.api
      .getPrevEduDirection(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('prevEduAreaId').value
      )
      .subscribe(
        (res: any) => {
          this.prevEduDirection = res.items;
          this.applicationForm.get('prevEduDirectionId').setValue(0);
          this.clearDown();
          this.eduAreaList = [];
          this.gopList = [];
        },
        (err: any) => {}
      );
  }

  clearDown() {
    this.applicationForm.get('masterTypeId').setValue(0);
    this.applicationForm.get('eduAreaId').setValue(0);
    this.applicationForm.get('eduProgramId').setValue(0);
    this.applicationForm.get('testLangId').setValue(0);
    this.applicationForm.get('foreignLangId').setValue(0);
  }

  getEduArea() {
    this.api
      .getEduAreaPvo(
        this.seasonId,
        this.appTypeId,
        2,
        this.applicationForm.get('prevEduDirectionId').value
      )
      .subscribe(
        (res: any) => {
          this.eduAreaList = res.items;
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('eduAreaId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getHelper() {
    this.api.getDisability(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.disabilityList = res.items;
      },
      (err: any) => {}
    );
  }

  getProfileDisciplines(id: any) {
    let index = document.forms[0].elements['gop'].selectedIndex;
    this.applicationForm.get('profileSubject1Id').setValue(0);
    this.applicationForm.get('profileSubject2Id').setValue(0);
    if (
      this.gopList[index - 1].id == id &&
      this.gopList[index - 1].hasMultipleProfileSubjects
    ) {
      this.showSubjects = true;
      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          1
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines1List = res.items;
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );

      this.api
        .getProfileSubjectPvo(
          this.seasonId,
          this.appTypeId,
          this.applicationForm.get('eduProgramId').value,
          this.applicationForm.get('testLangId').value,
          2
        )
        .subscribe(
          (res: any) => {
            this.profileDisciplines2List = res.items;
          },
          (err: any) => {
            this.translate.currentLang == 'ru'
              ? this._notifications.create(
                  'Ошибка',
                  err.error.errorMessage.ru,
                  NotificationType.Error,
                  5000
                )
              : this._notifications.create(
                  'Қате',
                  err.error.errorMessage.kk,
                  NotificationType.Error,
                  5000
                );
          }
        );
    } else {
      this.showSubjects = false;
    }
  }

  getForeignLang() {
    this.api.getForeignLangPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getTestLang() {
    // console.log(document.forms[0].elements["gop"].selectedIndex)
    this.api
      .getTestLangPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduProgramId').value
      )
      .subscribe(
        (res: any) => {
          this.langList = res.items;
          this.applicationForm.get('testLangId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  getMasterType() {
    this.api.getMasterTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.masterTypeList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getGop() {
    this.api
      .getGopPvo(
        this.seasonId,
        this.appTypeId,
        this.applicationForm.get('eduAreaId').value,
        this.applicationForm.get('masterTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.gopList = res.items;
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('testLangId').setValue(0);
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
  }

  // getEduArea(){
  //   this.api.getEduAreaPvo(this.seasonId, this.appTypeId, this.applicationForm.get('masterTypeId').value)
  //     .subscribe((res: any) => {
  //       this.eduAreaList = res.items;
  //       this.applicationForm.get('eduProgramId').setValue(0);
  //       this.applicationForm.get('eduAreaId').setValue(0);
  //     },(err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //     });
  // }

  getStudyType() {
    this.api.getStudyTypePvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.studyTypeList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getNationList() {
    this.api.getNationPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.nationsList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getCountryList() {
    this.api.getCountryPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.countryList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  getForeignLangCertList() {
    this.api.getForeignLangCertPvo(this.seasonId, this.appTypeId).subscribe(
      (res: any) => {
        this.foreignLangCertList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl(),
      })
    );
  }

  addFileZayav() {
    let obj = null;

    obj = this.zayav;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl(),
      })
    );
  }

  changeListener($event, i: number): void {
    let obj = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i]
        .get('data')
        .setValue(
          imgData.toString().substring(imgData.toString().indexOf(',') + 1)
        );
    };
    myReader.readAsDataURL(file);
  }

  changeListenerZayav($event, i: number): void {
    let obj = null;

    obj = this.zayav;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i]
        .get('data')
        .setValue(
          imgData.toString().substring(imgData.toString().indexOf(',') + 1)
        );
    };
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  dwnldCheckZayav(id: number): boolean {
    return this.zayav.controls[id].get('filename').value !== '';
  }

  // Удаление input для файла
  removeFile(type: string) {
    this.translate.currentLang == 'ru'
      ? this._notifications.create(
          'Файл',
          'успешно удален',
          NotificationType.Success,
          5000
        )
      : this._notifications.create(
          'Файл',
          'сәтті жойылды',
          NotificationType.Success,
          5000
        );
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

  removeFileZayav(type: string) {
    this.translate.currentLang == 'ru'
      ? this._notifications.create(
          'Файл',
          'успешно удален',
          NotificationType.Success,
          5000
        )
      : this._notifications.create(
          'Файл',
          'сәтті жойылды',
          NotificationType.Success,
          5000
        );
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.zayav;

    obj.removeAt(this.fileIdToDelete);
  }

  get files(): any {
    return this.applicationForm.get('foreignLangCertificateFiles');
  }
  get zayav(): any {
    return this.applicationForm.get('appFiles');
  }

  checkSertif(id) {
    this.foreignLangCertList.forEach((element) => {
      if (element.isScoreInChars && element.id == id) {
        this.charList = element.availableValues;
        this.showChars = true;
      }
      if (!element.isScoreInChars && element.id == id) {
        this.showChars = false;
      }
      if (element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = true;
        // this.foreignLangCertScoreMask = '0.0';
      }
      if (!element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = false;
        // this.foreignLangCertScoreMask = '000';
      }
    });
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck() {
    if (
      this.sertidTypeFloat == true &&
      this.applicationForm.get('foreignLangCertScore').value > 9
    ) {
      this.applicationForm
        .get('foreignLangCertScore')
        .patchValue(
          this.applicationForm.get('foreignLangCertScore').value / 10
        );
    }
  }

  postApplication() {
    this.scoreCheck();
    this.loading = true;
    this.api.postAppItemsPvo(this.applicationForm.value).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.data.paymentType.id == 2) {
          this.router.navigate(['/profile/applications/payment'], {
            queryParams: { id: res.data.id, type: 4 },
          });
        } else {
          this.router.navigateByUrl('/profile/history');
        }
      },
      (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );
  }

  agreeCheck(e: any) {
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }
}
