import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class entConfig {
  entBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
        this.entBackend = 'https://app.debug.testcenter.kz/ent/student/app/api/v1'; // Путь для localhost
       
      
    }
    if (!isDevMode()) {
        this.entBackend = 'ent/student/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      appType: '/app-type/items/',
      appItems: this.entBackend + '/app/items',
      appSeasonItems: this.entBackend + '/app/season/items'

      // appTestType: this.entBackend + '/app/app-type/',
      // appItems: this.entBackend + '/app/items'
    }

  }
}
