import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class messagesConfig {
  messagesBackend: any;
  appMessageBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.messagesBackend = 'https://app.debug.testcenter.kz/message/api/v1'; // Путь для localhost  
      this.appMessageBackend = 'http://localhost:8090/app/get-message';
    }
    if (!isDevMode()) {
      this.messagesBackend = 'message/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      items: this.messagesBackend + '/items'
    }

  }
}
