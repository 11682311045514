<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->
<div class="text-center text-3xl font-hairline text-blue-500 mb-4">{{ 'ChangeProfileData' | translate }}</div>
    <div class="flex pc-version">
        <div class="w-1/5">
            <!-- <p class="title">{{ 'IIN' | translate }}:</p> -->
            <p class="title">{{ 'Surname' | translate }}:</p>
            <p class="title">{{ 'Name' | translate }}:</p>
            <p class="title">{{ 'Patronymic' | translate }}:</p>
            <p class="title">{{ 'PhoneNumber' | translate }}:</p>
        </div>
        <div class="w-4/5">
            <form action="" [formGroup]="editProfileForm">
                <!-- <div class="relative">
                    <input (change)="changeIIN()" [value]="profileInfo.iin" [mask]="iin" min="12" formControlName="iin" placeholder="*{{ 'IIN' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                    <div *ngIf="iinCheck.invalid" class="err-txt">
                        *{{ 'FillInTheField' | translate }}
                    </div>
                    <svg (click)="findData(editProfileForm.get('iin').value)" class="search" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="49.999px" height="49.999px" viewBox="0 0 49.999 49.999" style="enable-background:new 0 0 49.999 49.999;" xml:space="preserve"><g><g>
                        <path d="M48.681,42.295l-8.925-8.904c-0.045-0.045-0.098-0.078-0.145-0.11c-0.802,1.233-1.761,2.405-2.843,3.487    c-1.081,1.082-2.255,2.041-3.501,2.845c0.044,0.046,0.077,0.1,0.122,0.144l8.907,8.924c1.763,1.76,4.626,1.758,6.383,0    C50.438,46.921,50.439,44.057,48.681,42.295z"/>
                        <path d="M35.496,6.079C27.388-2.027,14.198-2.027,6.089,6.081c-8.117,8.106-8.118,21.306-0.006,29.415    c8.112,8.105,21.305,8.105,29.413-0.001C43.604,27.387,43.603,14.186,35.496,6.079z M9.905,31.678    C3.902,25.675,3.904,15.902,9.907,9.905c6.003-6.002,15.77-6.002,21.771-0.003c5.999,6,5.997,15.762,0,21.774    C25.676,37.66,15.91,37.682,9.905,31.678z"/>
                        <path d="M14.18,22.464c-0.441-1.812-2.257-4.326-3.785-3.525c-1.211,0.618-0.87,3.452-0.299,5.128    c2.552,7.621,11.833,9.232,12.798,8.268C23.843,31.387,15.928,29.635,14.18,22.464z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                    </svg>
                </div> -->
                
                
                <input [value]="profileInfo.lastname" disabled readonly placeholder="{{ 'Surname' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                <input [value]="profileInfo.firstname" disabled readonly placeholder="{{ 'Name' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                <input [value]="profileInfo.patronymic" disabled readonly placeholder="{{ 'Patronymic' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                
                <input [value]="profileInfo.phoneNumber"  [mask]="phone"  formControlName="phoneNumber" placeholder="*{{ 'PhoneNumber' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
            </form>    
        </div>
    </div>
    <div class="flex mobile-version">
        <div class="w-full">
            <form action="" [formGroup]="editProfileForm">
                <div class="relative">
                    <p class="title">{{ 'IIN' | translate }}:</p>
                    <input (change)="changeIIN()" [value]="profileInfo.iin" [mask]="iin" min="12" formControlName="iin" placeholder="*{{ 'IIN' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                    <div *ngIf="iinCheck.invalid" class="err-txt">
                        *{{ 'FillInTheField' | translate }}
                    </div>
                    <svg (click)="findData(editProfileForm.get('iin').value)" class="search" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="49.999px" height="49.999px" viewBox="0 0 49.999 49.999" style="enable-background:new 0 0 49.999 49.999;" xml:space="preserve"><g><g>
                        <path d="M48.681,42.295l-8.925-8.904c-0.045-0.045-0.098-0.078-0.145-0.11c-0.802,1.233-1.761,2.405-2.843,3.487    c-1.081,1.082-2.255,2.041-3.501,2.845c0.044,0.046,0.077,0.1,0.122,0.144l8.907,8.924c1.763,1.76,4.626,1.758,6.383,0    C50.438,46.921,50.439,44.057,48.681,42.295z"/>
                        <path d="M35.496,6.079C27.388-2.027,14.198-2.027,6.089,6.081c-8.117,8.106-8.118,21.306-0.006,29.415    c8.112,8.105,21.305,8.105,29.413-0.001C43.604,27.387,43.603,14.186,35.496,6.079z M9.905,31.678    C3.902,25.675,3.904,15.902,9.907,9.905c6.003-6.002,15.77-6.002,21.771-0.003c5.999,6,5.997,15.762,0,21.774    C25.676,37.66,15.91,37.682,9.905,31.678z"/>
                        <path d="M14.18,22.464c-0.441-1.812-2.257-4.326-3.785-3.525c-1.211,0.618-0.87,3.452-0.299,5.128    c2.552,7.621,11.833,9.232,12.798,8.268C23.843,31.387,15.928,29.635,14.18,22.464z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                    </svg>
                </div>
                
                <p class="title">{{ 'Surname' | translate }}:</p>
                <input [value]="profileInfo.lastname" disabled readonly placeholder="{{ 'Surname' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                
                <p class="title">{{ 'Name' | translate }}:</p>
                <input [value]="profileInfo.firstname" disabled readonly placeholder="{{ 'Name' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                
                <p class="title">{{ 'Patronymic' | translate }}:</p>
                <input [value]="profileInfo.patronymic" disabled readonly placeholder="{{ 'Patronymic' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
                
                <p class="title">{{ 'PhoneNumber' | translate }}:</p>
                <input [value]="profileInfo.phoneNumber"  [mask]="phone"  formControlName="phoneNumber" placeholder="*{{ 'PhoneNumber' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
            </form>    
        </div>
    </div>
    <div class="text-center mt-8">
        <button [routerLink]="'/profile/im'" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{ 'Cancel' | translate }}</button>
        <button (click)="edit(6)" class="bg-green-500 ml-16 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">{{ 'Save' | translate }}</button>    
        <!-- *ngIf="trueData" [disabled]="!editProfileForm.valid && iinCheck.invalid && isValid" -->
    </div>
    