import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';


@Component({
  selector: 'app-nqt',
  templateUrl: './nqt.component.html',
  styleUrls: ['./nqt.component.css']
})
export class NqtComponent implements OnInit {

  appTypeList = [];

  constructor(private api: ApiService, public router: Router,
    private route: ActivatedRoute, public translate: TranslateService) { }

  ngOnInit(): void {


    this.api.getAppTypeNqt()
      .subscribe((res: any) => {
        this.appTypeList = res.items;
      },(err: any) => {

      });

  }

}
