import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class astConfig {
    certifBackend: any;
    astRoutes: any;
    satRoutes: any;
    ibRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.certifBackend = 'https://app.debug.testcenter.kz/ent/student/app/api/v1'; // Путь для localhost  
    }
    if (!isDevMode()) {
      this.certifBackend = 'ent/student/app/api/v1'; // Путь для боевого
    }

    this.astRoutes = { // ссылки для запросов
      getAppList: this.certifBackend + '/app/items',
      getTypesSertif: this.certifBackend + '/app/type/items',
      getProfilePair: this.certifBackend + '/app/ast/profile-subject-pair/items', 
      getForeignLangCert: this.certifBackend + '/app/ast/foreign-lang-cert/items', 
      appAstItems:  this.certifBackend + '/app/ast/items', 
    }

    this.satRoutes = { // ссылки для запросов
      getAppList: this.certifBackend + '/app/items',
      getTypesSertif: this.certifBackend + '/app/type/items',
      getForeignLangCert: this.certifBackend + '/app/sat/foreign-lang-cert/items', 
      appSatSubjectItems:  this.certifBackend + '/app/sat/subject/items', 
      appSatItems:  this.certifBackend + '/app/sat/items', 
      profileSubjectPair: this.certifBackend + '/app/sat/profile-subject-pair/items',
    }

    this.ibRoutes = {
      math: this.certifBackend + '/app/ib/subject/math/items',
      ibprofile: this.certifBackend + '/app/ib/subject/profile/items',
      ibforeign: this.certifBackend + '/app/ib/foreign-lang-cert/items',
      addib: this.certifBackend + '/app/ib/items',
      appIbSubjectItems:  this.certifBackend + '/app/ib/subject/items', 
      profileSubjectPair: this.certifBackend + '/app/ib/profile-subject-pair/items',
    }

  }
}
