<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<p class="text-3xl font-hairline text-blue-500 mb-4">{{ 'NewEmailText' | translate }}</p>
<form action="" (ngSubmit)="edit(5)" [formGroup]="changeEmail">
    <input formControlName="newEmail" placeholder="*{{ 'EmailReg' | translate }}" class="border rounded-md p-2 my-2 w-full border-black" type="text">
    <div *ngIf="newEmail.invalid && newEmail.touched" class="err-txt">
        *{{ 'FillInTheField' | translate }}
    </div>
    <show-hide-password class="hider" size="md" btnStyle="primary" [btnOutline]="false">
        <input formControlName="password" placeholder="*{{ 'Password' | translate }}" class="pass border rounded-md p-2 my-2 w-full border-black" type="password"  >
    </show-hide-password>
    <div *ngIf="password.invalid && password.touched" class="err-txt">
        *{{ 'FillInTheField' | translate }}
    </div>
    <div class="text-center mt-8">
        <button [routerLink]="'/profile/im'" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{ 'Cancel' | translate }}</button>
        <button type="submit" [disabled]="isValid" class="bg-green-500 ml-16 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">{{ 'Save' | translate }}</button>    
    </div>
</form>
 