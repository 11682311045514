import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../service/token.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  url = 'app-helper/api/v1/ent/konkurs';

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  public getEntKonkursData(body: any) {
    return this.http.post(
      `${this.url}/info`,
      body,
      this.tokenService.getHeaderAuth()
    );
  }

  public getForeignLangCert() {
    return this.http.get(
      `${this.url}/foreign-cert-type/items`,
      this.tokenService.getHeaderAuth()
    );
  }

  public calculateKonvertBall(body:any){
    return this.http.post(`${this.url}/calculate-konvert-ball`, body, this.tokenService.getHeaderAuth())
  }

  public getEntCertificate(body:any){
    return this.http.post(`${this.url}/certficate/items`, body, this.tokenService.getHeaderAuth())
  }

  public getSpec(body:any){
    return this.http.post(`${this.url}/spec/items`, body, this.tokenService.getHeaderAuth());
  }

  public getGosZakaz(body:any){
    return this.http.post(`${this.url}/gz/items`, body, this.tokenService.getHeaderAuth());
  }

  public getMinBall(body:any){
    return this.http.post(`${this.url}/min-ball/items`, body, this.tokenService.getHeaderAuth());
  }

  public getInstitute(body:any){
    return this.http.post(`${this.url}/institute/items`, body, this.tokenService.getHeaderAuth());
  }

  public getMinBallOblInstitute(body:any){
    return this.http.post(`${this.url}/min-ball-by-obl-and-institute`, body, this.tokenService.getHeaderAuth());
  }
}
