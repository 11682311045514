import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TokenService } from 'src/app/service/token.service';
import { TranslateService } from '@ngx-translate/core';
import { TimerService } from 'src/app/service/timer.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  auth = true;
  reg = false;
  loading = true;
  message = true;
  href: any;
  form: FormGroup;
  localToken: any;
  url: any;

  constructor(private route: ActivatedRoute, private router: Router, private timer: TimerService,
    private token: TokenService,
    public translate: TranslateService,
    private _notifications: NotificationsService, private api: ApiService) { }

  ngOnInit(): void {

    localStorage.setItem('projectLink', this.route.snapshot.queryParamMap.get('link'));

    if (localStorage.getItem('Lang') == 'null') {
      localStorage.setItem('Lang', 'ru')
    }

    if (localStorage.getItem('token') != 'null' && localStorage.getItem('isRegistrationComplete') != '0' && localStorage.getItem('projectLink') == null) {
      this.router.navigate(['/profile/im']);
    }

    if (this.router.url == '/auth/registration') {
      this.showRegistration();
    }

    this.createForm();

    setTimeout(() => {
      this.loading = false;
    }, 150);

  }

  private createForm(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });

  }
  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }

  submitForm() {
    //console.log(this.route.snapshot.queryParamMap.get('link'));
    if (window.navigator.onLine) {
      if (this.form.valid) {
        let obj = this.form.value;
        this.loading = true;
        this.api.auth(obj)
          .subscribe((res: any) => {
            let link = localStorage.getItem('projectLink');

            //console.log(this.route.snapshot.queryParamMap.get('link'));
            if (link != '' && link != 'null') {
              let url = localStorage.getItem('projectLink');
              window.location.href = url + '?token=' + res.data.token;
              // this.router.navigate([url, { queryParams: { token: res.data.token} }]);
            } else {
              if (res.data.isRegistrationComplete == false) {
                this.token.authorizeToken(res.data.token, '0');

                this.router.navigate(['/user-info']);
                this.loading = false;

              }
              if (res.data.isRegistrationComplete == true) {
                this.token.authorizeToken(res.data.token, '1');
                this.router.navigate(['/profile/applications']);
                // this.router.navigate(['/profile/applications']).then(()=>{
                //   window.location.reload();
                // });
                //this.router.navigate(['/page-error']);
                this.loading = false;

                this.timer.setInterval(res.data.pingTimeout);



              }
            }

          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.loading = false;
          });
      }
    } else {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'соединение потеряно', NotificationType.Error, 5000) : this._notifications.create('Қате', 'байланыс жоғалды', NotificationType.Error, 5000);
    }

  }

  showRegistration() {
    this.router.navigate(['auth/registration']);
    this.reg = true;
    this.auth = false;
  }
  showAuth() {
    this.router.navigate(['auth']);
    this.reg = false;
    this.auth = true;
  }

}
