import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-kaztest-results',
  templateUrl: './kaztest-results.component.html',
  styleUrls: ['./kaztest-results.component.css']
})
export class KaztestResultsComponent implements OnInit {

  @Input() app;

  loading: any;
  isCollapsed = true;

  constructor( 
    private api: ApiService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        console.log(res);
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
              .subscribe((res: any) => {
              let certifLink = res.data.certificateDownLoadUrl;
              window.open(certifLink, "_blank");
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

}
