import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DisabilityComponent } from 'src/app/components/profile/template/disability/disability.component';
import { PersonalInfoComponent } from 'src/app/components/profile/template/personal-info/personal-info.component';
import { TestOrgComponent } from 'src/app/components/profile/template/test-org/test-org.component';
import { ApiService } from 'src/app/service/api.service';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-e-doctoral-studies',
  templateUrl: './e-doctoral-studies.component.html',
  styleUrls: ['./e-doctoral-studies.component.css']
})
export class EDoctoralStudiesComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  pageLangId: any;
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOblList = [];
  testOrgList = [];
  testDateList = [];
  foreignLangCertCharScore: any;
  charList: any;
  studyTypeList: any;
  eduAreaList: any;
  masterTypeList: any;
  gopList: any;
  foreignLangList: any;
  profileDisciplines1List: any;
  profileDisciplines2List: any;
  showChars = false;
  showSubjects = false;
  vuzList: any[] = [];
  sertidTypeFloat = false;
  applicationId: any;
  shortInfo: any;
  incriment: number = 0;
  CSID: any;
  NID: any;
  fileIdToDelete: any;
  fileIdToDeleteGre: any;

  canEditOblId = false;
  prevEduDirection: any;
  prevEduArea: any;
  canEditRaionId = false;
  canEditSchoolId = false;
  kazTestList: any;
  tgoLangList: any;
  greEnable = false;

  @ViewChild(DisabilityComponent) disability: DisabilityComponent;
  @ViewChild(TestOrgComponent) testOrg: TestOrgComponent;
  @ViewChild(PersonalInfoComponent) person: PersonalInfoComponent;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }


  changeMasterType(event:any){
    this.getGop(event);
  }

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');



    // Инициализация формы
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(this.seasonId),
      appTypeId: new FormControl(this.appTypeId),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      foreignLangCertTypeId: new FormControl(0),
      kazTestCertificateId: new FormControl(0),
      foreignLangCertScore: new FormControl('0'),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      isHasDisability: new FormControl(0, Validators.required),
      disabilityId: new FormControl(0, Validators.required),
      masterTypeId: new FormControl(0, Validators.required),
      profileSubjectPairId: new FormControl(0),
      testLangId: new FormControl(0),
      tgoLangId: new FormControl(0),
      testCityId: new FormControl(0),
      testOrgId: new FormControl(0),
      oblId: new FormControl(0),
      prevEduDirectionId: new FormControl(0),
      prevEduAreaId: new FormControl(0),
      eduProgramId: new FormControl(0),
      eduAreaId: new FormControl(0),
      testPeriodId: new FormControl(0),
      profileSubject1Id: new FormControl(0),
      foreignLangId: new FormControl(0),
      eduInstituteId: new FormControl(0),
      docFiles: new FormArray([]),
      appFiles: new FormArray([]),
      isGreCertificateExists: new FormControl(false),
      gre: new FormGroup({
        registrationNumber: new FormControl(0),
        printDate: new FormControl(0),
        testDate: new FormControl(0),
        quantitativeReasoningScore: new FormControl(0),
        verbalReasoningScore: new FormControl(0),
        analyticalWritingScore: new FormControl(0)
      }),
      greCertificateFiles: new FormArray([]),
      grantMessage: new FormControl('')
    });

    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    this.getDataPvo();

  }

  getDataPvo() {
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.shortInfo = res.data;
        this.seasonId = this.shortInfo.seasonId;

        this.disability.getHelper(this.seasonId, this.appTypeId);
        this.person.getData(this.seasonId, this.appTypeId);
        this.testOrg.getCity(this.seasonId, this.appTypeId);
        this.applicationForm.patchValue(this.shortInfo);

        //this.getForeignLangCertList();
        this.getStudyType();
        this.getMasterType();
        this.getForeignLang();

        this.addFiles();
        this.addFilesGre();
        this.addFilesZayav();
        this.getGop(1);
        this.applicationForm.get('kazTestCertificateId').setValue(this.shortInfo.kazTestCertificateId);
        this.getTgoLang();
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);

        this.greEnable = this.shortInfo.isGreCertificateExists;
        if (this.greEnable) {
          this.applicationForm.get('isGreCertificateExists').patchValue(this.shortInfo.isGreCertificateExists);
          this.applicationForm.get('gre').get('registrationNumber').patchValue(this.shortInfo.gre.registrationNumber);
          this.applicationForm.get('gre').get('printDate').patchValue(this.shortInfo.gre.printDate);
          this.applicationForm.get('gre').get('testDate').patchValue(this.shortInfo.gre.testDate);
          this.applicationForm.get('gre').get('quantitativeReasoningScore').patchValue(this.shortInfo.gre.quantitativeReasoningScore);
          this.applicationForm.get('gre').get('verbalReasoningScore').patchValue(this.shortInfo.gre.verbalReasoningScore);
          this.applicationForm.get('gre').get('analyticalWritingScore').patchValue(this.shortInfo.gre.analyticalWritingScore);
        }

        if (this.shortInfo.citizenshipId != 0 && this.CSID == 0) {
          this.applicationForm.get('citizenshipId').patchValue(this.shortInfo.citizenshipId);
        }

        if (this.shortInfo.nationId != 0 && this.NID == 0) {
          this.applicationForm.get('nationId').patchValue(this.shortInfo.nationId);
        }

        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.shortInfo.foreignLangCertTypeId);

        this.applicationForm.get('foreignLangCertScore').setValue(this.shortInfo.foreignLangCertScore);

        // for (this.incriment; this.incriment < 1; this.incriment++) {
        //   this.applicationForm.get('foreignLangCertScore').patchValue(this.shortInfo.foreignLangCertScore);
        // }
        // this.applicationForm.get('sexId').setValue(this.shortInfo.sexId);
        this.applicationForm.get('phoneNumber').patchValue(this.shortInfo.phoneNumber);
      }, (err: any) => {

      });
  }

  getProfileDisciplines(id: any) {
    let index = document.forms[0].elements["gop"].selectedIndex;
    this.applicationForm.get('profileSubject1Id').setValue(0);
    this.applicationForm.get('profileSubject2Id').setValue(0);
    if (this.gopList[index - 1].id == id && this.gopList[index - 1].hasMultipleProfileSubjects) {
      this.showSubjects = true;
      this.api.getProfileSubjectPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLangId').value, 1)
        .subscribe((res: any) => {
          this.profileDisciplines1List = res.items;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });

      this.api.getProfileSubjectPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLangId').value, 2)
        .subscribe((res: any) => {
          this.profileDisciplines2List = res.items;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    } else {
      this.showSubjects = false;
    }



  }


  getForeignLang() {

    this.vuzList.forEach((e:any)=>{
      if (e.id == this.applicationForm.get('eduInstituteId').value) {
        if(!e.isGrantExists){

          this.applicationForm.get('grantMessage').setValue(this.translate.currentLang === 'ru' ? e.grantMessage.ru : e.grantMessage.kk);
        }else{
          this.applicationForm.get('grantMessage').setValue('');
        }
      }
    });

    this.api.getForeignLangPvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestLang() {
    this.api.getTestLangPhd(this.seasonId, this.appTypeId, this.applicationForm.get('masterTypeId').value, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('eduInstituteId').value)
      .subscribe((res: any) => {
        this.langList = res.items;

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTgoLang() {
    this.api.getTgoLangPvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.tgoLangList = res.items;
        this.applicationForm.get('tgoLangId').setValue(this.shortInfo.tgoLangId);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  gopChange(code: any) {
    this.gopList.forEach((e: any) => {
      if (e.id == this.applicationForm.get('eduProgramId').value) {
        if (!e.isGreAllowed) {
          this.applicationForm.get('isGreCertificateExists').setValue(false);
          this.greEnable = false;
        } else {
          this.greEnable = true;
        }

      }
    });

    if (code == 1) {
      this.applicationForm.get('testLangId').setValue(this.shortInfo.testLangId);
      this.getIntituteObl(this.shortInfo.eduProgramId, 1);

    } else {
      this.applicationForm.get('testLangId').setValue(0);
      this.getIntituteObl(this.applicationForm.get('eduProgramId').value, 0);
      this.langList = [];
      this.applicationForm.get('testLangId').setValue(0);
      this.applicationForm.get('eduInstituteId').setValue(0);
      this.applicationForm.get('oblId').setValue(0);
    }


  }

  getIntituteObl(gopId: number, code: number) {
    this.api.getEduInstituteOblPvo(this.seasonId, this.appTypeId, gopId, this.applicationForm.get('masterTypeId').value)
      .subscribe((res: any) => {
        this.oblList = res.items;
        if (code == 1) {
          this.applicationForm.get('oblId').setValue(this.shortInfo.eduInstituteOblId);
          this.getIntitute(1);
        } else {
          this.applicationForm.get('oblId').setValue(0);
        }

      }, (err: any) => {

      });
  }

  getIntitute(code: number) {
    this.api.getInstitutePvo(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('masterTypeId').value)
      .subscribe((res: any) => {
        this.vuzList = res.items;
        if (code == 1) {
          this.applicationForm.get('eduInstituteId').setValue(this.shortInfo.eduInstituteId);
          this.getTestLang();
        } else {
          this.applicationForm.get('eduInstituteId').setValue(0);
        }

      }, (err: any) => {

      });
  }

  langChang(){
    this.applicationForm.get('testLangId').setValue(0);
    this.getTestLang();
  }

  getMasterType() {
    this.api.getMasterTypePvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.masterTypeList = res.items;
        this.applicationForm.get('masterTypeId').setValue(this.shortInfo.masterTypeId);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getGop(code: any) {
    this.api.getGopPvo(this.seasonId, this.appTypeId, 0, this.applicationForm.get('masterTypeId').value)
      .subscribe((res: any) => {
        this.gopList = res.items;
        if (code == 1) {
          this.applicationForm.get('eduProgramId').setValue(this.shortInfo.eduProgramId);
          console.log(this.applicationForm.value);
          this.gopChange(1);
        } else {
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('testLangId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  clearDown() {
    this.applicationForm.get('eduProgramId').setValue(0);
    this.applicationForm.get('eduAreaId').setValue(0);
  }

  getEduArea(code: any) {
    if (code == 1) {
      this.applicationForm.get('prevEduDirectionId').setValue(this.shortInfo.prevEduDirectionId);
    }
    this.api.getEduAreaPvo(this.seasonId, this.appTypeId, 0, this.applicationForm.get('prevEduDirectionId').value)
      .subscribe((res: any) => {
        this.eduAreaList = res.items;
        if (code == 1) {
          this.applicationForm.get('eduProgramId').setValue(this.shortInfo.eduProgramId);
          this.applicationForm.get('eduAreaId').setValue(this.shortInfo.eduAreaId);
          this.getGop(1);
        } else {
          this.applicationForm.get('eduProgramId').setValue(0);
          this.applicationForm.get('eduAreaId').setValue(0);
        }

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getPrevEduArea() {
    this.api.getPrevEduArea(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.prevEduArea = res.items;
        this.applicationForm.get('prevEduAreaId').setValue(this.shortInfo.prevEduAreaId);
        this.getEduDirection(1);
      }, (err: any) => {

      });
  }



  getEduDirection(code: any) {
    if (code == 1) {
      this.applicationForm.get('prevEduAreaId').setValue(this.shortInfo.prevEduAreaId);
    }
    this.api.getPrevEduDirection(this.seasonId, this.appTypeId, this.applicationForm.get('prevEduAreaId').value)
      .subscribe((res: any) => {
        this.prevEduDirection = res.items;

        if (code == 1) {
          this.applicationForm.get('prevEduDirectionId').setValue(this.shortInfo.prevEduDirectionId);
        } else {
          this.clearDown();
          this.eduAreaList = [];
          this.gopList = [];
        }
      }, (err: any) => {

      });
  }

  getStudyType() {
    this.api.getStudyTypePvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.studyTypeList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  // getForeignLangCertList() {
  //   this.api.getForeignLangCertPvo(this.seasonId, this.appTypeId)
  //     .subscribe((res: any) => {
  //       this.foreignLangCertList = res.items;

  //       this.checkSertif(this.shortInfo.foreignLangCertTypeId);
  //     }, (err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //     });
  // }

  checkSertif(id:any) {
    this.foreignLangCertList.forEach(element => {
      if (element.isScoreInChars && element.id == id) {
        this.charList = element.availableValues;
        this.showChars = true;
      }
      if (!element.isScoreInChars && element.id == id) {
        this.showChars = false;
      }
      if (element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = true;
        this.scoreCheck();
        // this.foreignLangCertScoreMask = '0.0';
      }
      if (!element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = false;
        // this.foreignLangCertScoreMask = '000';
      }
    });
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }
  scoreCheck() {
    if (this.sertidTypeFloat == true && this.applicationForm.get('foreignLangCertScore').value > 9) {
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
    }
  }

  get files(): any { return this.applicationForm.get('docFiles') };
  get greFiles(): any { return this.applicationForm.get('greCertificateFiles') };
  get zayav(): any { return this.applicationForm.get('appFiles') };

  addFiles() {
    let obj = null;
    this.shortInfo.docFiles.forEach(element => {

      obj = this.files;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl('')
        })
      );

    });
  }

  addFilesZayav() {
    let obj = null;
    this.shortInfo.appFiles.forEach(element => {

      obj = this.zayav;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl('')
        })
      );

    });
  }

  addFilesGre() {
    let obj = null;
    this.shortInfo.greCertificateFiles.forEach(element => {

      obj = this.greFiles;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl('')
        })
      );

    });
  }

  postApplication() {
    this.loading = true;
    this.api.putAppItemsPvo(this.applicationId, this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        // if(res.data.paymentType.id == 2){
        //   this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id, type: 4 } });
        // }else{
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();
        }, 200);
        // }
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

}
