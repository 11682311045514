import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../helper.service';
import { forkJoin } from 'rxjs';

interface AdditionalModel {
  ikt:number;
  studyFormId:number;
  eduProgramId:number;
  tvorScore:number;
  score:number;
}

interface InstituteModel{
  ikt:number;
  instituteId:number;
  studyFormId:number;
  tvorScore:number;
  score:number;
}


@Component({
  selector: 'app-konkurs-enroll',
  templateUrl: './konkurs-enroll.component.html',
  styleUrls: ['./konkurs-enroll.component.css'],
})
export class KonkursEnrollComponent implements OnInit {
  enrolls = [
    {
      id: 1,
      label: {
        kk: 'ТЖК',
        ru: 'ИКТ',
      },
      value: 0,
    },
    {
      id: 2,
      label: {
        kk: 'Бес пән бойынша конкурсқа қатысу үшін балл сомасы',
        ru: 'Сумма баллов для участия в конкурсе по пяти предметам',
      },
      value: 0,
    },
    {
      id: 3,
      label: {
        kk: 'Шығармашылық емтихан бойынша конкурсқа қатысу үшін балл сомасы',
        ru:'Сумма баллов для участия в конкурсе с учетом творческих экзаменов'
      },
      value: 0,
    },
    {
      id: 4,
      label: {
        kk: 'Мүмкін болатын мамандықтар саны',
        ru:'Количество возможных специальностей'
      },
      value: 0,
    },
    {
      id: 5,
      label: {
        kk: 'Мүмкін болатын ЖОО саны',
        ru:'Количество возможных ВУЗов'
      },
      value: 0,
    },
  ];

  tabs = [
    {
      label: {
        kk: 'Мамандық бойынша',
        ru:'По специальностям'
      },
      value: 1,
    },
    {
      label: {
        kk: 'ЖОО бойынша',
        ru:'По ВУЗам'
      },
      value: 2,
    },
  ];

  activeTab = 1;
  info: any;
  spec: any[];

  selectedSpec:any;

  gosZakaz: any[];
  minBall:any[];
  loading = false;

  oblInstitutes:any[];

  obls:any[];
  institutes:any[];
  selectedInstitute:any;
  selectedObl:any;

  minBallOblInstitute:any[];

  constructor(public translate: TranslateService, private service: HelperService) {
    const data = sessionStorage.getItem('helpInfo');
    if(data){
      this.info = JSON.parse(data);
      this.enrolls[0].value=this.info.ikt;
      this.enrolls[1].value=this.info.konkursScore;
      this.enrolls[2].value=this.info.tvorScore
    }
  }

  ngOnInit(): void {
    if(this.info){
      const body = {
        ikt: this.info.ikt,
        studyFormId: +this.info.studyFormId
      }
      this.service.getSpec(body).subscribe({
        next: (res:any)=>{
          this.spec = res
          if(this.spec.length > 0){
            this.enrolls[3].value = this.spec.length;
          }
        }
      });
      this.service.getInstitute(body).subscribe({
        next:(res:any)=>{
          this.oblInstitutes = [...res];
          this.obls = [...res];
          this.obls = [...new Map(this.obls.map(item => [item.out_obl_i, item])).values()];

          this.obls = this.obls.sort((a, b) => a.out_obl_name_rus.localeCompare(b.out_obl_name_rus));
        }
      })
    }
  }




  changeSpec(e:any){
    const body: AdditionalModel = {
      ikt: +this.info.ikt,
      studyFormId: +this.info.studyFormId,
      eduProgramId: +e,
      tvorScore: +this.info.tvorScore,
      score: +this.info.konkursScore
    }
    this.loading = true;

    forkJoin({
      gosZakaz: this.service.getGosZakaz(body),
      minBall: this.service.getMinBall(body)
    }).subscribe({
      next: (results:any) => {
        // Assign the results to respective properties
        this.gosZakaz = results.gosZakaz;
        this.minBall = results.minBall;
      },
      error: (err) => {
        // Handle any errors here
        console.error(err);
        this.loading = false;
      },
      complete: () => {
        // Set loading to false after both requests have completed
        this.loading = false;

        this.enrolls[4].value = this.minBall.length;
      }
    });
  }

  changeObl(oblId:number){
    this.selectedInstitute = undefined;
    this.minBallOblInstitute = [];
    this.institutes = [...this.oblInstitutes];
    this.institutes = this.institutes.filter((e:any)=> +e.out_obl_i === +oblId);

  }

  changeInstitute(item:any){
    if(!this.selectedInstitute || !this.selectedObl){
      return;
    }

    const body: InstituteModel = {
      ikt: this.info.ikt,
      instituteId: +this.selectedInstitute.out_i_id,
      studyFormId: +this.info.studyFormId,
      tvorScore: this.info.tvorScore,
      score: this.info.konkursScore
    }
    this.loading = true;
    this.service.getMinBallOblInstitute(body).subscribe({
      next:(res:any)=>{
        this.minBallOblInstitute = res;
      },
      complete:()=>{
        this.loading = false;
      },error:(err:any)=>{
        this.loading = false;
        console.log(err);
      }
    });
  }

}
