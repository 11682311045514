import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { PersonalInfoComponent } from 'src/app/components/profile/template/personal-info/personal-info.component';
import { TestOrgComponent } from 'src/app/components/profile/template/test-org/test-org.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-voen',
  templateUrl: './e-voen.component.html',
  styleUrls: ['./e-voen.component.css']
})
export class EVoenComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  pageLangId: any;

  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOblList = [];
  testOrgList = [];
  testDateList = []


  foreignLangCertCharScore: any;
  charList: any;
  studyTypeList: any;
  eduAreaList: any;
  masterTypeList: any;
  gopList: any;
  foreignLangList: any;
  profileDisciplines1List: any;
  profileDisciplines2List: any;
  showChars = false;
  showSubjects = false;
  vuzList = [];
  applicationId: any;
  shortInfo: any;
  CSID: any;
  NID: any;
  incriment = 0;
  sertidTypeFloat = false;

  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;

  @ViewChild(TestOrgComponent) testOrg: TestOrgComponent;
  @ViewChild(PersonalInfoComponent) person: PersonalInfoComponent;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

      // Инициализация формы
      this.applicationForm = new FormGroup({
        seasonId: new FormControl(this.seasonId),
        appTypeId: new FormControl(this.appTypeId),
        citizenshipId: new FormControl(0, Validators.required),
        nationId: new FormControl(0, Validators.required),
        foreignLangCertTypeId: new FormControl(0),
        foreignLangCertScore: new FormControl('0'),
        phoneNumber: new FormControl(0, Validators.required),
        sexId: new FormControl(0, Validators.required),
        isHasDisability: new FormControl(0, Validators.required),
        disabilityId: new FormControl(0, Validators.required),
        studyTypeId: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0),
        testLangId: new FormControl(0),
        testCityId: new FormControl(0),
        oblId: new FormControl(0),
        eduProgramId: new FormControl(0),
        eduInstituteId: new FormControl(0),
        testPeriodId: new FormControl(0),
        profileSubject1Id: new FormControl(0),
        profileSubject2Id: new FormControl(0),
        foreignLangId: new FormControl(0),
      });
    
      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }

      this.getDataPvo();

  }

  getProfileDisciplines(id: any){
    let index = document.forms[0].elements["gop"].selectedIndex;
    this.applicationForm.get('profileSubject1Id').setValue(0);
    this.applicationForm.get('profileSubject2Id').setValue(0);
      if(this.gopList[index-1].id == id && this.gopList[index-1].hasMultipleProfileSubjects){
        this.showSubjects = true;
        this.api.getProfileSubjectPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLangId').value, 1)
          .subscribe((res: any) => {
            this.profileDisciplines1List = res.items;
          },(err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });

        this.api.getProfileSubjectPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLangId').value, 2)
          .subscribe((res: any) => {
            this.profileDisciplines2List = res.items;
          },(err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }else{
        this.showSubjects = false;
      }
 
    
    
  }

  getDataPvo(){
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.shortInfo = res.data;
        this.seasonId = this.shortInfo.seasonId;

        this.person.getData(this.seasonId, this.appTypeId);
        this.testOrg.getCity(this.seasonId, this.appTypeId);

        this.getForeignLangCertList();
        this.getStudyType();
        this.getMasterType();
        this.getEduArea();
        this.getForeignLang();
        this.getIntitute();
        this.applicationForm.get('testCityId').patchValue(this.shortInfo.testCityId);
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);
        
        if(this.shortInfo.citizenshipId != 0 && this.CSID == 0){
          this.applicationForm.get('citizenshipId').patchValue(this.shortInfo.citizenshipId);
        }

        if(this.shortInfo.nationId != 0 && this.NID == 0){
          this.applicationForm.get('nationId').patchValue(this.shortInfo.nationId);
        }
        
        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.shortInfo.foreignLangCertTypeId);
        this.applicationForm.get('foreignLangCertScore').patchValue(this.shortInfo.foreignLangCertScore);
        
        for (this.incriment; this.incriment < 1; this.incriment++) {
          this.applicationForm.get('foreignLangCertScore').patchValue(this.shortInfo.foreignLangCertScore);
        }
        // this.applicationForm.get('sexId').setValue(this.shortInfo.sexId);
        this.applicationForm.get('phoneNumber').patchValue(this.shortInfo.phoneNumber);
      },(err: any) => {

      });
  }

  getForeignLang(){
    this.api.getForeignLangPvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangList = res.items;
        this.applicationForm.get('foreignLangId').patchValue(this.shortInfo.foreignLangId);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getIntituteObl(){
    this.api.getEduInstituteOblPvo(this.seasonId, this.appTypeId, 0, 0)
      .subscribe((res: any) => {
        this.oblList = res.items;
      }, (err: any) => {

      });
  }

  getIntitute(){
    this.api.getInstitutePvo(this.seasonId, this.appTypeId, 0, 0, 0)
      .subscribe((res: any) => {
        this.vuzList = res.items;
        this.applicationForm.get('eduInstituteId').patchValue(this.shortInfo.eduInstituteId);
      }, (err: any) => {

      });
  }

  getTestLang(){
    // console.log(document.forms[0].elements["gop"].selectedIndex)
    this.api.getTestLangPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
    .subscribe((res: any) => {
      this.langList = res.items;
      this.applicationForm.get('testLangId').setValue(0);
    },(err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
    });
  }

  getMasterType(){
    this.api.getMasterTypePvo(this.seasonId, this.appTypeId)
    .subscribe((res: any) => {
      this.masterTypeList = res.items;
    },(err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
    });
  }

  getGop(){
    this.api.getGopPvo(this.seasonId, this.appTypeId, this.applicationForm.get('eduAreaId').value, 0)
      .subscribe((res: any) => {
        this.gopList = res.items;
        this.applicationForm.get('eduProgramId').setValue(0);
        this.applicationForm.get('testLangId').setValue(0);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getEduArea(){
    this.api.getEduAreaPvo(this.seasonId, this.appTypeId, 0,0)
      .subscribe((res: any) => {
        this.eduAreaList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getStudyType(){
    this.api.getStudyTypePvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.studyTypeList = res.items;
        this.applicationForm.get('studyTypeId').patchValue(this.shortInfo.studyTypeId);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }



  getForeignLangCertList(){
    this.api.getForeignLangCertPvo(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  checkSertif(id:any){
    this.foreignLangCertList.forEach(element => {
      if(element.isScoreInChars && element.id == id){
        this.charList = element.availableValues;
        this.showChars = true;
      }
      if(!element.isScoreInChars && element.id == id){
        this.showChars = false;
      }
      if(element.isScoreFloat && element.id == id){
        this.sertidTypeFloat = true;
        // this.foreignLangCertScoreMask = '0.0';
     }
     if(!element.isScoreFloat && element.id == id){
        this.sertidTypeFloat = false;
        // this.foreignLangCertScoreMask = '000';
      }
    });
    if(id == 0){
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck(){
    if(this.sertidTypeFloat == true && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);  
    }
  }


  postApplication(){
    this.scoreCheck();
    this.loading = true;
    this.api.putAppItemsPvo(this.applicationId, this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        // if(res.data.paymentType.id == 2){
        //   this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id, type: 4 } });
        // }else{
          this.router.navigateByUrl('/profile/history');
          setTimeout(() => {
            window.location.reload();          
          }, 200);
        // }
      },(err: any) =>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

}