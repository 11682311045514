<div [formGroup]="applicationForm">
  <div class="flex mb-4 mt-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "greCertificate" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>

  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
    <div class="md:col-span-full">
      <div class="inline-flex items-center gap-x-3">
        <input
          type="checkbox"
          name="gre"
          id="gre"
          class="form-checkbox"
          formControlName="isGreCertificateExists"
          #isGre
        />
        <label for="gre">{{ "hasGRE" | translate }}</label>
      </div>
    </div>
  </div>
  <div *ngIf="isGre.checked">
    <div
      class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4"
      [formGroup]="applicationForm.get('gre')"
    >
      <div class="md:col-span-4">
        <label for="greRegistrationNumber">{{
          "greRegistrationNumber" | translate
        }}</label>
        <input
          type="number"
          name="greRegistrationNumber"
          id="greRegistrationNumber"
          formControlName="registrationNumber"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
      <div class="md:col-span-2">
        <label for="grePrintDate">{{ "grePrintDate" | translate }}</label>
        <input
          type="date"
          name="grePrintDate"
          id="grePrintDate"
          formControlName="printDate"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
      <div class="md:col-span-full">
        <p class="text-gray-600 font-semibold flex flex-col">
          {{ "greSectionScores" | translate }}
        </p>
      </div>
      <div class="md:col-span-3">
        <label for="greQuantitativeReasoning">{{
          "greQuantitativeReasoning" | translate
        }}</label>
        <input
          type="number"
          name="greQuantitativeReasoning"
          id="greQuantitativeReasoning"
          formControlName="quantitativeReasoningScore"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
      <div class="md:col-span-3" *ngIf="applicationForm.get('masterTypeId').value != 1">
        <label for="greVerbalReasoning">{{
          "greVerbalReasoning" | translate
        }}</label>
        <input
          type="number"
          name="greVerbalReasoning"
          id="greVerbalReasoning"
          formControlName="verbalReasoningScore"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
      <div class="md:col-span-3">
        <label for="greAnalyticalWriting">{{
          "greAnalyticalWriting" | translate
        }}</label>
        <input
          type="number"
          name="greAnalyticalWriting"
          id="greAnalyticalWriting"
          formControlName="analyticalWritingScore"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
      <div class="md:col-span-3">
        <label for="greTestDate">{{ "greTestDate" | translate }}</label>
        <input
          type="date"
          name="greTestDate"
          id="greTestDate"
          formControlName="testDate"
          class="h-8 border mt-1 rounded px-2 w-full bg-gray-200"
          value=""
          placeholder=""
        />
      </div>
    </div>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="md:col-span-full">
        <p class="italic">{{ "CopySertif" | translate }}</p>
        <div class="modal-window mt-3 p-2 bg-white rounded-sm shadow-lg">
          <div class="flex">
            <button
              type="button"
              class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              (click)="addFileGre()"
            >
              {{ "addFile" | translate }}
            </button>
          </div>
          <span class="add-files-scroll">
            <div
              formArrayName="greCertificateFiles"
              class="w-full mt-4 controls-border"
              *ngFor="let item of greFiles.controls; index as i"
            >
              <div [formGroup]="greFiles.controls[i]">
                <span>
                  {{ "NameFile" | translate }}:
                  <input
                    disabled
                    class="mb-4 p-2 w-full"
                    type="text"
                    readonly
                    formControlName="filename"
                /></span>
                <div class="f-btn-group">
                  <div class="relative">
                    <input
                      (change)="changeListenerGre($event, i, 'edit')"
                      class="w-full absolute top-0 left-0 h-full opacity-0 mb-4"
                      type="file"
                    />
                    <div
                      class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                    >
                      <p>
                        <span *ngIf="dwnldCheckGre(i); else elseBlock">
                          {{ "ReplaceFile" | translate }}</span
                        >
                        <ng-template #elseBlock
                          ><span>
                            {{ "UploadFile" | translate }}</span
                          ></ng-template
                        >
                      </p>
                    </div>
                    <p
                      *ngIf="!dwnldCheckGre(i)"
                      class="text-red-500 text-sm text-left"
                    >
                      * {{ "FileNotAdd" | translate }}
                    </p>
                  </div>
                </div>
                <div class="s-btn-group">
                  <button
                    type="button"
                    title="Удалить файл"
                    class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    (click)="removeFileGre(i)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="trash"
                      class="svg-inline--fa fa-trash fa-w-14"
                      role="img"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
