<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<p
  class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4"
>
  {{ "Editing" | translate }}
</p>

<form [formGroup]="applicationForm">
  <div class="min-h-screen mt-10 flex justify-center">
    <div class="max-w-screen-lg">
      <personal-info [(applicationForm)]="applicationForm"></personal-info>
      <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
        <div
          class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
        >
          {{ "TestParams" | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      </div>

      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="md:col-span-3">
          <label for="studyTypeId">{{ "EduLevel" | translate }}</label>
          <select
            id="studyTypeId"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="studyTypeId"
          >
            <option disabled [value]="0">
              {{ "EduLevel" | translate }}
            </option>
            <option *ngFor="let item of studyTypeList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>

        <div class="md:col-span-3">
          <label for="eduInstituteId">{{ "VuzName" | translate }}</label>
          <select
            id="eduInstituteId"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="eduInstituteId"
          >
            <option disabled [value]="0">
              {{ "VuzName" | translate }}
            </option>
            <option *ngFor="let item of vuzList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>
        <div class="md:col-span-3">
          <label for="foreignLangId">{{ "ForeignLang" | translate }}</label>
          <select
            id="foreignLangId"
            class="block bg-gray-200 mt-1 h-8 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            formControlName="foreignLangId"
          >
            <option disabled [value]="0">
              {{ "ForeignLang" | translate }}
            </option>
            <option *ngFor="let item of foreignLangList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"
                >{{ item.name.kk }}
              </span>
              <span *ngIf="translate.currentLang == 'ru'"
                >{{ item.name.ru }}
              </span>
            </option>
          </select>
        </div>
      </div>

      <test-org-template
        [(applicationForm)]="applicationForm"
      ></test-org-template>

      <button
        (click)="postApplication()"
        class="bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8"
      >
        {{ "PostApplication" | translate }}
      </button>
    </div>
  </div>
</form>
