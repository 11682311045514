import { Component, OnInit, isDevMode } from '@angular/core';
import { ApiService } from '../../../service/api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

  certifList: any;
  isDev = isDevMode();

  constructor(private api: ApiService, public router: Router,
    public translate: TranslateService, private aroute:ActivatedRoute) { }

  ngOnInit(): void {

    // this.api.getAstAppTypeItems()
    //   .subscribe((res: any) => {
    //     this.certifList = res.items;
    //   },(err: any) => {
    //   });
    
  }

}
