import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-konkurs-analytics',
  templateUrl: './konkurs-analytics.component.html',
  styleUrls: ['./konkurs-analytics.component.css']
})
export class KonkursAnalyticsComponent implements OnInit {

  analytics = [
    {
      id: 1,
      label: {
        kk: 'ТЖК',
        ru: 'ИКТ',
      },
      value: 0,
    },
    {
      id: 2,
      label: {
        kk: 'Бес пән бойынша конкурсқа қатысу үшін балл сомасы',
        ru: 'Сумма баллов для участия в конкурсе по пяти предметам',
      },
      value: 0,
    },
    {
      id: 3,
      label: {
        kk: 'Шығармашылық емтихан бойынша конкурсқа қатысу үшін балл сомасы',
        ru:'Сумма баллов для участия в конкурсе с учетом творческих экзаменов'
      },
      value: 0,
    },
  ]

  tabs = [
    {
      label: {
        kk: 'Күндізгі толық оқу нысаны',
        ru:'Очная полная форма обучения'
      },
      value: 1,
    },
    {
      label: {
        kk: 'Күндізгі қысқартылған оқу нысаны',
        ru:'Очная сокращенная форма обучения'
      },
      value: 2,
    },
  ];

  activeTab = 1;

  specialities = [];
  selectedSpec: any[];

  constructor(public translate:TranslateService) {
    const data = sessionStorage.getItem('helpInfo');
    if(data){
      const info = JSON.parse(data);
      this.analytics[0].value=info.ikt;
      this.analytics[1].value=info.konkursScore;
      this.analytics[2].value=info.tvorScore
    }
  }

  ngOnInit(): void {
  }


}
