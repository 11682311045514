import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }

  getAuth(): boolean {
    let token = localStorage.getItem('token');
    if (token != null || token != undefined) {
      return true;
    } else {
      return false;
    }
  }

  getInterval(): number {
    let interval = sessionStorage.getItem('intervalCheck');
    if (interval != null || interval != undefined) {
      return JSON.parse(interval);
    } else {
      return 0;
    }
  }

  setInterval(interval: string) {
    sessionStorage.setItem('intervalCheck', interval)
  }

}
