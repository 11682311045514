<div class="no-data" *ngIf="showErr">
    {{ 'EntKonkursErrorData' | translate }}
</div>
<div *ngFor="let app of appList; index as i" class="bg-blue-100 border-blue-500 border-2 rounded mb-4 p-3">
    <div class="flex mb-2 w-full justify-between">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>
        <div class="text-lg font-bold uppercase"
            [ngClass]="{'text-green-400': app.status.id != 4, 'text-red-400': app.status.id == 4}">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.status.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.status.name.ru }}</span>
        </div>
    </div>
    <div class="flex w-full justify-between test-type-name">
        <div class="py-2 font-bold w-1/2">
            <!-- <span *ngIf="translate.currentLang == 'kz'">{{ app.konkursType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.konkursType.name.ru }}</span> -->
        </div>
        <div class="py-2 font-bold">
            {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
    </div>
    <div class="px-3 text-gray-600" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">
              <div *ngIf=" app.showGrantData == true" class="mb-2 text-xl text-center uppercase">
                <span class="grant-text" *ngIf="app.hasGrant == true">Решением Республиканской конкурсной комиссии Вам присужден образовательный грант</span>
                <span class="grant-text-no" *ngIf="app.hasGrant == false">Решением Республиканской конкурсной комиссии Вам не присужден образовательный грант</span>
              </div>
                <div class="mb-2">
                    {{ 'IKT' | translate }} : {{ app.formattedId }}
                </div>

                <div class="mb-2">
                    {{ 'IIN' | translate }} : {{ app.iin}}
                </div>

                <div class="mb-2">
                    {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                </div>

                <div class="mb-2">
                    {{ 'PzDate' | translate }} : {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
                </div>

                <div class="mb-2">
                    {{ 'NctBaseDate' | translate }} :  {{ app.receivedByNtcAt*1000 | date: 'dd.MM.yyy H:mm' }}
                </div>

                <div class="mb-2">
                    {{ 'SourceZayav' | translate }} :
                    <span *ngIf="translate.currentLang == 'kz'">{{ app.konkursAppSource.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ app.konkursAppSource.name.ru }}</span>
                </div>

                <div class="mb-2">
                    {{ 'ApplicationStatus' | translate }} :
                    <span *ngIf="translate.currentLang == 'kz'">{{ app.status.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ app.status.name.ru }}</span>
                </div>

                <div class="mb-1">
                    {{ 'Diplom3' | translate }} - {{ app.diploma3Count }}
                </div>

                <div class="mb-1">
                    {{ 'Diplom4' | translate }} - {{ app.diploma4Count }}
                </div>

                <div class="mb-1">
                    {{ 'Diplom5' | translate }} - {{ app.diploma5Count }}
                </div>

                <div *ngIf="app.foreignLangCertificates" class="mb-1">
                    <div *ngFor="let cert of app.foreignLangCertificates" class="">
                        <div class="mb-1">
                            {{ 'InternationalEnglishCertificate' | translate }} -
                            <span *ngIf="translate.currentLang == 'kz'">{{ cert.name.kk }}</span>
                            <span *ngIf="translate.currentLang == 'ru'">{{ cert.name.ru }}</span> - балл {{ cert.score }}
                        </div>

                        <div class="mb-1">
                            {{ 'TestDate' | translate }} - {{ cert.testDate }}
                        </div>
                        <div>
                            {{'regNumberForeignCert' | translate}} - {{cert.regNumber}}
                        </div>

                    </div>
                </div>

                <div class="mb-1">
                    {{ 'StudyForm' | translate }} -
                    <span *ngIf="translate.currentLang == 'kz'">{{ app.studyForm.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ app.studyForm.name.ru }}</span>
                </div>

                <div class="">
                    <div class="font-bold uppercase text-lg text-center my-2">
                        {{ 'SelectedGOP' | translate }}
                    </div>
                    <table *ngFor="let choise of app.choices, let i = index" class="table table-bordered">
                        <tr class="text-gray-600 font-bold ">
                            <td colspan="2" [ngClass]="{'green-cell': choise.hasGrant}" class="bg-gray-200">
                               {{'Choice' | translate }} {{choise.choiceOrder}}
                            </td>
                        </tr>
                        <tr class="text-gray-600">
                            <td colspan="2">
                                <span class="font-bold">{{'isAgreeWithDiffGrant' | translate}} :</span>
                                <span *ngIf="translate.currentLang == 'kz'">
                                     {{ choise.isAgreeWithDiffGrant.name.kk }}
                                </span>
                                <span *ngIf="translate.currentLang == 'ru'">
                                     {{ choise.isAgreeWithDiffGrant.name.ru }}
                                </span>
                            </td>
                        </tr>
                        <tr class="text-gray-600">
                            <td colspan="2">
                                <span class="font-bold">{{'GOP' | translate}} :</span>
                                {{ choise.eduProgram.code }} -
                                <span *ngIf="translate.currentLang == 'kz'">{{ choise.eduProgram.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ choise.eduProgram.name.ru }}</span>
                            </td>

                        </tr>
                        <tr class="text-gray-600">
                            <td colspan="2">
                                <span class="font-bold">{{'VuzNames' | translate}}</span>
                            </td>
                        </tr>
                        <tr *ngFor="let item of choise.institutes" class="text-gray-600">
                            <td colspan="2">
                                {{ item.id }} -
                                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                            </td>
                        </tr>
                      <tr *ngIf="choise.hasGrant == true && app.showGrantData == true" class="text-gray-600">
                        <td colspan="2">
                          <span class="font-bold">{{'Квота' | translate}}</span>
                        </td>
                      </tr>
                      <tr *ngIf="choise.hasGrant == true && app.showGrantData == true" class="text-gray-600">
                        <td  colspan="2">
                          <span *ngIf="translate.currentLang == 'kz'">{{ choise.quotaName.kk }}</span>
                          <span *ngIf="translate.currentLang == 'ru'">{{ choise.quotaName.ru }}</span>
                        </td>
                      </tr>
                    </table>
                </div>

                <div class="">
                    <div class="font-bold uppercase text-lg text-center my-2">
                        {{ 'Kvoti' | translate }}
                    </div>
                    <table class="table table-bordered text-gray-600">
                        <tr>
                            <td>
                                {{ "HasAltynBelgi" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.hasAltynBelgi.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.hasAltynBelgi.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "HasAwardingPatriotism" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.hasAwardingPatriotism.name.kk
                                    }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.hasAwardingPatriotism.name.ru
                                    }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "HasPerfectDiploma" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.hasPerfectDiploma.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.hasPerfectDiploma.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "HasRuralQuota" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.hasRuralQuota.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.hasRuralQuota.name.ru }}</span>
                            </td>
                        </tr>
<!--                        <tr>-->
<!--                            <td>-->
<!--                                {{ "HasSerpinQuota" | translate }}-->
<!--                            </td>-->
<!--                            <td class="text-center w-1/12">-->
<!--                                <span *ngIf="translate.currentLang == 'kz'">{{ app.hasSerpinQuota.name.kk }}</span>-->
<!--                                <span *ngIf="translate.currentLang == 'ru'">{{ app.hasSerpinQuota.name.ru }}</span>-->
<!--                            </td>-->
<!--                        </tr>-->
                        <tr>
                            <td>
                                {{ "IsFamWithInvalid" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isFamWithInvalid.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isFamWithInvalid.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsLargeFam" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isLargeFam.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isLargeFam.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsNotFullFam" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isNotFullFam.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isNotFullFam.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsOrphan" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isOrphan.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isOrphan.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsTipo" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isTipo.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isTipo.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsWarVeteran" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isWarVeteran.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isWarVeteran.name.ru }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ "IsInvalid2" | translate }}
                            </td>
                            <td class="text-center w-1/12">
                                <span *ngIf="translate.currentLang == 'kz'">{{ app.isInvalid.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ app.isInvalid.name.ru }}</span>
                            </td>
                        </tr>
                    </table>
                  <div *ngIf="app.showGrantData == true && app.hasGrant == true" class="justify-end flex w-full zayav-buttons">
                    <button (click)="checkGrant(app.studentTestId, app.iin, app.konkursSeason.konkursYear, 1)" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                      {{ 'Получить свидетельство' | translate }}
                    </button>
                  </div>
                </div>



                <div *ngIf="app.olympiads" class="">
                    <div class="font-bold uppercase text-lg text-center my-2">
                        {{ "Olympiads" | translate }}
                    </div>
                    <table class="table table-bordered">
                        <tr class="text-gray-600 text-center">
                            <td>
                                {{ "OlympiadsName" | translate }}
                            </td>
                            <td>
                                {{ 'Subject' | translate }}
                            </td>
                        </tr>
                        <tr *ngFor="let item of app.olympiads" class="text-gray-600">
                            <td class="">
                                <span *ngIf="translate.currentLang == 'kz'">{{ item.olympiadName.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ item.olympiadName.ru }}</span>
                            </td>
                            <td>
                                <span *ngIf="translate.currentLang == 'kz'">{{ item.olympiadSubjectName.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ item.olympiadSubjectName.ru }}</span>
                            </td>
                        </tr>
                    </table>


                </div>

                <div class="">
                    <div class="">
                        <button (click)="uvedomlenie(app.annulmentDocLink)" *ngIf="app.annulmentDocLink.length > 0"
                            class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                            {{ 'Uvedomlenie' | translate }}
                        </button>
                    </div>
                    <div class="">

                    </div>
                </div>

            </div>
        </div>
    </div>
    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
        class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z" />
        </svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z" />
        </svg>
    </div>
</div>
