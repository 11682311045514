import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { NgxMaskModule } from 'ngx-mask';
import { ApiService } from 'src/app/service/api.service';
import {DoctoralStudiesComponent} from "../../applications/pvo/doctoral-studies/doctoral-studies.component";

@Component({
  selector: 'personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css'],
})
export class PersonalInfoComponent implements OnInit {
  @Input() applicationForm: FormGroup;
  @Input() isForeignLang: boolean = false;
  @Input() isCertKaztest: boolean = false;
  @Output() changeMasterType = new EventEmitter<number>();

  nationsList = [];
  countryList = [];
  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  phone = '+0 (000) 000-00-00';
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  seasonId: any;
  appTypeId: any;
  masterTypeList: any;
  gopList: any;
  email: any;
  kazTestLists = [];
  foreignLangCertList = [];
  charList: any;
  needToPassForeignLang = false;
  greEnable = false;
  showChars = false;
  sertidTypeFloat = false;
  foreignLangCertScoreMask: string = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  kazTestList: any;


  constructor(
    private api: ApiService,
    public translate: TranslateService,
    private _notifications: NotificationsService
  ) {}

  ngOnInit(): void {
    this.api.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      if (res.data.sexId == 0) {
        this.canEditSexId = true;
      }
      if (res.data.nationId == 0 || res.data.nationId == 99) {
        this.canEditNationId = true;
      }
      if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
        this.canEditCitizenshipId = true;
      }
      if (res.data.phoneNumber == 0) {
        this.canEditPhoneNumber = true;
      }
      this.applicationForm.get('sexId').patchValue(res.data.sexId);
      this.applicationForm.get('isHasDisability').patchValue(0);
      this.applicationForm
        .get('citizenshipId')
        .patchValue(res.data.citizenshipId);
      this.applicationForm.get('nationId').patchValue(res.data.nationId);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
    });
  }

  getData(seasonId: number, appTypeId: number) {
    this.foreignLangCertList.forEach((e:any)=>{
      if (e.id == this.applicationForm.get('foreignLangCertTypeId').value) {
        if(!e.isAdditionalExamRequired){

          this.applicationForm.get('grantMessages').setValue(this.translate.currentLang === 'ru' ? e.grantMessages.ru : e.grantMessages.kk);
        }else{
          this.applicationForm.get('grantMessages').setValue('');
        }
      }
    });
    this.api.getNationPvo(seasonId, appTypeId).subscribe(
      (res: any) => {
        this.nationsList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );

    this.api.getCountryPvo(seasonId, appTypeId).subscribe(
      (res: any) => {
        this.countryList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
          : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
      }
    );

    if (this.isCertKaztest) {
      this.api.getKaztestCertificate(seasonId, appTypeId).subscribe(
        (res: any) => {
          this.kazTestList = res.items;
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
    }
    if (this.isForeignLang) {

      this.api.getForeignLangCertPvo(seasonId, appTypeId).subscribe(
        (res: any) => {
          this.foreignLangCertList = res.items;
          this.checkSertif(
            this.applicationForm.get('foreignLangCertTypeId').value, 0
          );
        },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
                'Ошибка',
                err.error.errorMessage.ru,
                NotificationType.Error,
                5000
              )
            : this._notifications.create(
                'Қате',
                err.error.errorMessage.kk,
                NotificationType.Error,
                5000
              );
        }
      );
    }
  }

   getGop(event:any) {
      this.changeMasterType.emit(event.target.value);
   }

  // visible(e: any){
  //  this.masterTypeList.forEach((elem: any) => {
  //   if(elem.id == e){
  //     this.needToPassForeignLang = elem.needToPassForeignLang
  //   }
  //  })
  //
  // }

  checkSertif(id: any, code: number) {
    if(code == 1){
      this.applicationForm.get('foreignLangCertScore').patchValue('');
    }
    this.foreignLangCertList.forEach((element) => {
      if (element.isScoreInChars && element.id == id) {
        this.charList = element.availableValues;
        this.showChars = true;
      }
      if (!element.isScoreInChars && element.id == id) {
        this.showChars = false;
      }
      if (element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = true;
      }
      if (!element.isScoreFloat && element.id == id) {
        this.sertidTypeFloat = false;
      }
    });
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue('');
    }
  }

  // scoreCheck() {
  //   this.applicationForm
  //     .get('foreignLangCertScore')
  //     .patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
  // }

  scoreCheck() {
    if (
      this.sertidTypeFloat == true &&
      this.applicationForm.get('foreignLangCertScore').value > 9
    ) {
      this.applicationForm
        .get('foreignLangCertScore')
        .patchValue(
          this.applicationForm.get('foreignLangCertScore').value / 10
        );
    }
  }
}
