<form *ngIf="type == 'sat' || type == 'ib'" [formGroup]="applicationForm" action="">
  <div *ngIf="type == 'sat'">
    <div class="flex p-2 pt-3">
      <div class="">
        <input id="subs" class="" type="checkbox" formControlName="isSubjectsExists">
      </div>
      <label for="subs" class="w-1/3 ml-4">
          {{ 'isSubjectsExists' | translate }}:
      </label>
      
  </div>
  <div class="">

  </div>
  </div>
  
  <div class="mb-2 rounded w-max p-2" [ngClass]="{'border': type == 'sat'}">
    <div class="mb-2 rounded w-max">
      <div class="flex pt-3">
          <div class="w-1/3">
              {{ 'SelectProfileSubjects' | translate }}:
          </div>
          <div class="w-1/3 flex">
            <select (change)="getProfileSubject(0)" formControlName="profileSubjectPairId" class="w-full mb-3 pl-1 bg-gray-200 rounded">
                  <option disabled [value]="0">
                      {{ 'Subject' | translate }}
                  </option>
                  <option *ngFor="let item of profileSubjectList" [value]="item.id">
                      <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                      <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                  </option>
              </select>
          </div>
      </div>
    </div>
      <div formArrayName="subjects"  class="flex">
        <div class="w-1/3">
          <span *ngIf="type == 'sat'">
            {{ 'SelectProfileSubjectsSat' | translate }}:
           </span>
           <span *ngIf="type == 'ib'">
            {{ 'SelectProfileSubjectsIb' | translate }}:
           </span>
        </div>
          <div class="w-2/3" [ngClass]="{'pl-3': type == 'sat'}">
              <div class="w-1/3 flex" [formGroupName]="i" *ngFor="let subject of subjects.controls; let i=index">
                 <div class="mr-1">{{ i + 1 }}.</div> <select formControlName="id" class="w-full mb-3 pl-1 bg-gray-200 rounded">
                      <option disabled [value]="0">
                          {{ 'Subject' | translate }}
                      </option>
                      <option *ngFor="let item of subjectList" [disabled]="checkSubject()" [value]="item.id">
                          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                      </option>
                  </select>
                  <div class="w-2/6">
                    <input [attr.disabled]="checkSubject()" placeholder="{{ 'Ball' | translate }}" class="w-full ml-3 mb-3 pl-1 bg-gray-200 rounded" type="text" formControlName="score" [mask]="ball">
                  </div>
              </div>
              
          </div>
      </div>
      
  </div>
</form>
  
  
  
  
  
  <div class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
      <form class="flex flex-wrap" [formGroup]="editAppealForm" (ngSubmit)="onSubmitEdit()">
          <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile('edit')">Добавить файл</button>
          <span class="add-files-scroll">
          <div formArrayName="additionalFiles" class="w-full mt-4 controls-border" *ngFor="let control of editAdditionalFiles.controls; index as i">
            <div [formGroup]="editAdditionalFiles.controls[i]">
              <!-- Описание файла: <input placeholder="Описание файла" formControlName="description" class="w-10/12 ml-3 mb-3 rounded border p-2"> -->
              <span> Имя файла: <input disabled class="mb-4 p-2 w-full" type="text"  readonly formControlName="filename"></span>
              <div class="f-btn-group">
                <div class="relative">
                  <input (change)="changeListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                  <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                    <p>
                      <!-- <svg *ngIf="dwnldCheck(i); else elseBlock" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" class="edit-icon svg-inline--fa fa-pen fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/></svg> -->
                      <span *ngIf="dwnldCheck(i); else elseBlock"> Заменить файл</span>
                      <!-- <svg #elseBlock xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M380.032,133.472l-112-128C264.992,2.016,260.608,0,256,0c-4.608,0-8.992,2.016-12.032,5.472l-112,128    c-4.128,4.736-5.152,11.424-2.528,17.152C132.032,156.32,137.728,160,144,160h64v208c0,8.832,7.168,16,16,16h64    c8.832,0,16-7.168,16-16V160h64c6.272,0,11.968-3.648,14.56-9.376C385.152,144.896,384.192,138.176,380.032,133.472z"/></g></g><g><g><path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"/></g> </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> -->
                      <ng-template #elseBlock><span> Загрузить файл</span></ng-template>
                    </p>
                  </div>
                  <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* Файл не добавлен</p>
                </div>
        
                <!-- <button type="button" title="Скачать ранее загруженный файл" class="dwnld-btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" (click)="downloadFile(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" class="dwnld-icon svg-inline--fa fa-download fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/></svg>
                Скачать файл
                </button> -->
              </div>
              <div class="s-btn-group">
                <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
                </button>
              </div>
            </div>
          </div>
         </span>
      </form>
  </div>
  
  <button  (click)="postApplication()" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-4">
      {{ 'Edit' | translate }}
  </button>
   