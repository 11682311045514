    <div *ngIf="app.moduleType?.id == 4" class="bg-blue-100 border-blue-500 zayav border-2 rounded mb-4 p-3">
    <div class="flex w-full justify-between test-type-name">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>
        <div class="text-lg text-green-400 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.testStatus.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.testStatus.name.ru }}</span>
        </div>
    </div>


    <div class="flex w-full justify-between test-type-name">
        <div class="py-2 font-bold w-1/2">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.appType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.appType.name.ru }}</span>
        </div>
        <div class="py-2 font-bold">
         {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">

                <div class="px-3 text-gray-600">
                    <!-- <div *ngIf="app.moduleType.id == 4" class="py-2 font-bold">
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.appType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.appType.name.ru }}</span>
                    </div> -->

                     <div class="mb-2">
                        {{ 'IKT' | translate }} : <input disabled readonly class="bg-transparent" [value]="app.formattedId">
                    </div>

                    <div class="mb-2">
                        {{ 'IIN' | translate }} : {{ app.iin}}
                    </div>
                    <div class="mb-2">
                        {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                    </div>
                    <div *ngIf="!!app.eduInstitute.name.ru" class="mb-2">
                        {{ 'VuzName' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.eduInstitute.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.eduInstitute.name.ru }}</span>
                    </div>
                    <div *ngIf="!!app.eduProgram.name.ru" class="mb-2">
                        {{ 'Gop' | translate }} : {{ app.eduProgram.code}} -
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.eduProgram.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.eduProgram.name.ru }}</span>
                    </div>
                    <div *ngIf="!!app.foreignLang.name.ru" class="mb-2">
                        {{ 'ForeignLang' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.foreignLang.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.foreignLang.name.ru }}</span>
                    </div>
                    <div *ngIf="app.foreignLangCertType.id > 0" class="mb-2">
                        {{ 'InternationalEnglishCertificate' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.foreignLangCertType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.foreignLangCertType.name.ru }}</span>
                        - {{ app.foreignLangCertScore }}
                    </div>
                    <div *ngIf="!!app.masterType.name.ru" class="mb-2">
                        {{ 'DirectionOfTraining' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.masterType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.masterType.name.ru }}</span>
                    </div>
                    <div class="mb-2">
                        {{ 'EduLevel' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.studyType.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.studyType.name.ru }}</span>
                    </div>
                    <div class="mb-2">
                        {{ 'TestCitySite' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.testCity.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.testCity.name.ru }}</span>
                    </div>
                    <div class="mb-2">
                        {{ 'TestLang' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.testLang.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.testLang.name.ru }}</span>
                    </div>
                    <div *ngIf="!!app.tgoLang.name.kk" class="mb-2">
                        {{ 'TgoLang' | translate }} :
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.tgoLang.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.tgoLang.name.ru }}</span>
                    </div>
                    <div *ngIf="app.isTestLocationShowed == false && app.isTestLocationShowed != undefined && !showTestLocation">
                        <button type="button" (click)="showTestDate(app.id)" class="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {{ 'SeenTestDate' | translate }}
                            <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div *ngIf="app.testPeriod && (app.isTestLocationShowed || showTestLocation)" class="mb-2">
                       {{ 'TestDate' | translate }}:
                      <span class="font-bold"> {{ app.testPeriod.testDate }} {{ app.testPeriod.startTime }} ({{ 'startTime' | translate }}: {{ app.testPeriod.enterTime }})</span>
                    </div>

                    <!-- <div *ngIf="app.testPeriod && (app.isTestLocationShowed || showTestLocation)" class="mb-2">
                        {{ 'AppealDate' | translate }}:
                        <span class="font-bold"> {{ app.testPeriod.appealDateTimePeriod }}</span>
                    </div> -->

                    <div *ngIf="app.testOrg && (app.isTestLocationShowed || showTestLocation)" class="mb-2">
                        {{ 'TestOrg' | translate }}:
                        <span *ngIf="translate.currentLang == 'kz'">{{ app.testOrg.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ app.testOrg.name.ru }}</span>
                    </div>

                        <div *ngIf="app.testStatus.id == 15" class="text-red-700">

                                <p>
                                    {{ 'pvonedopusktxt' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt1' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt2' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt3' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt4' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt5' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt6' | translate }}
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt7' | translate }} <a class="text-blue-600" href="mailto:uto@testcenter.kz">uto@testcenter.kz</a><span *ngIf="translate.currentLang == 'kz'"> жіберу қажет.</span>
                                </p>

                                <p>
                                    {{ 'pvonedopusktxt8' | translate }} <a class="text-blue-600" href="http://www.testcenter.kz/ru/contacts/vozvrat-denezhnykh-sredstv/">http://www.testcenter.kz/ru/contacts/vozvrat-denezhnykh-sredstv/</a>
                                </p>

                        </div>

                        <!-- <div *ngIf="app.appType.id == 1  && app.testStatus.id != 15" class="text-red-700">

                            {{ 'ashyqtxtm' | translate }}

                        </div>

                        <div *ngIf="app.appType.id == 6  && app.testStatus.id != 15" class="text-red-700">

                            {{ 'ashyqtxtd' | translate }}

                        </div>

                        <div *ngIf="app.appType.id == 8 && app.testStatus.id != 15" class="text-red-700">

                            {{ 'ashyqtxtf' | translate }}

                        </div> -->

                    <div *ngIf="app.testStatus.id == 9 || app.testStatus.id == 7 || app.testStatus.id == 6 || app.testStatus.id == 11 || app.testStatus.id == 10 " class="pc-version">
                        <div *ngIf="app.appType.id != 6" class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                            {{ 'testResults' | translate }}
                        </div>
                      <div *ngIf="app.appType.id == 6" class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                        {{ 'testResAppType6' | translate }}
                      </div>

                        <table  class="table table-bordered">
                            <tr class="text-center">
                                <td class="w-6/12">
                                    {{ 'Subject' | translate }}
                                </td>
                                <td class="w-2/12">
                                    {{ 'testBall' | translate }}
                                </td>
                                <td *ngIf="app.testStatus.id == 7" class="w-2/12">
                                    {{ 'appealBall' | translate }}
                                </td>
                                <td class="w-2/12">
                                    {{ 'subjectBall' | translate }}
                                </td>
                            </tr>
                            <tr *ngFor="let sub of app.subjects; index as num">
                                <td>
                                    <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>
                                    <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                                </td>
                                <td class="text-center">
                                    {{ sub.testScore }}
                                </td>
                                <td *ngIf="app.testStatus.id == 7" class="text-center">
                                    {{ sub.appealScore }}
                                </td>
                                <td class="text-center">
                                    {{ sub.totalScore }}
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="app.testStatus.id != 7" colspan="2" class="w-3/4">
                                    {{ 'totalScore' | translate }}
                                </td>
                                <td *ngIf="app.testStatus.id == 7 || app.testStatus.id == 11" colspan="3" class="w-3/4">
                                    {{ 'totalScore' | translate }}
                                </td>
                                <td class="text-center">
                                    {{ app.totalScore }}
                                </td>
                            </tr>
                        </table>
                    </div>



                     <div *ngIf="app.testStatus.id == 9 || app.testStatus.id == 7 || app.testStatus.id == 6 || app.testStatus.id == 11 || app.testStatus.id == 10 " class="mobile-version">
                       <div *ngIf="app.appType.id != 6" class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                         {{ 'testResults' | translate }}
                       </div>
                       <div *ngIf="app.appType.id == 6" class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                         {{ 'testResAppType6' | translate }}
                       </div>v

                        <table class="table table-bordered">
                            <thead *ngFor="let sub of app.subjects; index as num">
                                <tr>
                                    <td class="bg-blue-300 text-center">
                                        {{ 'Subject' | translate }}
                                    </td>
                                    <td *ngIf="app.testStatus.id == 7" colspan="3" class="bg-blue-200 text-center font-bold">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                                    </td>
                                    <td *ngIf="app.testStatus.id != 7" colspan="2" class="bg-blue-200 text-center font-bold">
                                        <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru  }}</span>
                                        <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk  }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ 'testBall' | translate }}
                                    </td>
                                    <td *ngIf="app.testStatus.id == 7" class="text-center">
                                        {{ 'appealBall' | translate }}
                                    </td>
                                    <td class="text-center">
                                        {{ 'subjectBall' | translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        {{ sub.testScore }}
                                    </td>
                                    <td *ngIf="app.testStatus.id == 7" class="text-center">
                                        {{ sub.appealScore }}
                                    </td>
                                    <td class="text-center">
                                        {{ sub.totalScore }}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td *ngIf="app.testStatus.id == 7" colspan="2" class="bg-blue-300 text-center">
                                        {{ 'totalScore' | translate }}
                                    </td>
                                    <td *ngIf="app.testStatus.id != 7" colspan="1" class="bg-blue-300 text-center">
                                        {{ 'totalScore' | translate }}
                                    </td>
                                    <td class="bg-blue-300 text-center">
                                        {{ app.totalScore }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                </div>
                <div class="flex w-full justify-end mt-2 zayav-buttons">
                    <button (click)="checkCertificate(app.id, app.iin, app.testPeriod.testYear, 2)" *ngIf="app.testStatus.id == 7" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'CheckCertificate' | translate }}
                    </button>
                    <button *ngIf="app.testStatus.id == 8" (click)="showPayment(app.id)" class="bg-blue-400 ml-3 tracking-wide w-64 hover:bg-blue-700 zayav-button text-white font-hairline py-1 px-3 rounded">
                        {{ 'Pay' | translate }}
                    </button>
                    <button *ngIf="app.appType.canChangeApp && (app.testStatus.id == 8 || app.testStatus.id == 9)" (click)="showEditEnt(app.id, app.appType.id)" class="bg-blue-400 zayav-button ml-3 tracking-wide w-64 hover:bg-blue-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'EditApplication' | translate }}
                    </button>
                    <button *ngIf="app.appType.canDeleteApp && (app.testStatus.id == 9 || app.testStatus.id == 8)" (click)="showDeleteModal()" class="bg-red-400 zayav-button ml-3 tracking-wide w-64 hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'DeleteApplication' | translate }}
                    </button>
                    <button *ngIf="(app.testStatus.id == 9 || app.testStatus.id == 8) && app.appType.canChangeAppType" (click)="showAppTypeModal()" class="bg-green-400 zayav-button ml-3 tracking-wide w-auto hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                        {{ 'EditAppType' | translate }}
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z"/></svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z"/></svg>
    </div>

</div>
