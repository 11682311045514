import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from "../../service/api.service"
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-from-reg-confirm',
  templateUrl: './from-reg-confirm.component.html',
  styleUrls: ['./from-reg-confirm.component.css']
})
export class FromRegConfirmComponent implements OnInit {

  loading = true;
  requestId: number;
  verificationCode: number;
  confirmData: FormGroup;

  constructor(private route: ActivatedRoute,
    public translate: TranslateService,
    private _notifications: NotificationsService,
    private router: Router,
    private token: TokenService,
    private fb: FormBuilder,
    public api: ApiService,) { 
    this.route.queryParams.subscribe(res => {
      this.requestId = res['id'];
      this.verificationCode = res['code'];
      this.confirmData = this.fb.group({
        id: [this.requestId],
        verificationCode: [this.verificationCode]
      });
      let data = this.confirmData.value;
      this.api.registerConfirmation(data)
          .subscribe((res: any) => {
            this.token.authorizeToken(res.data.token, '0');
            this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Вы подтвердили электронную почту', NotificationType.Success, 5000) : this._notifications.create('Қате', 'Қате', NotificationType.Success, 5000);
            this.router.navigate(['/user-info']);
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Внимание', err.error.errorMessage.ru, NotificationType.Info, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Info, 5000);
            this.router.navigate(['/auth']);
          });
        
    });
  }

  ngOnInit(): void {

    

    setTimeout(() => {
      this.loading = false;
      }, 150);

  }

}
