<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<router-outlet *ngIf="router.url != '/profile/history'"></router-outlet>

<div *ngIf="router.url == '/profile/history'" class="">

    <!-- <div *ngIf="applications.length == 0" class="text-center text-3xl text-green-500 uppercase my-12">
        {{ 'YouHaveNoApplications' | translate }}!
    </div> -->


    <div *ngIf="deleteModal" class="">
        <div class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed">

        </div>
        <div class="text-center border-2 p-4 realy-delete fixed z-30 w-1/3 bg-white">
            <p class="mb-8">
                {{ 'SureDeleteApplication' | translate }}?
            </p>
            <div class="flex justify-around w-64 mx-auto">
                <button (click)="deleteApplication()"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {{ 'Yes' | translate }}
                </button>
                <button (click)="deleteModal = !deleteModal"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    {{ 'No' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="appealModal" class="">
        <div class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed">

        </div>
        <div class="text-center border-2 p-4 realy-delete fixed z-30 w-1/3 bg-white">
            <p class="mb-8">
                {{ 'cancelAppealConfirm' | translate }}?
            </p>
            <div class="flex justify-around w-64 mx-auto">
                <button (click)="cancelAppeal()"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {{ 'Yes' | translate }}
                </button>
                <button (click)="appealModal = !appealModal"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    {{ 'No' | translate }}
                </button>
            </div>
        </div>
    </div>

<!--     <div class="mb-4 flex bg-blue-200 p-4">-->
<!--        <button class="znak-blue mr-4">-->
<!--            !-->
<!--        </button>-->
<!--        <div class="text-blue-600">-->
<!--            {{ 'ForENTInfo' | translate }}-->
<!--            <div class="text-blue-600 mt-4">-->
<!--                {{ 'ForCertifENTInfo' | translate }}-->
<!--            </div>-->
<!--        </div>-->

<!--     </div>-->

    <!--   <div class="mb-4 flex bg-blue-200 p-4">
          <button class="znak-blue mr-4">
              !
          </button>
          <div class="text-blue-600">
              {{ 'PZtext' | translate }}
              <div class="text-blue-600 mt-4">
                <a href=" https://testcenter.kz/ru/postupayushchim-v-magistraturu-i-doktoranturu/priem-na-obuchenie/prisuzhdenie-obrazovatelnogo-granta-dlya-pvo/index.php">https://testcenter.kz/ru/postupayushchim-v-magistraturu-i-doktoranturu/priem-na-obuchenie/prisuzhdenie-obrazovatelnogo-granta-dlya-pvo/index.php</a>

              </div>
          </div>

       </div> -->

    <!-- Сообщение от сервера -->
    <div *ngFor="let m of messages;" class="mb-4">
        <span *ngIf="translate.currentLang == 'kz'">
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(m.content.kk)"></div>
        </span>
        <span *ngIf="translate.currentLang == 'ru'">
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(m.content.ru)"></div>
        </span>
    </div>


    <!-- Сообщение от сервера -->


    <!-- РЕЗУЛЬТАТЫ КОНКУРСА -->

    <app-konkurs-results></app-konkurs-results>

    <!-- РЕЗУЛЬТАТЫ КОНКУРСА ПВО -->

    <app-konkurs-pvo-results></app-konkurs-pvo-results>



    <!--  РЕЗУЛЬТАТЫ НКТ  -->
    <!-- <button type="button" class="uppercase w-full mb-1 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsedNqt" aria-controls="collapseExample">
        НКТ
    </button>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedNqt">
        <div class="card">
            <div class="card-body">
                <div *ngIf="applications.length > 0" class="">
                    <div *ngFor="let item of applications; index as i" class="">
                        <div *ngFor="let app of item; index as j" class="">
                            <app-nqt-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)"></app-nqt-results>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--  РЕЗУЛЬТАТЫ ЕНТ  -->
    <!-- <button type="button" class="uppercase w-full mb-1 text-2xl text-center p-8 border rounded hover:bg-blue-200 cursor-pointer" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsedNqt" aria-controls="collapseExample">
            ЕНТ
        </button>

        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedEnt">
            <div class="card">
                <div class="card-body">

                    <div *ngIf="applications.length > 0" class="">
                        <div *ngFor="let item of applications; index as i" class="">
                            <div *ngFor="let app of item; index as j" class="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


    <div class="no-data" *ngIf="showErrNqt">
        {{ 'NqtErrorData' | translate }}
    </div>

    <div class="no-data" *ngIf="showErrPvo">
        {{ 'PvoErrorData' | translate }}
    </div>

    <div class="no-data" *ngIf="showErrCertif">
        {{ 'CertifErrorData' | translate }}
    </div>

    <div class="no-data" *ngIf="showErrEnt">
        {{ 'EntErrorData' | translate }}
    </div>

    <div class="no-data" *ngIf="showErrKaztest">
        {{ 'KaztestErrorData' | translate }}
    </div>

    <div *ngIf="applications.length > 0" class="">
        <div *ngFor="let app of applications; index as i" class="">
            <!-- <div *ngFor="let app of item; index as j" class=""> -->

            <!--  РЕЗУЛЬТАТЫ НКТ  -->
            <app-nqt-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)"></app-nqt-results>
            <!--  РЕЗУЛЬТАТЫ Тестирования директоров  -->
            <app-dir-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)"></app-dir-results>
            <!--  РЕЗУЛЬТАТЫ ЕНТ  -->
            <app-ent-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)" (cancelAppeal)="showAppealModal(app.id, app.moduleType.id)"
                (editAppType)="showAppTypeModal(app.season.id, app.id, app.appType.id)"></app-ent-results>
            <!-- СЕРТИФИКАТЫ -->
            <app-certif-results [app]="app"
                (deleteApp)="showDeleteModal(app.id, app.moduleType.id)"></app-certif-results>
            <!-- ПВО -->
            <app-pvo-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)"></app-pvo-results>
            <!-- Казтест -->
            <app-kaztest-results [app]="app"></app-kaztest-results>
            <!-- Казтест Zayav -->
            <app-kaztest-zayav-results [app]="app" (deleteApp)="showDeleteModal(app.id, app.moduleType.id)" *ngIf="isDev"></app-kaztest-zayav-results>
            <!-- </div> -->
        </div>
    </div>





</div>

<div *ngIf="showEditAppType" class="overlay mx-auto w-full left-0 text-center z-40 top-0 fixed">
    <div class="border-2 w-3/4 mx-auto border-black rounded bg-white mt-64 p-10">
        <div class="text-xl mb-4 text-blue-400">
            {{ 'SelectCategory' | translate }}
        </div>
        <div class="">
            <form [formGroup]="appTypesForm" action="">
                <select (change)="checkTestPeriod()" formControlName="categoryToChange"
                    class="border-2 rounded border-black p-1 " name="" id="">
                    <option disabled selected [value]="0">
                        {{ 'SelectCategory' | translate }}
                    </option>
                    <option *ngFor="let item of appTypeList; i as index" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
                    </option>
                </select>
            </form>
        </div>

        <div class="flex justify-around mt-4">
            <div (click)="closeEditApp()"
                class="bg-red-400 cursor-pointer ml-3 tracking-wide w-auto hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                {{ 'Cancel' | translate }}
            </div>
            <div (click)="editEntAppType()"
                class="bg-green-400 ml-3 tracking-wide w-auto cursor-pointer hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                {{ 'Editing' | translate }}
            </div>
        </div>

    </div>
</div>
